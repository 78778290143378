import React, { useState, useEffect } from "react"
import PaginationPack from "components/PaginationPack"
import { connect } from "react-redux"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"
// import { requestHours, acceptRequestHours } from ""
import Select from "react-select"
// import './style.scss';

// import ReactPaginate from 'react-paginate';

import Images from "utils/Images"
import {
  acceptRequestHours,
  requestHours
} from "Containers/ExpertDesign/Screens/Engagement/redux/actions"
import { Toaster } from "react-hot-toast"
// import { requestHours } from "Containers/ExpertDesign/Screens/Engagement/redux/actions"

function RequestedHours(props) {
  const { hoursResult, requestHoursRequesting, acceptRequestHoursRequesting } =
    props

  const [business, setBusiness] = useState(false)

  const [deny, setDeny] = useState(false)
  const [accept, setAccept] = useState(false)

  const [modal1, setModal1] = useState(false)

  const toggle1 = () => setModal1(!modal1)

  useEffect(() => {
    props.requestHours(1)
  }, [])

  const handlePageChange = page => {
    props.requestHours(page)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Requested Hours
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  style={{
                    border: "3px solid transparent"
                  }}
                >
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Requested Hours
                    </th>
                  </tr>

                  <tbody>
                    {requestHoursRequesting ? (
                      <div
                        style={{
                          height: " 200px",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : hoursResult && hoursResult?.results.length > 0 ? (
                      hoursResult?.results.map(item => {
                        return (
                          <>
                            <tr
                              onClick={() => {
                                setBusiness(item)
                                toggle1()
                              }}
                              style={{
                                backgroundColor: "white",
                                borderBottomLeftRadius: "8px",
                                borderTopLeftRadius: "8px",
                                marginLeft: "25px",
                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  padding: "25px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                  cursor: "pointer"
                                }}
                              >
                                {`${item.engagement_detail?.expert_detail.name} has requested ${item.hours} hours to add ${item.increase_hour}!`}
                              </td>
                            </tr>

                            <tr style={{ height: "12px" }}></tr>
                          </>
                        )
                      })
                    ) : (
                      <h6 style={{ textAlign: "center" }}>No Data</h6>
                    )}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {hoursResult && hoursResult?.results?.length > 0 ? (
                    requestHoursRequesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={hoursResult?.page_size}
                        totalItemsCount={hoursResult?.total_objects}
                        activePage={hoursResult?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal1} toggle1={toggle1}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Requested Hours
          </h5>
          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Requested Hours Description
              </Label>
              <Input
                disabled
                // defultValue="Client agreement title..."
                placeholder={`${business.engagement_detail?.expert_detail.name} has requested ${business.hours} hours to add ${business.increase_hour}!`}
                type="textarea"
                style={{ backgroundColor: "#EFEFEF" }}
              />
            </FormGroup>
          </Form>
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={() => {
                const data = {
                  id: business.id,
                  form: { accepted: "false" }
                }
                props.acceptRequestHours(data, toggle1)
                setDeny(true)
                setAccept(false)
              }}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "10px"
              }}
            >
              {deny && acceptRequestHoursRequesting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Deny"
              )}
            </Button>
            <Button
              onClick={() => {
                const data = {
                  id: business.id,
                  form: { accepted: "true" }
                }
                props.acceptRequestHours(data, toggle1)
                setAccept(true)
                setDeny(false)
              }}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{ backgroundColor: "#3A0F7D" }}
            >
              {accept && acceptRequestHoursRequesting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Accept"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  hoursResult: state.Engagement.hoursResult,
  requestHoursRequesting: state.Engagement.requestHoursRequesting,
  acceptedHoursResult: state.Engagement.acceptedHoursResult,
  acceptRequestHoursRequesting: state.Engagement.acceptRequestHoursRequesting
})

const mapDispatchToProps = dispatch => ({
  requestHours: data => dispatch(requestHours(data)),
  acceptRequestHours: (data, toggle) =>
    dispatch(acceptRequestHours(data, toggle))
})
export default connect(mapStateToProps, mapDispatchToProps)(RequestedHours)
