import {
  POST_TIMETRACKER_DATA,
  POST_TIMETRACKER_DATA_SUCCESS,
  POST_TIMETRACKER_DATA_FAILURE,
  GET_ENGAGEMENT_DATA,
  GET_ENGAGEMENT_DATA_SUCCESS,
  GET_ENGAGEMENT_DATA_FAILURE
} from "./types"
const initialState = {
  engagementData: false,
  requesting: false,
  timeTrackRequesting: false,
  error: false,
  timeTrackerSuccess: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case POST_TIMETRACKER_DATA:
      return {
        ...state,
        timeTrackRequesting: true
      }

    case POST_TIMETRACKER_DATA_SUCCESS:
      return {
        ...state,
        timeTrackRequesting: false,
        timeTrackerSuccess: action.data
        // error: false
      }

    case POST_TIMETRACKER_DATA_FAILURE:
      return {
        ...state,
        timeTrackRequesting: false,
        error: action.data
      }

    case GET_ENGAGEMENT_DATA:
      return {
        ...state,
        requesting: true
      }

    case GET_ENGAGEMENT_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        engagementData: action.data,

        error: false
      }

    case GET_ENGAGEMENT_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
