import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FALUIRE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FALUIRE,
  FEEDBACK_REQUEST,
  FEEDBACK_REQUEST_SUCCESS,
  FEEDBACK_REQUEST_FALUIRE,
  TERMS_AND_CONDITION,
  TERMS_AND_CONDITION_SUCCESS,
  PRIVICY_POLICY,
  PRIVICY_POLICY_SUCCESS,
  NOTIFICATION_SETTINGS,
  NOTIFICATION_SETTINGS_SUCCESS,
  NOTIFICATION_SETTINGS_FALUIRE
} from "./types"

const initialState = {
  user: false,
  requesting: false,
  error: false,
  userType: false,
  notificationData: false,
  termsConditions: [],
  privicyPolicy: [],
  feedBack: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ACCOUNT:
      return {
        ...state,
        requesting: true
      }

    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        requesting: false
        // user: action.data
      }

    case DELETE_ACCOUNT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        requesting: true
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false
        // user: action.data
      }

    case CHANGE_PASSWORD_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case FEEDBACK_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        feedBack: action.data
      }

    case FEEDBACK_REQUEST_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case NOTIFICATION_SETTINGS:
      return {
        ...state,
        requesting: true
      }

    case NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        requesting: false
        // feedBack: action.data
      }

    case NOTIFICATION_SETTINGS_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case TERMS_AND_CONDITION:
      return {
        ...state,
        requesting: true
      }
    case TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        requesting: false,
        termsConditions: [action.data]
      }
    case PRIVICY_POLICY:
      return {
        ...state,
        requesting: true
      }

    case PRIVICY_POLICY_SUCCESS:
      return {
        ...state,
        requesting: false,
        privicyPolicy: [action.data]
      }

    default:
      return state
  }
}
