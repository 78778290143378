import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { GET_CONTRACT_REQUEST, DOWNLOAD_CONTRACT_REQUEST } from "./types"

// actions
import {
  getContractSuccess,
  getContractFaluire,
  downloadContractSuccess,
  downloadContractFaluire
} from "./actions"

async function getContractAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/contracts_listing/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getContractFunction({ data }) {
  try {
    const response = yield call(getContractAPI, data)
    yield put(getContractSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getContractFaluire(response?.data))
  }
}

async function downloadContractAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/signed_contract/?sig_id=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET",
    data
  }

  return XHR(URL, options)
}

function* downloadContractFunction({ data }) {
  try {
    const response = yield call(downloadContractAPI, data)
    if (response?.data?.file_url) {
      var element = document.createElement("a")
      element.setAttribute("href", response?.data?.file_url)
      element.setAttribute("download", "contract.pdf")
      element.style.display = "none"
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      toast.success("Success")
    }
    yield put(downloadContractSuccess(response?.data))
    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e
    yield put(downloadContractFaluire(response?.data))
  }
}

export default all([
  takeLatest(GET_CONTRACT_REQUEST, getContractFunction),
  takeLatest(DOWNLOAD_CONTRACT_REQUEST, downloadContractFunction)
])
