import {
  ALL_BUSINESS_REQUEST,
  ALL_BUSINESS_SUCCESS,
  ALL_BUSINESS_FALUIRE,
  PATCH_BUSINESS_PROFILE,
  PATCH_BUSINESS_PROFILE_SUCCESS,
  PATCH_BUSINESS_PROFILE_FALUIRE,
  SHARE_BUSINESS_WITH_EXPERT,
  SHARE_BUSINESS_WITH_EXPERT_SUCCESS,
  SHARE_BUSINESS_WITH_EXPERT_FAILURE,
  GET_EXPERTS_NAMES,
  GET_EXPERTS_NAMES_SUCCESS,
  GET_EXPERTS_NAMES_FALUIRE,
  SEARCH_BUSINESS_REQUEST,
  SEARCH_BUSINESS_SUCCESS,
  SEARCH_BUSINESS_FALUIRE,
  ONBOARDING_LINK_REQUEST,
  ONBOARDING_LINK_SUCCESS,
  ONBOARDING_LINK_FAILURE
} from "./types"

const initialState = {
  allAccounts: false,
  requesting: false,
  error: false,
  allExpertsData: false,
  onBoardRequesting: false,
  onBoardingLink: false,
  shareSuccess: false,
  sendOpportunityLoader: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_BUSINESS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case ALL_BUSINESS_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allAccounts: action.data
      }

    case ALL_BUSINESS_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case PATCH_BUSINESS_PROFILE:
      return {
        ...state,
        requesting: true
      }

    case PATCH_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false
      }

    case PATCH_BUSINESS_PROFILE_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_EXPERTS_NAMES:
      return {
        ...state,
        requesting: true
      }

    case GET_EXPERTS_NAMES_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allExpertsData: action.data
      }

    case GET_EXPERTS_NAMES_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case SHARE_BUSINESS_WITH_EXPERT:
      return {
        ...state,
        sendOpportunityLoader: true
      }

    case SHARE_BUSINESS_WITH_EXPERT_SUCCESS:
      return {
        ...state,
        error: false,
        sendOpportunityLoader: false,
        shareSuccess: action.data
      }

    case SHARE_BUSINESS_WITH_EXPERT_FAILURE:
      return {
        ...state,
        sendOpportunityLoader: false,
        error: action.data
      }

    case SEARCH_BUSINESS_REQUEST:
      return {
        ...state,
        searchRequesting: true
      }

    case SEARCH_BUSINESS_SUCCESS:
      return {
        ...state,
        error: false,
        searchRequesting: false,
        allAccounts: action.data
      }

    case SEARCH_BUSINESS_FALUIRE:
      return {
        ...state,
        searchRequesting: false,
        error: action.data
      }

    case ONBOARDING_LINK_REQUEST:
      return {
        ...state,
        onBoardRequesting: true
      }

    case ONBOARDING_LINK_SUCCESS:
      return {
        ...state,
        error: false,
        onBoardRequesting: false,
        onBoardingLink: action.data
      }

    case ONBOARDING_LINK_FAILURE:
      return {
        ...state,
        onBoardRequesting: false,
        error: action.data
      }

    default:
      return state
  }
}
