import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Modal
} from "reactstrap"
import Select from "react-select"
// import './style.scss';

import PaginationPack from "components/PaginationPack"

// import ReactPaginate from 'react-paginate';

import Images from "utils/Images"
import { Link } from "react-router-dom"

import useForm from "../../../../../utils/useForm"
import validator from "../../../../../utils/validation"

import toast, { Toaster } from "react-hot-toast"

function TimeTrackingLogs(props) {
  const stateSchema = {
    reasonText: {
      value: "",
      error: ""
    },
    date: {
      value: "",
      error: ""
    },
    time: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    reasonText: {
      required: true
      // validator: validator.email
    },
    date: {
      required: true
      //   validator: validator.password,
    },
    time: {
      required: true
      //   validator: validator.password,
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSubmit = () => {
    if (state.reasonText.value && state.date.value && state.time.value) {
    } else {
      toast.error("All Fields are required")
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    <img
                      onClick={() => props.history.goBack()}
                      style={{
                        height: "40px",
                        width: "40px",
                        marginRight: "20px"
                      }}
                      src={Images.back_button}
                    />
                    Requested Hours
                  </CardTitle>
                  <Button
                    className="btn-round btn"
                    style={{
                      backgroundColor: "#fff",
                      color: "#3A0F7D",
                      border: "1px solid  #3A0F7D"
                    }}
                  >
                    <img
                      style={{
                        height: "12px",
                        width: "12px",
                        // marginLeft: "20px",
                        marginRight: "4px",
                        marginTop: "-3px",
                        borderRadius: "0px"
                      }}
                      src={Images.LogLight}
                    />
                    Export to CSV
                  </Button>
                </Row>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TimeTrackingLogs
