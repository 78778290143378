import {
  LOGHOUR_REQUEST,
  LOGHOUR_SUCCESS,
  LOGHOUR_FALUIRE,
  PAGE_REQUEST,
  PAGE_SUCCESS,
  PAGE_FALUIRE,
  EXPORT_CSV_REQUEST,
  EXPORT_CSV_REQUEST_SUCCESS,
  EXPORT_CSV_REQUEST_FALUIRE,
  LOG_HOUR_SORT_REQUEST,
  LOG_HOUR_SORT_SUCCESS,
  LOG_HOUR_SORT_FALUIRE,
  LOG_HOUR_FILTER_REQUEST,
  LOG_HOUR_FILTER_FALUIRE,
  LOG_HOUR_FILTER_SUCCESS,
  FILTER_CLIENTS_REQUEST,
  FILTER_CLIENTS_SUCCESS,
  FILTER_CLIENTS_FALUIRE,
  FILTER_ENGAGEMENTS_REQUEST,
  FILTER_ENGAGEMENTS_SUCCESS,
  FILTER_ENGAGEMENTS_FALUIRE
} from "./types"

export const logOurRequest = data => ({
  type: LOGHOUR_REQUEST,
  data
})

export const logOurSuccess = data => ({
  type: LOGHOUR_SUCCESS,
  data
})

export const logOurFaluire = data => ({
  type: LOGHOUR_FALUIRE,
  data
})

export const pageRequest = data => ({
  type: PAGE_REQUEST,
  data
})

export const pageSuccess = data => ({
  type: PAGE_SUCCESS,
  data
})

export const pageFaluire = data => ({
  type: PAGE_FALUIRE,
  data
})

export const exportCSVRequest = data => ({
  type: EXPORT_CSV_REQUEST,
  data
})

export const exportCSVSuccess = data => ({
  type: EXPORT_CSV_REQUEST_SUCCESS,
  data
})

export const exportCSVFaluire = data => ({
  type: EXPORT_CSV_REQUEST_FALUIRE,
  data
})

export const sortRequest = () => ({
  type: LOG_HOUR_SORT_REQUEST
})

export const sortRequestSuccess = data => ({
  type: LOG_HOUR_SORT_SUCCESS,
  data
})

export const sortRequestFaluire = data => ({
  type: LOG_HOUR_SORT_FALUIRE,
  data
})

export const filterRequest = () => ({
  type: LOG_HOUR_FILTER_REQUEST
})

export const filterRequestSuccess = data => ({
  type: LOG_HOUR_FILTER_SUCCESS,
  data
})

export const filterRequestFaluire = data => ({
  type: LOG_HOUR_FILTER_FALUIRE,
  data
})

export const filterClientsRequest = () => ({
  type: FILTER_CLIENTS_REQUEST
})

export const filterClientsSuccess = data => ({
  type: FILTER_CLIENTS_SUCCESS,
  data
})

export const filterClientsFaluire = data => ({
  type: FILTER_CLIENTS_FALUIRE,
  data
})

export const filterEngagementRequest = () => ({
  type: FILTER_ENGAGEMENTS_REQUEST
})

export const filterEngagementSuccess = data => ({
  type: FILTER_ENGAGEMENTS_SUCCESS,
  data
})

export const filterEngagementFaluire = data => ({
  type: FILTER_ENGAGEMENTS_FALUIRE,
  data
})
