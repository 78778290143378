import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../../../config/app"

// utils
import XHR from "../../../../../utils/XHR"

// types
import { GET_REFER_REQUEST, REFER_REQUEST } from "./types"

// actions
import {
  getReferSuccess,
  referSuccess,
  referFaluire,
  getReferFailure
} from "./actions"

async function getReferAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/refer/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}
function* getRefer({ data }) {
  try {
    const response = yield call(getReferAPI, data)
    yield put(getReferSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getReferFailure(response?.data))
  }
}

async function referAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/refer/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* history({ data }) {
  try {
    const response = yield call(referAPI, data)
    yield put(referSuccess(response))
    if (response?.data.referral_type == "Expert") {
      toast.success(`Success`)
    } else {
      toast.success(`Success`)
    }
  } catch (e) {
    const { response } = e
    yield put(referFaluire(response))
  }
}

export default all([
  takeLatest(GET_REFER_REQUEST, getRefer),
  takeLatest(REFER_REQUEST, history)
])
