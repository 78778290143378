import React, { useState } from "react"
import { Link } from "react-router-dom"
// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import Select from "react-select"

function ResourceArea() {
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle2 = () => setModal2(!modal2)

  const handleSubmit = () => { }

  return (
    <>
      <div className="content">
        <div className="header text-left">
          <h3
            className="title"
            style={{
              color: "#CACACA",
              fontFamily: "Libre Caslon Text",
              fontWeight: "700",
              fontSize: "22px"
            }}
          >
            Resources
          </h3>
        </div>
        <div style={{ marginTop: "-30px" }}>
          <Row>
            <Col md="12">
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "2px solid #E2E2E2",
                    borderRadius: "10px"
                  }}
                >
                  <CardHeader>
                    <CardTitle
                      className="text-center"
                      style={{ marginTop: "0px" }}
                    >
                      Fill up your Tax Information
                    </CardTitle>
                  </CardHeader>

                  <CardFooter>
                    <Button
                      className="btn-round text-capitalize"
                      color="secondary"
                      style={{
                        backgroundColor: "#3A0F7D",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                      }}
                    // onClick={toggle}
                    >
                      Tax Information
                    </Button>
                  </CardFooter>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{}}>
          <Row>
            <Col md="12">
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "2px solid #E2E2E2",
                    borderRadius: "10px"
                  }}
                >
                  <CardHeader>
                    <CardTitle
                      className="text-center"
                      style={{ marginTop: "0px" }}
                    >
                      Manage your Freelance Money
                    </CardTitle>
                  </CardHeader>

                  <CardFooter>
                    <Button
                      className="btn-round text-capitalize"
                      color="secondary"
                      style={{
                        backgroundColor: "#3A0F7D",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                      }}
                    // onClick={toggle2}
                    >
                      Manage Money
                    </Button>
                  </CardFooter>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{}}>
          <Row>
            <Col md="12">
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "2px solid #E2E2E2",
                    borderRadius: "10px"
                  }}
                >
                  <CardHeader>
                    <CardTitle
                      className="text-center"
                      style={{ marginTop: "0px" }}
                    >
                      Manage your Contracts
                    </CardTitle>
                  </CardHeader>

                  <CardFooter>
                    <Link to="/Expert/manage_contracts">
                      <Button
                        className="btn-round text-capitalize"
                        color="secondary"
                        style={{
                          backgroundColor: "#3A0F7D",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        Manage Contract
                      </Button>
                    </Link>
                  </CardFooter>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          isOpen={modal}
          toggle={toggle}
        // className={className}
        // external={externalCloseBtn}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Refer an Expert
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Name of Expert
                </Label>
                <Input placeholder="Type here" type="text" />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Shareable Application Link
                </Label>
                <Input placeholder="https://" type="text" />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Explanation of Incentives
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Description"
                  name="text"
                  type="textarea"
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={toggle}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                height: "49px"
              }}
            >
              Submit
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal2}
          toggle2={toggle2}
        // className={className}
        // external={externalCloseBtn}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Refer a Business
            </h5>
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Name of Expert
                </Label>
                <Input placeholder="Type here" type="text" />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Shareable Application Link
                </Label>
                <Input placeholder="https://" type="text" />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Explanation of Incentives
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Description"
                  name="text"
                  type="textarea"
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle2}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={toggle2}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                height: "49px"
              }}
            >
              Submit
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ResourceArea
