import React, { useState, useRef, useEffect } from "react"
import PictureUpload from "components/CustomUpload/PictureUpload.js"
import Modal from "react-bootstrap/Modal"
// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from "reactstrap"
import Select from "react-select"

import toast, { Toaster } from "react-hot-toast"

// import './style.scss';

// import ReactPaginate from 'react-paginate';

import { Link } from "react-router-dom"
import Images from "utils/Images"
import { connect } from "react-redux"
import { getBusinessUser } from "./redux/action"

function BusinessProfile(props) {
  const { getBusinessUser, BusinessProfileData } = props

  useEffect(() => {
    getBusinessUser()
  }, [])

  return (
    <>
      <Toaster position="top-center" />
      <div className="content">
        <Row>
          <Col md="12">
            <CardTitle
              tag="h4"
              style={{
                fontFamily: "Libre Caslon Text",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "27px",
                color: "#CACACA"
              }}
            >
              Profile
            </CardTitle>
            <CardBody
              style={{
                backgroundColor: "white",
                borderRadius: 8,

                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              <div style={{ margin: "20px" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Link to="/business/business_Edit_profile">
                    <Button
                      className=" btn-round mb-2 text-capitalize"
                      style={{
                        backgroundColor: "white",
                        // padding: "10px 75px 10px 75px",
                        marginBottom: "0px",
                        border: "2px #3A0F7D solid",
                        marginTop: "0px",

                        // borderRadius: "50px",
                        color: "#3A0F7D",
                        cursor: "pointer",
                        fontWeight: "700",
                        fontSize: "14px",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                      }}
                    >
                      Edit Profile
                    </Button>
                  </Link>
                </div>
                <Row>
                  <Col>
                    <div
                      className="mx-auto my-3"
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <img
                        style={{
                          width: "192px",
                          height: "192px",
                          borderRadius: "50%"
                        }}
                        src={
                          //   allData?.user?.profile_picture
                          //     ? allData?.user?.profile_picture
                          //     :

                          BusinessProfileData?.logo
                            ? BusinessProfileData?.logo
                            : Images.dummy_avatar
                        }
                      />
                    </div>

                    {/* <PictureUpload /> */}
                  </Col>
                </Row>
                <Row
                  style={{ justifyContent: "center" }}
                  className="mt-lg-3 mb-3"
                >
                  <Col sm="12">
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="5">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            whiteSpace: "nowrap"
                          }}
                          sm="6"
                        >
                          Company Name
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder={`${
                              BusinessProfileData?.company_name
                                ? BusinessProfileData?.company_name
                                : ""
                            }`}
                            type="text"
                            // disabled={disabled}
                            style={{ backgroundColor: "#EFEFEF", height: 55 }}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1"></Col>
                      <Col md="5">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            whiteSpace: "nowrap"
                          }}
                          sm="10"
                        >
                          Company Website
                        </Label>
                        <FormGroup>
                          <Input
                            placeholder={`${
                              BusinessProfileData?.website
                                ? BusinessProfileData?.website
                                : ""
                            }`}
                            type="text"
                            // disabled={disabled}
                            style={{ backgroundColor: "#EFEFEF", height: 55 }}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "center" }} className="mt-lg-3">
                  <Col sm="12">
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="5">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            whiteSpace: "nowrap"
                          }}
                          sm="6"
                        >
                          Associated Email
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder={`${
                              BusinessProfileData?.associated_email
                                ? BusinessProfileData?.associated_email
                                : ""
                            }`}
                            // placeholder={`${allData?.user?.name}`}
                            type="text"
                            // disabled={disabled}
                            style={{ backgroundColor: "#EFEFEF", height: 55 }}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1"></Col>
                      <Col md="5">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            whiteSpace: "nowrap"
                          }}
                          sm="10"
                        >
                          Point of Contact
                        </Label>
                        <FormGroup>
                          <Input
                            placeholder={`${
                              BusinessProfileData?.poc
                                ? BusinessProfileData?.poc
                                : ""
                            }`}
                            // placeholder={`${allData?.user?.linkedin_profile_url}`}
                            type="text"
                            // disabled={disabled}
                            style={{ backgroundColor: "#EFEFEF", height: 55 }}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>{" "}
                <Row style={{ justifyContent: "center" }} className="mt-lg-3">
                  <Col sm="12">
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="5">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            whiteSpace: "nowrap"
                          }}
                          sm="6"
                        >
                          Email for Invoice
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder={`${
                              BusinessProfileData?.invoices_email
                                ? BusinessProfileData?.invoices_email
                                : ""
                            }`}
                            // placeholder={`${allData?.user?.name}`}
                            type="text"
                            // disabled={disabled}
                            style={{ backgroundColor: "#EFEFEF", height: 55 }}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1"></Col>
                      <Col md="5">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            whiteSpace: "nowrap"
                          }}
                          sm="10"
                        >
                          Company information
                        </Label>
                        <FormGroup>
                          <Input
                            placeholder={`${
                              BusinessProfileData?.blrub
                                ? BusinessProfileData?.blrub
                                : ""
                            }`}
                            type="text"
                            // disabled={disabled}
                            style={{ backgroundColor: "#EFEFEF", height: 55 }}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  BusinessProfileData: state.BusinessProfile.BusinessProfileData,
  requesting: state.BusinessProfile.requesting
  //  industries: state.Profile.industries,
  //  data: state.HomeInitialProfileV2.data
})
const mapDispatchToProps = dispatch => ({
  getBusinessUser: () => dispatch(getBusinessUser())
  //  getProfileV2Request: () => dispatch(getProfileV2Request()),
  //  getIndustryExperience: () => dispatch(getIndustryExperience()),
  //  postAdditionalExperties: (data, toggle) =>
  //    dispatch(postAdditionalExperties(data, toggle))
})
export default connect(mapStateToProps, mapDispatchToProps)(BusinessProfile)
