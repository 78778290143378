import React, { useState, useEffect } from "react"
import { OS, currentBrowser } from "utils/platform"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap"
import Images from "utils/Images"
import Select, { StylesConfig } from "react-select"

import { registerRequest, registerFaluire } from "./redux/actions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"

import toast, { Toaster } from "react-hot-toast"

import { GoogleLogin } from "react-google-login"
import { gapi } from "gapi-script"
import { LinkedIn } from "react-linkedin-login-oauth2"
import {
  loginViaGoogleRequest,
  loginViaLinkedInRequest
} from "Containers/LoginScreen/redux/actions"

import { BASE_URL } from "config/app"
import { toggleButtonClasses } from "@mui/material"

const RegisterScreen = props => {
  const {
    history,
    registerRequest,
    requesting,
    loginViaGoogleRequest,
    loginViaLinkedInRequest,
    BackendError,
    registerFaluire
    // linkedInCode
  } = props

  const [linkedInToken, setlinkedInToken] = useState(false)
  const [isClick, setIsClick] = useState(false)
  const [check, setCheck] = useState(false)
  const [role, setRole] = useState("")
  const [dropdownValue, setDropDownValue] = useState({})
  const [isAppleBtn, setAppleBtn] = useState(false)

  var DropDownUserType = []

  const toggle = () => {
    setCheck(!check)
  }

  const clientId =
    "1048984404625-3kjheoht0ribe92j49a50kbnmettv0eu.apps.googleusercontent.com"

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    }
    gapi.load("client:auth2", start)
  }, [])

  const responseGoogle = res => {
    const data = {
      access_token: res.accessToken,
      user_type: dropdownValue.label
    }
    loginViaGoogleRequest(data)
  }

  const responseFailGoogle = res => {
    // loginViaGoogleRequest(data)
  }

  useEffect(() => {
    const query = new URLSearchParams(props.location.search)
    const token = query.get("code")
    token && responseLinkedIn(token)
    if (window.opener && window.opener !== window) {
      const query = new URLSearchParams(props.location.search)
      const token = query.get("code")
      // responseLinkedIn(token)
      setlinkedInToken(token)

      setTimeout(() => {
        window.close()
      }, 1000)
    }
  }, [])

  const responseLinkedIn = token => {
    const data = {
      access_token: token,
      user_type: dropdownValue.label
    }
    loginViaLinkedInRequest(data)
  }

  const stateSchema = {
    fullname: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    },
    ConfirmPassword: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullname: {
      required: true
      // validator: validator.email
    },
    email: {
      required: true,
      validator: validator.email
    },
    password: {
      required: true,
      validator: validator.password
    },
    ConfirmPassword: {
      required: true,
      validator: validator.password
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleSignUp = () => {
    setIsClick(true)
    if (check) {
      const data = {
        name: state.fullname.value,
        email: state.email.value,
        password: state.password.value,
        user_type: dropdownValue.label
      }
      if (state.password.value === state.ConfirmPassword.value) {
        registerRequest(data)
      } else {
        toast.error("passwords don’t match")
      }
    } else {
      toast.error("Accept Terms and Condition")
    }
  }
  useEffect(() => {
    registerFaluire(false)
    setState(stateSchema)
    OS(window) == "MacOS" && setAppleBtn(true)
  }, [])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 52,
      border: "1px solid #808080",
      boxShadow: state.isFocused ? "none" : "none",

      "&:hover": {
        border: "1px solid #808080"
      },
      "&:focus": {
        border: "1px solid #808080"
      },
      "&:acitve": {
        border: "1px solid #808080"
      }
    })
  }

  const options = [
    {
      label: "Expert",
      value: 2
    },
    {
      label: "or",
      isDisabled: true
    },
    {
      label: "Business",
      value: 3
    }
  ]

  function handleDropChange(option) {
    setDropDownValue(option)
  }

  const [selected, setSelected] = useState(null)

  return (
    <>
      <Toaster position="top-center" />
      <div className="register-page">
        <Container>
          <Row style={{ justifyContent: "space-between" }}>
            <Col className="mr-auto ml-atuo" lg="6" md="6">
              <img src={require("assets/img/left_images.png")} />
            </Col>

            <Col className="m-0" lg="6" md="6">
              <h5
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  fontFamily: "Libre Caslon Text",
                  fontSize: "30px",
                  fontWeight: "700"
                }}
              >
                Join as Expert or Business
              </h5>
              <Card
                className="card-signup text-center"
                style={{ padding: "30px 20px 0px 20px" }}
              >
                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Form action="" className="form" method="">
                    <label
                      style={{
                        display: "flex",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Full Name
                    </label>
                    <InputGroup>
                      <Input
                        placeholder="Type full name"
                        type="text"
                        style={{ height: "55px", border: "1px solid" }}
                        value={state.fullname.value}
                        onChange={e =>
                          handleOnChange(
                            "fullname",
                            e.target.value,
                            registerFaluire(false)
                          )
                        }
                      />
                    </InputGroup>

                    {BackendError && BackendError?.name ? (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px"
                        }}
                      >
                        {BackendError?.name[0]}
                      </label>
                    ) : state.fullname.error ? (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px"
                        }}
                      >
                        {state.fullname.error}
                      </label>
                    ) : null}

                    <label
                      style={{
                        display: "flex",

                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Email
                    </label>
                    <InputGroup>
                      <Input
                        placeholder="Type email"
                        type="email"
                        style={{ height: "55px", border: "1px solid" }}
                        value={state.email.value}
                        onChange={e =>
                          handleOnChange(
                            "email",
                            e.target.value,
                            registerFaluire(false)
                          )
                        }
                      />
                    </InputGroup>
                    {}

                    {BackendError && BackendError?.email ? (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px"
                        }}
                      >
                        {BackendError?.email[0]}
                      </label>
                    ) : state.email.error ? (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px"
                        }}
                      >
                        {state.email.error}
                      </label>
                    ) : null}

                    <label
                      style={{
                        display: "flex",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Password
                    </label>
                    <InputGroup>
                      <Input
                        placeholder="Type password"
                        type="password"
                        style={{ height: "55px", border: "1px solid" }}
                        value={state.password.value}
                        onChange={e =>
                          handleOnChange(
                            "password",
                            e.target.value,
                            registerFaluire(false)
                          )
                        }
                      />
                    </InputGroup>

                    {}

                    {BackendError && BackendError?.password ? (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px"
                        }}
                      >
                        {BackendError?.password[0]}
                      </label>
                    ) : state.password.error ? (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          // justifyContent: 'flex-start',
                          marginBottom: "0px",
                          textAlign: "left"
                        }}
                      >
                        {state.password.error}
                      </label>
                    ) : null}

                    {/* {(BackendError && BackendError?.password[0]) && (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px"
                        }}
                      >
                        {BackendError?.password[0]}
                      </label>
                    )} */}

                    <label
                      style={{
                        display: "flex",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Confirm Password
                    </label>
                    <InputGroup>
                      <Input
                        placeholder="Type password"
                        type="password"
                        style={{ height: "55px", border: "1px solid" }}
                        value={state.ConfirmPassword.value}
                        onChange={e =>
                          handleOnChange(
                            "ConfirmPassword",
                            e.target.value,
                            registerFaluire(false)
                          )
                        }
                      />
                    </InputGroup>
                    {state.ConfirmPassword.error && (
                      <label
                        style={{
                          color: "red",
                          display: "flex",
                          marginBottom: "0px",
                          textAlign: "left"
                        }}
                      >
                    passwords don’t match 
                      </label>
                    )}

                    <div style={{}}>
                      <label
                        style={{
                          display: "flex",
                          fontFamily: "Khula",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#808080"
                        }}
                      >
                        Join as
                      </label>
                      <Select
                        // className="react-select"
                        // classNamePrefix="react-select"
                        name="singleSelect"
                        // value={dropdownValue}
                        isSearchable={false}
                        styles={customStyles}
                        onChange={handleDropChange}
                        options={options}
                        placeholder="Select"
                      />

                      {/* {(!role && isClick) &&
                        <label
                          style={{
                            color: "red",
                            display: "flex",
                            // justifyContent: 'flex-start',
                            marginBottom: "0px",
                            textAlign: "left"
                          }}
                        >
                          {'UserType is required.'}
                        </label>
                      } */}

                      {BackendError && BackendError?.user_type && (
                        <label
                          style={{
                            color: "red",
                            display: "flex",
                            marginBottom: "0px"
                          }}
                        >
                          {BackendError?.user_type[0]}
                        </label>
                      )}
                    </div>

                    <FormGroup check>
                      <div
                        // className="justify-content-sm-between"
                        style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}
                      >
                        <Label check>
                          <Input type="checkbox" onChange={() => toggle()} />
                          <span
                            className="form-check-sign"
                            style={{
                              height: "10px",
                              backgroundColor: "#3A0F7D"
                            }}
                          />
                          <span
                            style={{
                              color: "#808080",
                              fontFamily: "Khula",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "22px",
                              margin: "0px"
                            }}
                          >
                            By signing up, you agree to our{` `}
                          </span>
                        </Label>
                        <span
                          className="pl-md-5 pl-lg-1 "
                          style={{
                            color: "#3A0F7D",
                            whiteSpace: "nowrap",
                            marginTop: 2
                          }}
                        >
                          <a
                            style={{ textDecoration: "none" }}
                            href="https://www.growtal.com/terms-and-conditions/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {`  Terms and Conditions`}
                          </a>
                        </span>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    onClick={e => {
                      e.preventDefault()
                      handleSignUp()
                    }}
                  >
                    <Button
                      className=" text-capitalize rounded-pill"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#3A0F7D",
                        // padding: "15px 70px 15px 70px",
                        width: "200px",
                        color: "white",
                        fontWeight: "700",
                        fontSize: "16px",
                        height: "54px"
                      }}
                    >
                      {requesting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </Button>
                  </div>
                  {/* <Button
                    style={{ backgroundColor: '#3A0F7D', width: '50%', }}
                    className="btn-round"
                    color=""
                    href="#"
                    onClick={(e) => { e.preventDefault(); handleSignUp() }}
                  >
                    Sign Up
                  </Button> */}
                  <div
                    className="my-4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        borderBottom: "1px #808080 solid",
                        width: "35%"
                      }}
                    />
                    <p
                      style={{
                        alignSelf: "center",

                        color: "#808080",
                        fontFamily: "Khula",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22px",
                        margin: "0px",
                        width: "100px"
                      }}
                    >
                      or signup with
                    </p>
                    <div
                      style={{
                        borderBottom: "1px #808080 solid",
                        width: "35%"
                      }}
                    />
                  </div>
                  <div className="social" style={{ marginBottom: "30px" }}>
                    {/* <FacebookLogin
                      appId="2124993807656772"
                      autoLoad={false}
                      callback={responseFacebook}
                      render={renderProps => (
                        <img onClick={renderProps.onClick} src={require("assets/img/facebook_img.png")} />
                        // <button >This is my custom FB button</button>
                      )}
                    /> */}

                    {/* <LinkedIn
                      clientId="77s7tpyzrmsjq8"
                      redirectUri={`http://localhost:3000/auth/login`}
                    >
                      {({ linkedInLogin }) => (
                        <img
                          onClick={linkedInLogin}
                          src={Images.linkedIn_social}
                          style={{
                            height: "60px",
                            width: "60px",
                            backgroundColor: "white",
                            cursor: "pointer"
                          }}
                        />
                      )}
                    </LinkedIn> */}

                    <img
                      onClick={() =>
                        window.location.replace(
                          `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=77s7tpyzrmsjq8&scope=r_liteprofile+r_emailaddress&state=1234&redirect_uri=${BASE_URL}/auth/login`
                        )
                      }
                      src={Images.linkedIn_social}
                      style={{
                        height: "60px",
                        width: "60px",
                        backgroundColor: "white",
                        cursor: "pointer"
                      }}
                    />

                    {/* <LinkedIn
                      clientId="77s7tpyzrmsjq8"
                      onFailure={handleFailure}
                      onSuccess={handleSuccess}
                      redirectUri={`https://www.google.com`}
                    >
                      <img src={Images.linkedIn_social} style={{ height: '60px', width: '60px', backgroundColor: 'white' }} />
                    </LinkedIn> */}

                    {/* <img onClick={requestProfile} src={Images.linkedIn_social} style={{ height: '60px', width: '60px', backgroundColor: 'white' }} /> */}

                    <GoogleLogin
                      clientId={clientId}
                      render={renderProps => (
                        // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                        <img
                          onClick={renderProps.onClick}
                          style={{
                            marginLeft: "20px",

                            cursor: "pointer"
                          }}
                          src={require("assets/img/google_img.png")}
                        />
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseFailGoogle}
                      cookiePolicy={"single_host_origin"}
                    />

                    {/* {isAppleBtn && (
                      <img src={require("assets/img/apple_img.png")} />
                    )} */}
                  </div>
                </CardFooter>
              </Card>
              <Link
                style={{ textDecoration: "none", fontSize: "16px" }}
                to="/auth/login"
              >
                <p style={{ color: "white", fontSize: "16px" }}>
                  <img
                    src={require("assets/img/back_vector.png")}
                    style={{ marginRight: "10px" }}
                  />
                  Cancel, Creating account
                </p>
              </Link>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/auth_bg.png")})`
          }}
        >
          <img
            src={Images.logo_name}
            style={{ bottom: 30, position: "absolute", right: 30 }}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  // userData: state.LoginScreen.user,
  requesting: state.RegisterScreen.requesting,
  BackendError: state.RegisterScreen.err
  // linkedInCode: state.LoginScreen.linkedInCode,
})

const mapDispatchToProps = dispatch => ({
  registerRequest: data => dispatch(registerRequest(data)),
  registerFaluire: data => dispatch(registerFaluire(data)),
  loginViaGoogleRequest: data => dispatch(loginViaGoogleRequest(data)),
  loginViaLinkedInRequest: data => dispatch(loginViaLinkedInRequest(data))

  // resetMsg: () => dispatch(resetMsg())
})
export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen)
