import React, { useState, useEffect } from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner
} from "reactstrap"

import ImageUpload from "components/CustomUpload/ImageUpload"
import { Link } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import Images from "utils/Images"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"
import { homeProfileRequest, homeProfileRequestFaluire } from "./redux/actions"

import { loginAccessToken } from "Containers/LoginScreen/redux/actions"

//Components
import { connect } from "react-redux"

const HomeIntialProfile = props => {
  const {
    history,
    homeProfileRequest,
    loginAccessToken,
    requesting,
    user,
    BackendError,
    homeProfileRequestFaluire
  } = props

  const newData = localStorage.getItem("userAllData")

  const [profile, setProfileData] = useState(JSON.parse(newData))

  const [mainImage, setMainImage] = useState()

  const [isChange, setIsChange] = useState(false)

  const [selectedFile, setselectedFile] = useState("")

  const defultEmail = localStorage.getItem("email")

  const defaultName = localStorage.getItem("name")

  const defultPicture = localStorage.getItem("Profile_Picture")
  const defultLinkedIn = localStorage.getItem("linkedIn_Url")

  const stateSchema = {
    fullname: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    link: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullname: {
      required: true
      // validator: validator.email
    },
    email: {
      required: true
      // validator: validator.email
    },
    link: {
      required: true
      //   validator: validator.password,
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (defultEmail == null) {
      history.push("/auth/welcome")
    }
  }, [])

  useEffect(() => {
    defultLinkedIn !== "null" && handleOnChange("link", defultLinkedIn)
    defultPicture !== "null" && setselectedFile(defultPicture)
    defultEmail !== "null" && handleOnChange("email", defultEmail)
    defaultName !== "null" && handleOnChange("fullname", defaultName)
  }, [])

  const handleContinue = () => {
    if (isChange) {
      const data = {
        profile_picture: selectedFile,
        linkedin_profile_url: state.link.value,
        name: state.fullname.value
      }
      homeProfileRequest(data)
    } else {
      const data = {
        linkedin_profile_url: state.link.value,
        name: state.fullname.value
      }
      homeProfileRequest(data)
    }
  }

  return (
    <div className="login-page">
      <Container>
        <Toaster position="top-center" />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "0px",
            marginBottom: "47px"
          }}
        >
          <img
            style={{ width: "140px", height: "40px" }}
            src={Images.logo_name}
            alt="Logo"
          />
          {/* <h1>Complete Your Profile</h1> */}
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          {/* <img style={{ width: '140px', height: '40px' }} src={Images.logo_name} alt="Logo" /> */}
          <p
            style={{
              fontFamily: "Libre Caslon Text",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "22px",
              lineHeight: "27px",
              color: "white"
            }}
          >
            Complete Your Profile
          </p>
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "30px",
            marginBottom: "0px"
          }}
        >
          <img
            style={{ width: "90px", height: "25px" }}
            src={Images.profile_page_1}
            alt="Logo"
          />
          {/* <h1>Complete Your Profile</h1> */}
        </div>
        <Row>
          <Col className="ml-auto mr-auto" lg="8" md="8" sm="8" xs="8">
            <Form action="" className="form" method="">
              <Card className="card-login" style={{ marginTop: "30px" }}>
                <CardHeader>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px"
                    }}
                  >
                    <ImageUpload
                      setselectedFile={setselectedFile}
                      avatar={defultPicture}
                      setIsChange={setIsChange}
                      isHide={true}
                    />
                  </div>
                  {/* <div className="author" style={{ justifyContent: 'center', display: 'flex', marginTop: '40px' }}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={Images.default_picture}
                        style={{ width: '138px', height: '138px' }}
                      />
                      <div
                      // style={{width:'209px', height: '54px'}}
                      >
                        <p style={{
                          backgroundColor: '#6EF5C3',
                          padding: '10px 24px 6px 24px',
                          borderRadius: '50px',
                          color: '#3A0F7D',
                          fontWeight: '700',
                          fontSize: '16px'
                        }}>Upload Picture</p>
                      </div>
                    </a>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <div style={{ width: "400px" }}>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "khula",
                            color: "#808080",
                            fontSize: "14px",
                            fontWeight: "600"
                          }}
                        >
                          Full Name
                        </label>
                        {/* <InputGroup> */}
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img src={Images.email_logo} />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <Input
                          // defaultValue={`${defaultName}`}
                          // disabled
                          value={state.fullname.value}
                          placeholder="Type name"
                          type="text"
                          onBlur={() =>
                            handleOnChange(
                              "fullname",
                              state.fullname.value.replace(/\s+/g, " ")
                            )
                          }
                          onChange={e =>
                            handleOnChange("fullname", e.target.value)
                          }
                          style={{ height: "55px" }}
                        />
                        {BackendError && BackendError?.name && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.name[0]}
                          </label>
                        )}
                        {/* </InputGroup> */}
                      </div>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "khula",
                            color: "#808080",
                            fontSize: "14px",
                            fontWeight: "600"
                          }}
                        >
                          Eamil
                        </label>
                        {/* <InputGroup> */}
                        {/* <FormGroup className="has-success"> */}
                        <Input
                          // defaultValue={`${defultEmail}`}
                          disabled
                          value={state.email.value}
                          // placeholder="Type email"
                          type="email"
                          // onChange={e =>
                          //   handleOnChange("email", e.target.value)
                          // }
                          style={{ height: "55px" }}
                        />
                        {/* </FormGroup> */}
                        {/* </InputGroup> */}
                      </div>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "khula",
                            color: "#808080",
                            fontSize: "14px",
                            fontWeight: "600"
                          }}
                        >
                          LinkedIn Profile Link
                        </label>
                        {/* <InputGroup> */}
                        <Input
                          // defaultValue={`${defultLinkedIn}`}
                          placeholder="https://"
                          type="url"
                          // disabled={defultLinkedIn !== "null"}
                          value={state.link.value}
                          onChange={e => {
                            handleOnChange("link", e.target.value)
                            homeProfileRequestFaluire(false)
                          }}
                          style={{ height: "55px" }}
                        />
                        {/* </InputGroup> */}

                        {BackendError && BackendError?.linkedin_profile_url && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.linkedin_profile_url[0]}
                          </label>
                        )}

                        {BackendError && BackendError?.non_field_errors && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.non_field_errors[0]}
                          </label>
                        )}

                        {state.link.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.link.error}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-evenly",
                      paddingBottom: "30px",
                      marginLeft: 10,
                      marginRight: 10
                    }}
                  >
                    {/* <Link to="/auth/HomeInitialProfileV2"> */}
                    <div
                    // style={{width:'209px', height: '54px'}}
                    >
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          handleContinue()
                        }}
                        className="btn-round text-capitalize"
                        color="secondary"
                        style={{
                          width: "160px",
                          backgroundColor: "#3A0F7D",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        {requesting ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Continue"
                        )}
                        <img
                          src={Images.forward_vector}
                          style={{ marginLeft: "25px" }}
                        />
                      </Button>
                      {/* <p
                        style={{
                          backgroundColor: "#3A0F7D",
                          padding: "15px 21px 15px 35px",
                          borderRadius: "50px",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "16px",
                          cursor: "pointer"
                        }}
                      >
                        {requesting ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Continue"
                        )}
                       
                        <img
                          src={Images.forward_vector}
                          style={{ marginLeft: "48px" }}
                        />
                      </p> */}
                    </div>
                    {/* </Link> */}
                  </div>
                  {/* <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        style={{ backgroundColor: '#3A0F7D', width: '40%', marginLeft: '20px', marginRight: '50px', TextDecoration: 'none' }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Sign Up
                      </Button>
                    </Link>
                    <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        outline
                        style={{ color: '#3A0F7D', borderColor: '#3A0F7D', width: '40%', }}
                        onClick={(e) => e.preventDefault()}
                        href="/auth/login"
                      >
                        Log In
                      </Button>
                    </Link> */}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/simple_bg.png")})`
        }}
      />
    </div>
    // </div >
  )
}

// export default HomeIntialProfile;
const mapStateToProps = state => ({
  BackendError: state.HomeIntialProfile.error,
  requesting: state.HomeIntialProfile.requesting,
  accessToken: state.LoginScreen.accessToken,
  user: state.LoginScreen.user
})
const mapDispatchToProps = dispatch => ({
  homeProfileRequest: data => dispatch(homeProfileRequest(data)),
  homeProfileRequestFaluire: data => dispatch(homeProfileRequestFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(HomeIntialProfile)
