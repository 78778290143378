import React from "react"
import { NavLink } from "react-router-dom"
import { Nav, Collapse } from "reactstrap"
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"

import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg"
import logo from "assets/img/react-logo.png"
import Images from "utils/Images"

var ps

class BusinessSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getCollapseStates(props.routes)
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        }
      }
      return null
    })
    return initialState
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.collapse) {
        var st = {}
        st[prop["state"]] = !this.state[prop.state]
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              style={{textDecoration: "none"}}
              href="#"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault()
                this.setState(st)
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        )
      }

      return (
        <>
          {prop.isShow === "business" && (
            <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
              <NavLink to={prop.layout + prop.path} activeClassName="">
                {prop.icon !== undefined ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row"
                        // backgroundColor: "red"
                      }}
                    >
                      {/* {prop.image ? <img src={prop.image} style={{ width: '15px', height: '15px', alignSelf: 'center', marginRight: '15px' }} /> : <i className={prop.icon} />} */}
                      <img
                        src={prop.image}
                        style={{
                          width: "15px",
                          height: "15px",
                          alignSelf: "center",
                          marginRight: "15px"
                        }}
                      />
                      <p>{prop.name}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
              </NavLink>
            </li>
          )}
        </>
      )
    })
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    // this.setState({ activeRot: routeName })
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      })
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy()
    }
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo" style={{ backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
              paddingBottom: "14px"
            }}
          >
            <img
              style={{ alignSelf: "center", height: "29px", width: "109px" }}
              src={require("assets/img/app_logo.png")}
              alt="Logo"
            />
          </div>
          {/* <a
            href='https://www.creative-tim.com'
            className='simple-text logo-mini'
          >
            <div className='logo-img'>
              <img src={logo} alt='react-logo' />
            </div>
          </a>
          <a
            href='https://www.creative-tim.com'
            className='simple-text logo-normal'
          >
            Creative Tim
          </a> */}
        </div>

        <div
          className="sidebar-wrapper"
          ref="sidebar"
          style={{ backgroundColor: "#6434AD", width: "260px" }}
        >
          {/* <div className='user'>
            <div className='photo'>
              <img src={avatar} alt='Avatar' />
            </div>
            <div className='info'>
              <a
                href='#'
                data-toggle='collapse'
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({openAvatar: !this.state.openAvatar})
                }
              >
                <span>
                  Chet Faker
                  <b className='caret' />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className='nav'>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>MP</span>
                      <span className='sidebar-normal'>My Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>EP</span>
                      <span className='sidebar-normal'>Edit Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>S</span>
                      <span className='sidebar-normal'>Settings</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div> */}
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    )
  }
}

export default BusinessSidebar
