import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../config/app"

// utils
import XHR from "../../../utils/XHR"

// types
import {
  HOME_PROFILE_REQUEST,
  HOME_PROFILE_SUCCESS,
  HOME_PROFILE_FALUIRE
} from "./types"

// actions
import {
  homeProfileRequest,
  homeProfileRequestSuccess,
  homeProfileRequestFaluire
} from "./actions"

async function homeProfileAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const userId = await localStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/updater_user/${userId}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data
  }

  return XHR(URL, options)
}
function* homeProfile({ data }) {
  try {
    const response = yield call(homeProfileAPI, data)
    yield put(homeProfileRequestSuccess(response.data))
    localStorage.setItem("Profile_Picture", response?.data?.profile_picture)
    localStorage.setItem("linkedIn_Url", response?.data?.linkedin_profile_url)

    localStorage.setItem("name", response?.data?.name)

    yield put(
      push({
        pathname: "/profile/HomeInitialProfileV2"
      })
    )
  } catch (e) {
    const { response } = e
    yield put(homeProfileRequestFaluire(response?.data))
  }
}

export default all([takeLatest(HOME_PROFILE_REQUEST, homeProfile)])
