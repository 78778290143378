import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGING_FALUIRE,
  LOGIN_VIA_LINKEDIN_REQUEST,
  LOGIN_VIA_LINKEDIN_SUCCESS,
  LOGIN_VIA_LINKEDIN_FALUIRE,
  LOGIN_VIA_GOOGLE_REQUEST,
  LOGIN_VIA_GOOGLE_SUCCESS,
  LOGIN_VIA_GOOGLE_FALUIRE,
  LOGIN_ACCESS_TOKEN,
  RESET_STATE,
  LOGIN_PROFILE_DATA,
  LINKEDIN_CODE,
  INITIAL_PROFILE_CHECK,
  SET_DEVICE_ID,
  NOTFICATION_SETTING_TOGGLE
} from "./types"

export const loginRequest = data => ({
  type: LOGIN_REQUEST,
  data
})

export const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
  data
})

export const loginFaluire = data => ({
  type: LOGING_FALUIRE,
  data
})

export const loginViaLinkedInRequest = data => ({
  type: LOGIN_VIA_LINKEDIN_REQUEST,
  data
})

export const loginViaLinkedInSuccess = data => ({
  type: LOGIN_VIA_LINKEDIN_SUCCESS,
  data
})

export const loginViaLinkedInFaluire = data => ({
  type: LOGIN_VIA_LINKEDIN_FALUIRE,
  data
})

export const loginViaGoogleRequest = data => ({
  type: LOGIN_VIA_GOOGLE_REQUEST,
  data
})

export const loginViaGoogleSuccess = data => ({
  type: LOGIN_VIA_GOOGLE_SUCCESS,
  data
})

export const loginViaGoogleFaluire = data => ({
  type: LOGIN_VIA_GOOGLE_FALUIRE,
  data
})

export const loginAccessToken = data => ({
  type: LOGIN_ACCESS_TOKEN,
  data
})

export const loginProfileData = data => ({
  type: LOGIN_PROFILE_DATA,
  data
})

export const linkedInCodeData = data => ({
  type: LINKEDIN_CODE,
  data
})

export const setInitialProfileValue = data => ({
  type: INITIAL_PROFILE_CHECK,
  data
})

export const resetAllStates = () => ({
  type: RESET_STATE
})

export const setDeviceId = data => ({
  type: SET_DEVICE_ID,
  data
})
export const notficationHandleToggle = data => ({
  type: NOTFICATION_SETTING_TOGGLE,
  data
})
