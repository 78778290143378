import React, { useState, useEffect } from "react"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap"
import Select from "react-select"
import "./style.scss"
import Images from "utils/Images"

// import ReactPaginate from 'react-paginate';

function LogHour() {
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)

  let PageSize = 10

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map(item => (
            <div>
              <h3>Item #{item}</h3>
            </div>
          ))}
      </>
    )
  }

  const dummyData = [
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    }
  ]

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Expert Hired
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}
                  <Form>
                    <InputGroup
                      className="no-border"
                      style={{
                        borderRadius: "5px"
                      }}
                    >
                      <Input
                        defaultValue=""
                        placeholder="Search..."
                        type="text"
                        style={{ backgroundColor: "white" }}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText style={{ backgroundColor: "white" }}>
                          <img
                            style={{
                              height: "17px",
                              width: "17px"
                              // marginLeft: "20px",
                              // marginRight: "4px",
                              // marginTop: "-3px"
                            }}
                            src={Images.CSearch}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Form>
                  {/* </div> */}
                </Row>
                {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    borderTop: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Expert Name
                    </th>
                    <th style={{ color: "#808080" }}>Hours</th>
                    <th style={{ color: "#808080" }}>Rate</th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Time Frame
                    </th>

                    <th className="text-center" style={{ color: "#808080" }}>
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            marginLeft: "25px",
                            border: "3px solid transparent"
                          }}
                        >
                          <td
                            style={{
                              padding: "25px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px"
                            }}
                          >
                            {item.Name}
                          </td>
                          <td>{item.Country}</td>
                          <td>{item.City}</td>
                          <td className="text-right" style={{}}>
                            {item.Salary}
                          </td>
                          <td
                            className="text-right pr-lg-5"
                            style={{
                              paddingTop: "14px",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px"
                            }}
                          >
                            <Button
                              className="btn-round  text-capitalize "
                              style={{
                                backgroundColor: "#fff",
                                color: "#3A0F7D",
                                border: "1px solid  #3A0F7D",
                                // display: "flex",

                                width: "max-content"
                              }}
                            >
                              <img
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  // marginLeft: "20px",
                                  marginRight: "4px",
                                  marginTop: "-3px",
                                  borderRadius: "0px"
                                }}
                                src={Images.LogLight}
                              />
                              Export to CSV
                            </Button>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  <PaginationPack />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default LogHour
