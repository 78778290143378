import {
  GET_REFER_REQUEST,
  GET_REFER_SUCCESS,
  GET_REFER_FAILURE,
  REFER_REQUEST,
  REFER_SUCCESS,
  REFER_FALUIRE
} from "./types"

const initialState = {
  refer: false,
  requesting: false,
  referData: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REFER_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_REFER_SUCCESS:
      return {
        ...state,
        requesting: false,
        referData: action.data
      }

    case GET_REFER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case REFER_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case REFER_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: false,
        refer: action.data
      }

    case REFER_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
