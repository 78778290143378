import {
  DOWNLOAD_CONTRACT_REQUEST,
  DOWNLOAD_CONTRACT_SUCCESS,
  DOWNLOAD_CONTRACT_FALUIRE,
  GET_INDUSTRIAL_EXPERIENCE_REQUEST,
  GET_INDUSTRIAL_EXPERIENCE_SUCCESS,
  GET_INDUSTRIAL_EXPERIENCE_FALUIRE,
  ADDITIONAL_EXPERTIES_REQUEST,
  ADDITIONAL_EXPERTIES_SUCCESS,
  ADDITIONAL_EXPERTIES_FALUIRE,
  GET_EXPERT_PROFILE_REQUEST,
  GET_EXPERT_PROFILE_FALUIRE,
  GET_EXPERT_PROFILE_SUCCESS
} from "./types"

export const downloadContract = (data, setModal) => ({
  type: DOWNLOAD_CONTRACT_REQUEST,
  data,
  setModal
})

export const downloadContractSuccess = (data, setModal) => ({
  type: DOWNLOAD_CONTRACT_SUCCESS,
  data,
  setModal
})

export const downloadContractFailure = data => ({
  type: DOWNLOAD_CONTRACT_FALUIRE,
  data
})

export const getIndustryExperience = data => ({
  type: GET_INDUSTRIAL_EXPERIENCE_REQUEST,
  data
})

export const getIndustryExperienceSuccess = data => ({
  type: GET_INDUSTRIAL_EXPERIENCE_SUCCESS,
  data
})

export const getIndustryExperienceFailure = data => ({
  type: GET_INDUSTRIAL_EXPERIENCE_FALUIRE,
  data
})

export const postAdditionalExperties = (data, setModal) => ({
  type: ADDITIONAL_EXPERTIES_REQUEST,
  data,
  setModal
})

export const postAdditionalExpertiesSuccess = (data, setModal) => ({
  type: ADDITIONAL_EXPERTIES_SUCCESS,
  data,
  setModal
})

export const postAdditionalExpertiesFailure = data => ({
  type: ADDITIONAL_EXPERTIES_FALUIRE,
  data
})

export const getExpertProfile = data => ({
  type: GET_EXPERT_PROFILE_REQUEST,
  data
})

export const getExpertProfileSuccess = data => ({
  type: GET_EXPERT_PROFILE_FALUIRE,
  data
})

export const getExpertProfileFailure = data => ({
  type: GET_EXPERT_PROFILE_SUCCESS,
  data
})
