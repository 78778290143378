import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap"
import Select from "react-select"
import { NavLink } from "react-router-dom"
// import './style.scss';

// import ReactPaginate from 'react-paginate';

function BillingInformation() {
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)

  let PageSize = 10

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map(item => (
            <div>
              <h3>Item #{item}</h3>
            </div>
          ))}
      </>
    )
  }

  const handleSubmit = () => {}

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Billing Information
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}
                  {/* <p style={{
                                        border: '2px #3A0F7D solid',
                                        padding: '12px 60px 12px 60px',
                                        borderRadius: '50px',
                                        color: '#3A0F7D',
                                        fontWeight: '700',
                                        fontSize: '16px'
                                    }}>+ Suggest New Engagements</p> */}
                  {/* </div> */}
                </div>
                {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
              </CardHeader>
              <CardBody>
                <Row
                  className=" justify-content-center justify-content-sm-between"
                  style={{
                    display: "flex",

                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: "10px",
                    marginBottom: "25px",
                    border: "1px solid #E2E2E2"
                  }}
                >
                  <div className="mt-2" style={{ paddingLeft: "25px" }}>
                    To preview and edit billing information, please visit Gusto
                  </div>
                  <div
                    className="pr-lg-3 pr-2"
                    style={{
                      alignItems: "center",
                      paddingTop: "16px",
                      paddingBottom: "16px"
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      href="https://app.gusto.com/login"
                    >
                      <Button
                        className="py-1 px-5 my-2 btn-round"
                        style={{
                          backgroundColor: "#EB5E4A",
                          // padding: "10px 75px 10px 75px",
                          marginBottom: "0px",
                          marginTop: "0px",
                          height: "49px",
                          // borderRadius: "50px",
                          color: "white",
                          cursor: "pointer",
                          fontWeight: "700",
                          fontSize: "16px",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        gusto
                      </Button>
                    </a>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default BillingInformation
