import {
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FALUIRE,
  DOWNLOAD_CONTRACT_REQUEST,
  DOWNLOAD_CONTRACT_SUCCESS,
  DOWNLOAD_CONTRACT_FALUIRE
} from "./types"

const initialState = {
  requesting: false,
  Contracts: false,
  loader: false,
  DownloadData: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        requesting: false,
        Contracts: action.data
      }

    case GET_CONTRACT_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    case DOWNLOAD_CONTRACT_REQUEST:
      return {
        ...state,
        loader: true
      }

    case DOWNLOAD_CONTRACT_SUCCESS:
      return {
        ...state,
        loader: false,
        DownloadData: action.data
      }

    case DOWNLOAD_CONTRACT_FALUIRE:
      return {
        ...state,
        loader: false
      }

    default:
      return state
  }
}
