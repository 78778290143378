import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../../../config/app"

// utils
import XHR from "../../../../../utils/XHR"

// types
import {
  GET_ACTIVE_ENGAGEMENT_REQUEST,
  PATCH_ACTIVE_ENGAGEMENT_REQUEST,
  PATCH_END_ENGAGEMENT_REQUEST,
  GET_ENGAGEMENT_OPPORTUNITY,
  GET_ENGAGEMENT_HISTORY_REQUEST,
  CREATE_ADMIN_ENGAGEMENT_REQUEST,
  GET_ADMIN_BUSINESS_SEARCH_REQUEST,
  GET_ADMIN_EXPERT_SEARCH_REQUEST
} from "./types"

// actions
import {
  getActiveEngagementSuccess,
  getActiveEngagementFaluire,
  patchActiveEngagementSuccess,
  patchActiveEngagementFaluire,
  patchEndEngagementSuccess,
  patchEndEngagementFaluire,
  getActiveEngagementRequest,
  //engagement
  getEngagementOpportunitySuccess,
  getEngagementOpportunityFaluire,
  getEngagementHistrorySuccess,
  getEngagementHistroryFaluire,
  postEngagementSuccess,
  postEngagementFaluire,
  getBusinessDataSuccess,
  getBusinessDataFaluire,
  getExpertDataSuccess,
  getExpertDataFaluire
} from "./actions"

async function patchActiveEngagementAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchActiveEngagement({ data }) {
  try {
    const response = yield call(patchActiveEngagementAPI, data)
    yield put(patchActiveEngagementSuccess(response.data))
  } catch (e) {
    const { response } = e
    toast.error("Failed")
    yield put(patchActiveEngagementFaluire(response?.data))
  }
}

async function getEngagementsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/?page=${data}`

  const options = {
    headers: {
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}
function* getEngagements({ data }) {
  try {
    const response = yield call(getEngagementsAPI, data)
    yield put(getActiveEngagementSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getActiveEngagementFaluire(response?.data))
  }
}

async function patchEndEngagementAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchEndEngagement({ data }) {
  try {
    const response = yield call(patchEndEngagementAPI, data)
    yield put(patchEndEngagementSuccess(response.data))
    yield put(getActiveEngagementRequest(1))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(patchEndEngagementFaluire(response?.data))
  }
}

async function createAdminEngagementAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* createAdminEngagement({ data }) {
  try {
    const response = yield call(createAdminEngagementAPI, data)
    yield put(postEngagementSuccess(response?.data))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(postEngagementFaluire(response?.data))
  }
}

async function engagementOpportunityApi(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/Opportunity/?page=${data}`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* engagementOpportunityFunction({ data }) {
  try {
    const response = yield call(engagementOpportunityApi, data)
    yield put(getEngagementOpportunitySuccess(response?.data))
    // toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(getEngagementOpportunityFaluire(response?.data))
    // toast.error("Failure")
  }
}

async function engagementHistoryApi(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/expert_engagement_history/?page=${data}`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* engagementHistoryFunction({ data }) {
  try {
    const response = yield call(engagementHistoryApi, data)
    yield put(getEngagementHistrorySuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getEngagementHistroryFaluire(response?.data))
  }
}

async function getBusinessAccountsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/all_business_profile/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getAdminBusinessAccounts({ data }) {
  try {
    const response = yield call(getBusinessAccountsAPI, data)
    yield put(getBusinessDataSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getBusinessDataFaluire(e.response))
  }
}

async function getExpertsNamesAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/all_expert_profile/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getAdminExpertsNames({ data }) {
  try {
    const response = yield call(getExpertsNamesAPI, data)
    yield put(getExpertDataSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getExpertDataFaluire(response?.data))
  }
}

export default all([
  takeLatest(PATCH_ACTIVE_ENGAGEMENT_REQUEST, patchActiveEngagement),
  takeLatest(GET_ACTIVE_ENGAGEMENT_REQUEST, getEngagements),
  takeLatest(PATCH_END_ENGAGEMENT_REQUEST, patchEndEngagement),
  takeLatest(GET_ENGAGEMENT_OPPORTUNITY, engagementOpportunityFunction),
  takeLatest(GET_ENGAGEMENT_HISTORY_REQUEST, engagementHistoryFunction),
  takeLatest(CREATE_ADMIN_ENGAGEMENT_REQUEST, createAdminEngagement),
  takeLatest(GET_ADMIN_BUSINESS_SEARCH_REQUEST, getAdminBusinessAccounts),
  takeLatest(GET_ADMIN_EXPERT_SEARCH_REQUEST, getAdminExpertsNames)
])
