import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../config/app"

// utils
import XHR from "../../../utils/XHR"

// types
import { POST_TIMETRACKER_DATA, GET_ENGAGEMENT_DATA } from "./types"

// actions
import {
  getEngagementSuccess,
  getEngagementFaluire,
  timeTrackerRequestFaluire,
  timeTrackerRequestSuccess
} from "./actions"

async function postTimeTrackerAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await localStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/dashboard/time_log/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* postTimeTracker({ data, clearValue }) {
  try {
    const response = yield call(postTimeTrackerAPI, data)
    yield put(timeTrackerRequestSuccess(response?.data))
    toast.success("Success")
    yield put(clearValue())
  } catch (e) {
    const { response } = e
    {
      response?.status == 400 && toast.error(response?.data?.Message)
    }
    yield put(timeTrackerRequestFaluire(response))
  }
}

async function getEngagementsAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/engagement_list/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getEngagementsFunction({ data }) {
  try {
    const response = yield call(getEngagementsAPI, data)
    yield put(getEngagementSuccess(response?.data))

    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e

    yield put(getEngagementFaluire(response?.data))
  }
}
export default all([
  takeLatest(POST_TIMETRACKER_DATA, postTimeTracker),
  takeLatest(GET_ENGAGEMENT_DATA, getEngagementsFunction)
])
