import React, { useEffect, useState } from "react"

import PaginationPack from "components/PaginationPack"
import PictureUpload from "components/CustomUpload/PictureUpload"
// hooks
import useForm from "utils/useForm"
import validator from "utils/validation"

// import {useForm} from 'react-hook-form'
import toast, { Toaster } from "react-hot-toast"

import { connect } from "react-redux"
import { pageRequest, postFeedBackRequest, toggleModal } from "./redux/actions"
import { useDispatch } from "react-redux"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap"
import Select from "react-select"

function Feedback(props) {
  const { postFeedBackRequest, allAccounts, requesting, replyRequesting } =
    props

  const [modal, setModal] = useState(false)
  const [details, setDetails] = useState("")

  const toggle = item => {
    setDetails(item)
    setModal(!modal)
  }

  const getProfileImage = item => {
    if (item?.user_data?.user_type == "Expert") {
      if (item?.user_data?.profile_picture) {
        return item?.user_data?.profile_picture
      }
    }
    if (item?.user_data?.business_detail[0]?.logo) {
      return item?.user_data?.business_detail[0]?.logo
    }

    return require("assets/img/dummy_avatar.jpg")
  }
  const toggle1 = () => {
    props.toggleModal(!props.visible)
    setState(stateSchema)
    // setModal1(!modal1)
  }

  const handlePageChange = page => {
    props.pageRequest(page)
  }

  const stateSchema = {
    descriptionOfEngagement: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    descriptionOfEngagement: {
      required: true
      // validator: validator.email
    }
  }

  const { state, setState, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSubmit = () => {
    if (state.descriptionOfEngagement.value) {
      props.postFeedBackRequest({
        feedback: details.id,
        reply: state.descriptionOfEngagement.value
      })

      props.toggleModal(false)
      setState(stateSchema)
    }
  }

  useEffect(() => {
    props.pageRequest(1)
  }, [])

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            {/* {props.BackendError ? (
              <div
                style={{
                  display: "flex",
                  height: "80vh",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {props.BackendError == "Connection Error" ? (
                  <p style={{ color: "red" }}>
                    {props.BackendError} : Please check your internet
                    connection.
                  </p>
                ) : (
                  <p style={{ color: "red" }}>{props.BackendError}</p>
                )}
              </div>
              ) : ( */}
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex" }}>
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Feedback
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}

                  {/* </div> */}
                </div>

                {/* <p className="card-category">
                  Here is a subtitle for this table
                    </p> */}
              </CardHeader>
              <CardBody>
                <Toaster position="top-center" />
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    minHeight: requesting || !allAccounts ? 400 : null
                  }}
                >
                  <thead>
                    <tr style={{}}>
                      <th
                        style={{
                          color: "#808080",
                          backgroundColor: "#f4f3ef",
                          paddingLeft: "18px"
                        }}
                      >
                        Users
                      </th>

                      <th
                        className="text-right"
                        style={{
                          color: "#808080",
                          paddingRight: "75px",
                          backgroundColor: "#f4f3ef"
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "20%",
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : allAccounts && allAccounts?.results?.length > 0 ? (
                    <tbody>
                      {allAccounts &&
                        allAccounts?.results.map(item => (
                          <>
                            <tr
                              style={{
                                backgroundColor: "white",
                                marginLeft: "25px",
                                borderRadius: "8px",
                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  padding: "25px",
                                  borderBottomLeftRadius: "8px",
                                  borderTopLeftRadius: "8px"
                                }}
                              >
                                {item?.user_data?.user_type == "Expert" ? (
                                  item?.user_data?.profile_picture ? (
                                    <img
                                      style={{
                                        width: "42px",
                                        height: "42px",
                                        marginRight: 16
                                      }}
                                      src={item?.user_data?.profile_picture}
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        width: "42px",
                                        height: "42px",
                                        marginRight: 16,
                                        borderRadius: "50%"
                                      }}
                                      src={require("assets/img/dummy_avatar.jpg")}
                                    />
                                  )
                                ) : item?.user_data?.business_detail[0]
                                    ?.logo ? (
                                  <img
                                    style={{
                                      width: "42px",
                                      height: "42px",
                                      marginRight: 16
                                    }}
                                    src={
                                      item?.user_data?.business_detail[0]?.logo
                                    }
                                  />
                                ) : (
                                  <img
                                    style={{
                                      width: "42px",
                                      height: "42px",
                                      marginRight: 16,
                                      borderRadius: "50%"
                                    }}
                                    src={require("assets/img/dummy_avatar.jpg")}
                                  />
                                )}

                                <span style={{ whiteSpace: "nowrap" }}>
                                  {" "}
                                  {/* {item?.user_data?.name} */}
                                  {item?.user_data?.user_type == "Expert"
                                    ? item?.user_data?.name
                                    : item?.user_data?.user_type == "Business"
                                    ? item?.user_data?.business_detail[0]
                                        ?.company_name
                                    : item?.user_data?.name}
                                </span>
                              </td>

                              <td
                                className="text-right"
                                style={{
                                  paddingRight: "25px",
                                  paddingTop: "14px",
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px"
                                }}
                              >
                                <Button
                                  className="btn-round btn text-capitalize"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#3A0F7D",
                                    border: "1px solid #3A0F7D",
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "fit-content",
                                    whiteSpace: "nowrap"
                                  }}
                                  onClick={() => toggle(item)}
                                >
                                  <img
                                    style={{
                                      width: "14px",
                                      height: "9px",
                                      // marginLeft: "20px",
                                      marginRight: "4px"
                                    }}
                                    src={require("assets/img/eye.png")}
                                  />
                                  View Details
                                </Button>
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))}
                    </tbody>
                  ) : (
                    <tobdy>
                      <tr>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            left: "50%"
                          }}
                        >
                          <h6>No Data Found</h6>
                        </div>
                      </tr>
                    </tobdy>
                  )}
                </Table>
                {props.BackendError ||
                (allAccounts && allAccounts?.results?.length) <= 0 ||
                props.requesting ? null : (
                  <div className="pt-4 d-flex justify-content-center">
                    <PaginationPack
                      onChange={handlePageChange}
                      itemsCountPerPage={allAccounts?.page_size}
                      totalItemsCount={allAccounts?.total_objects}
                      activePage={allAccounts?.current_page_number}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
            {/* // )} */}
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
          style={{ maxWidth: "483px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Feedback Details
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0px 0px 40px 0px"
                  }}
                >
                  <img
                    style={{
                      width: "140px",
                      height: "140px",
                      marginRight: 16,
                      borderRadius: "50%"
                    }}
                    src={getProfileImage(details)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula",
                        fontWeight: "600"
                      }}
                    >
                      User Name
                    </Label>
                    <Input
                      disabled
                      defaultValue={
                        details?.user_data?.user_type == "Business"
                          ? details?.user_data?.business_detail[0]?.company_name
                          : details?.user_data?.name
                          ? details.user_data.name
                          : "Expert Name goes here.."
                      }
                      // placeholder="Expert Name goes here.."
                      type="text"
                      style={{ backgroundColor: "#EFEFEF" }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula",
                        fontWeight: "600"
                      }}
                    >
                      Email
                    </Label>
                    <Input
                      disabled
                      defaultValue={
                        details?.user_data?.email
                          ? details.user_data.email
                          : "asddsa@gmail.com"
                      }
                      // placeholder="asddsa@gmail.com"
                      type="text"
                      style={{ backgroundColor: "#EFEFEF" }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula",
                        fontWeight: "600"
                      }}
                    >
                      Subject
                    </Label>
                    <Input
                      disabled
                      defaultValue={
                        details?.subject
                          ? details.subject
                          : "Need some improvement in time tracker"
                      }
                      // placeholder="Need some improvement in time tracker"
                      type="text"
                      style={{ backgroundColor: "#EFEFEF" }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula",
                        fontWeight: "600"
                      }}
                    >
                      Description
                    </Label>
                    <Input
                      disabled
                      defaultValue={
                        details?.body
                          ? details.body
                          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti"
                      }
                      id="exampleText"
                      // placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti"
                      name="text"
                      type="textarea"
                      style={{ height: "223px", backgroundColor: "#EFEFEF" }}
                    />
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", marginTop: "33px" }}>
              <div>
                <Button
                  className="mr-2 btn-round btn text-capitalize"
                  style={{
                    border: "2px #3A0F7D solid",

                    borderRadius: "30px",
                    color: "#3A0F7D",
                    fontWeight: "700",

                    // marginRight: "34px",
                    boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                    cursor: "pointer",
                    backgroundColor: "white",
                    width: 132,
                    height: 49
                  }}
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  className="btn-round btn text-capitalize"
                  style={{
                    border: "2px #3A0F7D solid",

                    borderRadius: "30px",
                    color: "#FFFFFF",
                    backgroundColor: "#3A0F7D",
                    fontWeight: "700",
                    fontSize: "16px",
                    boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                    cursor: "pointer",
                    width: 132,
                    height: 49
                  }}
                  onClick={() => {
                    toggle1()
                    setModal(false)
                  }}
                >
                  Reply
                </Button>
              </div>
            </Row>
          </div>
        </Modal>
        <Modal
          isOpen={props.visible}
          toggle={toggle1}
          // className={className}
          // external={externalCloseBtn}
          style={{}}
        >
          {/* <Toaster position="top-center" /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Reply Feedback
            </h5>
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <Row style={{ margin: "20px" }}>
            <Col>
              <Form>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Subject
                </Label>
                <Input
                  disabled
                  defaultValue={
                    details?.subject ? details.subject : "Your Subject"
                  }
                  // placeholder="Need some improvement in time tracker"
                  type="text"
                  style={{ backgroundColor: "#EFEFEF", height: "55px" }}
                />

                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "30px",
                      fontWeight: "600"
                    }}
                  >
                    Description of Engagement
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Enter Description."
                    name="text"
                    maxlength="170"
                    type="textarea"
                    value={state.descriptionOfEngagement.value}
                    onChange={e =>
                      handleOnChange("descriptionOfEngagement", e.target.value)
                    }
                    style={{ height: "143px", backgroundColor: "" }}
                  />
                  {props.BackendError ? (
                    <p style={{ color: "red" }}>{props.BackendError}</p>
                  ) : null}
                  {state.descriptionOfEngagement.error ? (
                    <p style={{ color: "red", padding: 5 }}>
                      {state.descriptionOfEngagement.error}
                    </p>
                  ) : null}
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row
            style={{
              justifyContent: "center",
              marginTop: "22px",
              marginBottom: 20
            }}
          >
            <div>
              <Button
                className="btn-round btn text-capitalize"
                style={{
                  border: "2px #3A0F7D solid",

                  borderRadius: "30px",
                  color: "#3A0F7D",
                  fontWeight: "700",

                  marginRight: "22px",
                  boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                  cursor: "pointer",
                  backgroundColor: "white",
                  width: 132,
                  height: 49
                }}
                onClick={toggle1}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className="btn-round btn text-capitalize"
                style={{
                  border: "2px #3A0F7D solid",

                  color: "#FFFFFF",
                  backgroundColor: "#3A0F7D",
                  fontWeight: "700",

                  boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                  cursor: "pointer",

                  width: 132,
                  height: 49
                }}
                onClick={() => handleOnSubmit()}
              >
                {replyRequesting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </Row>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.Feedback.error,
  requesting: state.Feedback.requesting,
  allAccounts: state.Feedback.allAccounts,
  replyRequesting: state.Feedback.replyRequesting,
  visible: state.Feedback.visible
  // accessToken: state.LoginScreen.accessToken,
  // user: state.LoginScreen.user
})
const mapDispatchToProps = dispatch => ({
  pageRequest: page => dispatch(pageRequest(page)),
  postFeedBackRequest: data => dispatch(postFeedBackRequest(data)),
  toggleModal: data => dispatch(toggleModal(data))
  // patchAccountRequest: (data) => dispatch(patchAccountRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
