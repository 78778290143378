import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  POST_BUSINESS_HOME_REQUEST,
  POST_BUSINESS_HOME_SUCCESS,
  POST_BUSINESS_HOME_FAILURE
} from "./types"

// actions
import { loginSuccess } from "Containers/LoginScreen/redux/actions"
import { postHomeDataSuccess, postHomeDataFaluire } from "./actions"

async function postBusinessHomeAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business_profile/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postBusinessHome({ data }) {
  const accessToken = localStorage.getItem("authToken")
  try {
    const response = yield call(postBusinessHomeAPI, data)
    yield put(postHomeDataSuccess(response.data))
    localStorage.setItem("accessToken", accessToken)
    yield put(loginSuccess(response.data))
    localStorage.setItem("profileid", response.data?.id)
    yield put(
      push({
        pathname: "/business/my_team"
      })
    )
  } catch (e) {
    const { response } = e
    yield put(postHomeDataFaluire(response?.data))
  }
}

export default all([takeLatest(POST_BUSINESS_HOME_REQUEST, postBusinessHome)])
