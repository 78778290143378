import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  LOGHOUR_REQUEST,
  EXPORT_CSV_REQUEST,
  PAGE_REQUEST,
  LOG_HOUR_SORT_REQUEST,
  LOG_HOUR_FILTER_REQUEST,
  FILTER_CLIENTS_REQUEST,
  FILTER_ENGAGEMENTS_REQUEST
} from "./types"

// actions
import {
  logOurSuccess,
  logOurFaluire,
  exportCSVSuccess,
  exportCSVFaluire,
  pageSuccess,
  pageFaluire,
  sortRequestSuccess,
  sortRequestFaluire,
  filterRequestSuccess,
  filterRequestFaluire,
  filterClientsSuccess,
  filterClientsFaluire,
  filterEngagementSuccess,
  filterEngagementFaluire
} from "./actions"

async function getLogourDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/time_log/?start_date=${data.start_date}&end_date=${data.end_date}&client=${data.client}&engagement=${data.engagement}&hour=${data.hour}&minute=${data.minute}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getLogourData({ data }) {
  try {
    const response = yield call(getLogourDataAPI, data)
    yield put(logOurSuccess(response?.data))
  } catch (e) {
    yield put(logOurFaluire(e.response))
  }
}

async function paginationAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/time_log/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* pagination({ data }) {
  try {
    const response = yield call(paginationAPI, data)
    yield put(pageSuccess(response?.data))
  } catch (e) {
    yield put(pageFaluire(e.response))
  }
}

async function getExportCSVDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/time_log/time_log_csv/?engagement=${data.engagement}&client=${data.client}&start_date=${data.start_date}&end_date=${data.end_date}&hour=${data.hour}&minute=${data.minute}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExportCSVData({ data }) {
  try {
    const response = yield call(getExportCSVDataAPI, data)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.setAttribute("download", "Log History CSV.csv")
    link.href = url
    document.body.appendChild(link)
    link.click()
    yield put(exportCSVSuccess(response?.data))
    toast.success("Success")
  } catch (e) {
    yield put(exportCSVFaluire(e.response))
  }
}

async function sortAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/?sort=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* sortData({ data }) {
  try {
    const response = yield call(sortAPI, data)
    yield put(sortRequestSuccess(response.data))
  } catch (e) {
    yield put(sortRequestFaluire(e.response))
  }
}

async function filterAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/get_engagement/?sort=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* filterData({ data }) {
  try {
    const response = yield call(filterAPI, data)
    yield put(filterRequestSuccess(response?.data))
  } catch (e) {
    yield put(filterRequestFaluire(e.response))
  }
}

async function getClientsDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/my_clients/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getClientsData({ data }) {
  try {
    const response = yield call(getClientsDataAPI, data)
    yield put(filterClientsSuccess(response?.data))
  } catch (e) {
    yield put(filterClientsFaluire(e.response))
  }
}

async function getEngagementDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/my_engagements/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getEngagementData({ data }) {
  try {
    const response = yield call(getEngagementDataAPI, data)
    yield put(filterEngagementSuccess(response?.data))
  } catch (e) {
    yield put(filterEngagementFaluire(e.response))
  }
}

export default all([
  takeLatest(PAGE_REQUEST, pagination),
  takeLatest(LOGHOUR_REQUEST, getLogourData),
  takeLatest(EXPORT_CSV_REQUEST, getExportCSVData),
  takeLatest(LOG_HOUR_SORT_REQUEST, sortData),
  takeLatest(LOG_HOUR_FILTER_REQUEST, filterData),
  takeLatest(FILTER_CLIENTS_REQUEST, getClientsData),
  takeLatest(FILTER_ENGAGEMENTS_REQUEST, getEngagementData)
])
