import React from "react"
// used for making the prop types of this component
import PropTypes from "prop-types"

import { Button } from "reactstrap"

import defaultImage from "assets/img/default_picture.png"
import defaultAvatar from "assets/img/default_picture.png"

class ImageUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      imagePreviewUrl:
        this.props.avatar !== "null" ? this.props.avatar : defaultImage
    }
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }
  handleImageChange(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    // this.props.setselectedFile(file)
    this.props.setIsChange(true)

    reader.onloadend = () => {
      this.props.setselectedFile(reader.result)
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      })
    }

    reader.readAsDataURL(file)
  }
  handleSubmit(e) {
    e.preventDefault()
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click()
  }

  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    })
    this.refs.fileInput.value = null
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div
          className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}
          style={{ borderRadius: "70px" }}
        >
          <img
            src={this.state.imagePreviewUrl}
            alt="..."
            style={{ height: 100, width: 100, borderRadius: "50%" }}
          />
        </div>
        <div>
          {this.state.file === null ? (
            <Button
              className="btn-round"
              color="success"
              onClick={() => this.handleClick()}
            >
              {/* {this.props.avatar ? "Add Photo" : "Select image"} */}
              {this.props.avatar ? "Upload Picture" : "Upload Picture"}
            </Button>
          ) : this.props.isHide ? (
            <Button
              className="btn-round"
              color="success"
              onClick={() => this.handleClick()}
            >
              {this.props.avatar ? "Upload Picture" : "Upload Picture"}
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Change
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color="danger"
                className="btn-round"
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" />
                Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    )
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool
}

export default ImageUpload
