import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../config/app"

// utils
import XHR from "../../../utils/XHR"

// types
import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FALUIRE,
  CHANGE_PASSWORD,
  FEEDBACK_REQUEST,
  TERMS_AND_CONDITION,
  TERMS_AND_CONDITION_SUCCESS,
  PRIVICY_POLICY,
  PRIVICY_POLICY_SUCCESS,
  NOTIFICATION_SETTINGS
} from "./types"

import { notficationHandleToggle } from "Containers/LoginScreen/redux/actions"

// actions
import {
  getTermsAndCondition,
  getTermsAndConditionSucess,
  getPrivicyPolicySucess,
  changePassFaluire,
  feedbackSuccess,
  feedbackFaluire,
  notificationSettingsFaluire,
  notificationSettingsSuccess
} from "./actions"

async function deleteAccountAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const userId = await localStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/updater_user/${userId}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "DELETE"
    // data,
  }

  return XHR(URL, options)
}

function* deleteAccount({ data }) {
  try {
    const response = yield call(deleteAccountAPI, data)
    toast.success(`Success`)
  } catch (e) {
    const { response } = e
    toast.error(`deleteAccount failure`)
  }
}

async function changeNewPasswordAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const userId = localStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/password/change/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* changeNewPassword({ data }) {
  try {
    const response = yield call(changeNewPasswordAPI, data)
    toast.success(`Success`)
    yield put(changePassFaluire(false))

    localStorage.setItem("authToken", response?.data?.token)
    localStorage.setItem("user", response?.data?.user?.id.toString())
  } catch (e) {
    const { response } = e
    yield put(changePassFaluire(response?.data))
  }
}

async function feedbackRequestAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/user_feedback/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* feedbackRequest({ data }) {
  try {
    const response = yield call(feedbackRequestAPI, data)
    yield put(feedbackSuccess(response?.data))
    yield put(feedbackFaluire(false))
  } catch (e) {
    const { response } = e
    yield put(feedbackFaluire(response?.data))
  }
}

function termsAndConditionAPI() {
  const accessToken = localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/terms_and_conditions/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}
function* termsAndCondition() {
  try {
    const response = yield call(termsAndConditionAPI)

    yield put(getTermsAndConditionSucess(response.data))
  } catch (e) {
    const { response } = e
  }
}
function privicyPolicyAPI() {
  const accessToken = localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/privacy_policy/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}
function* privicyPolicy() {
  try {
    const response = yield call(privicyPolicyAPI)
    yield put(getPrivicyPolicySucess(response.data))
  } catch (e) {
    const { response } = e
  }
}

async function postNotificationSettingsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const userId = await localStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/all_user/${userId}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data
  }

  return XHR(URL, options)
}
function* postNotificationSettings({ data }) {
  try {
    const response = yield call(postNotificationSettingsAPI, data)
    yield put(notificationSettingsSuccess(response?.data))
    yield put(notficationHandleToggle(response?.data))
  } catch (e) {
    const { response } = e
    yield put(notificationSettingsFaluire(response?.data))
  }
}

export default all([
  takeLatest(DELETE_ACCOUNT, deleteAccount),
  takeLatest(CHANGE_PASSWORD, changeNewPassword),
  takeLatest(FEEDBACK_REQUEST, feedbackRequest),
  takeLatest(TERMS_AND_CONDITION, termsAndCondition),
  takeLatest(PRIVICY_POLICY, privicyPolicy),
  takeLatest(NOTIFICATION_SETTINGS, postNotificationSettings)
])
