import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  GET_PUSH_NOTIFICATION,
  CREATE_PUSH_NOTIFICATION,
  EDIT_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION,
  GET_ALL_USER
} from "./types"

// actions
import {
  getPushNotification,
  getPushNotificationSuccess,
  getPushNotificationFaluire,
  createPushNotificationSuccess,
  createPushNotificationFaluire,
  editPushNotificationSuccess,
  editPushNotificationFaluire,
  deletePushNotificationSuccess,
  deletePushNotificationFaluire,
  getAllUserSuccess,
  getAllUserFaluire
} from "./actions"
import { data } from "jquery"
// import { resourcesRequest } from "Containers/ExpertDesign/Screens/ResourceArea/redux/actions"

async function getPushNotificationAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/notification/push_notification/?page=${data}`

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getPushNotificationFun({ data }) {
  try {
    const response = yield call(getPushNotificationAPI, data)

    yield put(getPushNotificationSuccess(response.data))
    // yield put(resourcesRequest({ page: 1, type: "" }))
    // yield put(getEngagementRequest(1))
    // toast.success("Engagement Request Done")
  } catch (e) {
    const { response } = e
    yield put(getPushNotificationFaluire(response?.data))
  }
}

async function editPushNotificationAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/notification/push_notification/${data.id}/`

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data
  }

  return XHR(URL, options)
}

function* editPushNotificationFun({ data }) {
  try {
    const response = yield call(editPushNotificationAPI, data)

    yield put(editPushNotificationSuccess(response.data))
    yield put(getPushNotification(1))

    // yield put(resourcesRequest({ page: 1, type: "" }))
    // yield put(getEngagementRequest(1))
    // toast.success("Engagement Request Done")
  } catch (e) {
    const { response } = e
    yield put(editPushNotificationFaluire(response?.data))
  }
}

async function createPushNotificationAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/notification/push_notification/`

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* createPushNotificationFun({ data }) {
  try {
    const response = yield call(createPushNotificationAPI, data)

    yield put(createPushNotificationSuccess(response.data))
    yield put(getPushNotification(1))
  } catch (e) {
    const { response } = e

    yield put(createPushNotificationFaluire(response?.data))
  }
}

async function deleteNotificationAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/notification/push_notification/${data.id}/`

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "DELETE",
    data
  }

  return XHR(URL, options)
}

function* deleteNotificationFun({ data }) {
  try {
    const response = yield call(deleteNotificationAPI, data)
    yield put(deletePushNotificationSuccess(response))
    yield put(getPushNotification(1))

    // yield put(resourcesRequest({ page: 1, type: "" }))
    // toast.success("End  Successfully")
  } catch (e) {
    const { response } = e
    yield put(deletePushNotificationFaluire(response?.data))
  }
}

async function getAllUserAPI() {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/all_user/?expert=Expert&business=Business`

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getAllUserFun({ data }) {
  try {
    const response = yield call(getAllUserAPI, data)

    yield put(getAllUserSuccess(response.data))
    // yield put(resourcesRequest({ page: 1, type: "" }))
    // yield put(getEngagementRequest(1))
    // toast.success("Engagement Request Done")
  } catch (e) {
    const { response } = e
    yield put(getAllUserFaluire(response?.data))
  }
}

export default all([
  takeLatest(GET_PUSH_NOTIFICATION, getPushNotificationFun),
  takeLatest(EDIT_PUSH_NOTIFICATION, editPushNotificationFun),
  takeLatest(CREATE_PUSH_NOTIFICATION, createPushNotificationFun),
  takeLatest(DELETE_PUSH_NOTIFICATION, deleteNotificationFun),
  takeLatest(GET_ALL_USER, getAllUserFun)
])
