import {
  GET_EXPERTIES_REQUEST,
  GET_EXPERTIES_SUCCESS,
  GET_EXPERTIES_FALUIRE,
  GET_UPSELL_DISCLAIMER,
  GET_UPSELL_DISCLAIMER_SUCCESS,
  GET_UPSELL_DISCLAIMER_FALUIRE,
  GET_ENGAGEMENT_HISTORY,
  GET_ENGAGEMENT_HISTORY_SUCCESS,
  GET_ENGAGEMENT_HISTORY_FALUIRE,
  GET_MY_CLIENT,
  GET_MY_CLIENT_SUCCESS,
  GET_MY_CLIENT_FALUIRE,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FALUIRE,
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENT_FALUIRE,
  ACCEPT_REQUEST,
  ACCEPT_REQUEST_SUCCESS,
  ACCEPT_REQUEST_FALUIRE,
  REQUEST_HOURS,
  REQUEST_HOURS_SUCCESS,
  REQUEST_HOURS_FALUIRE,
  ACCEPT_REQUEST_HOURS,
  ACCEPT_REQUEST_HOURS_SUCCESS,
  ACCEPT_REQUEST_HOURS_FALUIRE,
  POST_REQUEST_HOURS,
  POST_REQUEST_HOURS_SUCCESS,
  POST_REQUEST_HOURS_FALUIRE,
  GET_HISTORY_CSV,
  GET_HISTORY_CSV_SUCCESS,
  GET_HISTORY_CSV_FALUIRE,
  HISTORY_FILTER_REQUEST,
  HISTORY_FILTER_SUCCESS,
  HISTORY_FILTER_FALUIRE,
  EXPERT_END_ENGAGEMENT_REQUEST,
  EXPERT_END_ENGAGEMENT_SUCCESS,
  EXPERT_END_ENGAGEMENT_FALUIRE
} from "./types"

export const getExperties = data => ({
  type: GET_EXPERTIES_REQUEST,
  data
})

export const expertiesSuccess = data => ({
  type: GET_EXPERTIES_SUCCESS,
  data
})

export const expertiesFaluire = data => ({
  type: GET_EXPERTIES_FALUIRE,
  data
})

export const getUpsellExperties = () => ({
  type: GET_UPSELL_DISCLAIMER
})

export const upsellExpertiesSuccess = data => ({
  type: GET_UPSELL_DISCLAIMER_SUCCESS,
  data
})

export const upsellExpertiesFaluire = data => ({
  type: GET_UPSELL_DISCLAIMER_FALUIRE,
  data
})

export const getEngagementHistory = data => ({
  type: GET_ENGAGEMENT_HISTORY,
  data
})

export const EngagementHistorySuccess = data => ({
  type: GET_ENGAGEMENT_HISTORY_SUCCESS,
  data
})

export const EngagementHistoryFaluire = data => ({
  type: GET_ENGAGEMENT_HISTORY_FALUIRE,
  data
})

export const getClient = () => ({
  type: GET_MY_CLIENT
})

export const getClientSuccess = data => ({
  type: GET_MY_CLIENT_SUCCESS,
  data
})

export const getClientFailure = data => ({
  type: GET_MY_CLIENT_FALUIRE,
  data
})

export const addTeam = (data, setModal1) => ({
  type: ADD_TEAM,
  data,
  setModal1
})

export const addTeamSuccess = data => ({
  type: ADD_TEAM_SUCCESS,
  data
})

export const addTeamFailure = data => ({
  type: ADD_TEAM_FALUIRE,
  data
})

export const getEngagement = data => ({
  type: GET_ENGAGEMENT,
  data
})

export const getEngagementSuccess = data => ({
  type: GET_ENGAGEMENT_SUCCESS,
  data
})

export const getEngagementFailure = data => ({
  type: GET_ENGAGEMENT_FALUIRE,
  data
})

export const acceptRequest = (data, setModal) => ({
  type: ACCEPT_REQUEST,
  data,
  setModal
})

export const acceptRequestSuccess = (data, setModal) => ({
  type: ACCEPT_REQUEST_SUCCESS,
  data,
  setModal
})

export const acceptRequestFailure = data => ({
  type: ACCEPT_REQUEST_FALUIRE,
  data
})

export const requestHours = data => ({
  type: REQUEST_HOURS,
  data
})

export const requestHoursSuccess = data => ({
  type: REQUEST_HOURS_SUCCESS,
  data
})

export const requestHoursFailure = data => ({
  type: REQUEST_HOURS_FALUIRE,
  data
})

export const acceptRequestHours = (data, setModal) => ({
  type: ACCEPT_REQUEST_HOURS,
  data,
  setModal
})

export const acceptRequestHoursSuccess = (data, setModal) => ({
  type: ACCEPT_REQUEST_HOURS_SUCCESS,
  data,
  setModal
})

export const acceptRequestHoursFailure = data => ({
  type: ACCEPT_REQUEST_HOURS_FALUIRE,
  data
})

export const postRequestHours = (data, setModal) => ({
  type: POST_REQUEST_HOURS,
  data,
  setModal
})

export const postRequestHoursSuccess = (data, setModal) => ({
  type: POST_REQUEST_HOURS_SUCCESS,
  data,
  setModal
})

export const postRequestHoursFailure = data => ({
  type: POST_REQUEST_HOURS_FALUIRE,
  data
})

export const getHistoryCSV = data => ({
  type: GET_HISTORY_CSV,
  data
})

export const getHistoryCSVSuccess = data => ({
  type: GET_HISTORY_CSV_SUCCESS,
  data
})

export const getHistoryCSVFailure = data => ({
  type: GET_HISTORY_CSV_FALUIRE,
  data
})

export const hitoryFilters = data => ({
  type: HISTORY_FILTER_REQUEST,
  data
})

export const hitoryFiltersSuccess = data => ({
  type: HISTORY_FILTER_SUCCESS,
  data
})

export const hitoryFiltersFailure = data => ({
  type: HISTORY_FILTER_FALUIRE,
  data
})

export const expertEndEngagementRequest = (data, toggle1) => ({
  type: EXPERT_END_ENGAGEMENT_REQUEST,
  data,
  toggle1
})

export const expertEndEngagementSuccess = data => ({
  type: EXPERT_END_ENGAGEMENT_SUCCESS,
  data
})

export const expertEndEngagementFailure = data => ({
  type: EXPERT_END_ENGAGEMENT_FALUIRE,
  data
})
