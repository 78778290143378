import {
  ALL_EXPERT_REQUEST,
  ALL_EXPERT_SUCCESS,
  ALL_EXPERT_FALUIRE,
  PATCH_EXPERT_PROFILE,
  PATCH_EXPERT_PROFILE_SUCCESS,
  PATCH_EXPERT_PROFILE_FALUIRE,
  GET_BUSINESS_NAMES,
  GET_BUSINESS_NAMES_SUCCESS,
  GET_BUSINESS_NAMES_FALUIRE,
  SHARE_EXPERT_WITH_BUSINESS,
  SHARE_EXPERT_WITH_BUSINESS_SUCCESS,
  SHARE_EXPERT_WITH_BUSINESS_FAILURE,
  SEARCH_EXPERT_REQUEST,
  SEARCH_EXPERT_SUCCESS,
  SEARCH_EXPERT_FALUIRE,
  DELETE_INDUSTRY_AND_EXPERTIES,
  DELETE_INDUSTRY_AND_EXPERTIES_SUCCESS,
  DELETE_INDUSTRY_AND_EXPERTIES_FAILURE
} from "./types"

const initialState = {
  allAccounts: false,
  requesting: false,
  error: false,
  allBusinessData: false,
  shareRequesting: false,
  shareSuccess: false,
  searchRequesting: false,
  delRequesting: false,
  deleteResponse: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_EXPERT_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case ALL_EXPERT_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allAccounts: action.data
      }

    case ALL_EXPERT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case PATCH_EXPERT_PROFILE:
      return {
        ...state,
        requesting: true
      }

    case PATCH_EXPERT_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false
      }

    case PATCH_EXPERT_PROFILE_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_BUSINESS_NAMES:
      return {
        ...state,
        requesting: true
      }

    case GET_BUSINESS_NAMES_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allBusinessData: action.data
      }

    case GET_BUSINESS_NAMES_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case SHARE_EXPERT_WITH_BUSINESS:
      return {
        ...state,
        shareRequesting: true
      }

    case SHARE_EXPERT_WITH_BUSINESS_SUCCESS:
      return {
        ...state,
        error: false,
        shareRequesting: false,
        shareSuccess: action.data
      }

    case SHARE_EXPERT_WITH_BUSINESS_FAILURE:
      return {
        ...state,
        shareRequesting: false,
        error: action.data
      }

    case SEARCH_EXPERT_REQUEST:
      return {
        ...state,
        searchRequesting: true
      }

    case SEARCH_EXPERT_SUCCESS:
      return {
        ...state,
        error: false,
        searchRequesting: false,
        allAccounts: action.data
      }

    case SEARCH_EXPERT_FALUIRE:
      return {
        ...state,
        searchRequesting: false,
        error: action.data
      }

    case DELETE_INDUSTRY_AND_EXPERTIES:
      return {
        ...state,
        delRequesting: true
      }

    case DELETE_INDUSTRY_AND_EXPERTIES_SUCCESS:
      return {
        ...state,
        error: false,
        delRequesting: false,
        deleteResponse: action.data
      }

    case DELETE_INDUSTRY_AND_EXPERTIES_FAILURE:
      return {
        ...state,
        delRequesting: false,
        error: action.data
      }

    default:
      return state
  }
}
