import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Spinner
} from "reactstrap"
import "./style.css"
import PaginationPack from "components/PaginationPack"
import Select from "react-select"
import toast, { Toaster } from "react-hot-toast"

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import TextField from "@mui/material/TextField"

import { connect } from "react-redux"

import Images from "utils/Images"
import {
  createPushNotification,
  deletePushNotification,
  getPushNotification,
  getAllUser,
  createPushNotificationFaluire,
  editPushNotification,
  editPushNotificationFaluire
} from "./redux/actions"
import { getBadgeUnstyledUtilityClass } from "@mui/base"
import { data } from "jquery"
function PushNotification(props) {
  const {
    getPushNotifications,
    requesting,
    createNotification,
    getAllUsers,
    deleteRequest,
    backendError,
    editNotification
  } = props

  useEffect(() => {
    props.getPushNotification(1)
    props.getAllUser()
  }, [])

  useEffect(() => {
    createNotification && setModal(false)
  }, [createNotification])

  useEffect(() => {
    editNotification && setModal1(false)
  }, [editNotification])

  const handlePageChange = page => {
    props.getPushNotification(page)
  }
  const onChanges = newValue => {
    setValue(newValue)
  }

  const onDateChange = addValue => {
    setScheduleTime(addValue)
  }

  // const ACTIVATION_TIME_MAP = {
  //   Send_Now: "Send Now"
  // }

  // const getActivationDisplayValue = value => ACTIVATION_TIME_MAP[value] || value
  const [value, setValue] = useState(new Date())
  const [fileName, setFileName] = useState("")
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)

  const [notificationName, setNotificationName] = useState("")
  const [activationTime, setActivationTime] = useState(false)
  const [sendNotification, setSendNotification] = useState(false)

  const [multiData, setMultiData] = useState(false)
  const [selectLabels, setSelectLabels] = useState("")

  const [editNotificationName, setEditNotificationName] = useState("")
  const [activeTime, setActiveTime] = useState(false)
  const [scheduleTime, setScheduleTime] = useState(false)
  const [sentNotification, setsentNotification] = useState(false)
  const [selectExpert, setSelectExpert] = useState(false)
  const [editnotification, setEditNotification] = useState(false)
  const [subject, setSubject] = useState("")

  const [userId, setUserId] = useState(false)
  const [selectExperties, setSelectExperties] = useState(false)

  useEffect(() => {
    if (editnotification) {
      setEditNotificationName(
        editnotification?.title && editnotification?.title
      )
      setActiveTime(
        editnotification?.notification_type &&
          editnotification?.notification_type
      )
      // setSelectExpert(editnotification?.id && editnotification?.id)
      setsentNotification(
        editnotification?.scheduled_time && editnotification?.scheduled_time
      )
      setScheduleTime(editnotification?.created && editnotification?.created)
    }
  }, [editnotification])

  const onEdditNotification = () => {
    const apiData = {
      title: editNotificationName,
      notification_type: activeTime?.value,
      user: getPushNotifications ? getPushNotifications?.results[0]?.user : "",
      experts: selectExpert,
      expert_q: sentNotification?.label,
      scheduled_time: scheduleTime?.$d,
      message: editNotificationName,
      id: editnotification?.id,
      subject: subject
    }
    props.editPushNotificationFaluire(false)
    props.editPushNotification(apiData)
  }

  useEffect(() => {
    let tempArr = []
    getAllUsers &&
      getAllUsers.results.map(item => {
        tempArr.push({ value: item.id, label: item?.name })
      })
    setMultiData(tempArr)
  }, [getAllUsers])

  const onCreateNotification = () => {
    const apiData = {
      title: notificationName,
      notification_type: activationTime?.value,
      user: getPushNotifications ? getPushNotifications?.results[0]?.user : "",
      experts: selectLabels,
      expert_q: sendNotification?.label,
      scheduled_time: value?.$d,
      message: notificationName,
      subject: subject

      // subject:"Sdsf"
    }

    props.createPushNotificationFaluire(false)
    props.createPushNotification(apiData)
  }

  const toggle1 = () => {
    setModal(!modal)
    setNotificationName("")
    setActivationTime("")
    setSendNotification("")
    setSubject("")
    props.createPushNotificationFaluire(false)
  }
  const toggle2 = () => {
    setModal1(!modal1)
  }

  const data = [
    { name: "John Fred" },
    { name: "John Fred" },
    { name: "John Fred" },
    { name: "John Fred" },
    { name: "John Fred" },
    { name: "John Fred" }
  ]

  const dummyData = [
    {
      Name: "Description line goes here...",
      Client: "Client Name",
      Description: "educationcent.co/content",
      Rate: "Educational Center",
      StartDate: "06-15-2022",
      Status: "Active"
    }
  ]
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Push Notification
                  </CardTitle>
                  <Row style={{ display: "flex", paddingRight: "24px" }}>
                    <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={toggle1}
                    >
                      + Create Notification
                    </Button>
                    {/* {check && ( */}

                    {/* )} */}
                  </Row>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    overflowY: "hidden"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        padding: "25px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Notification Name
                    </th>
                    <th
                      style={{ color: "#808080", whiteSpace: "nowrap" }}
                      className="text-center"
                    >
                      Activation Time
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#808080",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Sent to
                    </th>

                    <th
                      className="text-center"
                      style={{
                        paddingLeft: "3%",
                        color: "#808080",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Actions
                    </th>
                  </tr>

                  {requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "90%",
                        // transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : getPushNotifications &&
                    getPushNotifications?.results.length > 0 ? (
                    <tbody>
                      {getPushNotifications?.results &&
                        getPushNotifications?.results.map(item => (
                          <>
                            <tr
                              style={{
                                // border: "3px solid",
                                // borderRadius: "50px !mportant",
                                backgroundColor: "white",
                                marginLeft: "25px",
                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  paddingLeft: "25px",
                                  borderBottomLeftRadius: "8px",
                                  borderTopLeftRadius: "8px"
                                }}
                              >
                                {item.title}
                              </td>

                              <td className="text-center">
                                {item?.notification_type == "Send_Now"
                                  ? "Send Now"
                                  : item?.notification_type}
                              </td>
                              <td className="text-center">{item.expert_q}</td>

                              <td
                                className="text-right "
                                // className="text-left "
                                style={{
                                  paddingTop: "14px",
                                  paddingRight: "30px",

                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end"
                                  }}
                                >
                                  <Button
                                    className="btn-round btn text-capitalize"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#3A0F7D",
                                      border: "1px solid  #3A0F7D",
                                      // display: "flex",
                                      // alignItems: "center",
                                      width: "max-content"
                                    }}
                                    onClick={() => {
                                      toggle2()
                                      setEditNotification(item)
                                      setSelectExperties(item?.expert_detail)
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "12px",

                                        // marginLeft: "20px",
                                        marginRight: "4px",
                                        marginTop: "-3px",
                                        borderRadius: "0px"
                                      }}
                                      src={Images.edit}
                                    />
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      props.deletePushNotification({
                                        id: item.id
                                      })
                                      setUserId(item.id)
                                    }}
                                    className="btn-round btn text-capitalize"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#3A0F7D",
                                      border: "1px solid  #3A0F7D",
                                      // display: "flex",
                                      // alignItems: "center",
                                      width: "max-content"
                                    }}
                                  >
                                    {userId === item.id &&
                                    props.deleteRequest ? null : (
                                      <img
                                        style={{
                                          height: "12px",

                                          // marginLeft: "20px",
                                          marginRight: "4px",
                                          marginTop: "-3px",
                                          borderRadius: "0px"
                                        }}
                                        src={Images.del}
                                      />
                                    )}
                                    {userId === item.id &&
                                    props.deleteRequest ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      "Delete"
                                    )}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))}
                    </tbody>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {getPushNotifications &&
                  getPushNotifications?.results.length > 0 ? (
                    requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={getPushNotifications?.page_size}
                          totalItemsCount={getPushNotifications?.total_objects}
                          activePage={getPushNotifications?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle1}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        {/* <Toaster position="top-center" /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Create Notification
          </h5>
          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row style={{}}>
            <Col>
              <Form>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "25px",
                      fontWeight: "600"
                    }}
                  >
                    Notification Name
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Enter Description."
                    name="text"
                    maxlength="170"
                    type="textarea"
                    value={notificationName}
                    onChange={e => setNotificationName(e.target.value)}
                    style={{ height: "143px", backgroundColor: "" }}
                  />
                  {backendError && backendError?.title && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px"
                      }}
                    >
                      {backendError?.title}
                    </label>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      // marginTop: "25px",
                      fontWeight: "600"
                    }}
                  >
                    Subject{" "}
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Subject"
                    name="text"
                    maxlength="170"
                    type="text"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    style={{}}
                  />

                  {backendError && backendError?.subject && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px"
                      }}
                    >
                      {backendError?.subject}
                    </label>
                  )}
                </FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    marginTop: "20px",
                    fontWeight: "600"
                  }}
                >
                  Activation Time
                </Label>

                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  style={{}}
                  value={activationTime}
                  onChange={value => {
                    setActivationTime(value)
                    // timeTrackerRequestFaluire(false)
                  }}
                  options={[
                    { value: "Send_Now", label: "Send Now" },
                    { value: "Scheduled", label: "Scheduled" }
                    // { value: 2, label: "30" },
                    // { value: 3, label: "45" }
                  ]}
                />
                {backendError && backendError?.notification_type && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {backendError?.notification_type}
                  </label>
                )}

                {activationTime?.label == "Scheduled" && (
                  <div>
                    <Label
                      style={{
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula",
                        marginTop: "20px",
                        fontWeight: "600"
                      }}
                    >
                      Notification Sent to
                    </Label>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        // label="Date&Time picker"
                        value={value}
                        onChange={onChanges}
                        renderInput={params => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                )}
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    marginTop: "20px",
                    fontWeight: "600"
                  }}
                >
                  Notification Sent to
                </Label>

                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  style={{}}
                  value={sendNotification}
                  onChange={value => {
                    setSendNotification(value)
                    // timeTrackerRequestFaluire(false)
                  }}
                  options={[
                    { value: 1, label: "Selected User" },
                    { value: 2, label: "All Business" },
                    { value: 3, label: "All Expert" }
                  ]}
                />

                {sendNotification?.label == "Selected User" && (
                  <>
                    <label
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Select User
                    </label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      placeholder="Choose User"
                      name="multipleSelect"
                      closeMenuOnSelect={false}
                      isMulti
                      // value={this.state.multipleSelect}
                      // onChange={(value) =>
                      //   setMultiDataName({ multipleSelect: value })
                      // }
                      onChange={e => {
                        setSelectLabels(`${e.map(a => a.value)}`)
                      }}
                      options={multiData}
                    />
                  </>
                )}
              </Form>
            </Col>
          </Row>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: 132,

                height: "49px"
              }}
              onClick={toggle1}
            >
              Cancel
            </Button>
            <div style={{ margin: "0px 10px 0px 10px" }}></div>
            <Button
              onClick={onCreateNotification}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                width: 154,
                height: "49px"
              }}
            >
              {props.createNotificationRequesting ? (
                <Spinner size="sm" />
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal1}
        toggle={toggle2}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        {/* <Toaster position="top-center" /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Edit Notification
          </h5>
          <img
            onClick={toggle2}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div>
            <label
              style={{
                marginBottom: "7px",
                marginTop: "17px",
                fontSize: "14px",
                fontFamily: "Khula",
                fontWeight: "600",
                color: "#808080"
              }}
            >
              Notification Name
            </label>
            <Input
              id="exampleText"
              placeholder="Enter Description."
              name="text"
              maxlength="170"
              type="textarea"
              value={editNotificationName}
              onChange={e => setEditNotificationName(e.target.value)}
              style={{ height: "143px", backgroundColor: "" }}
            />
            {/* <img style={{ width: '90px', height: '25px' }} src={Images.add_resume} alt="Logo" /> */}
          </div>
          <Row style={{}}>
            <Col>
              <Form>
                <FormGroup>
                  {/* {props.BackendError ? (
                    <p style={{ color: "red" }}>{props.BackendError}</p>
                  ) : null}
                  {state.descriptionOfEngagement.error ? (
                    <p style={{ color: "red", padding: 5 }}>
                      {state.descriptionOfEngagement.error}
                    </p>
                  ) : null} */}
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "20px",
                      fontWeight: "600"
                    }}
                  >
                    Activation Time
                  </Label>

                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    style={{}}
                    placeholder={
                      editnotification?.notification_type
                        ? editnotification?.notification_type
                        : "select"
                    }
                    // isSearchable={false}
                    value={activeTime}
                    onChange={value => {
                      setActiveTime(value)
                      // timeTrackerRequestFaluire(false)
                      // setBtnName("Submit")
                    }}
                    options={[
                      { value: "Send_Now", label: "Send Now" },
                      { value: "Scheduled", label: "Scheduled" }
                    ]}
                  />

                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "20px",
                      fontWeight: "600"
                    }}
                  >
                    Schedule Time
                  </Label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      // label="Date&Time picker"
                      value={scheduleTime}
                      onChange={onDateChange}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "20px",
                      fontWeight: "600"
                    }}
                  >
                    Send Notification To
                  </Label>

                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    style={{}}
                    placeholder={
                      editnotification?.scheduled_time
                        ? editnotification?.scheduled_time
                        : "select"
                    }
                    // isSearchable={false}
                    value={sentNotification}
                    onChange={value => {
                      setsentNotification(value)
                      // timeTrackerRequestFaluire(false)
                      // setBtnName("Submit")
                    }}
                    options={[
                      { value: 1, label: "Selected User" },
                      { value: 2, label: "All Business" },
                      { value: 3, label: "All Expert" }
                    ]}
                  />

                  <>
                    <label
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Select User
                    </label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      placeholder={"Select User"}
                      name="multipleSelect"
                      closeMenuOnSelect={false}
                      isMulti
                      value={selectExperties}
                      // onChange={(value) =>
                      //   setMultiDataName({ multipleSelect: value })
                      // }
                      onChange={e => {
                        setSelectExperties(e)
                        setSelectExpert(`${e.map(a => a.value)}`)
                      }}
                      options={multiData}
                    />
                  </>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",

                height: "49px",
                width: 154
              }}
              onClick={toggle2}
            >
              Cancel
            </Button>
            <div style={{ margin: "0px 10px 0px 10px" }}></div>
            <Button
              onClick={() => onEdditNotification()}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px",
                width: 154
              }}
            >
              {props.createNotificationRequesting ? (
                <Spinner size="sm" />
              ) : (
                " Create"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  getPushNotifications: state.PushNotification.getPushNotifications,
  createNotificationRequesting:
    state.PushNotification.createNotificationRequesting,
  createNotification: state.PushNotification.createNotification,
  requesting: state.PushNotification.requesting,
  getAllUsers: state.PushNotification.getAllUsers,
  deleteRequest: state.PushNotification.deleteRequest,
  backendError: state.PushNotification.error,
  editNotification: state.PushNotification.editNotification
})

const mapDispatchToProps = dispatch => ({
  getPushNotification: data => dispatch(getPushNotification(data)),
  createPushNotification: data => dispatch(createPushNotification(data)),
  createPushNotificationFaluire: data =>
    dispatch(createPushNotificationFaluire(data)),
  deletePushNotification: data => dispatch(deletePushNotification(data)),
  getAllUser: data => dispatch(getAllUser(data)),
  editPushNotification: data => dispatch(editPushNotification(data)),
  editPushNotificationFaluire: data =>
    dispatch(editPushNotificationFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(PushNotification)
