import React, { useState, useEffect } from "react"

// hooks
import useForm from "utils/useForm"

import toast, { Toaster } from "react-hot-toast"

import validator from "utils/validation"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Spinner
} from "reactstrap"

import {
  referRequest,
  referFaluire
} from "../../ExpertDesign/Screens/ExpertRefer/redux/actions"
import { Link } from "react-router-dom"
import Images from "utils/Images"
import { connect } from "react-redux"
import { getReferRequest } from "./redux/actions"

function Refer(props) {
  const {
    referRequest,
    requesting,
    Backenderror,
    refer,
    referFaluire,
    getReferRequest,
    refferData
  } = props

  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const toggle = () => {
    setModal(!modal)
    setState(stateSchema)
    referFaluire(false)
  }

  console.log("refferData", refferData)

  useEffect(() => {
    if (refer) {
      setModal(false)
      setState(stateSchema)
    }
  }, [refer])

  useEffect(() => {
    if (refer) {
      setModal2(false)
      setState(stateSchema)
    }
  }, [refer])

  const toggle2 = () => {
    setModal2(!modal2)
    setState(stateSchema)
    referFaluire(false)
  }

  const stateSchema = {
    nameOfExpert: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    shareableApplicationLink: {
      value: "",
      error: ""
    },
    explanationOfIncentives: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    nameOfExpert: {
      required: true
      // validator: validator.email
    },
    email: {
      required: true,
      validator: validator.email
    },
    shareableApplicationLink: {
      required: true
      // validator: validator.email
    },
    explanationOfIncentives: {
      required: true
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSubmit = async () => {
    const userId = await localStorage.getItem("user")

    const data = {
      user: userId,
      email: state.email.value,
      // application_link: state.shareableApplicationLink.value,
      description: state.explanationOfIncentives.value,
      name: state.nameOfExpert.value,
      referral_type: "Expert"
      // vertical_section: multiSelect
    }

    referRequest(data)
  }

  const handleOnSubmitS = async () => {
    const userId = await localStorage.getItem("user")

    const data = {
      user: userId,
      email: state.email.value,
      web_url: state.shareableApplicationLink.value,
      description: state.explanationOfIncentives.value,
      name: state.nameOfExpert.value,
      referral_type: "Business"
      // vertical_section: multiSelect
    }
    referRequest(data)
  }

  useEffect(() => {
    getReferRequest()
  }, [])

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Card className="card-plain">
          <CardHeader>
            <CardTitle
              tag="h4"
              style={{
                fontFamily: "Libre Caslon Text",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "27px",
                color: "#CACACA"
              }}
            >
              Refer
            </CardTitle>
          </CardHeader>
        </Card>
        <div>
          <Card>
            <Row
              className="justify-content-center justify-content-sm-between m-0"
              style={{
                display: "flex",
                alignItems: "center",

                border: "2px solid #E2E2E2",
                borderRadius: "10px"
              }}
            >
              <CardHeader>
                <CardTitle className="text-center" style={{ marginTop: "0px" }}>
                  Do you know someone amazing at digital marketing? Refer an
                  Expert!
                </CardTitle>
              </CardHeader>

              <CardFooter>
                <Button
                  className="btn-round text-capitalize
                      "
                  color="secondary"
                  style={{
                    backgroundColor: "#3A0F7D",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    width: "140px",
                    whiteSpace: "nowrap"
                  }}
                  onClick={toggle}
                >
                  Refer Expert
                </Button>
              </CardFooter>
            </Row>
          </Card>
        </div>

        <Card>
          <Row
            className="justify-content-center justify-content-sm-between m-0"
            style={{
              display: "flex",

              alignItems: "center",
              border: "2px solid #E2E2E2",
              borderRadius: "10px"
            }}
          >
            <CardHeader>
              <CardTitle className="text-center" style={{ marginTop: "0px" }}>
                Do you know a business that needs GrowTal’s support? Refer a
                Business!
              </CardTitle>
            </CardHeader>

            <CardFooter>
              <Button
                className="btn-round text-capitalize
                      "
                color="secondary"
                style={{
                  backgroundColor: "#3A0F7D",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                  width: "140px",
                  whiteSpace: "nowrap"
                }}
                onClick={toggle2}
              >
                Refer Business
              </Button>
            </CardFooter>
          </Row>
        </Card>

        <Card>
          <Row
            className="justify-content-center justify-content-sm-between m-0"
            style={{
              display: "flex",

              alignItems: "center",
              border: "2px solid #E2E2E2",
              borderRadius: "10px"
            }}
          >
            <CardHeader>
              <CardTitle className="text-center" style={{ marginTop: "0px" }}>
                Your referral history
              </CardTitle>
            </CardHeader>

            <CardFooter>
              <Link to="/business/business_referral_history">
                <Button
                  className="btn-round text-capitalize
                        "
                  color="secondary"
                  style={{
                    backgroundColor: "#3A0F7D",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    width: "144px",
                    whiteSpace: "nowrap"
                  }}
                >
                  Referral History
                </Button>
              </Link>
            </CardFooter>
          </Row>
        </Card>
        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px",
                whiteSpace: "nowrap"
              }}
            >
              Refer an Expert
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Name of Expert
                </Label>
                <Input
                  placeholder="Name"
                  type="text"
                  value={state.nameOfExpert.value}
                  onChange={e => {
                    handleOnChange("nameOfExpert", e.target.value)
                    referFaluire(false)
                  }}
                />
                {state.nameOfExpert.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {state.nameOfExpert.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.name ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.name[0]}
                  </label>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Email of Expert
                </Label>
                <Input
                  placeholder="Email"
                  type="email"
                  value={state.email.value}
                  onChange={e => {
                    handleOnChange("email", e.target.value)
                    referFaluire(false)
                  }}
                />
                {state.email.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {state.email.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.email ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.email[0]}
                  </label>
                ) : null}
              </FormGroup>
              {/* <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Shareable Application Link
                </Label>
                <Input
                  placeholder="https://"
                  type="url"
                  value={state.shareableApplicationLink.value}
                  onChange={e =>
                    handleOnChange(
                      "shareableApplicationLink",
                      e.target.value,
                      referFaluire(false)
                    )
                  }
                />
                {state.shareableApplicationLink.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {state.shareableApplicationLink.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.application_link && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.application_link}
                  </label>
                )}
              </FormGroup> */}
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description of Expertise
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Description"
                  name="text"
                  type="textarea"
                  value={state.explanationOfIncentives.value}
                  onChange={e => {
                    handleOnChange("explanationOfIncentives", e.target.value)
                    referFaluire(false)
                  }}
                />
                {state.explanationOfIncentives.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {state.explanationOfIncentives.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.description && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.description}
                  </label>
                )}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                marginRight: "11px"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnSubmit}
              className="btn-round text-capitalize
              "
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                height: "49px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              {requesting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal2}
          toggle2={toggle2}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Refer a Business
            </h5>
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Contact Name
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.nameOfExpert.value}
                  onChange={e => {
                    handleOnChange("nameOfExpert", e.target.value)
                    referFaluire(false)
                  }}
                />
                {state.nameOfExpert.error && (
                  <label style={{ color: "red" }}>
                    {state.nameOfExpert.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.name ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.name}
                  </label>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Contact Email
                </Label>
                <Input
                  placeholder="Type here"
                  type="email"
                  value={state.email.value}
                  onChange={e => {
                    handleOnChange("email", e.target.value)
                    referFaluire(false)
                  }}
                />
                {state.email.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {state.email.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.email ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.email[0]}
                  </label>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Business Name or Website URL
                </Label>
                <Input
                  placeholder="https://"
                  type="url"
                  value={state.shareableApplicationLink.value}
                  onChange={e =>
                    handleOnChange("shareableApplicationLink", e.target.value)
                  }
                />
                {state.shareableApplicationLink.error && (
                  <label style={{ color: "red" }}>
                    {state.shareableApplicationLink.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.application_link ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.application_link}
                  </label>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Additional information
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Description"
                  name="text"
                  type="textarea"
                  value={state.explanationOfIncentives.value}
                  onChange={e => {
                    handleOnChange("explanationOfIncentives", e.target.value)
                    referFaluire(false)
                  }}
                />
                {state.explanationOfIncentives.error && (
                  <label style={{ color: "red" }}>
                    {state.explanationOfIncentives.error}
                  </label>
                )}
                {Backenderror && Backenderror?.data?.description ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {Backenderror?.data?.description}
                  </label>
                ) : null}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle2}
              className="btn-round btn  text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                marginRight: "11px"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnSubmitS}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                height: "49px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              {requesting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

// export default ExpertRefer
const mapStateToProps = state => ({
  requesting: state.ExpertRefer.requesting,
  Backenderror: state.ExpertRefer.error,
  refer: state.ExpertRefer.refer,
  refferData: state.reffer.getReffer
})
const mapDispatchToProps = dispatch => ({
  referRequest: data => dispatch(referRequest(data)),
  referFaluire: data => dispatch(referFaluire(data)),
  getReferRequest: data => dispatch(getReferRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Refer)
