import React, { useState, useEffect } from "react"

const ReadMore = props => {
  
  const [isReadMore, setIsReadMore] = useState(false)
  const [paraId, SetparaId] = useState([])

  return (
    <>
     <span>
      {props.text.length > props.length? (
        <>
          {paraId.includes(props.item.id)
            ? props.text
            : props.text.slice(0, props.length)}
          <span
      
            style={{
              textDecoration: "none",
              cursor: "pointer"
            }}
          >
            {paraId.includes(props.item.id) ? (
              <p
                onClick={() => {
                  SetparaId(paraId.filter(val => val !== props.item.id))
                  setIsReadMore(false)
                }}
                style={{ color: "blue" }}
              >
                show less
              </p>
            ) : (
              <p
                onClick={() => {
                  SetparaId([...paraId, props.item.id])
                  setIsReadMore(true)
                }}
                style={{ color: "blue" }}
              >
                ...read more
              </p>
            )}
          </span>
        </>
      ) : (
        <>{props.text}</>
      )}
    </span>
    </>
  )
}
export default ReadMore
