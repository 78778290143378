export const GET_EXPERTIES_REQUEST = "GET_EXPERTIES_REQUEST"
export const GET_EXPERTIES_SUCCESS = "GET_EXPERTIES_SUCCESS"
export const GET_EXPERTIES_FALUIRE = "GET_EXPERTIES_FALUIRE"

export const GET_UPSELL_DISCLAIMER = "GET_UPSELL_DISCLAIMER"
export const GET_UPSELL_DISCLAIMER_SUCCESS = "GET_UPSELL_DISCLAIMER_SUCCESS"
export const GET_UPSELL_DISCLAIMER_FALUIRE = "GET_UPSELL_DISCLAIMER_FALUIRE"

export const GET_ENGAGEMENT_HISTORY = "GET_ENGAGEMENT_HISTORY"
export const GET_ENGAGEMENT_HISTORY_SUCCESS = "GET_ENGAGEMENT_HISTORY_SUCCESS"
export const GET_ENGAGEMENT_HISTORY_FALUIRE = "GET_ENGAGEMENT_HISTORY_FALUIRE"

export const GET_MY_CLIENT = "GET_MY_CLIENT"
export const GET_MY_CLIENT_SUCCESS = "GET_MY_CLIENT_SUCCESS"
export const GET_MY_CLIENT_FALUIRE = "GET_MY_CLIENT_FALUIRE"

export const ADD_TEAM = "ADD_TEAM"
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS"
export const ADD_TEAM_FALUIRE = "ADD_TEAM_FALUIRE"

export const GET_ENGAGEMENT = "GET_ENGAGEMENT"
export const GET_ENGAGEMENT_SUCCESS = "GET_ENGAGEMENT_SUCCESS"
export const GET_ENGAGEMENT_FALUIRE = "GET_ENGAGEMENT_FALUIRE"

export const ACCEPT_REQUEST = "ACCEPT_REQUEST"
export const ACCEPT_REQUEST_SUCCESS = "ACCEPT_REQUEST_SUCCESS"
export const ACCEPT_REQUEST_FALUIRE = "ACCEPT_REQUEST_FALUIRE"

export const REQUEST_HOURS = "REQUEST_HOURS"
export const REQUEST_HOURS_SUCCESS = "REQUEST_HOURS_SUCCESS"
export const REQUEST_HOURS_FALUIRE = "REQUEST_HOURS_FALUIRE"

export const ACCEPT_REQUEST_HOURS = "ACCEPT_REQUEST_HOURS"
export const ACCEPT_REQUEST_HOURS_SUCCESS = "ACCEPT_REQUEST_HOURS_SUCCESS"
export const ACCEPT_REQUEST_HOURS_FALUIRE = "ACCEPT_REQUEST_HOURS_FALUIRE"

export const POST_REQUEST_HOURS = "POST_REQUEST_HOURS"
export const POST_REQUEST_HOURS_SUCCESS = "POST_REQUEST_HOURS_SUCCESS"
export const POST_REQUEST_HOURS_FALUIRE = "POST_REQUEST_HOURS_FALUIRE"

export const GET_HISTORY_CSV = "GET_HISTORY_CSV"
export const GET_HISTORY_CSV_SUCCESS = "GET_HISTORY_CSV_SUCCESS"
export const GET_HISTORY_CSV_FALUIRE = "GET_HISTORY_CSV_FALUIRE"

export const HISTORY_FILTER_REQUEST = "HISTORY_FILTER_REQUEST"
export const HISTORY_FILTER_SUCCESS = "HISTORY_FILTER_SUCCESS"
export const HISTORY_FILTER_FALUIRE = "HISTORY_FILTER_FALUIRE"

export const EXPERT_END_ENGAGEMENT_REQUEST = "EXPERT_END_ENGAGEMENT_REQUEST"
export const EXPERT_END_ENGAGEMENT_SUCCESS = "EXPERT_END_ENGAGEMENT_SUCCESS"
export const EXPERT_END_ENGAGEMENT_FALUIRE = "EXPERT_END_ENGAGEMENT_FALUIRE"
