import {
  GET_PUSH_NOTIFICATION,
  GET_PUSH_NOTIFICATION_SUCCESS,
  GET_PUSH_NOTIFICATION_FALUIRE,
  CREATE_PUSH_NOTIFICATION,
  CREATE_PUSH_NOTIFICATION_SUCCESS,
  CREATE_PUSH_NOTIFICATION_FALUIRE,
  EDIT_PUSH_NOTIFICATION,
  EDIT_PUSH_NOTIFICATION_SUCCESS,
  EDIT_PUSH_NOTIFICATION_FALUIRE,
  DELETE_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION_SUCCESS,
  DELETE_PUSH_NOTIFICATION_FALUIRE,
  GET_ALL_USER,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FALUIRE
} from "./types"

export const getPushNotification = data => ({
  type: GET_PUSH_NOTIFICATION,
  data
})

export const getPushNotificationSuccess = data => ({
  type: GET_PUSH_NOTIFICATION_SUCCESS,
  data
})
export const getPushNotificationFaluire = () => ({
  type: GET_PUSH_NOTIFICATION_FALUIRE
})

export const createPushNotification = data => ({
  type: CREATE_PUSH_NOTIFICATION,
  data
})

export const createPushNotificationSuccess = data => ({
  type: CREATE_PUSH_NOTIFICATION_SUCCESS,
  data
})
export const createPushNotificationFaluire = data => ({
  type: CREATE_PUSH_NOTIFICATION_FALUIRE,
  data
})

export const editPushNotification = data => ({
  type: EDIT_PUSH_NOTIFICATION,
  data
})

export const editPushNotificationSuccess = data => ({
  type: EDIT_PUSH_NOTIFICATION_SUCCESS,
  data
})
export const editPushNotificationFaluire = data => ({
  type: EDIT_PUSH_NOTIFICATION_FALUIRE,
  data
})

export const deletePushNotification = data => ({
  type: DELETE_PUSH_NOTIFICATION,
  data
})

export const deletePushNotificationSuccess = data => ({
  type: DELETE_PUSH_NOTIFICATION_SUCCESS,
  data
})
export const deletePushNotificationFaluire = data => ({
  type: DELETE_PUSH_NOTIFICATION_FALUIRE,
  data
})

export const getAllUser = data => ({
  type: GET_ALL_USER,
  data
})

export const getAllUserSuccess = data => ({
  type: GET_ALL_USER_SUCCESS,
  data
})
export const getAllUserFaluire = data => ({
  type: GET_ALL_USER_FALUIRE,
  data
})
