import React, { useState, useEffect } from "react"

import moment from "moment"
//Components
import {
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Spinner,
  CardTitle,
  CardHeader,
  Button
} from "reactstrap"
import PaginationPack from "components/PaginationPack"
import ReactTable from "components/ReactTable/ReactTable.js"

import { logOurRequest, pageRequest, exportCSVRequest } from "./redux/actions"
import { connect } from "react-redux"
import "./style.scss"

function BusinessLogHour(props) {
  const {
    logOurRequest,
    logHourData,
    requesting,
    pageRequest,
    exportCSVRequest,
    csvrequesting
  } = props

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    logOurRequest()
  }, [])

  useEffect(() => {
    let tempArray = []

    if (logHourData && logHourData.results.length > 0) {
      logHourData.results.map(item => {
        const tempObject = {
          id: item.id,
          engagement: item.user_engagement?.description,
          expert: item?.expert_name,
          description: item.description,
          time: `${item?.hour} hour ${item?.minute} minutes`,
          date: item?.date ? moment(item.date).format("MM-DD-YYYY") : "N/A"
        }
        tempArray.push(tempObject)
      })
    }

    setTableData(tempArray)
  }, [logHourData])

  const handlePageChange = page => {
    pageRequest(page)
  }

  const handleExportCSV = () => {
    exportCSVRequest()
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader className={{ padding: 0 }}>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Col lg="6" md="6" sm="6">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        onClick={() => props.history.goBack()}
                        style={{
                          height: "40px",
                          width: "40px",
                          marginRight: "19px",
                          cursor: "pointer"
                        }}
                        src={require("assets/img/backbtn.png")}
                      />
                      <CardTitle
                        tag="h4"
                        style={{
                          fontFamily: "Libre Caslon Text",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "22px",
                          lineHeight: "27px",
                          color: "#CACACA"
                        }}
                      >
                        Log History
                      </CardTitle>
                    </div>
                  </Col>

                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      margin: "0px"
                    }}
                  >
                    <Button
                      onClick={() => handleExportCSV()}
                      className="btn-round btn px-2 py-3 text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        width: "220px",
                        height: "42px",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px"
                      }}
                    >
                      {csvrequesting ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          <img
                            style={{
                              height: 13,
                              marginRight: "13px",
                              borderRadius: "0px"
                            }}
                            src={require("assets/img/LogLight.png")}
                          />
                          Export Excel, CSV
                        </>
                      )}
                    </Button>
                  </Row>
                </Row>
              </CardHeader>

              <CardBody>
                <div style={{}}>
                  <Table
                    responsive="md"
                    style={{
                      border: "3px solid transparent"
                    }}
                  >
                    {requesting ? (
                      <div
                        style={{
                          height: " 200px",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : logHourData && logHourData?.results.length > 0 ? (
                      <ReactTable
                        data={tableData}
                        columns={[
                          {
                            Header: "Engagements",
                            accessor: "engagement"
                          },
                          {
                            Header: "Experts",
                            accessor: "expert"
                          },
                          {
                            Header: "Discription",
                            accessor: "description",
                            sortable: false,
                            filterable: false
                          },
                          {
                            Header: "Time",
                            accessor: "time",
                            sortable: false,
                            filterable: false
                          },
                          {
                            Header: "Date",
                            accessor: "date"
                          }
                        ]}
                        className="-striped -highlight primary-pagination"
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </Table>
                </div>
                <div className="pt-4 d-flex justify-content-center">
                  {logHourData && logHourData?.results.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={logHourData?.page_size}
                        totalItemsCount={logHourData?.total_objects}
                        activePage={logHourData?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.BusinessLogHour.requesting,
  logHourData: state.BusinessLogHour.logourData,
  csvrequesting: state.BusinessLogHour.csvrequesting
})

const mapDispatchToProps = dispatch => ({
  logOurRequest: data => dispatch(logOurRequest(data)),
  pageRequest: data => dispatch(pageRequest(data)),
  exportCSVRequest: () => dispatch(exportCSVRequest())
})
export default connect(mapStateToProps, mapDispatchToProps)(BusinessLogHour)
