import {
  PATCH_ACCOUNTS_REQUEST,
  PATCH_ACCOUNTS_SUCCESS,
  PATCH_ACCOUNTS_FALUIRE,
  ALL_ACCOUNT_PAGE_REQUEST,
  ALL_ACCOUNT_PAGE_SUCCESS,
  ALL_ACCOUNT_PAGE_FALUIRE
} from "./types"

const initialState = {
  allAccounts: false,
  pagination: false,
  updatedAccount: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PATCH_ACCOUNTS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case PATCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        updatedAccount: action.data
      }

    case PATCH_ACCOUNTS_FALUIRE:
      return {
        ...state,
        error: action.data,
        requesting: false
      }

    case ALL_ACCOUNT_PAGE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case ALL_ACCOUNT_PAGE_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allAccounts: action.page,
        pagination: action.page
      }

    case ALL_ACCOUNT_PAGE_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }

    default:
      return state
  }
}
