import { BENEFITS_REQUEST, BENEFITS_SUCCESS, BENEFITS_FALUIRE } from "./types"

export const benefitsRequest = data => ({
  type: BENEFITS_REQUEST,
  data
})

export const benefitsSuccess = data => ({
  type: BENEFITS_SUCCESS,
  data
})

export const benefitsFaluire = data => ({
  type: BENEFITS_FALUIRE,
  data
})
