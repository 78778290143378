import React, { useEffect, useState } from "react"
import { Route, Redirect } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import routes from "routes"
import NotFound from "Containers/NotFound"

const RouteGuard = ({
  isProtected = false,
  isInitalProfile = false,
  // accessToken,
  // user,
  token,
  profileData,
  component: Component,
  layout: layout,
  ...rest
}) => {
  let allData = false
  let accessToken = null

  const newData = localStorage.getItem("userAllData")
  accessToken = localStorage.getItem("accessToken")
  allData = JSON.parse(newData)

  const accessToken1 = localStorage.getItem("authToken")
  const usertype = localStorage.getItem("userType")

  return (
    <Route
      {...rest}
      render={props => {
        const isPathExists = routes.find(
          e => `${e.layout}${e.path}` == props.location.pathname
        )

        if (isProtected) {
          return accessToken ? (
            // <Redirect to={{ pathname: "/admin/time_tracker" }} />
            // <Component {...props} />
            <>
              {isPathExists ? (
                <Component {...props} />
              ) : (
                <Route component={NotFound} />
              )}
            </>
          ) : !allData?.profile && accessToken1 ? (
            usertype === "Business" ? (
              <Redirect to={{ pathname: "/profile/BusinessHome" }} />
            ) : usertype === "Admin" ? (
              <Redirect to={{ pathname: "/admin/time_tracker" }} />
            ) : (
              <Redirect to={{ pathname: "/profile/HomeIntialProfile" }} />
            )
          ) : allData?.profile && accessToken1 ? (
            <Component {...props} />
          ) : !allData?.profile && accessToken1 && usertype === "Admin" ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/auth/welcome" }} />
          )
          // (!allData?.profile && allData?.token && allData?.user?.profile_picture !== null && allData?.user?.linkedin_profile_url !== null) ? (
          //   <Redirect to={{ pathname: "/auth/HomeInitialProfileV2" }} />
          // ) : (
          // )
          // <Component {...props} />
        } else {
          return accessToken ? (
            usertype === "Expert" ? (
              <Redirect
                to={{
                  pathname: "/Expert/Engagements"
                }}
              />
            ) : usertype === "Business" ? (
              <Redirect
                to={{
                  pathname: "/business/my_team"
                }}
              />
            ) : (
              usertype === "Admin" && (
                <Redirect
                  to={{
                    pathname: "/admin/time_tracker"
                  }}
                />
              )
            )
          ) : (
            <>
              {isPathExists ? (
                <Component {...props} />
              ) : (
                <Route component={NotFound} />
              )}
            </>
          )
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  // accessToken: state.signIn.accessToken
  // userDetails: state.signIn.userDetails,
  // timerRef: state.flightSearch.timerRef
  // userType: state.LoginScreen.userType,
  token: state.LoginScreen.accessToken,
  // user: state.LoginScreen.user,
  profileData: state.LoginScreen.profileData
})

// const mapStateToProps = createStructuredSelector({
//     user: makeSelectUser(),
// });

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(RouteGuard)

// layout === "/admin" ? (
//   <Component {...props} />
// ) : layout === "/expert" ? (
//   <Component {...props} />
// ) : layout === "/business" ? (
//   <Component {...props} />
// ) : null

// return accessToken ? (
//   usertype === "Expert" ? (
//     <Redirect
//       to={{
//         pathname: "/Expert/engagement_history"
//       }}
//     />
//   ) : usertype === "business" ? (
//     <Redirect
//       to={{
//         pathname: "/business/my_team"
//       }}
//     />
//   ) : usertype === "admin" ? (
//     <Redirect
//       to={{
//         pathname: "/admin/time_tracker"
//       }}
//     />
//   ) : (
//     // <Redirect to={{ pathname: '/admin/time_tracker' }} />
//     <Component {...props} />
//   )
// ) : (
//   <Redirect to={{ pathname: "/auth/welcome" }} />
// )
