import React, { useState } from "react"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap"
import Select from "react-select"

function ManageContracts(props) {
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)
  const [modal, setModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  let PageSize = 10

  const toggle = () => setModal(!modal)

  const handleSubmit = () => {}
  const dummyData = [
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    },
    {
      Name: "Agreement tile goes here..."
    }
  ]

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex",alignItems:"center" }}>
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      // marginLeft: "20px",
                      marginRight: "19px"
                      // marginTop: "1px"
                    }}
                    src={require("assets/img/backbtn.png")}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Manage Contracts
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}

                  {/* </div> */}
                </div>
                {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
              </CardHeader>
              <CardBody>
                <Table
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Agreement Title
                    </th>
                    {/* <th style={{ color: "#808080" }}>Project Exhibits</th>
                    <th style={{ color: "#808080" }}>Expert Fullname</th>
                    <th style={{ color: "#808080" }}>Document Type</th> */}
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            marginLeft: "25px"
                          }}
                        >
                          <td style={{ padding: "25px" }}>{item.Name}</td>

                          <td
                            className="text-right"
                            style={{
                              paddingRight: "25px",
                              paddingTop: "14px"
                            }}
                          >
                            <Button
                              className="btn-round btn text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                color: "#3A0F7D",
                                border: "1px solid  #3A0F7D"
                              }}
                              onClick={toggle}
                            >
                              <img
                                style={{
                                  height: "7px",
                                  width: "14px",
                                  // marginLeft: "20px",
                                  marginRight: "4px",
                                  marginTop: "-3px"
                                }}
                                src={require("assets/img/eyeIcon.png")}
                              />
                              View Details
                            </Button>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  <PaginationPack />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
          style={{ maxWidth: "994px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Agreement Detail
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Client Agreement
                </Label>
                <Input
                  disabled
                  defultValue="Client agreement title..."
                  placeholder="Client agreement title..."
                  type="text"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Agreement Content
                </Label>
                <Input
                  disabled
                  defultValue="Lorem ipsum dolor sit amet"
                  placeholder="Lorem ipsum dolor sit amet, 
                  "
                  type="textarea"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  Signature
                </Label>
              </FormGroup>
              <img
                // onClick={toggle}
                style={{
                  height: 94,
                  width: 391,
                  marginTop: "-10px",
                  // marginLeft: "20px"
                  // marginRight: "12px"
                  marginBottom: "20px"
                }}
                src={require("assets/img/signature.png")}
              />
            </Form>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ManageContracts
