import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { GET_REFFER_REQUEST } from "./types"

// actions
import { getReferSuccess, getReferFaluire } from "./actions"

async function getReferAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/refer_score/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getReferData({ data }) {
  try {
    const response = yield call(getReferAPI, data)
    yield put(getReferSuccess(response?.data))
    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e
    yield put(getReferFaluire(response?.data))
  }
}

export default all([takeLatest(GET_REFFER_REQUEST, getReferData)])
