import { GET_REFFER_REQUEST, GET_REFFER_SUCCESS, GET_REFFER_FALUIRE } from "./types"

export const getReferRequest = data => ({
  type: GET_REFFER_REQUEST,
  data
})

export const getReferSuccess = data => ({
  type: GET_REFFER_SUCCESS,
  data
})

export const getReferFaluire = data => ({
  type: GET_REFFER_FALUIRE,
  data
})
