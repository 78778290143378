import { GET_REFFER_REQUEST, GET_REFFER_SUCCESS, GET_REFFER_FALUIRE } from "./types"

const initialState = {
  requesting: false,
  error: false,
  getReffer: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REFFER_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_REFFER_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        getReffer: action.data
      }

    case GET_REFFER_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
