import React, { useState } from "react"

import PaginationPack from "components/PaginationPack"

import toast, { Toaster } from "react-hot-toast"

// hooks
import useForm from "utils/useForm"
import validator from "utils/validation"
// import {useForm} from 'react-hook-form'
import Images from "utils/Images"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"

function ManageHistory() {
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)

  const dummyData = [
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    }
  ]

  const stateSchema = {
    Expert: {
      value: "",
      error: ""
    },
    rate: {
      value: "",
      error: ""
    },
    description: {
      value: "",
      error: ""
    },
    name: {
      value: "",
      error: ""
    },
    companyName: {
      value: "",
      error: ""
    },
    associateEmail: {
      value: "",
      error: ""
    },
    emailForInvoice: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    Expert: {
      required: true
      // validator: validator.email
    },
    rate: {
      required: true
      // validator: validator.email
    },
    description: {
      required: true
      // validator: validator.email
    },
    name: {
      required: true
      // validator: validator.email
    },
    companyName: {
      required: true
      // validator: validator.email
    },
    associateEmail: {
      required: true
      // validator: validator.email
    },
    emailForInvoice: {
      required: true
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSubmit = () => {
    if (state.Expert.value && state.rate.value && state.description.value) {
    } else {
      toast.error("All fields are Require")
    }
  }
  //  On Edit Profile Information
  const handleOnEditProfileInformation = () => {
    if (
      state.name.value &&
      state.companyName.value &&
      state.associateEmail.value &&
      state.emailForInvoice.value
    ) {
    } else {
      toast.error("All fields are Require")
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: 0
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Management History
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}

                  <Button
                    className="btn-round text-capitalize"
                    style={{
                      backgroundColor: "#fff",
                      color: "#3A0F7D",
                      border: "1px solid  #3A0F7D",
                      width: "220px",
                      height: "49px",
                      marginTop: "0px",
                      borderRedius: "0px",
                      display: "flex",
                      alignItems: "center",
                      width: "max-content"
                    }}
                  >
                    {/* <Link to={sample} target="_blank" rel="noreferrer"> */}
                    <img
                      //   onClick={toggle}
                      style={{
                        height: 13,
                        width: 13,
                        marginTop: "-4px",
                        // marginLeft: "20px"
                        marginRight: "16px",
                        borderRadius: "0px"
                      }}
                      src={Images.LogLight}
                    />
                    Expport Excel, CSV
                    {/* </Link> */}
                  </Button>
                  {/* </div> */}
                </Row>
                {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
              </CardHeader>
              <CardBody>
                <Table
                //  responsive="md"
                responsive
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px",
                    borderRadius: "8px"
                  }}
                >
                  {/* <thead> */}

                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        // marginTop: "40px",
                        // padding: "25px",
                        fountFimly: "khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        width: "max-content",
                        paddingLeft: "24px",border: "3px solid transparent"
                      }}
                    >
                      <FormGroup check>
                        <div
                          style={{
                            display: "flex"
                          }}
                        >
                          <Label check>
                            <Input type="checkbox" />
                            <span
                              className="form-check-sign"
                              style={{
                                height: "10px",
                                backgroundColor: "#3A0F7D"
                              }}
                            />
                            <span
                              style={{
                                color: "#808080",
                                fontFamily: "Khula",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "15px",
                                lineHeight: "22px",
                                // margin: "0px"
                                marginLeft: "38px"
                              }}
                            >
                              Rectangle Engagement Title
                              {/* {item.Name} */}
                            </span>
                          </Label>
                        </div>
                      </FormGroup>
                    </th>

                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        // paddingRight: "35%",
                        fountFimly: "khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        paddingRight: "450px"
                      }}
                    >
                      Action
                    </th>
                  </tr>

                  {/* </thead> */}
                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            // borderRadius: "8px",

                            borderBottomLeftRadius: "18px",
                            borderTopLeftRadius: "18px",
                            marginLeft: "25px",
                            border: "3px solid transparent"
                          }}
                        >
                          <td
                            style={{
                              padding: "25px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px"
                            }}
                          >
                            <FormGroup check>
                              <div
                                style={{
                                  display: "flex"
                                }}
                              >
                                <Label check>
                                  <Input type="checkbox" />
                                  <span
                                    className="form-check-sign"
                                    style={{
                                      height: "10px",
                                      backgroundColor: "#3A0F7D"
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#808080",
                                      fontFamily: "Khula",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "22px",
                                      // margin: "0px"
                                      marginLeft: "38px"
                                    }}
                                  >
                                    <Link
                                      style={{
                                        textDecoration: "none",
                                        color: "black"
                                      }}
                                    >
                                      {item.Name}
                                    </Link>
                                  </span>
                                </Label>
                              </div>
                            </FormGroup>
                            {/* <img
                              style={{
                                height: 22,
                                width: 22,
                                borderRadius: 0,
                                marginRight: 10
                              }}
                              src={Images.Rectangle}
                            />
                            {item.Name} */}
                          </td>

                          <td
                            className="text-center"
                            style={{
                              paddingRight: "25px",
                              paddingTop: "14px",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end"
                              }}
                            >
                              <Button
                                className="btn-round btn text-capitalize"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  marginRight: "5px",
                                  display: "flex",
                                  width: "max-content",
                                  alignItems: "center"
                                }}
                                // onClick={toggle}
                              >
                                <img
                                  style={{
                                    height: "11px",

                                    // marginLeft: "20px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.LogLight}
                                />
                                Export to CSV
                              </Button>
                              <Button
                                className="btn-round btn text-capitalize"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  marginRight: "5px",
                                  display: "flex",
                                  width: "max-content",
                                  alignItems: "center"
                                }}
                                onClick={toggle}
                              >
                                <img
                                  style={{
                                    height: "11px",

                                    // marginLeft: "20px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={require("assets/img/EngagementInfo.png")}
                                />
                                Engagement Info
                              </Button>
                              <Button
                                className="btn-round btn text-capitalize"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  display: "flex",
                                  width: "max-content",
                                  alignItems: "center"
                                }}
                                onClick={toggle1}
                              >
                                <img
                                  style={{
                                    height: "13px",

                                    // marginLeft: "20px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={require("assets/img/EditProfileInfo.png")}
                                />
                                Edit Profile Info
                              </Button>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  <PaginationPack />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Expert Information
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.Expert.value}
                  onChange={e => handleOnChange("Expert", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Rate
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.rate.value}
                  onChange={e => handleOnChange("rate", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name="text"
                  type="textarea"
                  value={state.description.value}
                  onChange={e => handleOnChange("description", e.target.value)}
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                fontSize:"14px",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnSubmit}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                height: "49px",
                fontSize:"14px",

                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          //    className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Edit Profile Information
            </h5>
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Name
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.name.value}
                  onChange={e => handleOnChange("name", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Company Name
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.companyName.value}
                  onChange={e => handleOnChange("companyName", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Associate Email
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.associateEmail.value}
                  onChange={e =>
                    handleOnChange("associateEmail", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Email for Invoice
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  value={state.emailForInvoice.value}
                  onChange={e =>
                    handleOnChange("emailForInvoice", e.target.value)
                  }
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle1}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnEditProfileInformation}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                height: "49px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ManageHistory
