import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Modal,
  Spinner
} from "reactstrap"
import Switch from "react-switch"
import { connect } from "react-redux"
import Select from "react-select"
import { resetAllStates } from "Containers/LoginScreen/redux/actions"

import validator from "utils/validation"
import useForm from "utils/useForm"

import toast, { Toaster } from "react-hot-toast"
import {
  DeleteAccountRequest,
  changePassRequest,
  feedbackRequest,
  getTermsAndCondition,
  getPrivicyPolicy,
  notificationSettings,
  feedbackFaluire,
  changePassFaluire,
  feedbackSuccess
} from "./redux/actions"

function Settings(props) {
  const {
    DeleteAccountRequest,
    history,
    changePassRequest,
    feedbackRequest,
    getTermsAndCondition,
    getPrivicyPolicy,
    resetAllStates,
    requesting,
    BackendError,
    feedBack,
    notificationSettings,
    changePassFaluire,
    feedbackFaluire,
    feedbackSuccess,
    notifiySetting
  } = props

  const [verticalTabs, setVerticalTabs] = useState("Privacy Policy")
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [openSwitch, SetopenSwitch] = useState(
    notifiySetting ? notifiySetting.notification_email : true
  )
  const [openSwitch1, SetopenSwitch1] = useState(false)

  const [openSwitch2, SetopenSwitch2] = useState(
    notifiySetting ? notifiySetting.notification_app : true
  )

  const toggle = () => {
    setModal(!modal)
  }
  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => setModal2(!modal2)
  const stateSchema = {
    newPassword: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    },
    confirmPassword: {
      value: "",
      error: ""
    },
    subject: {
      value: "",
      error: ""
    },
    message: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    newPassword: {
      required: true
    },
    password: {
      required: true
    },
    confirmPassword: {
      required: true
    },
    subject: {
      required: true
    },
    message: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    getTermsAndCondition()
    getPrivicyPolicy()
  }, [])

  useEffect(() => {
    if (feedBack) {
      setModal(true)
      setState(stateSchema)
    }
  }, [feedBack])

  const onChangePassword = () => {
    // if (
    //   // state.engagement.value &&
    //   // state.client.value &&
    //   state.newPassword.value &&
    //   state.password.value &&
    //   state.confirmPassword.value
    // ) {
    handleChangePassword()
    // } else {
    //   toast.error("All fields are Require")
    // }
  }

  const onFeedBack = () => {
    if (
      // state.engagement.value &&
      // state.client.value &&
      state.subject.value &&
      state.message.value
    ) {
      // toggle()
    } else {
      // toast.error("All fields are Require")
    }
  }

  const handleDeleteAccount = () => {
    DeleteAccountRequest()
    toggle1()
    setTimeout(() => {
      localStorage.clear()
      history.push("/auth/welcome")
    }, 1000)
  }

  const handleLogout = () => {
    // handleLogout();
    localStorage.clear()
    // localStorage.clear()
    resetAllStates()
    setTimeout(() => {
      history.push("/auth/welcome")
    }, 1000)
    toggle2()
  }

  const handleChangePassword = () => {
    const data = {
      old_password: state.password.value,
      new_password1: state.newPassword.value,
      new_password2: state.confirmPassword.value
    }
    changePassRequest(data)
    setState(stateSchema)
  }

  const handleFeedback = async () => {
    const userId = await localStorage.getItem("user")
    const data = {
      subject: state.subject.value,
      body: state.message.value,
      user: userId
    }
    feedbackRequest(data)
    // toggle()
  }

  // const handleToggleSettign = async () => {
  //   const userId = await localStorage.getItem("user")
  //   setTimeout(() => {
  //     const data = {
  //       user: userId,
  //       email: toggleEmail,
  //       app: toggleInApp
  //     }
  //     notificationSettings(data)
  //   }, 1000)
  // }

  const handleChange = e => {
    const data = {
      notification_email: e
    }
    notificationSettings(data)
    SetopenSwitch(e)
    // handleToggleSettign()
  }
  const handleChange1 = () => SetopenSwitch1(!openSwitch1)
  const handleChange2 = e => {
    const data = {
      notification_app: e
    }
    notificationSettings(data)
    SetopenSwitch2(e)
    // handleToggleSettign()
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12">
            <Card className="card-plain" style={{}}>
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA",
                      paddingLeft: "14px"
                    }}
                  >
                    Settings
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}
                  {/* <p style={{
                                        border: '2px #3A0F7D solid',
                                        padding: '12px 60px 12px 60px',
                                        borderRadius: '50px',
                                        color: '#3A0F7D',
                                        fontWeight: '700',
                                        fontSize: '16px'
                                    }}>+ Suggest New Engagements</p> */}
                </div>
              </CardHeader>
              <CardBody>
                <Col lg="12">
                  <Card style={{ backgroundColor: "transparent" }}>
                    <CardBody>
                      <Row>
                        <Col
                          xxl="1"
                          xl="3"
                          lg="5"
                          md="4"
                          sm="12"
                          xs="12"
                          className="pb-3"
                        >
                          <div
                            className="nav-tabs-navigation verical-navs p-0"
                            style={{}}
                          >
                            <div
                              className="nav-tabs-wrapper"
                              style={{
                                backgroundColor: "white",

                                border: "2px solid #E2E2E2",
                                borderRadius: "8px",
                                padding: "0px"
                              }}
                            >
                              <Nav
                                className="flex-column nav-stacked"
                                role="tablist"
                                tabs
                              >
                                <NavItem>
                                  <NavLink
                                    exact={true}
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Privacy Policy"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setVerticalTabs("Privacy Policy")
                                      changePassFaluire(false)
                                      feedbackFaluire(false)
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px",
                                        whiteSpace: "nowrap"
                                      }}
                                    >
                                      Privacy Policy
                                    </p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Terms & Conditions"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setVerticalTabs("Terms & Conditions")
                                      changePassFaluire(false)
                                      feedbackFaluire(false)
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px"
                                      }}
                                    >
                                      Terms & Conditions
                                    </p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Change Password"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setVerticalTabs("Change Password")
                                      changePassFaluire(false)
                                      feedbackFaluire(false)
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px"
                                      }}
                                    >
                                      Change Password
                                    </p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Feedback"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setVerticalTabs("Feedback")
                                      changePassFaluire(false)
                                      feedbackFaluire(false)
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px"
                                      }}
                                    >
                                      Feedback
                                    </p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Notifications Settings"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setVerticalTabs("Notifications Settings")
                                      changePassFaluire(false)
                                      feedbackFaluire(false)
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px"
                                      }}
                                    >
                                      Notifications Settings
                                    </p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Delete Account"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      // setVerticalTabs("Delete Account")
                                      toggle1()
                                      changePassFaluire(false)
                                      feedbackFaluire(false)
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px"
                                      }}
                                    >
                                      Delete Account
                                    </p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    data-toggle="tab"
                                    href="# "
                                    role="tab"
                                    className={
                                      verticalTabs === "Logout" ? "active" : ""
                                    }
                                    onClick={
                                      () => {
                                        toggle2()
                                        changePassFaluire(false)
                                        feedbackFaluire(false)
                                      }
                                      // setVerticalTabs("Logout")
                                    }
                                    style={{ display: "flex" }}
                                  >
                                    <div></div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        marginLeft: "25px"
                                      }}
                                    >
                                      Logout
                                    </p>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </Col>
                        <Col
                          lg="7"
                          md="7"
                          sm="12"
                          xs="12"
                          xxl="10"
                          xl="9"
                          style={{
                            backgroundColor: "white",
                            border: "2px solid #E2E2E2",
                            borderRadius: "8px"
                          }}
                        >
                          {/* Tab panes */}
                          <TabContent activeTab={verticalTabs}>
                            <Toaster position="top-center" />
                            <TabPane tabId="Privacy Policy">
                              <p
                                style={{
                                  marginTop: "25px",
                                  color: "#CACACA",
                                  fontSize: "22px"
                                }}
                              >
                                Privacy Policy
                              </p>
                              <div className="privDiv1">
                                {props.requesting ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Spinner size="lg" />
                                  </div>
                                ) : (
                                  <div className="divBorderSetting">
                                    <div>
                                      {props.privicypolicy &&
                                        props.privicypolicy.map(item => (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item.privacy_policy
                                            }}
                                          ></div>
                                        ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TabPane>
                            <TabPane tabId="Terms & Conditions">
                              <p
                                style={{
                                  marginTop: "25px",
                                  color: "#CACACA",
                                  fontSize: "22px"
                                }}
                              >
                                Terms & Conditions
                              </p>
                              <div className="privDiv1">
                                {props.requesting ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Spinner size="lg" />
                                  </div>
                                ) : (
                                  <div className="divBorderSetting">
                                    <div>
                                      {props.termsAndCondition &&
                                        props.termsAndCondition.map(item => (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item.terms_and_condition
                                            }}
                                          ></div>
                                        ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TabPane>
                            <TabPane tabId="Change Password">
                              {/* <Toaster position="top-center" /> */}
                              <p
                                style={{
                                  marginTop: "25px",
                                  color: "#CACACA",
                                  fontSize: "22px"
                                }}
                              >
                                Change Password
                              </p>
                              <div
                                style={
                                  {
                                    // marginLeft: "20px",
                                    // marginRight: "20px"
                                  }
                                }
                              >
                                <Form
                                  style={{
                                    marginRight: "20px"
                                  }}
                                >
                                  <FormGroup>
                                    <Label
                                      style={{
                                        color: "#808080",
                                        fontSize: 14,
                                        fontFamily: "khula",
                                        fontWeight: "600"
                                      }}
                                    >
                                      Current Password
                                    </Label>
                                    <Input
                                      placeholder="Type current password"
                                      type="password"
                                      value={state.password.value}
                                      onChange={e =>
                                        handleOnChange(
                                          "password",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {BackendError
                                      ? BackendError?.old_password && (
                                          <label style={{ color: "red" }}>
                                            {BackendError?.old_password[0]}
                                          </label>
                                        )
                                      : state.password.error && (
                                          <label style={{ color: "red" }}>
                                            {state.password.error}
                                          </label>
                                        )}
                                  </FormGroup>
                                  <FormGroup>
                                    <Label
                                      style={{
                                        color: "#808080",
                                        fontSize: 14,
                                        fontFamily: "khula",
                                        fontWeight: "600"
                                      }}
                                    >
                                      New Password
                                    </Label>
                                    <Input
                                      placeholder="Type new password"
                                      type="password"
                                      value={state.newPassword.value}
                                      onChange={e =>
                                        handleOnChange(
                                          "newPassword",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {BackendError
                                      ? BackendError?.new_password1 && (
                                          <label style={{ color: "red" }}>
                                            {BackendError?.new_password1[0]}
                                          </label>
                                        )
                                      : state.newPassword.error && (
                                          <label style={{ color: "red" }}>
                                            {state.newPassword.error}
                                          </label>
                                        )}
                                  </FormGroup>
                                  <FormGroup>
                                    <Label
                                      style={{
                                        color: "#808080",
                                        fontSize: 14,
                                        fontFamily: "khula",
                                        fontWeight: "600"
                                      }}
                                    >
                                      Confirm Password
                                    </Label>
                                    <Input
                                      placeholder="Re-type new password"
                                      type="password"
                                      value={state.confirmPassword.value}
                                      onChange={e =>
                                        handleOnChange(
                                          "confirmPassword",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {BackendError
                                      ? BackendError?.new_password2 && (
                                          <label style={{ color: "red" }}>
                                            {BackendError?.new_password2[0]}
                                          </label>
                                        )
                                      : state.confirmPassword.error && (
                                          <label style={{ color: "red" }}>
                                            {state.confirmPassword.error}
                                          </label>
                                        )}
                                  </FormGroup>
                                </Form>
                              </div>

                              <div
                                style={{
                                  margin: "20px"
                                }}
                              >
                                <Button
                                  className="btn-round text-capitalize
                                  "
                                  color="secondary"
                                  style={{
                                    backgroundColor: "#3A0F7D",
                                    marginLeft: "-18px",
                                    boxShadow:
                                      "0px 4px 15px rgba(0, 0, 0, 0.25)"
                                  }}
                                  onClick={onChangePassword}
                                >
                                  {/* {props.requesting ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    "Change Password"
                                  )} */}
                                  {props.requesting ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    "Change Password"
                                  )}
                                </Button>
                              </div>
                            </TabPane>
                            <TabPane tabId="Feedback">
                              {/* <Toaster position="top-center" /> */}
                              <p
                                style={{
                                  marginTop: "25px",
                                  color: "#CACACA",
                                  fontSize: "22px"
                                }}
                              >
                                Feedback
                              </p>
                              <div
                                style={
                                  {
                                    // marginLeft: "20px",
                                    // marginRight: "20px"
                                  }
                                }
                              >
                                <Form>
                                  <FormGroup>
                                    <Label
                                      style={{
                                        color: "#808080",
                                        fontSize: 14,
                                        fontFamily: "khula",
                                        fontWeight: "600"
                                      }}
                                    >
                                      Subject
                                    </Label>
                                    <Input
                                      placeholder="Type here"
                                      type="text"
                                      value={state.subject.value}
                                      onChange={e =>
                                        handleOnChange(
                                          "subject",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {BackendError
                                      ? BackendError?.subject && (
                                          <label style={{ color: "red" }}>
                                            {BackendError?.subject[0]}
                                          </label>
                                        )
                                      : state.subject.error && (
                                          <label style={{ color: "red" }}>
                                            {state.subject.error}
                                          </label>
                                        )}
                                  </FormGroup>
                                  <FormGroup>
                                    <Label
                                      style={{
                                        color: "#808080",
                                        fontSize: 14,
                                        fontFamily: "khula",
                                        fontWeight: "600"
                                      }}
                                    >
                                      Message
                                    </Label>
                                    <Input
                                      placeholder="Type here"
                                      type="textarea"
                                      value={state.message.value}
                                      onChange={e =>
                                        handleOnChange(
                                          "message",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {BackendError
                                      ? BackendError?.body && (
                                          <label style={{ color: "red" }}>
                                            {BackendError?.body[0]}
                                          </label>
                                        )
                                      : state.message.error && (
                                          <label style={{ color: "red" }}>
                                            {state.message.error}
                                          </label>
                                        )}
                                  </FormGroup>
                                </Form>
                              </div>

                              <div
                                style={{
                                  margin: "20px"
                                }}
                              >
                                <Button
                                  className="btn-round text-capitalize
                                  "
                                  color="secondary"
                                  style={{
                                    backgroundColor: "#3A0F7D",
                                    marginLeft: "-18px",
                                    boxShadow:
                                      "0px 4px 15px rgba(0, 0, 0, 0.25)"
                                  }}
                                  // onClick={onFeedBack}
                                  onClick={() => {
                                    handleFeedback()
                                  }}
                                >
                                  {props.requesting ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    "Submit"
                                  )}
                                </Button>
                              </div>
                            </TabPane>
                            <TabPane tabId="Notifications Settings">
                              <p
                                style={{
                                  marginTop: "25px",
                                  color: "#CACACA",
                                  fontSize: "22px"
                                }}
                              >
                                Notifications Settings
                              </p>
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <p
                                  style={{
                                    color: "#000000",
                                    fontSize: "15px",
                                    fontFamily: "Khula"
                                  }}
                                >
                                  Receive via SMS
                                </p>
                                <div>
                                  <Switch
                                    onChange={handleChange1}
                                    checked={openSwitch1}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="3A0F7D"
                                    width={48}
                                    offColor="#808080"
                                    onColor="#3A0F7D"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                  />
                                </div>
                              </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <p
                                  style={{
                                    color: "#000000",
                                    fontSize: "15px",
                                    fontFamily: "Khula"
                                  }}
                                >
                                  Receive via Email
                                </p>
                                <div>
                                  <Switch
                                    onChange={handleChange}
                                    checked={openSwitch}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="3A0F7D"
                                    width={48}
                                    offColor="#808080"
                                    onColor="#3A0F7D"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <p
                                  style={{
                                    color: "#000000",
                                    fontSize: "15px",
                                    fontFamily: "Khula"
                                  }}
                                >
                                  Receive only in-app
                                </p>
                                <div>
                                  <Switch
                                    onChange={handleChange2}
                                    checked={openSwitch2}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="3A0F7D"
                                    width={48}
                                    offColor="#808080"
                                    onColor="#3A0F7D"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="Delete Account"></TabPane>
                            <TabPane tabId="Logout"></TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
          style={{}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              style={{
                height: 100,
                width: 100,
                marginTop: "-10px"
                // marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/feedbackTick.png")}
            />
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              fontSize: "22px",
              fontFamily: "Libre Caslon Text",
              color: "#CACACA"
            }}
          >
            Successfully Done!
          </p>
          <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              padding: "30px",
              textAlign: "center",
              fontSize: "15px",
              fontFamily: "Libre Caslon Text",
              color: "#000000"
            }}
          >
            Your feedback has been successfully submited to Growtal team.
            <p
              style={{
                // display: "flex",
                // justifyContent: "center",
                margin: 0,
                textAlign: "center",
                fontSize: "15px",
                fontFamily: "Libre Caslon Text",
                color: "#000000"
              }}
            >
              We will reach out soon with feedback
            </p>
          </p>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {/* <Button
              onClick={toggle}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button> */}
            <Button
              onClick={() => {
                // handleFeedback()
                // toggle();
                setModal(false)
                feedbackSuccess(false)
              }}
              className="btn-round text-capitalize
              "
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                marginLeft: "20px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Continue
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          // className={className}
          // external={externalCloseBtn}
          style={{}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              // onClick={toggle1}
              style={{
                height: 100,
                width: 100,
                marginTop: "-10px"

                // marginRight: "12px"
              }}
              src={require("assets/img/deleteAccount.png")}
            />
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              fontSize: "22px",
              fontFamily: "Libre Caslon Text",
              color: "#CACACA"
            }}
          >
            Delete Account
          </p>
          <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              padding: "35px",
              textAlign: "center",
              fontSize: "15px",
              fontFamily: "Libre Caslon Text",
              color: "#000000"
            }}
          >
            “By deleting your account, you understand that you will lose access
            to all historical account information, will no longer be able to
            receive payments, and will no longer be eligible for new project
            engagements”
          </p>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle1}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteAccount()
              }}
              className="btn-round text-capitalize
              "
              // color="secondary"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "#3A0F7D",
                marginLeft: "20px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Yes, Delete Account
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal2}
          toggle2={toggle2}
          // className={className}
          // external={externalCloseBtn}
          style={{}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              // onClick={toggle1}
              style={{
                height: 100,
                width: 100,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/logout.png")}
            />
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              fontSize: "22px",
              fontFamily: "Libre Caslon Text",
              color: "#CACACA"
            }}
          >
            Do you want to logout?
          </p>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle2}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                toggle2()
                handleLogout()
              }}
              className="btn-round text-capitalize
              "
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "132px",
                marginLeft: "20px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Log out
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  notifiySetting: state.LoginScreen.notificationSetting,
  BackendError: state.Settings.error,
  requesting: state.Settings.requesting,
  termsAndCondition: state.Settings.termsConditions,
  privicypolicy: state.Settings.privicyPolicy,
  feedBack: state.Settings.feedBack
  // notificationSettings: state.Settings.notificationSettings
})

const mapDispatchToProps = dispatch => ({
  DeleteAccountRequest: data => dispatch(DeleteAccountRequest(data)),
  changePassRequest: data => dispatch(changePassRequest(data)),
  feedbackRequest: data => dispatch(feedbackRequest(data)),
  getTermsAndCondition: () => dispatch(getTermsAndCondition()),
  getPrivicyPolicy: () => dispatch(getPrivicyPolicy()),
  resetAllStates: () => dispatch(resetAllStates()),
  notificationSettings: data => dispatch(notificationSettings(data)),
  feedbackFaluire: data => dispatch(feedbackFaluire(data)),
  changePassFaluire: data => dispatch(changePassFaluire(data)),
  feedbackSuccess: data => dispatch(feedbackSuccess(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Settings)
