import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"
import Switch from "react-bootstrap-switch"
import PaginationPack from "components/PaginationPack"
import Images from "utils/Images"
import moment from "moment"
import toast, { Toaster } from "react-hot-toast"
import {
  getActiveEngagementRequest,
  patchActiveEngagementRequest,
  patchEndEngagementRequest
} from "./redux/actions"
import { connect } from "react-redux"
import useForm from "utils/useForm"
import validator from "utils/validation"
import ReadMore from "components/ReadMore"

function ActiveEngagement(props) {
  const {
    getActiveEngagementRequest,
    patchActiveEngagementRequest,
    patchEndEngagementRequest,
    allEngagements,
    requesting,
    detailRequest,
    endRequest,
    successEndEngagement,
    patchActiveSuccess
  } = props

  const stateSchema = {
    Discription: {
      value: "",
      error: ""
    },
    Hour: {
      value: "",
      error: ""
    },
    Minutes: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    Discription: {
      required: true
      // validator: validator.email
    },
    Hour: {
      required: true
      // validator: validator.email
    },
    Minutes: {
      required: true
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    getActiveEngagementRequest(1)
  }, [])

  useEffect(() => {
    successEndEngagement && setModal1(false)
  }, [successEndEngagement])

  useEffect(() => {
    if (patchActiveSuccess) {
      setModal(false)
      setHour({ value: 0 })
    }
  }, [patchActiveSuccess])

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)

  const [hour, setHour] = useState({ value: 0 })
  const [minutes, setMinutes] = useState({ value: 0 })
  const [selectedData, setSeletedData] = useState(false)

  // const handleHour = e => {
  //   // handleOnChange("time", e.target.value);
  //   // timeTrackerRequestFaluire(false);
  //   // setBtnName('Submit');
  //   handleHourChange(e)
  // }

  // const handelMinutes = e => {
  //   // handleOnChange("minutes", e.target.value);
  //   // timeTrackerRequestFaluire(false);
  //   // setBtnName('Submit')
  //   handleMinutesChange(e)
  // }

  const handleSaveChanges = () => {
    const data = {
      id: selectedData.id,
      data: {
        // expert_rate: "",
        // business_rate: "",
        // rate_per_bill: ""
        hour: hour.value ? hour.value : ""
      }
    }
    patchActiveEngagementRequest(data)
  }

  const handlePageChange = page => {
    getActiveEngagementRequest(page)
  }

  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state =>
        (value.length <= 2 &&
          value <= 24 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )
  const handleMinutesChange = ({ target: { value } }) =>
    setMinutes(
      state =>
        (value.length <= 2 &&
          value <= 60 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )

  const handleEngEngagement = () => {
    const data = {
      id: selectedData.id,
      data: {
        end: true
      }
    }
    patchEndEngagementRequest(data)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Active Engagements
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        padding: "25px"
                      }}
                    >
                      Experts
                    </th>
                    <th style={{ color: "#808080" }}>Business Name</th>
                    <th
                      className="text-center"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Engagement Name
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Expert & <br /> Business Rate
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Start Date
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Total Billed
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Total Paid
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        // paddingRight: "5%"
                        paddingRight: "46px"
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allEngagements && allEngagements?.results?.length ? (
                      allEngagements?.results.map(item => (
                        <>
                          <tr
                            style={{
                              // border: "3px solid",
                              // borderRadius: "50px !mportant",
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "25px",
                                borderBottomLeftRadius: "8px",
                                borderTopLeftRadius: "8px"
                              }}
                            >
                              {item.expert_name}
                            </td>

                            <td>
                              {
                                item?.business_detail?.business_detail[0]
                                  ?.company_name
                              }
                            </td>
                            <td
                              className="text-center"
                              style={{ maxWidth: 300 }}
                            >
                              <ReadMore
                                length={100}
                                item={item}
                                text={item.description}
                              />
                            </td>
                            <td className="text-center">
                              {`$${item.expert_rate}/hr`}
                              <br />
                              {`$${item.business_rate}/hr`}
                              {/* {moment(item.agreement_start_date).format(
                                "MM-DD-YYYY"
                              )} */}
                            </td>
                            <td className="text-right">
                              {moment(item.agreement_start_date).format(
                                "MM-DD-YYYY"
                              )}
                            </td>

                            <td className="text-right">{`$${
                              item?.amounts?.length > 0
                                ? item?.amounts[0]?.payment_amount
                                : 0
                            }`}</td>
                            <td className="text-right">{`$${
                              item?.amounts?.length > 0
                                ? item?.amounts[0]?.invoice_amount
                                : 0
                            }`}</td>

                            <td
                              className="text-right pr-lg-3"
                              // className="text-left "
                              style={{
                                paddingTop: "14px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px"
                              }}
                            >
                              <Button
                                className="btn-round btn text-capitalize
                              "
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  // display: "flex",
                                  // alignItems: "center",
                                  width: "max-content"
                                }}
                                onClick={() => {
                                  toggle()
                                  setSeletedData(item)
                                }}
                              >
                                <img
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    // marginLeft: "20px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.edit}
                                />
                                edit
                              </Button>
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6 style={{ textAlign: "center" }}>No Data</h6>
                      </div>
                    )}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {allEngagements && allEngagements?.results.length > 0 ? (
                    requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={allEngagements?.page_size}
                          totalItemsCount={allEngagements?.total_objects}
                          activePage={allEngagements?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <Toaster position="top-center" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",
              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Engagement Details
          </h5>
          <img
            onClick={toggle}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Expert Name
              </Label>
              <Input
                disabled
                defaultValue={selectedData.expert_name}
                // placeholder="Expert Name goes here.."
                type="text"
                style={{
                  backgroundColor: "#EFEFEF"
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Client Name
              </Label>
              <Input
                disabled
                defaultValue={
                  selectedData?.business_detail?.business_detail[0]
                    ?.company_name
                }
                // placeholder="Expert Name goes here.."
                type="text"
                style={{
                  backgroundColor: "#EFEFEF"
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Engagement Name
              </Label>
              <Input
                disabled
                id="exampleText"
                placeholder="Type here"
                name=""
                type="textarea"
                value={selectedData.description}
                // onChange={e => handleOnChange("Discription", e.target.value)}
                //   value={state.ratingDescription.value}
                //   onChange={e =>
                //     handleOnChange("ratingDescription", e.target.value)
                //   }
              />
            </FormGroup>
            <div style={{ display: "flex" }}>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Rate
                </Label>

                <Input
                  disabled
                  type="number"
                  min="0"
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  defaultValue={selectedData.expert_rate}
                />
              </FormGroup>

              <FormGroup className="ml-4">
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Start Date
                </Label>
                <Input
                  disabled
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  defaultValue={selectedData.agreement_start_date}
                />
              </FormGroup>
            </div>
            <label
              className=""
              style={{
                color: "#808080",
                fontFamily: "Khula",
                fontSize: "14px",
                fontWeight: "600",
                margin: "0px",
                marginBottom: "5px"
              }}
            >
              Current Hours
            </label>
            <div style={{ display: "flex" }}>
              <FormGroup style={{ display: "flex" }}>
                <Input
                  style={{ height: "55px", maxWidth: "95px" }}
                  placeholder="hour"
                  type="number"
                  min="0"
                  autoComplete="off"
                  value={hour.value}
                  onChange={e => {
                    //handleOnChange("Hour", e.target.value)
                    handleHourChange(e)
                  }}
                  // onChange={e => setHour(e.target.value)}
                />
                <label
                  className="ml-2 mr-3"
                  style={{
                    margin: "0px",
                    fontFamily: "Khula",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#808080",
                    marginTop: "15px"
                  }}
                >
                  Hour
                </label>
              </FormGroup>
              {/* {state.Hour.error && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {state.Hour.error}
                  </Label>
                )} */}

              {/* <FormGroup style={{ display: "flex" }}>
                <Input
                  style={{ height: "55px", maxWidth: "95px" }}
                  placeholder="Minute"
                  type="number"
                  autoComplete="off"
                  value={minutes.value}
                  onChange={e => {
                    //handleOnChange("Minutes", e.target.value)
                    handleMinutesChange(e)
                  }}
                  // value={state.minutes.value}
                  // onChange={e =>
                  //   handleOnChange("minutes", e.target.value)
                  // }
                  // onChange={e => setMinutes(e.target.value)}
                />
                <label
                  className="ml-2"
                  style={{
                    margin: "0px",
                    fontFamily: "Khula",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#808080",
                    marginTop: "15px"
                  }}
                >
                  Minutes
                </label>
              </FormGroup> */}
            </div>

            {/* <FormGroup
              className="my-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <label
                style={{
                  margin: "0px",
                  fontFamily: "Khula",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#808080"
                }}
              >
                Auto-Time-Lock
              </label>
              <div>
                <Switch onColor="primary" onText="" offText=""></Switch>
                <span className="ml-2">on</span>
              </div>
            </FormGroup> */}
          </Form>
        </div>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={toggle}
            className="btn-round btn text-capitalize "
            style={{
              backgroundColor: "#fff",
              color: "#3A0F7D",
              border: "1px solid  #3A0F7D",
              marginRight: "11px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // toggle()
              // handleOnExpertRating()
              handleSaveChanges()
            }}
            className="btn-round text-capitalize "
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              fontSize: "16px"
            }}
          >
            {detailRequest ? <Spinner size="sm" /> : "save changes"}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            className="btn-link text-capitalize"
            onClick={() => {
              toggle1()
              setModal(false)
            }}
            color="secondary"
            style={{
              color: "#3A0F7D",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              style={{
                height: 12,
                width: 12,
                // marginLeft: "20px"
                marginRight: "5px"
              }}
              src={require("assets/img/cross.png")}
            />
            End Engagement
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={modal1}
        toggle1={toggle1}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            // onClick={toggle1}
            style={{
              height: 100,
              width: 100,
              marginTop: "-10px",
              marginLeft: "20px"
              // marginRight: "12px"
            }}
            src={require("assets/img/closingHour.png")}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            fontFamily: "Libre Caslon Text",
            color: "#CACACA"
          }}
        >
          End Engagement
        </p>
        <p
          style={{
            // display: "flex",
            // justifyContent: "center",
            padding: "35px",
            textAlign: "center",
            fontSize: "15px",
            fontFamily: "Libre Caslon Text",
            color: "#000000"
          }}
        >
          By ending the enagement both the client and expert won’t be able to
          work together for this engagement and expert won’t be able to send any
          hour request for this engagement.
        </p>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={toggle1}
            className="btn-round btn text-capitalize
              "
            style={{
              backgroundColor: "#fff",
              color: "#3A0F7D",
              border: "1px solid  #3A0F7D",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleEngEngagement()
            }}
            className="btn-round text-capitalize
              "
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              marginLeft: "20px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            {endRequest ? <Spinner size="sm" /> : "Yes, End Engagement"}
          </Button>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.ActiveEngagement.error,
  requesting: state.ActiveEngagement.requesting,
  detailRequest: state.ActiveEngagement.detailRequest,
  allEngagements: state.ActiveEngagement.allEngagements,
  endRequest: state.ActiveEngagement.endRequest,
  successEndEngagement: state.ActiveEngagement.successEndEngagement,
  patchActiveSuccess: state.ActiveEngagement.patchActiveSuccess
})
const mapDispatchToProps = dispatch => ({
  getActiveEngagementRequest: data =>
    dispatch(getActiveEngagementRequest(data)),
  patchActiveEngagementRequest: data =>
    dispatch(patchActiveEngagementRequest(data)),
  patchEndEngagementRequest: data => dispatch(patchEndEngagementRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ActiveEngagement)
