import React, { useEffect, useState } from "react"

import toast, { Toaster } from "react-hot-toast"

import { connect } from "react-redux"

import {
  getReferralRequest,
  updateReferralStatusRequest
} from "../redux/actions"
import PaginationPack from "components/PaginationPack"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Spinner
} from "reactstrap"
import ReadMore from "components/ReadMore"

function ExpertReferralHistory(props) {
  const [userId, setUserId] = useState(false)

  const { requesting, referralData, updateRequesting } = props

  useEffect(() => {
    props.getReferralRequest({ page: 1, type: "Expert" })
  }, [])

  const handlePageChange = page => {
    props.getReferralRequest({ page: page, type: "Expert" })
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />

        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      // marginLeft: "20px",
                      marginRight: "19px"
                      // marginTop: "1px"
                    }}
                    src={require("assets/img/backbtn.png")}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA",
                      marginTop: "11px"
                    }}
                  >
                    Expert Referral History
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px",
                    borderRadius: "8px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Experts Name
                    </th>
                    <th style={{ color: "#808080" }}>Referral Source</th>
                    <th style={{ color: "#808080" }}>Description</th>

                    <th style={{ color: "#808080" }}>Email</th>
                    {/* <th style={{ color: "#808080" }}>Status</th>
                    <th
                      className="text-right"
                      style={{ color: "#808080", paddingRight: 89 }}
                    >
                      Actions
                    </th> */}
                  </tr>
                  {/* </thead> */}

                  {requesting ? (
                    <div
                      style={{
                        height: 200,
                        position: "absolute",
                        top: "90%",
                        // transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : referralData && referralData?.results.length > 0 ? (
                    <tbody>
                      {referralData.results.map(item => (
                        <>
                          <tr
                            style={{
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                padding: "25px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px"
                              }}
                            >
                              {item.name}
                            </td>
                            <td>{item.referral_type}</td>
                            <td>
                              <ReadMore
                                length={100}
                                item={item}
                                text={item.description}
                              />
                            </td>
                            <td>{item.email}</td>
                            {/* <td>{item.paid_status ? "paid" : "unpaid"}</td>
                              <td
                                className="text-right"
                                style={{
                                  paddingRight: "25px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px"
                                }}
                              >
                                <Button
                                  className="btn-round btn text-capitalize"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#3A0F7D",
                                    border: "1px solid  #3A0F7D",
                                    borderRedius: "0px",
                                    marginRight: 13
                                  }}
                                  onClick={() => {
                                    setUserId(item.id)
                                    props.updateReferralStatusRequest({
                                      id: item.id,
                                      data: {
                                        paid_status: true
                                      }
                                    })
                                  }}
                                >
                                  {userId == item.id &&
                                  props.updateRequesting ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    " Change Status"
                                  )}
                                </Button>
                              </td> */}
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))}
                    </tbody>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: 120,
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {referralData && referralData?.results.length > 0 ? (
                    requesting ? null : (
                      // <div className="pt-4 d-flex justify-content-center">
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={referralData?.page_size}
                        totalItemsCount={referralData?.total_objects}
                        activePage={referralData?.current_page_number}
                      />
                      // </div>
                    )
                  ) : null}
                </div>
                {/* {requesting ? null : (
                  <div className="pt-4 d-flex justify-content-center">
                    {referralData &&
                    referralData?.results.length < 10 ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={referralData?.page_size}
                        totalItemsCount={referralData?.total_objects}
                        activePage={referralData?.current_page_number}
                      />
                    )}
                  </div>
                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.Referrals.requesting,
  referralData: state.Referrals.referralData,
  updateRequesting: state.Referrals.updateRequesting
})
const mapDispatchToProps = dispatch => ({
  getReferralRequest: data => dispatch(getReferralRequest(data)),
  updateReferralStatusRequest: data =>
    dispatch(updateReferralStatusRequest(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertReferralHistory)
// export default ExpertReferralHistory
