import React, { useEffect, useState } from "react"

// hooks
// import {useForm} from 'react-hook-form'
import Images from "utils/Images"
import moment from "moment"

import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file

// config
import { BASE_URL } from "config/app"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  Table,
  Spinner,
  Dropdown
} from "reactstrap"
import Select from "react-select"
import Switch from "react-bootstrap-switch"

import useForm from "utils/useForm"
import toast, { Toaster } from "react-hot-toast"

import {
  timetrackingLogsRequest,
  timetrackingLogsFailure,
  exportCSVRequest,
  filterTimeTrackLogsRequest,
  patchTimeTrackLogsRequest,
  patchTimeTrackLogsFaluire,
  getBillingPeriodsRequest
} from "./redux/actions"
import { connect } from "react-redux"
import { deDE } from "rsuite/esm/locales"
import ReadMore from "components/ReadMore"

function AdminTimeTracking(props) {
  const {
    timetrackingLogsRequest,
    allTimeTrackData,
    requesting,
    exportCSVRequest,
    csvrequesting,
    filterTimeTrackLogsRequest,
    patchTimeTrackLogsRequest,
    isFiltered,
    patchDataSuccess,
    patchRequesting,
    BackendError,
    patchTimeTrackLogsFaluire,
    billingPeriods,
    getBillingPeriodsRequest
  } = props

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const [image, setImage] = useState(false)
  const [selectLabels, setSelectLabels] = useState("")
  const [multiData, setMultiData] = useState(false)
  const [isFilter, setIsFiltered] = useState(false)
  const [selectedTimeLog, setSelectedTimeLog] = useState(false)

  const toggle = item => {
    handleOnChange("editDate", item?.date)
    setHour({ value: item?.hour })
    setMinutes({ value: item?.minute })
    handleOnChange("editDiscription", item?.description)
    handleOnChange("Engagement", item?.engagement_name)

    setModal(!modal)
    setSelectedTimeLog(item)
    patchTimeTrackLogsFaluire(false)
  }

  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => setModal2(!modal2)
  const toggle3 = () => setModal3(!modal3)
  const toggle4 = () => setModal4(!modal4)

  useEffect(() => {
    patchDataSuccess && setModal(false)
  }, [patchDataSuccess])

  const [hour, setHour] = useState({ value: "0" })
  const [minutes, setMinutes] = useState({ value: "0" })

  const [dropDpown1, setDropDown1] = useState(false)

  const [switchStatus, setSwitchStatus] = useState(false)
  const [filterValue, setFilterValue] = useState("")

  const stateSchema = {
    search: {
      value: "",
      error: ""
    },
    from: {
      value: "",
      error: ""
    },
    to: {
      value: "",
      error: ""
    },
    ratingDescription: {
      value: "",
      error: ""
    },
    nameOfExpert: {
      value: "",
      error: ""
    },
    Engagement: {
      value: "",
      error: ""
    },
    amountOfHours: {
      value: "",
      error: ""
    },
    requestHoursDescription: {
      value: "",
      error: ""
    },
    areaOfExpertise: {
      value: "",
      error: ""
    },
    digitalMarketing: {
      value: "",
      error: ""
    },
    additionalInformation: {
      value: "",
      error: ""
    },
    weeklyCommitment: {
      value: "",
      error: ""
    },
    scheduleLink: {
      value: "",
      error: ""
    },
    endEngagementDescription: {
      value: "",
      error: ""
    },
    searchExpert: {
      value: "",
      error: ""
    },
    engagementHourDescription: {
      value: "",
      error: ""
    },
    hourLimit: {
      value: "",
      error: ""
    },
    filterClient: {
      value: "",
      error: ""
    },
    filterExpert: {
      value: "",
      error: ""
    },
    filterDate: {
      value: null,
      error: ""
    },
    editDate: {
      value: "",
      error: ""
    },
    editHour: {
      value: "",
      error: ""
    },
    editMinutes: {
      value: "",
      error: ""
    },
    editDiscription: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    search: {
      required: true
      // validator: validator.email
    },
    from: {
      required: true
      // validator: validator.email
    },
    to: {
      required: true
      // validator: validator.email
    },
    ratingDescription: {
      required: true
      // validator: validator.email
    },

    nameOfExpert: {
      required: true
      // validator: validator.email
    },
    Engagement: {
      required: true
      // validator: validator.email
    },
    amountOfHours: {
      required: true
      // validator: validator.email
    },
    requestHoursDescription: {
      required: true
      // validator: validator.email
    },
    areaOfExpertise: {
      required: true
      // validator: validator.email
    },
    digitalMarketing: {
      required: true
      // validator: validator.email
    },
    additionalInformation: {
      required: true
      // validator: validator.email
    },
    weeklyCommitment: {
      required: true
      // validator: validator.email
    },
    scheduleLink: {
      required: true
      // validator: validator.email
    },
    endEngagementDescription: {
      required: true
      // validator: validator.email
    },
    searchExpert: {
      required: true
      // validator: validator.email
    },
    engagementHourDescription: {
      required: true
      // validator: validator.email
    },
    hourLimit: {
      required: true
      // validator: validator.email
    },
    filterClient: {
      required: true
    },
    filterExpert: {
      required: true
    },
    filterDate: {
      required: true
    },
    editDate: {
      required: true
    },
    editHour: {
      required: true
    },
    editMinutes: {
      required: true
    },
    editDiscription: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    timetrackingLogsRequest(1)
    getBillingPeriodsRequest()
  }, [])

  const [value, onChange] = useState([new Date(), new Date()])
  const [isSelectDate, setIsSelectDate] = useState(false)
  const [selectValues, setSelectValues] = useState([])

  // on filter Data

  const handleOnFilter = () => {
    if (state.search.value && state.from.value && state.to.value) {
    } else {
      toast.error("All fields Require")
    }
  }
  // On Expert Rating Modal
  const handleOnExpertRating = () => {
    const data = {
      id: selectedTimeLog?.id,
      data: {
        engagement_name: selectedTimeLog?.engagement_name,
        date: `${moment(state.editDate.value).format("YYYY-MM-DD")}`,
        hour: hour.value,
        minute: minutes.value,
        description: state.editDiscription.value,
        time_log: switchStatus
      }
    }
    patchTimeTrackLogsRequest(data)
    // setState(stateSchema)
    // patchTimeTrackLogsFaluire(false)
    // if (state.ratingDescription.value) {
    // } else {
    //   toast.error("Rating Description field is Require")
    // }
  }

  // On Request Hours Modal
  const handleOnRequestHours = () => {
    if (
      state.nameOfExpert.value &&
      state.Engagement.value &&
      state.amountOfHours.value &&
      state.requestHoursDescription.value
    ) {
    } else {
      toast.error("All field are Require")
    }
  }

  // On Request Engagement Modal
  const handleOnRequestEngagement = () => {
    if (
      state.areaOfExpertise.value &&
      state.digitalMarketing.value &&
      state.additionalInformation.value &&
      state.weeklyCommitment.value
    ) {
    } else {
      toast.error("All field are Require")
    }
  }

  // On End Engagement
  const handleOnEndEngagement = () => {
    if (state.scheduleLink.value && state.endEngagementDescription.value) {
    } else {
      toast.error("All field are Require")
    }
  }

  // On Manage Engagement Hour
  const handleOnManageEngagementHour = () => {
    if (
      state.searchExpert.value &&
      state.engagementHourDescription.value &&
      state.hourLimit.value
    ) {
    } else {
      toast.error("All field are Require")
    }
  }

  const handleExportCSV = () => {
    if (isFilter) {
      if (isSelectDate && value[0]) {
        exportCSVRequest({
          expert: state.filterExpert.value || "",
          client: state.filterClient.value || "",
          start_date: value && value[0] ? `${moment(value[0]).format("YYYY-MM-DD")}` : "",
          end_date: value && value[1] ? `${moment(value[1]).format("YYYY-MM-DD")}` : ""
        })
      } else {
        exportCSVRequest({
          expert: state.filterExpert.value || "",
          client: state.filterClient.value || "",
          start_date: filterValue ? `${moment(filterValue?.value[0]).format("YYYY-MM-DD")}` : "",
          end_date: filterValue ? `${moment(filterValue?.value[1]).format("YYYY-MM-DD")}` : ""
        })
      }
    } else {
      exportCSVRequest({
        expert: "",
        client: "",
        start_date: "",
        end_date: ""
      })
    }
  }

  const handleApplyFilter = () => {
    if (
      state.filterExpert.value ||
      state.filterClient.value ||
      state.filterDate.value ||
      value ||
      filterValue
    ) {
      if (isFilter) {
        if (isSelectDate && value[0]) {
          const data = {
            expert: state.filterExpert.value,
            client: state.filterClient.value,
            // start_date: state.filterDate.value
            //   ? moment(state.filterDate.value).format("YYYY-MM-DD")
            //   : "",
            // end_date: ""
            start_date: `${moment(value[0]).format("YYYY-MM-DD")}`,
            end_date: `${moment(value[1]).format("YYYY-MM-DD")}`
            // period: selectLabels ? selectLabels?.value : null
          }
          setFilterValue("")
          filterTimeTrackLogsRequest(data)
        } else {
          const data = {
            expert: state.filterExpert.value || "",
            client: state.filterClient.value || "",
            // start_date: state.filterDate.value
            //   ? moment(state.filterDate.value).format("YYYY-MM-DD")
            //   : "",
            // end_date: ""
            start_date: filterValue
              ? `${moment(filterValue?.value[0]).format("YYYY-MM-DD")}`
              : "",
            end_date: filterValue
              ? `${moment(filterValue?.value[1]).format("YYYY-MM-DD")}`
              : ""
            // start_date: filterValue?.value[0] || "",
            // end_date: filterValue?.value[1] || ""
            // period: selectLabels ? selectLabels?.value : null
          }

          filterTimeTrackLogsRequest(data)
        }
      }
      setDropDown1(false)
      setSelectLabels("")
    }
  }

  const handleClearFilter = () => {
    setDropDown1(false)
    handleOnChange("filterExpert", "")
    handleOnChange("filterClient", "")
    handleOnChange("filterDate", "")
    setSelectLabels("")
    setFilterValue("")
    onChange([new Date(), new Date()])
    // const data = {
    //   expert: '',
    //   client: '',
    //   start_date: '',
    //   end_date: '',
    // }
    // filterTimeTrackLogsRequest(data)
    if (isFiltered) {
      timetrackingLogsRequest(1)
    }
  }

  const handleHour = e => {
    // handleOnChange("time", e.target.value);

    handleHourChange(e)
  }

  const handleMinute = e => {
    // handleOnChange("time", e.target.value);

    handleMinutesChange(e)
  }

  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state =>
        (value.length <= 2 &&
          value <= 24 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )
  const handleMinutesChange = ({ target: { value } }) =>
    setMinutes(
      state =>
        (value.length <= 2 &&
          value <= 60 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )

  const handlePageChange = page => {
    timetrackingLogsRequest(page)
  }

  const handleToggle = item => {
    setSwitchStatus(!switchStatus)
  }
  const [disable2, setDisable2] = useState(false)

  useEffect(() => {
    const tempArray = []
    if (billingPeriods) {
      billingPeriods &&
        billingPeriods.map(item => {
          const tempObject = {
            start: moment(item.start_date).format("YYYY MMM Do"),
            end: moment(item.end_date).format("YYYY MMM Do"),
            res: [item.start_date, item.end_date]
          }
          tempArray.push(tempObject)

          if (item.is_active) {
            setFilterValue({
              label: `${tempObject.start} - ${tempObject.end}`,
              value: tempObject.res
            })
          }
        })
      // console.log("date-------------------",date);
      tempArray.length > 0 &&
        setSelectValues(
          tempArray.map(item => {
            return {
              label: `${item.start} - ${item.end}`,
              value: item.res
            }
          })
        )
    }
  }, [billingPeriods])

  // console.log("startDate", startDate);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "0px"
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Time Tracking
                  </CardTitle>

                  <Row
                    className="header"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      marginTop: "-10px"
                    }}
                  >
                    <Button
                      className="btn-round btn text-capitalize"
                      disabled={
                        allTimeTrackData && allTimeTrackData?.Data.length === 0
                      }
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D"
                      }}
                      onClick={() => handleExportCSV()}
                    >
                      <img
                        style={{
                          height: "12px",
                          width: "12px",
                          // marginLeft: "20px",
                          marginRight: "4px",
                          marginTop: "-3px",
                          borderRadius: "0px"
                        }}
                        src={Images.LogLight}
                      />
                      {csvrequesting ? (
                        <Spinner
                          size="sm"
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        />
                      ) : (
                        "Export to CSV"
                      )}
                    </Button>
                    <Dropdown
                      isOpen={dropDpown1}
                      toggle={() => setDropDown1(!dropDpown1)}
                      style={{}}
                    >
                      <Toaster position="top-center" />
                      <DropdownToggle
                        aria-haspopup={true}
                        // caret
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                      >
                        <Button
                          onClick={() => {
                            setImage(!image)
                          }}
                          className="btn-round btn text-capitalize"
                          style={{
                            backgroundColor: "#fff",
                            color: "#3A0F7D",
                            border: "1px solid  #3A0F7D",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <img
                            style={{
                              height: "11px",
                              width: "12px",
                              // marginLeft: "20px",
                              marginRight: "4px",
                              borderRadius: "0px",
                              marginTop: "-2px"
                            }}
                            src={Images.filtericon}
                          />
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                      >
                        <Row
                          style={{ height: "60vh", overflowY: "scroll" }}
                          className="m-md-3 m-2"
                        >
                          <FormGroup>
                            <Label
                              style={{
                                color: "#808080",
                                fontSize: 14,
                                fontFamily: "khula",
                                fontWeight: "600"
                              }}
                            >
                              Company Name
                            </Label>
                            <Input
                              value={state.filterClient.value}
                              placeholder="Type Company Name"
                              type="text"
                              className=" pr-2"
                              style={{ width: "233px", height: 49 }}
                              onChange={e => {
                                handleOnChange("filterClient", e.target.value)
                                setIsFiltered(true)
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              style={{
                                color: "#808080",
                                fontSize: 14,
                                fontFamily: "khula",
                                fontWeight: "600"
                              }}
                            >
                              Expert Name
                            </Label>
                            <Input
                              value={state.filterExpert.value}
                              placeholder="Type expert name"
                              type="text"
                              className=" pr-2"
                              style={{ width: "233px", height: 49 }}
                              onChange={e => {
                                handleOnChange("filterExpert", e.target.value)
                                setIsFiltered(true)
                              }}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "100%" }}>
                            <Label
                              style={{
                                color: "#808080",
                                fontSize: 14,
                                fontFamily: "khula",
                                fontWeight: "600"
                              }}
                            >
                              Billing Periods
                            </Label>
                            <Select
                              styles={{
                                singleValue: base => ({
                                  ...base,
                                  color: "#66615b",
                                  fontSize: "14px",
                                  fontFamily: "khula",
                                  fontWeight: "400"
                                }),
                                menuList: base => ({
                                  ...base,
                                  fontSize: "14px"
                                })
                              }}
                              name="singleSelect"
                              value={filterValue}
                              onChange={value => {
                                setFilterValue(value)
                                setIsFiltered(true)
                              }}
                              options={selectValues}
                              placeholder="Select Billing Period"
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "100%" }}>
                            <Label
                              style={{
                                color: "#808080",
                                fontSize: 14,
                                fontFamily: "khula",
                                fontWeight: "600"
                              }}
                            >
                              Date
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid #DDDDDD",
                                width: 233,
                                height: 49,
                                borderRadius: "4px"

                                // borderRadius: 8
                              }}
                            >
                              <img
                                style={{
                                  height: 17,
                                  width: 17,
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  borderRadius: 0

                                  // marginRight: "12px"
                                }}
                                src={require("assets/img/cel.png")}
                              />
                              <div
                                style={{ width: "100%", paddingLeft: "1rem" }}
                              >
                                {`${new Date(value[0]).toLocaleDateString(
                                  undefined,
                                  {
                                    day: "2-digit",
                                    month: "2-digit"
                                  }
                                )} - ${new Date(value[1]).toLocaleDateString(
                                  undefined,
                                  { day: "2-digit", month: "2-digit" }
                                )}`}
                              </div>
                            </div>
                          </FormGroup>

                          <DateRange
                            ranges={[
                              {
                                startDate: value[0] || new Date(),
                                endDate: value[1] || new Date(),
                                key: "selection"
                              }
                            ]}
                            showDateDisplay={false}
                            onChange={ranges => {
                              onChange([
                                ranges.selection.startDate,
                                ranges.selection.endDate
                              ])
                              setIsFiltered(true)
                              setIsSelectDate(true)
                            }}
                            showPreview={false}
                            maxDate={new Date()}
                          />
                          {/* <Input
                            value={state.filterDate.value}
                            placeholder="-From"
                            type="date"
                            onChange={e =>
                              handleOnChange("filterDate", e.target.value)
                            }
                            style={{ height: 49 }}
                            // onChange={e => handleOnChange("password", e.target.value)}
                          /> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "20px",
                              width: "100%"
                            }}
                          >
                            <Button
                              onClick={() => handleClearFilter()}
                              className=" btn-round btn-link text-capitalize"
                              color="secondary"
                              style={{
                                color: "#3A0F7D",
                                display: "flex",
                                alignItems: "center",
                                height: 49
                              }}
                            >
                              <img
                                style={{
                                  height: 12,
                                  width: 12,
                                  // marginLeft: "20px"
                                  marginRight: "5px"
                                }}
                                src={require("assets/img/cross.png")}
                              />
                              Clear
                            </Button>
                            <Button
                              onClick={() => handleApplyFilter()}
                              className="btn-round btn text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                color: "#3A0F7D",
                                border: "1px solid  #3A0F7D",
                                height: 49
                              }}
                            >
                              Apply
                            </Button>
                          </div>
                        </Row>
                      </DropdownMenu>
                    </Dropdown>
                  </Row>
                </Row>
              </CardHeader>
              <Row
                className="example"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  height: 73,
                  flexWrap: "nowrap",
                  borderRadius: "8px",
                  marginLeft: 4,
                  marginRight: 20,
                  overflowX: "scroll",
                  overflowY: "hidden"
                }}
              >
                <Col lg="6" md="6" style={{ display: "flex" }}>
                  <div style={{}}>
                    {allTimeTrackData?.Current_billing_period ? (
                      <p
                        className="sm-mt-5"
                        style={{
                          color: "#808080",
                          fontWeight: "600",
                          fontFamily: "Khula",
                          fontSize: 14,
                          marginTop: 25,
                          marginLeft: 10,
                          whiteSpace: "nowrap",
                          marginBottom: 0
                        }}
                      >
                        {`Current Billing Period ${"   "} (${moment(
                          allTimeTrackData?.Current_billing_period?.start_date
                        ).format("MMM DD -")}  ${moment(
                          allTimeTrackData?.Current_billing_period?.end_date
                        ).format("MMM DD")})`}
                      </p>
                    ) : (
                      <p
                        className="sm-mt-5"
                        style={{
                          color: "#808080",
                          fontWeight: "600",
                          fontFamily: "Khula",
                          fontSize: 14,
                          marginTop: 25,
                          marginLeft: 10,
                          whiteSpace: "nowrap",
                          marginBottom: 0
                        }}
                      >
                        {`Current Billing Period ${"   "} (N/A - N/A)`}
                      </p>
                    )}
                  </div>
                </Col>
                <Col lg="6 " md="6">
                  <div
                    // className="w-md-100"
                    style={{
                      width: 500,
                      marginTop: 15,
                      display: "flex",
                      // backgroundColor: "pink"
                      justifyContent: "center"
                      // marginRight: "-2%"
                    }}
                  >
                    <p
                      style={{
                        color: "#000000",
                        fontWeight: "400",
                        fontFamily: "Khula",
                        fontSize: 15,
                        marginRight: "20%"
                      }}
                    >
                      {/* {` $ ${
                        allTimeTrackData?.Total?.total_hours
                          ? allTimeTrackData?.Total?.total_hours
                          : 0
                      }/hr`} */}
                      {"Total Invoice Amount"}
                      <br />
                      {`$ ${
                        allTimeTrackData?.Total?.total_invoice
                          ? allTimeTrackData?.Total?.total_invoice
                          : 0
                      }`}
                    </p>
                    <p
                      style={{
                        color: "#000000",
                        fontWeight: "400",
                        fontFamily: "Khula",
                        fontSize: 15
                      }}
                    >
                      {/* {` $ ${
                        allTimeTrackData?.Total?.total_hours
                          ? allTimeTrackData?.Total?.total_hours
                          : 0
                      }/hr`} */}
                      {"Total Payment Amount"}
                      <br />
                      {`$ ${
                        allTimeTrackData?.Total?.total_payment
                          ? allTimeTrackData?.Total?.total_payment
                          : 0
                      }`}
                    </p>
                  </div>
                </Col>
              </Row>

              <CardBody style={{}}>
                <Table
                  responsive
                  style={{
                    borderTop: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Company Name
                    </th>
                    <th style={{ color: "#808080" }}>Expert Name</th>

                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                        // textAlign: "center"
                      }}
                    >
                      Engagement
                    </th>
                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                        // textAlign: "center"
                      }}
                    >
                      Billing Period
                    </th>
                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                        // textAlign: "center"
                      }}
                    >
                      No. of Hours
                    </th>
                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Invoice Rate
                    </th>
                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Invoice Amount
                    </th>

                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                        // whiteSpace: "nowrap"
                        // textAlign: "center"
                      }}
                    >
                      Payment Rate
                    </th>
                    <th
                      className="pr-lg-5"
                      style={{
                        color: "#808080"
                        // whiteSpace: "nowrap"
                        // textAlign: "center"
                      }}
                    >
                      Payment Amount
                    </th>

                    <th className="text-center" style={{ color: "#808080" }}>
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                          // backgroundColor: 'red'
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allTimeTrackData &&
                      allTimeTrackData?.Data.length > 0 ? (
                      allTimeTrackData?.Data.map(item => (
                        <>
                          <tr
                            style={{
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                padding: "25px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px"
                              }}
                            >
                              {item.client}
                            </td>
                            <td>{item.expert_name}</td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {/* <ReadMore
                              item={item.engagement_name}
                              /> */}
                              <ReadMore
                                length={100}
                                item={item}
                                text={item.engagement_name}
                              />
                              {}
                              {/* {item.engagement_name? item.engagement_name:


                               } */}
                              {/* {item.engagement_name} */}
                            </td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {`${moment(item.start_date).format(
                                "MMM DD -"
                              )} ${moment(item.end_date).format("MMM DD")}`}
                            </td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {`${item.total_hours} hr ${item.total_minute} min`}
                            </td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {`$${item.invoice_rate}/hr`}
                            </td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {`$${item.total_invoice}`}
                            </td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {`$${item.payment_rate}/hr`}
                            </td>
                            <td
                              // className="text-center pl-lg-5"
                              style={
                                {
                                  // paddingRight: "52px",
                                }
                              }
                            >
                              {`$${item.total_payment}`}
                            </td>
                            <td
                              className="text-center"
                              style={
                                {
                                  // paddingRight: "52px",
                                  // paddingTop: "14px",
                                  // borderTopRightRadius: "10px",
                                  // borderBottomRightRadius: "10px",
                                }
                              }
                            >
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  aria-haspopup={true}
                                  // caret
                                  color="default"
                                  data-toggle="dropdown"
                                  id="navbarDropdownMenuLink"
                                  nav
                                >
                                  <img
                                    style={{
                                      // width: "9px",
                                      height: "4px",
                                      marginLeft: "10px"

                                      // marginTop: "-3px"
                                      // marginRight: "5px"
                                      // backgroundColor: "#3A0F7D"
                                    }}
                                    src={Images.threedots}
                                  />
                                </DropdownToggle>
                                <DropdownMenu
                                  persist
                                  aria-labelledby="navbarDropdownMenuLink"
                                  right
                                >
                                  <DropdownItem
                                    header
                                    style={{ color: "#808080" }}
                                  >
                                    Edit Tracking Details
                                  </DropdownItem>

                                  {item?.data.map(entry_item => (
                                    <DropdownItem
                                      style={{
                                        color: "#3A0F7D",
                                        fontFamily: "Khula",
                                        fontSize: 15
                                      }}
                                      href="#"
                                      onClick={() => {
                                        toggle(entry_item)
                                        setSwitchStatus(entry_item.time_log)
                                      }}
                                      // onClick={e => e.preventDefault()}
                                    >
                                      <img
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          marginLeft: "10px",
                                          marginRight: "18px",
                                          borderRadius: "0px"
                                        }}
                                        src={Images.edit}
                                      />
                                      {entry_item.hour} hr {entry_item.minute}{" "}
                                      min
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          // transform: "translate(-50%, -50%)",
                          left: "40%"
                          // backgroundColor: 'red'
                        }}
                      >
                        <h6 style={{ textAlign: "center" }}>
                          {" "}
                          {allTimeTrackData?.Message
                            ? allTimeTrackData?.Message
                            : "No Data Found"}
                        </h6>
                      </div>
                    )}
                  </tbody>
                </Table>

                {/* <div className="pt-4 d-flex justify-content-center">
                  {allTimeTrackData && allTimeTrackData?.results.length > 0 ? (
                    requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={allTimeTrackData?.page_size}
                          totalItemsCount={allTimeTrackData?.total_objects}
                          activePage={allTimeTrackData?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Edit Tracking Details
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Engagement Title
                </Label>
                <Input
                  disabled
                  defaultValue={`${selectedTimeLog?.engagement_name}`}
                  // placeholder="Expert Name goes here.."
                  type="text"
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Date
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name=""
                  type="Date"
                  value={state.editDate.value}
                  // value={state.ratingDescription.value}
                  onChange={e => {
                    handleOnChange("editDate", e.target.value)
                  }}
                />
                {state.editDate.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px",
                      marginRight: "20%"
                    }}
                  >
                    {state.editDate.error}
                  </label>
                )}
                {BackendError?.date && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.date[0]}
                  </label>
                )}
              </FormGroup>
              <label
                className=""
                style={{
                  color: "#808080",
                  fontFamily: "Khula",
                  fontSize: "14px",
                  fontWeight: "600",
                  margin: "0px",
                  marginBottom: "5px"
                }}
              >
                Time
              </label>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormGroup style={{ display: "flex" }}>
                    <Input
                      style={{ height: "55px", maxWidth: "95px" }}
                      placeholder="hour"
                      type="number"
                      min="0"
                      autoComplete="off"
                      value={hour.value}
                      // onChange={e => handleOnChange("editHour", e.target.value)}
                      onChange={e => {
                        handleHour(e)
                      }}
                    />
                    <label
                      className="ml-2 mr-3"
                      style={{
                        margin: "0px",
                        fontFamily: "Khula",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#808080",
                        marginTop: "15px"
                      }}
                    >
                      Hour
                    </label>
                  </FormGroup>
                  {BackendError?.hour && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px",
                        fontSize: 10
                      }}
                    >
                      A valid integer is required.
                    </label>
                  )}
                  {/* {hour?.value == 0 && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px",
                        marginRight: "20%"
                      }}
                    >
                      {"Required"}
                    </label>
                  )} */}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormGroup style={{ display: "flex" }}>
                    <Input
                      style={{ height: "55px", maxWidth: "95px" }}
                      placeholder="Minute"
                      type="number"
                      min="0"
                      autoComplete="off"
                      value={minutes.value}
                      // onChange={e =>
                      //   handleOnChange("minutes", e.target.value)
                      // }
                      onChange={e => {
                        handleMinute(e)
                      }}
                    />
                    <label
                      className="ml-2"
                      style={{
                        margin: "0px",
                        fontFamily: "Khula",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#808080",
                        marginTop: "15px"
                      }}
                    >
                      Minutes
                    </label>
                  </FormGroup>
                  {BackendError?.minute && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px",
                        fontSize: 10
                      }}
                    >
                      A valid integer is required.
                    </label>
                  )}
                  {/* {minutes?.value == 0 && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px"
                      }}
                    >
                      {"Required"}
                    </label>
                  )} */}
                </div>
              </div>

              {/* <div style={{ display: "flex" }}>
                {hour?.value == 0 && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px",
                      marginRight: "20%"
                    }}
                  >
                    {"Required"}
                  </label>
                )}

                {minutes?.value == 0 && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {"Required"}
                  </label>
                )}
              </div> */}

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description of Engagement
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name=""
                  type="textarea"
                  value={state.editDiscription.value}
                  onChange={e => {
                    handleOnChange("editDiscription", e.target.value)
                  }}
                />
                {state.editDiscription.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px",
                      marginRight: "20%"
                    }}
                  >
                    {state.editDiscription.error}
                  </label>
                )}
                {BackendError?.description && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px",
                      marginRight: "20%"
                    }}
                  >
                    {BackendError?.description[0]}
                  </label>
                )}
              </FormGroup>
              <FormGroup
                className="my-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p className="m-0">Auto-Time Lock</p>
                <div>
                  <Switch
                    onColor="primary"
                    onText=""
                    offText=""
                    value={switchStatus}
                    onChange={() => {
                      handleToggle()
                    }}
                  ></Switch>
                  <span className="ml-2">{switchStatus ? "on" : "off"}</span>
                </div>
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: 49,
                width: 132,
                fontSize: "14px",
                fontWeight: "700"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // toggle()
                handleOnExpertRating()
              }}
              className="btn-round btn text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                fontWeight: "700",
                whiteSpace: "nowrap",
                width: "132px",
                height: 49
              }}
            >
              {patchRequesting ? <Spinner size="sm" /> : "Save Changes"}
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Bellin to Client
            </h5>
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Client
                </Label>
                <Input
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  placeholder="Type name"
                  type="text"
                  value={state.nameOfExpert.value}
                  onChange={e => handleOnChange("nameOfExpert", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Engagement
                </Label>
                <Input
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  placeholder="Type engagement"
                  type="text"
                  value={state.Engagement.value}
                  onChange={e => handleOnChange("Engagement", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert
                </Label>
                <Input
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  placeholder="Type engagement"
                  type="text"
                  value={state.amountOfHours.value}
                  onChange={e =>
                    handleOnChange("amountOfHours", e.target.value)
                  }
                />
              </FormGroup>
              <div style={{ display: "flex" }}>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600"
                    }}
                  >
                    Client Rate
                  </Label>

                  <Input
                    style={{
                      backgroundColor: "#EFEFEF"
                    }}
                  />
                </FormGroup>

                <FormGroup className="ml-4">
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600"
                    }}
                  >
                    Hours
                  </Label>
                  <Input
                    style={{
                      backgroundColor: "#EFEFEF"
                    }}
                  />
                </FormGroup>
              </div>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Biling
                </Label>

                <Input
                  style={{
                    backgroundColor: "#EFEFEF",
                    width: "47%"
                  }}
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle1}
              className="btn-round  py-3 text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                fontSize: "16px",

                // boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                width: "130px"
              }}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => handleExportCSV()}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",

                color: "#FFFF",
                fontSize: "16px",
                height: 49,
                alignItems: "center",
                display: "flex",

                marginRight: "10px"
              }}
            >
              <img
                //   onClick={toggle}
                style={{
                  height: 13,

                  marginRight: "13px",
                  borderRadius: "0px"
                }}
                src={require("assets/img/csvdark.png")}
              />
              Download CSV
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal2}
          toggle1={toggle2}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Payment TO Expert
            </h5>
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Client
                </Label>
                <Input
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  placeholder="Type name"
                  type="text"

                  // value={state.nameOfExpert.value}
                  // onChange={e => handleOnChange("nameOfExpert", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Engagement
                </Label>
                <Input
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  placeholder="Type engagement"
                  type="text"
                  // value={state.Engagement.value}
                  // onChange={e => handleOnChange("Engagement", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert
                </Label>
                <Input
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                  placeholder="Type engagement"
                  type="text"
                  // value={state.amountOfHours.value}
                  // onChange={e =>
                  //   handleOnChange("amountOfHours", e.target.value)
                  // }
                />
              </FormGroup>
              <div style={{ display: "flex" }}>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600"
                    }}
                  >
                    Client Rate
                  </Label>

                  <Input
                    style={{
                      backgroundColor: "#EFEFEF"
                    }}
                  />
                </FormGroup>

                <FormGroup className="ml-4">
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600"
                    }}
                  >
                    Hours
                  </Label>
                  <Input
                    style={{
                      backgroundColor: "#EFEFEF"
                    }}
                  />
                </FormGroup>
              </div>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Biling
                </Label>

                <Input
                  style={{
                    backgroundColor: "#EFEFEF",
                    width: "47%"
                  }}
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle2}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                fontSize: "16px"

                // boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              }}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => handleExportCSV()}
              className="btn-round  text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",

                color: "#FFFF",
                fontSize: "16px",

                alignItems: "center",

                display: "flex",
                height: 49,
                marginRight: "10px"
              }}
            >
              <img
                //   onClick={toggle}
                style={{
                  height: 13,

                  marginRight: "13px",
                  borderRadius: "0px"
                }}
                src={require("assets/img/csvdark.png")}
              />
              Download CSV
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.AdminTimeTracking.error,
  requesting: state.AdminTimeTracking.requesting,
  allTimeTrackData: state.AdminTimeTracking.allTimeTrackData,
  timetrackingLogsFailure: state.AdminTimeTracking.timetrackingLogsFailure,
  csvrequesting: state.AdminTimeTracking.csvrequesting,
  isFiltered: state.AdminTimeTracking.isFiltered,
  patchDataSuccess: state.AdminTimeTracking.patchDataSuccess,
  patchRequesting: state.AdminTimeTracking.patchRequesting,
  billingPeriods: state.AdminTimeTracking.billingPeriods
})
const mapDispatchToProps = dispatch => ({
  timetrackingLogsRequest: data => dispatch(timetrackingLogsRequest(data)),
  exportCSVRequest: data => dispatch(exportCSVRequest(data)),
  filterTimeTrackLogsRequest: data =>
    dispatch(filterTimeTrackLogsRequest(data)),
  patchTimeTrackLogsRequest: data => dispatch(patchTimeTrackLogsRequest(data)),
  patchTimeTrackLogsFaluire: data => dispatch(patchTimeTrackLogsFaluire(data)),
  getBillingPeriodsRequest: data => dispatch(getBillingPeriodsRequest(data)),
  timetrackingLogsFailure: error => dispatch(timetrackingLogsFailure(error))
})
export default connect(mapStateToProps, mapDispatchToProps)(AdminTimeTracking)
