import {
  GET_REFER_REQUEST,
  GET_REFER_SUCCESS,
  GET_REFER_FAILURE,
  REFER_REQUEST,
  REFER_SUCCESS,
  REFER_FALUIRE
} from "./types"

export const getReferRequest = data => ({
  type: GET_REFER_REQUEST,
  data
})

export const getReferSuccess = data => ({
  type: GET_REFER_SUCCESS,
  data
})

export const getReferFailure = data => ({
  type: GET_REFER_FAILURE,
  data
})

export const referRequest = data => ({
  type: REFER_REQUEST,
  data
})

export const referSuccess = data => ({
  type: REFER_SUCCESS,
  data
})
export const referFaluire = data => ({
  type: REFER_FALUIRE,
  data
})
