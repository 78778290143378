import { all, call, put, takeLatest } from "redux-saga/effects"
import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { ALL_ACCOUNT_PAGE_REQUEST, PATCH_ACCOUNTS_REQUEST } from "./types"

// actions
import {
  pageRequest,
  pageSuccess,
  pageFaluire,
  patchAccountSuccess,
  patchAccountFailure
} from "./actions"

async function patchAproveAccountsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/approve_profile/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchAproveAccounts({ data }) {
  try {
    const response = yield call(patchAproveAccountsAPI, data)
    toast.success("Success")
    yield put(patchAccountSuccess(response?.data))
    yield put(pageRequest(1))
  } catch (e) {
    let err = e?.response?.data?.detail
      ? e.response.data.detail
      : "Something went wrog."
    if (e.message == "Network Error") {
      toast.error("Connection Error")
      yield put(patchAccountFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(patchAccountFailure(err))
    }
  }
}

async function paginationAPI(page) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/approve_profile/?page=${page}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* pagination({ page }) {
  try {
    const response = yield call(paginationAPI, page)
    yield put(pageSuccess(response?.data))
  } catch (e) {
    yield put(pageFaluire(e.response))
  }
}

export default all([
  takeLatest(ALL_ACCOUNT_PAGE_REQUEST, pagination),
  takeLatest(PATCH_ACCOUNTS_REQUEST, patchAproveAccounts)
])
