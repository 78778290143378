import React, { useEffect, useState, useRef } from "react"

import PaginationPack from "components/PaginationPack"

import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner
} from "reactstrap"

import Images from "utils/Images"
import useForm from "utils/useForm"
import validator from "utils/validation"
import { connect } from "react-redux"

import { Toaster } from "react-hot-toast"

import PictureUpload from "components/CustomUpload/PictureUpload"

import moment from "moment"

import {
  businessPageRequest,
  patchBusinessProfile,
  getAllExpertsNames,
  postShareBusinessData,
  shareBusinessDataFaluire,
  searchBusinessData,
  onBoardingLinkRequest,
  onBoardingLinkFaluire
} from "./redux/actions"

import Select from "react-select"

function Business(props) {
  const {
    businessPageRequest,
    allAccounts,
    requesting,
    patchBusinessProfile,
    getAllExpertsNames,
    postShareBusinessData,
    allExpertsData,
    BackendError,
    shareBusinessDataFaluire,
    searchBusinessData,
    onBoardingLinkRequest,
    onBoardRequesting,
    onBoardingLinkFaluire,
    onBoardingLink,
    shareSuccess
  } = props

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [search, setSearch] = useState("")
  const [check, setCheck] = useState(false)
  const [hour, setHour] = useState({ value: " " })
  const [selectData, setSelectData] = useState(false)
  const [isSearched, setIsSearched] = useState(false)

  const [allBusinessData, setAllBusinessData] = useState(false)
  const [allBusinessNames, setAllBusinessNames] = useState([])
  const [selectBusinessNames, setSelectBusinessNames] = useState({ value: 0 })
  const [checkedData, setCheckedData] = useState(false)
  const [modal2, setModal2] = useState(false)

  const fileRef = useRef()

  const handleChange = e => {
    const [file] = e.target.files
  }
  const handleHour = ({ target: { value } }) =>
    setHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )

  const toggle = () => {
    setState(stateSchema)
    setModal(!modal)
    setHour("")
    setSelectBusinessNames("")
    shareBusinessDataFaluire(false)
  }
  const toggle1 = () => {
    setState(stateSchema)
    setModal1(!modal1)
  }

  useEffect(() => {
    businessPageRequest(1)
    getAllExpertsNames("")
  }, [])

  useEffect(() => {
    allExpertsData && handleExpertNamesData()
  }, [allExpertsData])
  const handleExpertNamesData = () => {
    let tempArray = []
    if (allExpertsData) {
      allExpertsData &&
        allExpertsData.forEach((item, index) => {
          tempArray.push({
            value: index,
            label: `${item?.expert_data?.name}`,
            email: `${item?.expert_data?.email}`,

            businessEmail: `${item?.expert_data?.email}`
          })
        })
    }
    setAllBusinessNames(tempArray)
  }
  useEffect(() => {
    allAccounts && setAllBusinessData(allAccounts?.results)
  }, [allAccounts])

  const stateSchema = {
    businessName: {
      value: "",
      error: ""
    },
    expertName: {
      value: "",
      error: ""
    },
    linkedinLink: {
      value: "",
      error: ""
    },
    expertBio: {
      value: "",
      error: ""
    },
    hourlyRate: {
      value: "",
      error: ""
    },
    expertMessage: {
      value: "",
      error: ""
    },
    noteForAdmin: {
      value: "",
      error: ""
    },
    shareExpertBio: {
      value: "",
      error: ""
    },
    onBoardEmail: {
      value: "",
      error: ""
    },
    onBoardMessage: {
      value: "",
      error: ""
    },
    expertEmail: {
      value: "",
      error: ""
    },
    POC: {
      value: "",
      error: ""
    },
    Blurb: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    businessName: {
      required: true
      // validator: validator.email
    },
    expertName: {
      required: true
      // validator: validator.email
    },
    linkedinLink: {
      required: true
      // validator: validator.email
    },
    expertBio: {
      required: true
      // validator: validator.email
    },
    hourlyRate: {
      required: true
      // validator: validator.email
    },
    expertMessage: {
      required: true
      // validator: validator.email
    },
    noteForAdmin: {
      required: true
      // validator: validator.email
    },
    shareExpertBio: {
      required: true
      // validator: validator.email
    },
    onBoardEmail: {
      required: true,
      validator: validator.email
    },
    onBoardMessage: {
      required: true
    },
    expertEmail: {
      required: true,
      validator: validator.email
    },
    POC: {
      required: true
    },
    Blurb: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const data = {
    d1: state.businessName.value,
    d2: state.expertName.value,
    d3: state.linkedinLink.value,
    d4: state.expertBio.value,
    d5: state.hourlyRate.value,
    d6: state.expertMessage.value
  }

  const handleSearch = value => {
    if (value == "Search") {
      setIsSearched(true)
      searchBusinessData(search)
    } else {
      setIsSearched(false)
      setSearch("")
      searchBusinessData("")
    }
  }

  const handleCheckBox = item => {
    setCheckedData(item)
    setCheck(item?.id)
    if (check === item?.id) {
      setCheck(false)
    }
  }

  const handleSaveChange = item => {
    const data = {
      id: selectData?.id,
      data: {
        admin_note: state.noteForAdmin.value,
        poc: state.POC.value,
        blrub: state.Blurb.value
      }
    }

    patchBusinessProfile(data)
    toggle1()
  }

  const handleOpenShareExpert = () => {
    handleOnChange("businessName", checkedData?.company_name)
    handleOnChange(
      "linkedinLink",
      checkedData?.website ? checkedData?.website : ""
    )
    // setHour({ value: checkedData?.hourly_rate })
    // handleOnChange("shareExpertBio", checkedData?.expert_bio)
  }

  useEffect(() => {
    shareSuccess && setModal(false)
  }, [shareSuccess])

  const handleShareExpert = () => {
    const data = {
      expert_name: selectBusinessNames?.label,
      expert_email: selectBusinessNames.email,
      business_web_url: state.linkedinLink.value,
      details: state.shareExpertBio.value,
      hourly_rate: hour.value,
      business_name: state.businessName.value
    }
    postShareBusinessData(data)
  }
  const toggle2 = () => {
    setState(stateSchema)
    setModal2(!modal2)
    onBoardingLinkFaluire(false)
  }

  const handlePageChange = data => {
    businessPageRequest(data)
  }

  const handleOnSearch = item => {
    if (item !== "") {
      setIsSearched(true)
    } else {
      setIsSearched(false)
    }
    searchBusinessData(item)
  }

  useEffect(() => {
    onBoardingLink && setModal2(false)
  }, [onBoardingLink])

  const handleOnBoarding = () => {
    // toggle2();
    const data = {
      message: state.onBoardMessage.value,
      email: state.onBoardEmail.value,
      user_type: "Business"
    }
    onBoardingLinkRequest(data)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Business
                  </CardTitle>
                  <Row style={{ display: "flex", paddingRight: "24px" }}>
                    <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={() => {
                        toggle2()
                        // handleOpenShareExpert()
                      }}
                    >
                      Onboard Business
                    </Button>
                    {check && (
                      <Button
                        className="btn-round btn text-capitalize"
                        style={{
                          backgroundColor: "#fff",
                          color: "#3A0F7D",
                          border: "1px solid  #3A0F7D",
                          // width: "220px",
                          // height: "49px",
                          // marginTop: "0px",
                          borderRedius: "0px",
                          marginRight: 13
                        }}
                        onClick={() => {
                          toggle()
                          handleOpenShareExpert()
                        }}
                      >
                        Share Opportunity with Expert
                      </Button>
                    )}
                    <Form style={{}} onSubmit={e => e.preventDefault()}>
                      <InputGroup
                        className="no-border"
                        style={{
                          borderRadius: "5px",
                          marginTop: "10px",
                          // backgroundColor: "yellow",
                          borderRadius: 30,
                          border: "1px solid #808080",
                          paddingLeft: "15px",
                          backgroundColor: "white"
                        }}
                      >
                        <Input
                          defaultValue=""
                          placeholder="Search..."
                          type="text"
                          value={search}
                          style={{
                            backgroundColor: "white",
                            borderBottomLeftRadius: "30px",
                            borderTopLeftRadius: "30px"
                          }}
                          onChange={e => {
                            handleOnSearch(e.target.value)
                            setSearch(e.target.value)
                            // setIsSearched(false)
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              backgroundColor: "white",
                              borderBottomRightRadius: "30px",
                              borderTopRightRadius: "30px"
                            }}
                          >
                            {!isSearched ? (
                              <img
                                onClick={() => handleSearch("Search")}
                                style={{
                                  height: "17px",
                                  width: "17px"
                                }}
                                src={Images.darkSearch}
                              />
                            ) : (
                              <img
                                onClick={() => handleSearch("Clear")}
                                style={{
                                  height: "17px",
                                  width: "17px"
                                }}
                                src={Images.cros}
                              />
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                  </Row>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        paddingLeft: "22px"
                      }}
                    >
                      Business Name
                    </th>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Contact Email
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        paddingRight: "72px"
                      }}
                    >
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          // transform: "translate(-50%, -50%)",
                          left: "50%"
                          // backgroundColor: 'red'
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allBusinessData ? (
                      allBusinessData.map(item => (
                        <>
                          <tr
                            style={{
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                padding: "25px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                border: "3px"
                              }}
                            >
                              <FormGroup check>
                                <div
                                  style={{
                                    display: "flex"
                                  }}
                                >
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      value={check}
                                      checked={item?.id == check ? true : false}
                                      onChange={e => {
                                        handleCheckBox(item)
                                      }}
                                    />
                                    <span
                                      check
                                      className="form-check-sign"
                                      style={{
                                        height: "10px",
                                        backgroundColor: "#3A0F7D"
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: "#808080",
                                        fontFamily: "Khula",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        // margin: "0px"
                                        marginLeft: "38px"
                                      }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "black"
                                        }}
                                      >
                                        {item?.company_name}
                                      </Link>
                                    </span>
                                  </Label>
                                </div>
                              </FormGroup>
                            </td>
                            <td
                              style={{
                                paddingLeft: "12px"
                              }}
                            >
                              {item?.business_data?.email}
                            </td>
                            <td
                              className="text-right"
                              style={{
                                paddingTop: "14px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                paddingRight: "36px"
                              }}
                            >
                              <Button
                                className="btn-round btn text-capitalize
                              "
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  width: "max-content"
                                }}
                                onClick={() => {
                                  toggle1()
                                  setSelectData(item)
                                  handleOnChange(
                                    "noteForAdmin",
                                    item?.admin_note
                                  )
                                  handleOnChange("POC", item?.poc)
                                  handleOnChange("Blurb", item?.blrub)
                                }}
                              >
                                <img
                                  style={{
                                    height: "7px",
                                    width: "12px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.eye}
                                />
                                Preview
                              </Button>
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          // transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {allAccounts && allAccounts?.results?.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={allAccounts?.page_size}
                        totalItemsCount={allAccounts?.total_objects}
                        activePage={allAccounts?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Modal isOpen={modal} toggle={toggle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Share Expert Link
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
              }}
              src={Images.cros}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Business Name
                </Label>
                <Input
                  placeholder="Business name goes here.."
                  onChange={e => handleOnChange("businessName", e.target.value)}
                  type="text"
                  style={{
                    backgroundColor: "#EFEFEF",
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />
                {state.businessName.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.businessName.error}
                  </label>
                )}
                
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert Name
                </Label>
                <Input
                  placeholder="Type expert name"
                  onChange={e => handleOnChange("expertName", e.target.value)}
                  type="text"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />
                {state.expertName.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.expertName.error}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  LinkedIn URL
                </Label>
                <Input
                  placeholder="https://"
                  onChange={e => handleOnChange("linkedinLink", e.target.value)}
                  type="url"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert Pitch/Bio
                </Label>
                <Input placeholder="Type here..." type="textarea" onChange={e => handleOnChange("expertBio", e.target.value)} />
                {state.expertBio.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.expertBio.error}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Hourly Rate
                </Label>
                <div style={{ display: "flex" }}>
                  <Input
                    placeholder="Rate"
                    onChange={e => handleOnChange("hourlyRate", e.target.value)}
                    type="number"
                    style={{ height: "49px", width: "96px" }}
                  />
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600",
                      margin: "14px"
                    }}
                  >
                    /hr
                  </Label>
                </div>
                {state.hourlyRate.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.hourlyRate.error}
                  </label>
                )}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Message for Expert
                </Label>
                <Input
                  onChange={e => handleOnChange("expertMessage", e.target.value)}
                  placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                  type="textarea"
                />
                {state.expertMessage.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.expertMessage.error}
                  </label>
                )}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",

                height: "49px",
                width: "max-content"
              }}
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button
              onClick={toggle}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              Send Opportunity
            </Button>
          </div>
        </Modal> */}

        <Modal isOpen={modal} toggle={toggle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Share Opportunity with Expert
            </h5>
            <img
              onClick={() => {
                setModal(false)
                shareBusinessDataFaluire(false)
              }}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
              }}
              src={Images.cros}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert Name
                </Label>
                {/* <Input
                  placeholder="Business name goes here.."
                  onChange={e => handleOnChange("businessName", e.target.value)}
                  type="text"
                  style={{
                    // backgroundColor: "#EFEFEF",
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                /> */}
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="pageSelect"
                  search={true}
                  value={selectBusinessNames}
                  onChange={value => {
                    setSelectBusinessNames(value)
                  }}
                  options={allBusinessNames}
                  placeholder="Choose Page"
                />
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert Email
                </Label>
                <Input
                  placeholder="Type expert email"
                  // defaultValue={state.expertEmail.value}

                  defaultValue={selectBusinessNames.email}
                  // onChange={e => {

                  // }}
                  type="text"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    // placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />

                {BackendError && BackendError?.expert_email ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.expert_email[0]}
                  </label>
                ) : // ) : state.expertEmail.error ? (
                //   <label style={{ color: "red", display: "flex" }}>
                //     {state.expertEmail.error}
                //   </label>
                null}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Business Name
                </Label>
                <Input
                  placeholder="Type expert name"
                  // defaultValue={state.businessName.value}
                  value={state.businessName.value}
                  onChange={e => handleOnChange("businessName", e.target.value)}
                  type="text"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    // placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />

                {BackendError && BackendError?.business_name ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.business_name[0]}
                  </label>
                ) : // ) : state.businessName.error ? (
                //   <label style={{ color: "red", display: "flex" }}>
                //     {state.businessName.error}
                //   </label>
                null}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Website URL of Business
                </Label>
                <Input
                  value={state.linkedinLink.value}
                  placeholder="https://"
                  onChange={e => {
                    handleOnChange("linkedinLink", e.target.value)
                  }}
                  type="url"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />

                {BackendError && BackendError?.business_web_url ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.business_web_url[0]}
                  </label>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Opportunity Details
                </Label>
                <Input
                  value={state.shareExpertBio.value}
                  onChange={e => {
                    handleOnChange("shareExpertBio", e.target.value)
                  }}
                  placeholder="Type Details Here"
                  type="textarea"
                />

                {BackendError && BackendError?.details ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.details[0]}
                  </label>
                ) : // ) : state.shareExpertBio.error ? (
                //   <label style={{ color: "red", display: "flex" }}>
                //     {state.shareExpertBio.error}
                //   </label>
                null}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Hourly Rate
                </Label>
                <div style={{ display: "flex" }}>
                  <Input
                    // placeholder="$50"
                    value={`${hour.value}`}
                    onChange={e => {
                      handleHour(e)
                    }}
                    type="number"
                    min="0"
                    style={{
                      height: "49px",
                      width: "96px"
                      // backgroundColor: "#EFEFEF"
                    }}
                  />
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600",
                      margin: "14px"
                    }}
                  >
                    /hr
                  </Label>
                </div>

                {BackendError && BackendError?.hourly_rate && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.hourly_rate[0]}
                  </label>
                )}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",

                height: "49px",
                width: "max-content"
              }}
              onClick={() => {
                setModal(false)
              }}
            >
              Cancel
            </Button>
            <div style={{ margin: "0px 10px 0px 10px" }}></div>
            <Button
              onClick={() => {
                handleShareExpert()
              }}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              {props.sendOpportunityLoader ? (
                <Spinner size="sm" />
              ) : (
                "Share Opportunity"
              )}
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          // className={className}
          // external={externalCloseBtn}
          style={{ maxWidth: "1073px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                cursor: "pointer",

                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {selectData?.logo === null ? (
                    <img src={require("assets/img/default_picture.png")} />
                  ) : (
                    <img
                      style={{
                        width: "192px",
                        height: "192px",
                        borderRadius: "50%"
                      }}
                      src={selectData?.logo}
                    />
                    // <div
                    //   style={{
                    //     backgroundImage: `url('${`${selectData?.logo}`}')`,
                    //     width: "192px",
                    //     height: "192px",
                    //     borderRadius: "50%"
                    //   }}
                    // ></div>
                  )}
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", marginTop: "23px" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Company Name
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=""
                        type="text"
                        value={`${selectData?.company_name}`}
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="10"
                    >
                      Company Website
                    </Label>
                    <FormGroup>
                      <Input
                        disabled
                        placeholder=""
                        type="text"
                        value={`${
                          selectData?.website ? selectData?.website : ""
                        }`}
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-md-4" style={{ justifyContent: "center" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Associated Email
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=""
                        type="text"
                        value={`${
                          selectData?.associated_email
                            ? selectData?.associated_email
                            : ""
                        }`}
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Point of Contact
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=""
                        type="text"
                        value={state.POC.value}
                        onChange={e => handleOnChange("POC", e.target.value)}
                        // style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-md-4" style={{ justifyContent: "center" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Email to Invoice
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=""
                        type="text"
                        value={`${selectData?.invoices_email}`}
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Company Information
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=""
                        type="text"
                        value={state.Blurb.value}
                        // style={{ backgroundColor: "#EFEFEF" }}
                        onChange={e => handleOnChange("Blurb", e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row
              className={"pl-md-5 mr-md-4"}
              style={{ justifyContent: "center", marginTop: "23px" }}
            >
              <Col sm="12">
                <Row style={{}}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Notes from Admin
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        placeholder=""
                        type="text"
                        value={`${
                          state.noteForAdmin.value
                            ? state.noteForAdmin.value
                            : ""
                        }`}
                        style={{ maxWidth: "990px", height: "109px" }}
                        onChange={e =>
                          handleOnChange("noteForAdmin", e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <Row style={{ justifyContent: "center", marginTop: "75px" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  {/* <div onClick={() => toggle1()}>
                    <Button
                      className="btn-round btn text-capitalize mr-lg-4"
                      style={{
                        border: "1px #3A0F7D solid",

                        color: "#3A0F7D",
                        fontWeight: "700",
                        fontSize: "16px",

                        boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                        cursor: "pointer",
                        backgroundColor: "white",
                        height: 49
                      }}
                    >
                      Deny the Request
                    </Button>
                  </div>
                  <div onClick={() => handleSaveChange()}>
                    <Button
                      className="btn-round btn text-capitalize "
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "#3A0F7D",
                        fontWeight: "700",
                        fontSize: "16px",
                        boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                        cursor: "pointer",
                        height: 49
                      }}
                    >
                      Accept Request
                    </Button>
                  </div> */}
                </Row>
              </Col>
            </Row>
            {/* <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              <div style={{ marginTop: "42px" }}>
                <p
                  style={{
                    color: "#3A0F7D",
                    fontSize: "16px",
                    foontWeight: "700",
                    borderBottom: "1.5px solid #3A0F7D",
                    cursor: "pointer"
                  }}
                >
                  Ask for rest of information
                </p>
              </div>
            </Row> */}
          </div>

          <div style={{ marginLeft: "20px", marginRight: "20px" }}></div>
        </Modal>

        <Modal
          isOpen={modal2}
          toggle2={toggle2}
          // className={className}
          // external={externalCloseBtn}
          //   style={{ maxWidth: "994px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Onboarding Link
            </h5>
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Email
                </Label>
                <Input
                  placeholder="Type email"
                  type="email"
                  value={state.onBoardEmail.value}
                  onChange={e => {
                    handleOnChange("onBoardEmail", e.target.value)
                  }}
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px",
                    border: "1px solid"
                  }}
                />

                {BackendError && BackendError?.email ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.email[0]}
                  </label>
                ) : state.onBoardEmail.error ? (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.onBoardEmail.error}
                  </label>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Message
                </Label>
                <Input
                  value={state.onBoardMessage.value}
                  onChange={e => {
                    handleOnChange("onBoardMessage", e.target.value)
                  }}
                  placeholder="Type Message"
                  type="textarea"
                  style={{ border: "1px solid" }}
                />

                {BackendError && BackendError?.message ? (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.message[0]}
                  </label>
                ) : state.onBoardMessage.error ? (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.onBoardMessage.error}
                  </label>
                ) : null}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize
                              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                // display: "flex",
                // alignItems: "center",
                height: "49px",
                width: "max-content"
              }}
              onClick={toggle2}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleOnBoarding()
              }}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              {onBoardRequesting ? <Spinner size="sm" /> : "Save Changes"}
            </Button>
          </div>
        </Modal>
        {/* <Modal isOpen={modal1} toggle1={toggle1}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Business Profile
            </h5>
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
              }}
              src={Images.cros}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Joined Date
                </Label>
                <Input
                  placeholder={`${moment(selectData?.business_data?.date_joined).format("DD-MMM-YYYY")}`}


                  type="text"
                  disabled
                  style={{
                    backgroundColor: "#EFEFEF",
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Company Name
                </Label>
                <Input
                  placeholder="Company name goes here.."
                  type="text"
                  value={selectData?.company_name}
                  disabled
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px",
                    backgroundColor: "#EFEFEF"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Associated Email
                </Label>
                <Input
                  placeholder="Associated email goes here.."
                  type="email"
                  value={selectData?.associated_email}
                  disabled
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px",
                    backgroundColor: "#EFEFEF"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Email for Invoice
                </Label>
                <Input
                  placeholder="Associated email goes here.."
                  type="email"
                  value={selectData?.invoices_email}
                  disabled
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px",
                    backgroundColor: "#EFEFEF"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Notes from Admin
                </Label>
                <Input
                  // disabled
                  value={state.noteForAdmin.value}
                  placeholder="Enter Notes Here..."
                  type="textarea"
                  onChange={(e) => handleOnChange('noteForAdmin', e.target.value)}
                />
              </FormGroup>

            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize
                              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                height: "49px",
                width: "max-content"
              }}
              onClick={toggle1}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSaveChange()}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              Save Changes
            </Button>
          </div>
        </Modal> */}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.Business.requesting,
  allAccounts: state.Business.allAccounts,
  allExpertsData: state.Business.allExpertsData,
  BackendError: state.Business.error,
  onBoardRequesting: state.Business.onBoardRequesting,
  onBoardingLink: state.Business.onBoardingLink,
  shareSuccess: state.Business.shareSuccess,
  sendOpportunityLoader: state.Business.sendOpportunityLoader
  // accessToken: state.LoginScreen.accessToken,
  // user: state.LoginScreen.user
})
const mapDispatchToProps = dispatch => ({
  businessPageRequest: page => dispatch(businessPageRequest(page)),
  patchBusinessProfile: data => dispatch(patchBusinessProfile(data)),
  postShareBusinessData: data => dispatch(postShareBusinessData(data)),
  getAllExpertsNames: data => dispatch(getAllExpertsNames(data)),
  shareBusinessDataFaluire: data => dispatch(shareBusinessDataFaluire(data)),
  searchBusinessData: data => dispatch(searchBusinessData(data)),
  onBoardingLinkRequest: data => dispatch(onBoardingLinkRequest(data)),
  onBoardingLinkFaluire: data => dispatch(onBoardingLinkFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Business)

// export default Business
