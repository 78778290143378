import React, { useEffect, useState, useRef } from "react"

import PaginationPack from "components/PaginationPack"
// import Modal from "react-bootstrap/Modal"

import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"

import Images from "utils/Images"
import useForm from "utils/useForm"
import validator from "utils/validation"
import { connect } from "react-redux"

import Select from "react-select"
import { Toaster } from "react-hot-toast"

import PictureUpload from "components/CustomUpload/PictureUpload"

import {
  expertPageRequest,
  patchExpertProfile,
  getAllBusinessNames,
  postShareExpertData,
  searchExpertData,
  deleteExperienceData,
  shareExpertDataFaluire
} from "./redux/actions"

import { getProfileV2Request } from "Containers/HomeInitialProfileV2/redux/actions"

import {
  getIndustryExperience,
  postAdditionalExperties
} from "Containers/ExpertDesign/Screens/MyProfile/redux/actions"

import {
  onBoardingLinkRequest,
  onBoardingLinkFaluire
} from "../Business/redux/actions"
import moment from "moment"
import { searchBusinessData } from "../Business/redux/actions"
import { async } from "@firebase/util"

function ExpertsScreen(props) {
  const {
    expertPageRequest,
    allAccounts,
    requesting,
    patchExpertProfile,
    getAllBusinessNames,
    businessName,
    postShareExpertData,
    shareRequesting,
    searchExpertData,
    onBoardingLinkRequest,
    onBoardingLinkFaluire,
    BackendError,
    onBoardingLink,
    onBoardRequesting,
    shareSuccess,
    postAdditionalExperties,
    deleteExperienceData,
    deleteResponse,
    backendError,
    shareExpertDataFaluire
  } = props
  useEffect(() => {
    props.getIndustryExperience()
    props.getProfileV2Request()
  }, [])

  useEffect(() => {
    shareSuccess && setModal(false)
  }, [shareSuccess])
  const [modal, setModal] = useState(false)
  const [hour, setHour] = useState({ value: "0" })
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [search, setSearch] = useState("")
  const [check, setCheck] = useState(false)
  const [selectData, setSelectData] = useState(false)
  const [isSearched, setIsSearched] = useState(false)
  const [allBusinessData, setAllBusinessData] = useState(false)
  const [allBusinessNames, setAllBusinessNames] = useState([])
  const [selectBusinessNames, setSelectBusinessNames] = useState(false)
  const [checkedData, setCheckedData] = useState(false)

  const [show, setShow] = useState(false)
  const [experty, setExperty] = useState(false)
  const [industry, setIndustry] = useState(false)
  const [linkedInUrl, setlinkedInUrl] = useState(
    checkedData?.expert_data?.linkedin_profile_urlc
  )

  const getExpertise = () => {
    return (
      props.data &&
      props.data.user_expertise?.map(item => {
        return {
          label: item.type,
          value: item.type
        }
      })
    )
  }

  const getIndustries = () => {
    return (
      props.industries &&
      props.industries.results?.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    )
  }
  const handleClose = () => setShow(false)

  const fileRef = useRef()

  const toggle = () => {
    setState(stateSchema)
    setModal(!modal)
    setSelectBusinessNames("")
    shareExpertDataFaluire(false)
  }
  const toggle1 = () => {
    setState(stateSchema)
    setModal1(!modal1)
  }
  const toggle2 = () => {
    setState(stateSchema)
    setModal2(!modal2)
    onBoardingLinkFaluire(false)
  }

  useEffect(() => {
    expertPageRequest(1)
    getAllBusinessNames("")
    shareExpertDataFaluire(false)
  }, [])

  useEffect(() => {
    businessName && handleBusinessNamesData()
  }, [businessName])

  const handleBusinessNamesData = () => {
    let tempArray = []
    if (businessName) {
      businessName &&
        businessName.forEach((item, index) => {
          tempArray.push({
            value: index,
            label: `${item?.company_name}`,
            businessEmail: `${item?.associated_email}`
          })
        })
    }
    setAllBusinessNames(tempArray)
  }
  useEffect(() => {
    allAccounts && setAllBusinessData(allAccounts?.results)
    // businessPageRequest()
  }, [allAccounts])

  useEffect(() => {
    deleteResponse && setSelectData(deleteResponse)
    // businessPageRequest()
  }, [deleteResponse])
  // deleteResponse

  const stateSchema = {
    businessName: {
      value: "",
      error: ""
    },
    expertName: {
      value: "",
      error: ""
    },
    linkedinLink: {
      value: "",
      error: ""
    },
    expertBio: {
      value: "",
      error: ""
    },
    hourlyRate: {
      value: "",
      error: ""
    },
    expertMessage: {
      value: "",
      error: ""
    },
    noteForAdmin: {
      value: "",
      error: ""
    },
    shareExpertBio: {
      value: "",
      error: ""
    },
    onBoardEmail: {
      value: "",
      error: ""
    },
    onBoardMessage: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    businessName: {
      required: true
      // validator: validator.email
    },
    expertName: {
      required: true
      // validator: validator.email
    },
    linkedinLink: {
      required: true
      // validator: validator.email
    },
    expertBio: {
      required: true
      // validator: validator.email
    },
    hourlyRate: {
      required: true
      // validator: validator.email
    },
    expertMessage: {
      required: true
      // validator: validator.email
    },
    noteForAdmin: {
      required: true
    },
    shareExpertBio: {
      required: true
    },
    onBoardEmail: {
      required: true,
      validator: validator.email
    },
    onBoardMessage: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOpenShareExpert = () => {
    handleOnChange("expertName", checkedData?.expert_data?.name)
    setlinkedInUrl(checkedData?.expert_data?.linkedin_profile_ur)

    setHour({ value: checkedData?.hourly_rate })
    handleOnChange("shareExpertBio", checkedData?.expert_bio)
    setState(stateSchema)
    shareExpertDataFaluire(false)
  }

  const handleShareExpert = () => {
    const data = {
      // message: state.noteForAdmin.value,
      company_name: selectBusinessNames?.label,
      expert_name: checkedData?.expert_data?.name,
      business_email: selectBusinessNames?.businessEmail,
      bio: state.shareExpertBio.value,
      linkedin_url:
        linkedInUrl || checkedData?.expert_data?.linkedin_profile_url,
      hourly_rate: hour.value
    }
    postShareExpertData(data)
    setState(stateSchema)
  }
  const data = {
    d1: state.businessName.value,
    d2: state.expertName.value,
    d3: state.linkedinLink.value,
    d4: state.expertBio.value,
    d5: state.hourlyRate.value,
    d6: state.expertMessage.value
  }

  const handleSearch = value => {
    if (value == "Search") {
      setIsSearched(true)
      searchExpertData(search)
    } else {
      setIsSearched(false)
      setSearch("")
      searchExpertData("")
    }
  }

  const handleCheckBox = item => {
    setCheckedData(item)
    setCheck(item?.id)
    if (check === item?.id) {
      setCheck(false)
    }
  }

  const handleSaveChange = async item => {
    const data = {
      industry_type: industry?.value,
      experties: experty?.value,
      profile: selectData?.id
    }
    props.postAdditionalExperties(data)
    const data2 = {
      id: selectData?.id,
      data: {
        admin_note: state.noteForAdmin.value
      }
    }

    patchExpertProfile(data2)
    toggle1()
  }

  const handleHour = ({ target: { value } }) => {
    setHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )
  }
  const handlePageChange = data => {
    expertPageRequest(data)
  }

  const handleOnSearch = item => {
    if (item !== "") {
      setIsSearched(true)
    } else {
      setIsSearched(false)
    }
    searchExpertData(item)
  }

  useEffect(() => {
    onBoardingLink && setModal2(false)
  }, [onBoardingLink])

  const handleOnBoarding = () => {
    // toggle2();
    const data = {
      message: state.onBoardMessage.value,
      email: state.onBoardEmail.value,
      user_type: "Expert"
    }
    onBoardingLinkRequest(data)
    setState(stateSchema)
  }

  const handleIndustryDelete = item => {
    const data = {
      industry: item.id,
      expert: selectData.id
    }
    deleteExperienceData(data)
  }

  const handleExpertiseDelete = item => {
    const data = {
      expertise: item.id,
      expert: selectData.id
    }
    deleteExperienceData(data)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Experts
                  </CardTitle>
                  <Row style={{ display: "flex", paddingRight: "24px" }}>
                    <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={() => {
                        toggle2()
                        // handleOpenShareExpert()
                      }}
                    >
                      Onboard Expert
                    </Button>
                    {check && (
                      <Button
                        className="btn-round btn text-capitalize"
                        style={{
                          backgroundColor: "#fff",
                          color: "#3A0F7D",
                          border: "1px solid  #3A0F7D",
                          // width: "220px",
                          // height: "49px",
                          // marginTop: "0px",
                          borderRedius: "0px",
                          marginRight: 13
                        }}
                        onClick={() => {
                          toggle()
                          handleOpenShareExpert()
                        }}
                      >
                        Share Expert with Business
                      </Button>
                    )}
                    <Form style={{}} onSubmit={e => e.preventDefault()}>
                      <InputGroup
                        className="no-border"
                        style={{
                          borderRadius: "5px",
                          marginTop: "10px",
                          // backgroundColor: "yellow",
                          borderRadius: 30,
                          border: "1px solid #808080",
                          paddingLeft: "15px",
                          backgroundColor: "white"
                        }}
                      >
                        <Input
                          defaultValue=""
                          placeholder="Search..."
                          type="text"
                          value={search}
                          style={{
                            backgroundColor: "white",
                            borderBottomLeftRadius: "30px",
                            borderTopLeftRadius: "30px"
                          }}
                          onChange={e => {
                            handleOnSearch(e.target.value)
                            setSearch(e.target.value)
                            // setIsSearched(false)
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              backgroundColor: "white",
                              borderBottomRightRadius: "30px",
                              borderTopRightRadius: "30px"
                            }}
                          >
                            {!isSearched ? (
                              <img
                                onClick={() => handleSearch("Search")}
                                style={{
                                  height: "17px",
                                  width: "17px"
                                }}
                                src={Images.darkSearch}
                              />
                            ) : (
                              <img
                                onClick={() => handleSearch("Clear")}
                                style={{
                                  height: "17px",
                                  width: "17px"
                                }}
                                src={Images.cros}
                              />
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                  </Row>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        paddingLeft: "22px"
                      }}
                    >
                      Expert Name
                    </th>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Engagement Name
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        paddingRight: "72px"
                      }}
                    >
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          // transform: "translate(-50%, -50%)",
                          left: "50%"
                          // backgroundColor: 'red'
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allBusinessData ? (
                      allBusinessData.map(item => (
                        <>
                          <tr
                            style={{
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                padding: "25px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                border: "3px"
                              }}
                            >
                              <FormGroup check>
                                <div
                                  style={{
                                    display: "flex"
                                  }}
                                >
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      value={check}
                                      checked={item?.id == check ? true : false}
                                      onChange={e => handleCheckBox(item)}
                                    />
                                    <span
                                      check
                                      className="form-check-sign"
                                      style={{
                                        height: "10px",
                                        backgroundColor: "#3A0F7D"
                                      }}
                                    />
                                  </Label>
                                  <span
                                    style={{
                                      color: "#808080",
                                      fontFamily: "Khula",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "22px",
                                      // margin: "0px"
                                      marginLeft: "38px"
                                    }}
                                  >
                                    <Link
                                      style={{
                                        textDecoration: "none",
                                        color: "black"
                                      }}
                                    >
                                      {item?.expert_data?.name}
                                    </Link>
                                  </span>
                                </div>
                              </FormGroup>
                            </td>
                            <td
                              style={{
                                paddingLeft: "12px"
                              }}
                            >
                              {item?.engagement_name.length > 0
                                ? `${item?.engagement_name.map(
                                    item => `${" "}${item}`
                                  )}`
                                : "N/A"}
                            </td>
                            <td
                              className="text-right"
                              style={{
                                paddingTop: "14px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                paddingRight: "36px"
                              }}
                            >
                              <Button
                                className="btn-round btn text-capitalize
                              "
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  width: "max-content"
                                }}
                                onClick={() => {
                                  toggle1()
                                  setSelectData(
                                    deleteResponse ? deleteResponse : item
                                  )
                                  handleOnChange(
                                    "noteForAdmin",
                                    item?.admin_note
                                  )
                                }}
                              >
                                <img
                                  style={{
                                    height: "7px",
                                    width: "12px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.eye}
                                />
                                Preview
                              </Button>
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          // transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {allAccounts && allAccounts?.results.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={allAccounts?.page_size}
                        totalItemsCount={allAccounts?.total_objects}
                        activePage={allAccounts?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Share Expert with Business
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
              }}
              src={Images.cros}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Business Name
                </Label>
                {/* <Input
                  placeholder="Business name goes here.."
                  onChange={e => handleOnChange("businessName", e.target.value)}
                  type="text"
                  style={{
                    // backgroundColor: "#EFEFEF",
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                /> */}
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="pageSelect"
                  search={true}
                  value={selectBusinessNames}
                  onChange={value => {
                    setSelectBusinessNames(value)
                  }}
                  options={allBusinessNames}
                  placeholder="Choose Page"
                />
                {props?.backendError?.data?.company_name && (
                  <p style={{ color: "red", marginTop: 10 }}>
                    {props?.backendError?.data?.company_name}
                  </p>
                )}
                {/* {
                 props?.backendError?.data?.company_name ? (
                  <p style={{ color: "red", marginTop: 10 }}>
                    {props?.backendError?.data?.company_name}
                  </p>
                ) 
                :state.shareExpertBio.error ?(
                  <label style={{ color: "red", display: "flex" }}>
                    {state.shareExpertBio.error}
                  </label>
                ):null} */}
                {/* {(BackendError && BackendError?.password) && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.password[0]}
                  </label>
                )} */}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert Name
                </Label>
                <Input
                  disabled
                  placeholder="Type expert name"
                  defaultValue={checkedData?.expert_data?.name}
                  onChange={e => handleOnChange("expertName", e.target.value)}
                  type="text"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />
                {state.expertName.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.expertName.error}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  LinkedIn URL
                </Label>
                <Input
                  defaultValue={
                    checkedData?.expert_data?.linkedin_profile_url || ""
                  }
                  value={linkedInUrl}
                  placeholder="https://"
                  onChange={e => setlinkedInUrl(e.target.value)}
                  type="url"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px"
                  }}
                />

                {/* {(BackendError && BackendError?.password) && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.password[0]}
                  </label>
                )} */}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Hourly Rate
                </Label>
                <div style={{ display: "flex" }}>
                  <Input
                    // placeholder="$50"
                    value={`${hour.value}`}
                    onChange={e => handleHour(e)}
                    type="number"
                    min="0"
                    style={{
                      height: "49px",
                      width: "96px"
                      // backgroundColor: "#EFEFEF"
                    }}
                  />
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600",
                      margin: "14px"
                    }}
                  >
                    /hr
                  </Label>
                </div>
                {/* {`${hour.value}` == "" && (
                  <label style={{ color: "red", marginTop: "10px" }}>
                    {"Field Required"}
                  </label>
                )} */}
              </FormGroup>
              {props?.backendError?.data?.hourly_rate && (
                <p style={{ color: "red", marginTop: 10 }}>
                  {props?.backendError?.data?.hourly_rate}
                </p>
              )}
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Bio
                </Label>
                <Input
                  value={state.shareExpertBio.value}
                  onChange={e =>
                    handleOnChange("shareExpertBio", e.target.value)
                  }
                  placeholder="Type bio here"
                  type="textarea"
                />
                {props?.backendError?.data?.bio ? (
                  <p style={{ color: "red", marginTop: 10 }}>
                    {props?.backendError?.data.bio}
                  </p>
                ) : state.shareExpertBio.error ? (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.shareExpertBio.error}
                  </label>
                ) : null}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",

                height: "49px",
                width: "132px"
              }}
              onClick={toggle}
            >
              Cancel
            </Button>
            <div style={{ margin: "0px 10px 0px 10px" }}></div>
            <Button
              onClick={() => handleShareExpert()}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px",
                width: "159px",
                whiteSpace: "nowrap"
              }}
            >
              {shareRequesting ? <Spinner size="sm" /> : "Share Expert"}
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          // className={className}
          // external={externalCloseBtn}
          style={{ maxWidth: "1073px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,

                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col>
                <div>
                  <PictureUpload
                    style={{ cursor: "not-allowed" }}
                    avatar={`${selectData?.expert_data?.profile_picture}`}
                    disable={true}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", marginTop: "23px" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Full Name
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=" "
                        value={`${selectData?.expert_data?.name}`}
                        type="text"
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="10"
                    >
                      LinkedIn Profile Link
                    </Label>
                    <FormGroup>
                      <Input
                        disabled
                        placeholder=" "
                        value={`${selectData?.expert_data?.linkedin_profile_url}`}
                        type="text"
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-md-4" style={{ justifyContent: "center" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Email
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=" "
                        value={`${selectData?.expert_data?.email}`}
                        type="text"
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="10"
                    >
                      Hourly Rate
                    </Label>
                    <FormGroup
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Input
                        disabled
                        placeholder=" "
                        type="text"
                        value={`${selectData?.hourly_rate}`}
                        style={{ width: 97, backgroundColor: "#EFEFEF" }}
                      />
                      <p style={{ margin: 0, marginLeft: "13px" }}>/hr</p>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-md-4" style={{ justifyContent: "center" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Resume
                    </Label>
                    <FormGroup className={{}}>
                      <div
                        style={{
                          backgroundColor: "rgba(58, 15, 125, 0.3)",
                          borderRadius: 4,
                          alignItems: "center",
                          display: "flex",
                          paddingLeft: "15px",
                          width: 200,
                          height: 40
                        }}
                      >
                        <img
                          style={{
                            height: "20px",

                            // marginLeft: "20px",
                            marginRight: "4px"
                          }}
                          src={require("assets/img/uploadPdf.png")}
                          onClick={() => fileRef.current.click()}
                        />
                        <a
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            padding: 5
                          }}
                          href={
                            selectData && selectData?.resume
                              ? selectData?.resume
                              : "#"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Resume.pdf
                        </a>
                        {/* <p
                          style={{
                            alignSelf: "center",
                            marginBottom: "0px",
                            padding: 5,
                            wordBreak: "break-all"
                          }}
                        >
                          {selectData?.resume ?selectData?.resume:"Not Upload Resume "}
                        </p> */}
                      </div>
                      {/* <div>
                                <Label
                                  style={{
                                    color: "#808080",
                                    padding: "0px",
                                    marginBottom: "7px",
                                    fontWeight: "600"
                                  }}
                                  sm="6"
                                >
                                  Resume
                                </Label>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer"
                                  }}
                                  href={
                                    expertProfileData &&
                                    expertProfileData?.resume
                                      ? expertProfileData?.resume
                                      : "#"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div
                                    style={{
                                      // backgroundColor: "red",
                                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                                      // width: "157px",

                                      // height: "51px",
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      paddingLeft: "20px",
                                      borderRadius: "4px"
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "20px",
                                        width: "16px",
                                        // marginLeft: "20px",
                                        marginRight: "2px",
                                        borderRadius: "0px"
                                      }}
                                      src={require("assets/img/uploadPdf.png")}
                                      onClick={() => fileRef.current.click()}
                                    />
                                    <span
                                      style={{
                                        marginBottom: 0,
                                        padding: 15,
                                        display: "flex",
                                        wordBreak: "break-all"
                                      }}
                                    >
                                      {"Resume.pdf"}
                                    </span>
                                  </div>
                                </a>
                              </div> */}
                    </FormGroup>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5"></Col>
                </Row>
              </Col>
            </Row>
            <Row
              className={"pl-md-5"}
              style={{ justifyContent: "center", marginTop: "23px" }}
            >
              <Col sm="12">
                <Row style={{}}>
                  <Col md="5" className={"pr-md-0"}>
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Weekly Availability
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=" "
                        type="text"
                        value={`${selectData?.user_availability}`}
                        style={{ backgroundColor: "#EFEFEF" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={"pl-lg-0"} md="1"></Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", marginTop: "23px" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Industry Experience
                    </Label>
                    <div
                      style={{ display: "flex", flexWrap: "wrap" }}
                      // onClick={() => setShow(true)}
                    >
                      {selectData &&
                      selectData?.industry_experience?.length > 0 ? (
                        selectData?.industry_experience.map(item => (
                          <div
                            style={{
                              padding: "9px 12px 9px 12px",
                              backgroundColor: "rgba(58, 15, 125, 0.3)",
                              display: "flex",
                              borderRadius: "8px",
                              alignItems: "center",
                              width: "fit-content",
                              marginRight: "10px",
                              marginTop: 10
                            }}
                          >
                            <p
                              style={{
                                color: "#3A0F7D",
                                fontWeight: "400",
                                fontSize: "14px",
                                margin: 0,
                                width: "fit-content",
                                borderRight: "1px solid #3A0F7D",
                                paddingRight: "10px"
                              }}
                            >
                              {/* asdasd */}
                              {item?.industry_name}
                            </p>

                            <i
                              onClick={() => handleIndustryDelete(item)}
                              className="nc-icon nc-simple-remove "
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                color: "#3A0F7D"
                              }}
                            ></i>
                          </div>
                        ))
                      ) : (
                        <p
                          style={{
                            backgroundColor: "rgba(58, 15, 125, 0.3)",
                            padding: "10px 12px 10px 12px",
                            borderRadius: "8px",
                            color: "#3A0F7D",
                            fontWeight: "400",
                            fontSize: "14px",
                            marginRight: "10px",
                            width: "fit-content"
                          }}
                        >
                          {"N/A"}
                        </p>
                      )}
                    </div>
                    {/* <div
                      style={{
                        padding: "12px 12px 12px 12px",
                        backgroundColor: "rgba(58, 15, 125, 0.3)",
                        display: "flex",
                        borderRadius: "8px",
                        alignItems: "center"
                      }}
                    >
                      
                      <i className="nc-icon nc-simple-remove"></i>
                      <p
                        style={{
                          color: "#3A0F7D",
                          fontWeight: "400",
                          fontSize: "14px",
                          margin: 0,
                          width: "fit-content",
                          borderLeft: "1px solid black",
                          paddingLeft: "5px",
                          marginLeft:"5px"
                        }}
                      >
                        asdasd
                       
                      </p>
                    </div> */}
                  </Col>
                  <Col md="1"></Col>
                  <Col md="5">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="10"
                    >
                      Area of expertise
                    </Label>
                    {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selectData && selectData?.expertise_detail.length > 0 ? (
                        selectData?.expertise_detail.map(item => (
                          <p
                            style={{
                              backgroundColor: "rgba(58, 15, 125, 0.3)",
                              padding: "10px 12px 10px 12px",
                              borderRadius: "8px",
                              color: "#3A0F7D",
                              fontWeight: "400",
                              fontSize: "14px",
                              marginRight: "10px",
                              width: "fit-content",
                              cursor: "pointer"
                            }}
                          >
                            {item.type}
                          </p>
                        ))
                      ) : (
                        <p
                          style={{
                            backgroundColor: "rgba(58, 15, 125, 0.3)",
                            padding: "10px 12px 10px 12px",
                            borderRadius: "8px",
                            color: "#3A0F7D",
                            fontWeight: "400",
                            fontSize: "14px",
                            marginRight: "10px",
                            width: "fit-content",
                            cursor: "pointer"
                          }}
                        >
                          {"N/A"}
                        </p>
                      )}
                    </div> */}
                    <div
                      style={{ display: "flex", flexWrap: "wrap" }}
                      // onClick={() => setShow(true)}
                    >
                      {selectData && selectData?.expertise.length > 0 ? (
                        selectData?.expertise.map(item => (
                          <div
                            style={{
                              padding: "9px 12px 9px 12px",
                              backgroundColor: "rgba(58, 15, 125, 0.3)",
                              display: "flex",
                              borderRadius: "8px",
                              alignItems: "center",
                              width: "fit-content",
                              marginRight: "10px",
                              marginTop: 10
                            }}
                          >
                            <p
                              style={{
                                color: "#3A0F7D",
                                fontWeight: "400",
                                fontSize: "14px",
                                margin: 0,
                                width: "fit-content",
                                borderRight: "1px solid #3A0F7D",
                                paddingRight: "10px"
                              }}
                            >
                              {item?.detail?.name}
                            </p>
                            <i
                              onClick={() => handleExpertiseDelete(item)}
                              className="nc-icon nc-simple-remove "
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                color: "#3A0F7D"
                              }}
                            ></i>
                          </div>
                        ))
                      ) : (
                        <p
                          style={{
                            backgroundColor: "rgba(58, 15, 125, 0.3)",
                            padding: "10px 12px 10px 12px",
                            borderRadius: "8px",
                            color: "#3A0F7D",
                            fontWeight: "400",
                            fontSize: "14px",
                            marginRight: "10px",
                            width: "fit-content"
                          }}
                        >
                          {"N/A"}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              className={"pl-md-5 mr-md-4"}
              style={{ justifyContent: "center", marginTop: "23px" }}
            >
              <Col sm="12">
                <Row style={{}}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Expert Bio
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        disabled
                        placeholder=" "
                        type="text"
                        value={`${
                          selectData?.expert_bio ? selectData?.expert_bio : ""
                        }`}
                        style={{
                          maxWidth: "990px",
                          height: "109px",
                          backgroundColor: "#EFEFEF"
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col sm="12">
                <Row style={{}}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#808080",
                        padding: "0px",
                        marginBottom: "7px"
                      }}
                      sm="6"
                    >
                      Notes from Admin
                    </Label>
                    <FormGroup className={{}}>
                      <Input
                        placeholder=" "
                        type="text"
                        value={`${
                          state.noteForAdmin.value
                            ? state.noteForAdmin.value
                            : ""
                        }`}
                        style={{ maxWidth: "990px", height: "109px" }}
                        onChange={e =>
                          handleOnChange("noteForAdmin", e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", marginTop: "75px" }}>
              <Col sm="12">
                <Row style={{ justifyContent: "center" }}>
                  <div onClick={() => toggle1()}>
                    <p
                      style={{
                        border: "2px #3A0F7D solid",
                        padding: "16px 38px 16px 38px",
                        borderRadius: "30px",
                        color: "#3A0F7D",
                        fontWeight: "700",
                        fontSize: "16px",
                        marginRight: "34px",
                        boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                        cursor: "pointer"
                      }}
                    >
                      Cancel
                    </p>
                  </div>
                  <div onClick={() => handleSaveChange()}>
                    <p
                      style={{
                        border: "2px #3A0F7D solid",
                        padding: "16px 38px 16px 38px",
                        borderRadius: "30px",
                        color: "#FFFFFF",
                        backgroundColor: "#3A0F7D",
                        fontWeight: "700",
                        fontSize: "16px",
                        boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                        cursor: "pointer"
                      }}
                    >
                      Save Changes
                    </p>
                  </div>
                </Row>
              </Col>
            </Row>
            {/* <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              <div
                style={{ marginTop: "42px" }}
                onClick={() => {
                  props.history.push("/admin/engagements_active")
                  props.getActiveEngagementRequest({
                    page: 1,
                    id: selectData?.expert_data?.id
                  })
                }}
              >
                <p
                  style={{
                    color: "#3A0F7D",
                    fontSize: "16px",
                    foontWeight: "700",
                    borderBottom: "1.5px solid #3A0F7D",
                    cursor: "pointer"
                  }}
                >
                  View Engagement
                </p>
              </div>
            </Row> */}
          </div>
          <div style={{ marginLeft: "20%" }}>
            <Modal isOpen={show} toggle2={handleClose} centered>
              <ModalBody>
                {/* <Input
                        placeholder="Areas of Experties"
                        style={{ padding: "14px" }}
                      /> */}
                <div style={{}}>
                  {/* <label style={{ display: "flex", color: "#808080" }}>
                Client
              </label> */}
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula"
                    }}
                  >
                    Industry Experience
                  </Label>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    // value={role}
                    onChange={value => setIndustry(value)}
                    options={getIndustries()}
                    placeholder="Select Time"
                  />
                  {props?.backendError?.experties ? (
                    <p style={{ color: "red", marginTop: 10 }}>
                      {props?.backendError?.experties}
                    </p>
                  ) : null}
                </div>

                <div style={{}}>
                  {/* <label style={{ display: "flex", color: "#808080" }}>
                Client
              </label> */}
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula"
                    }}
                  >
                    Areas of Experties
                  </Label>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    // value={role}
                    onChange={value => setExperty(value)}
                    options={getExpertise()}
                    placeholder="Select Time"
                  />
                  {props.backendError?.industry_type ? (
                    <p style={{ color: "red", marginTop: 10 }}>
                      {props?.backendError?.industry_type}
                    </p>
                  ) : null}
                </div>

                {/* <Input
                        placeholder="Industry Experience"
                        style={{ padding: "14px", marginTop: "24px" }}
                      /> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10
                  }}
                >
                  <Button
                    className="btn-round text-capitalize"
                    style={{
                      width: "160px",
                      backgroundColor: "#3A0F7D",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                      whiteSpace: "nowrap"
                    }}
                    onClick={() => {
                      const id = localStorage.getItem("profileid")
                      const data = {
                        industry_type: industry?.value,
                        experties: experty?.value,
                        profile: id
                      }
                      setShow(false)
                      // props.postAdditionalExperties(data, handleClose)
                    }}
                  >
                    {requesting ? <Spinner size="sm" /> : " Apply"}
                  </Button>
                </div>
              </ModalBody>
            </Modal>
            {/* <Button
                          className="btn-round text-capitalize"
                          color="secondary"
                          style={{
                            width: "160px",
                            backgroundColor: "#3A0F7D",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                          }}
                        >
                          {requesting ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Complete Profile"
                          )}
                        </Button> */}
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}></div>
        </Modal>

        <Modal
          isOpen={modal2}
          toggle2={toggle2}
          // className={className}
          // external={externalCloseBtn}
          //   style={{ maxWidth: "994px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Onboarding Link
            </h5>
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Email
                </Label>
                <Input
                  placeholder="Type email"
                  type="email"
                  value={state.onBoardEmail.value}
                  onChange={e => {
                    handleOnChange("onBoardEmail", e.target.value)
                    onBoardingLinkFaluire(false)
                  }}
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px",
                    border: "1px solid"
                  }}
                />
                {state.onBoardEmail.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.onBoardEmail.error}
                  </label>
                )}
                {BackendError && BackendError?.email && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.email[0]}
                  </label>
                )}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Message
                </Label>
                <Input
                  value={state.onBoardMessage.value}
                  onChange={e => {
                    handleOnChange("onBoardMessage", e.target.value)
                    // onBoardingLinkFaluire(false)
                  }}
                  placeholder="Type Message."
                  type="textarea"
                  style={{ border: "1px solid" }}
                />
                {state.onBoardMessage.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.onBoardMessage.error}
                  </label>
                )}
                {BackendError && BackendError?.message && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.message[0]}
                  </label>
                )}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize
                              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                // display: "flex",
                // alignItems: "center",
                height: "49px",
                width: "132px"
              }}
              onClick={toggle2}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleOnBoarding()}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px",
                width: "149px"
              }}
            >
              {onBoardRequesting ? <Spinner size="sm" /> : "Save Changes"}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.ExpertsScreen.requesting,
  allAccounts: state.ExpertsScreen.allAccounts,
  shareSuccess: state.ExpertsScreen.shareSuccess,
  businessName: state.ExpertsScreen.allBusinessData,
  shareRequesting: state.ExpertsScreen.shareRequesting,
  BackendError: state.Business.error,
  onBoardRequesting: state.Business.onBoardRequesting,
  onBoardingLink: state.Business.onBoardingLink,
  industries: state.Profile.industries,
  data: state.HomeInitialProfileV2.data,
  deleteResponse: state.ExpertsScreen.deleteResponse,
  backendError: state.ExpertsScreen.error
  // user: state.LoginScreen.user
})
const mapDispatchToProps = dispatch => ({
  expertPageRequest: data => dispatch(expertPageRequest(data)),
  patchExpertProfile: data => dispatch(patchExpertProfile(data)),
  getAllBusinessNames: data => dispatch(getAllBusinessNames(data)),
  postShareExpertData: data => dispatch(postShareExpertData(data)),
  searchExpertData: data => dispatch(searchExpertData(data)),
  shareExpertDataFaluire: data => dispatch(shareExpertDataFaluire(data)),
  onBoardingLinkRequest: data => dispatch(onBoardingLinkRequest(data)),
  onBoardingLinkFaluire: data => dispatch(onBoardingLinkFaluire(data)),
  getProfileV2Request: () => dispatch(getProfileV2Request()),
  getIndustryExperience: () => dispatch(getIndustryExperience()),
  postAdditionalExperties: data => dispatch(postAdditionalExperties(data)),
  deleteExperienceData: data => dispatch(deleteExperienceData(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ExpertsScreen)
