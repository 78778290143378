import React, { useEffect } from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap"

import { Link } from "react-router-dom"

import Images from "utils/Images"

const NotFound = () => {
  // useEffect(() => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  // }, [])
  return (
    <div
    // style={{ height: "100%", margin: 0 }}
    // className="full-page-background"
    // style={{ backgroundColor: 'red', flex: 1 }}
    // className="full-page-background"
    // style={{
    //   // backgroundImage: `url(${require("assets/img/bg/bg_img.png")})`,
    //   // backgroundColor: 'red'
    // }}
    >
      <div className="login-page" >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8" sm="">
              <Form action="" className="form" method="">
                <Card className="card-login" style={{ marginTop: "8rem" }}>
                  <CardHeader>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "0px"
                        }}
                      >
                        <img
                          style={{ alignSelf: "center" }}
                          src={require("assets/img/app_logo.png")}
                          alt="Logo"
                        />
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <br />
                    <FormGroup>
                      <div style={{ marginTop: '30%' }}>
                        <h1 style={{
                          textAlign: "center",
                          // fontSize: "16px",
                          fontWeight: "Bold",
                          fontFamily: "khula",
                          marginTop: "40%"
                        }}>Lost your way?</h1>

                        <p style={{
                          textAlign: "center",
                          fontSize: "20px",
                          // fontWeight: "Bold",
                          fontFamily: "khula",
                          // marginTop: "40%"
                        }}>Sorry, we can't find that page. You'll find loads to explore on the home page.</p>
                      </div>

                      <Link to="/auth/welcome" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          className="px-md-5 text-capitalize rounded-pill"
                          // style={{width:'209px', height: '54px'}}
                          style={{
                            backgroundColor: "#3A0F7D",
                            color: "white",
                            height: "54px",
                            fontSize: "16px",
                            alignSelf: 'center'
                          }}
                        >
                          Home
                        </Button>
                      </Link>
                    </FormGroup>
                  </CardBody>

                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        {/* <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/bg_img1.png")})`
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              src={Images.vector_1}
              style={{
                position: "absolute",
                right: 19,
                top: 19,
                height: "80px",
                width: "80px"
              }}
            />
          </div>
          <img
            src={Images.vector_2}
            style={{
              position: "absolute",
              bottom: 19,
              left: 19,
              height: "80px",
              width: "80px"
            }}
          />
        </div> */}
      </div>
    </div>
  )
}

export default NotFound
