import React, { useState, useEffect } from "react"
import PaginationPack from "components/PaginationPack"
import { connect, useSelector } from "react-redux"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"
import ReadMore from "components/ReadMore"
import Select from "react-select"
import "./style.css"

import Images from "utils/Images"

import { educationalDataRequest } from "../redux/actions"

function EducationResources(props) {
  const { educationalDataRequest, educationalData, requesting } = props

  const handleSubmit = () => {}

  useEffect(() => {
    educationalDataRequest(1)
  }, [])

  const handlePageChange = page => {
    educationalDataRequest(page)
  }

  const [isReadMore, setIsReadMore] = useState(false)
  const [paraId, SetparaId] = useState([])

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  /> */}
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Education Center
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Row style={{}} className="p-0 m-0">
                  {requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : educationalData && educationalData?.results.length > 0 ? (
                    educationalData?.results?.map(item => {
                      return (
                        <>
                          <Col lg="3" md="3" sm="4">
                            <div
                              className="cards"
                              style={{
                                backgroundColor: "white",
                                padding: 10,
                                borderRadius: 8,
                                //   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                                border: "1px solid #E2E2E2",
                                marginTop: 16,
                                // height: "1",
                                maxWidth: "250px"
                              }}
                            >
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                                href={item.url}
                              >
                                <img
                                  style={{
                                    marginRight: "20px",
                                    cursor: "pointer",
                                    height: "180px",
                                    width: "100%"
                                  }}
                                  src={item?.image}
                                />
                                {item.title === "" ? (
                                  <div style={{ height: 40 }}></div>
                                ) : (
                                  <p
                                    style={{
                                      marginTop: 15,
                                      textAlign: "center",
                                      color: "#000000",
                                      fontSize: 16,
                                      fontFamily: "khula",
                                      fontWeight: "bold",
                                      lineHeight: "24px"
                                    }}
                                  >
                                    {item.title}
                                  </p>
                                )}
                              </a>

                              <p
                                style={{
                                  marginTop: 15,
                                  textAlign: "center",
                                  color: "#000000",
                                  fontSize: 15,
                                  fontFamily: "khula",
                                  fontWeight: "500",
                                  lineHeight: "24px"
                                }}
                              >
                                <ReadMore
                                  length={100}
                                  item={item}
                                  text={item.details}
                                />
                              </p>

                              {/* {item?.details>20?(
                                    <>{item?.details }</>
                                  ):item?.details } */}
                            </div>
                          </Col>
                        </>
                      )
                    })
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </Row>
                <div className="pt-4 d-flex justify-content-center">
                  {educationalData && educationalData?.results?.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={educationalData?.page_size}
                        totalItemsCount={educationalData?.total_objects}
                        activePage={educationalData?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

// const mapStateToProps = state => ({
//   hoursResult: state.Engagement.hoursResult,
//   requestHoursRequesting: state.Engagement.requestHoursRequesting,
//   acceptedHoursResult: state.Engagement.acceptedHoursResult,
//   acceptRequestHoursRequesting: state.Engagement.acceptRequestHoursRequesting
// })

// const mapDispatchToProps = dispatch => ({
//   requestHours: () => dispatch(requestHours()),
//   acceptRequestHours: (data, toggle) =>
//     dispatch(acceptRequestHours(data, toggle))
// })

const mapStateToProps = state => ({
  requesting: state.EducationalCenter.requesting,
  educationalData: state.EducationalCenter.educationalData
})

const mapDispatchToProps = dispatch => ({
  educationalDataRequest: data => dispatch(educationalDataRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(EducationResources)
