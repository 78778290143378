import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"

import { educationalDataRequest } from "./redux/actions"
import { connect } from "react-redux"

function EducationalCenter(props) {
  const { educationalDataRequest, educationalData, requesting } = props
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle2 = () => setModal2(!modal2)

  useEffect(() => {
    educationalDataRequest()
  }, [])

  const handleSubmit = () => {}

  return (
    <>
      <div className="content">
        <div className="header text-left">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <h3
              className="title"
              style={{
                color: "#CACACA",
                fontFamily: "Libre Caslon Text",
                fontWeight: "700",
                fontSize: "22px",
                margin: "0px"
              }}
            >
              Educational Center
            </h3>
            <Button
              className="btn-round text-capitalize"
              color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Apply for Expertise
            </Button>
          </div>
        </div>

        <div style={{ marginTop: "-30px" }}>
          <Card>
            <Row
              className="justify-content-center justify-content-sm-between m-0"
              style={{
                display: "flex",

                alignItems: "center",
                border: "2px solid #E2E2E2",
                borderRadius: "10px"
              }}
            >
              <CardHeader>
                <CardTitle className="text-center" style={{ marginTop: "0px" }}>
                  To enrich your profile learn from more resources
                </CardTitle>
              </CardHeader>

              <CardFooter>
                <Link to="/Expert/LearningResources">
                  <Button
                    className="btn-round text-capitalize"
                    color="secondary"
                    style={{
                      backgroundColor: "#3A0F7D",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                    }}
                  >
                    Learning Resources
                  </Button>
                </Link>
              </CardFooter>
            </Row>
          </Card>
        </div>

        <Card>
          <Row
            className="justify-content-center justify-content-sm-between m-0"
            style={{
              display: "flex",

              alignItems: "center",
              border: "2px solid #E2E2E2",
              borderRadius: "10px"
            }}
          >
            <Row>
              <Col sm="12">
                <CardHeader>
                  <CardTitle
                    className="text-center"
                    style={{ marginTop: "0px" }}
                  >
                    To enrich you profile you can apply for more expertise
                  </CardTitle>
                </CardHeader>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <CardFooter>
                  <Link to="/Expert/ApplyForExpertise">
                    <Button
                      className="btn-round text-capitalize"
                      color="secondary"
                      style={{
                        backgroundColor: "#3A0F7D",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                      }}
                    >
                      Apply for Expertise
                    </Button>
                  </Link>
                </CardFooter>
              </Col>
            </Row>
          </Row>
        </Card>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.EducationalCenter.requesting,
  educationalData: state.EducationalCenter.educationalData
})

const mapDispatchToProps = dispatch => ({
  educationalDataRequest: data => dispatch(educationalDataRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(EducationalCenter)
