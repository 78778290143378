import React, { useState, useRef, useEffect } from "react"

import PaginationPack from "components/PaginationPack"

import { Toaster } from "react-hot-toast"
// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Spinner
} from "reactstrap"

import { patchAccountRequest, pageRequest } from "./redux/actions"
import { connect } from "react-redux"

function ApproveAccounts(props) {
  const { patchAccountRequest, allAccounts, requesting } = props

  const [modal, setModal] = useState(false)
  const [allApproveAccounts, setAllApproveAccounts] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [previewModalData, setPreviewModalData] = useState(false)

  const toggle = val => {
    setPreviewModalData(val)
    setModal(!modal)
  }
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)

  const fileRef = useRef()

  const handleChange = e => {
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
  }

  const handlePageChange = page => {
    props.pageRequest(page)
  }

  useEffect(() => {
    props.pageRequest(1)
  }, [])

  useEffect(() => {
    allAccounts && setAllApproveAccounts(allAccounts?.results)
  }, [allAccounts])

  const handleAcceptRequest = async item => {
    const data = {
      id: item,
      data: { verified: true }
    }
    await patchAccountRequest(data)
    setModal(!modal)
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            {props.BackendError ? (
              <div
                style={{
                  display: "flex",
                  height: "80vh",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {props.BackendError == "Connection Error" ? (
                  <p style={{ color: "red" }}>
                    {props.BackendError} : Please check your internet
                    connection.
                  </p>
                ) : (
                  <p style={{ color: "red" }}>{props.BackendError}</p>
                )}
              </div>
            ) : (
              <Card className="card-plain">
                <CardHeader>
                  <div style={{ display: "flex" }}>
                    <CardTitle
                      tag="h4"
                      style={{
                        fontFamily: "Libre Caslon Text",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#CACACA"
                      }}
                    >
                      Approve Accounts
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardBody>
                  <Toaster position="top-center" />
                  <Table
                    responsive="md"
                    style={{
                      border: "3px solid transparent"
                      // minHeight: 400
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "#808080",
                            padding: "25px",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Expert
                        </th>

                        <th
                          className="text-right"
                          style={{
                            color: "#808080",
                            paddingRight: "60px",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allAccounts && allAccounts?.results?.length > 0 ? (
                      <tbody>
                        {allApproveAccounts &&
                          allApproveAccounts.map(item => (
                            <>
                              <tr
                                style={{
                                  backgroundColor: "white",
                                  marginLeft: "25px",
                                  borderRadius: "8px",
                                  border: "3px solid transparent"
                                }}
                              >
                                <td
                                  style={{
                                    padding: "25px",
                                    borderBottomLeftRadius: "8px",
                                    borderTopLeftRadius: "8px"
                                  }}
                                >
                                  {item?.user_data?.name}
                                </td>

                                <td
                                  className="text-right"
                                  style={{
                                    paddingRight: "25px",
                                    paddingTop: "14px",
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px"
                                  }}
                                >
                                  <Button
                                    className="btn-round btn text-capitalize"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#3A0F7D",
                                      border: "1px solid  #3A0F7D",
                                      display: "flex",
                                      alignItems: "center",
                                      float: "right"
                                    }}
                                    onClick={() => toggle(item)}
                                  >
                                    <img
                                      style={{
                                        height: "9px",
                                        marginRight: "4px"
                                      }}
                                      src={require("assets/img/eye.png")}
                                    />
                                    Preview
                                  </Button>
                                </td>
                              </tr>
                              <tr style={{ height: "12px" }}></tr>
                            </>
                          ))}
                      </tbody>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </Table>
                  {!props.BackendError || allAccounts?.results?.length > 0 ? (
                    <div className="pt-4 d-flex justify-content-center">
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={allAccounts?.page_size}
                        totalItemsCount={allAccounts?.total_objects}
                        activePage={allAccounts?.current_page_number}
                      />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: "1073px" }}>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",

                margin: "20px"
              }}
            >
              <img
                onClick={toggle}
                style={{
                  height: 15,
                  width: 15,
                  marginLeft: "20px"
                  // marginRight: "12px"
                }}
                src={require("assets/img/cros.png")}
              />
            </div>
            <div style={{ margin: "20px" }}>
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <div
                  style={{
                    border: "1px solid black",
                    width: "192px",
                    height: "192px",
                    borderRadius: "50%"
                  }}
                >
                  {previewModalData?.user_data?.profile_picture ? (
                    <img
                      style={{
                        width: "192px",
                        height: "192px",
                        borderRadius: "50%"
                      }}
                      src={previewModalData?.user_data?.profile_picture}
                    />
                  ) : (
                    <img
                      style={{
                        width: "192px",
                        height: "192px",
                        borderRadius: "50%"
                      }}
                      src={require("assets/img/Profile.png")}
                    />
                  )}
                </div>
              </Row>
              <Row style={{ justifyContent: "center", marginTop: "23px" }}>
                <Col sm="12">
                  <Row style={{ justifyContent: "center" }}>
                    <Col md="5">
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="6"
                      >
                        full Name
                      </Label>
                      <FormGroup className={{}}>
                        <Input
                          disabled
                          defaultValue={`${
                            previewModalData &&
                            previewModalData?.user_data?.name
                          }`}
                          // placeholder="Name"
                          type="text"
                          style={{ backgroundColor: "#EFEFEF" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="5">
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="10"
                      >
                        LinkedIn Profile Link
                      </Label>
                      <FormGroup>
                        <Input
                          disabled
                          defaultValue={`${
                            previewModalData &&
                            previewModalData?.user_data?.linkedin_profile_url
                          }`}
                          // placeholder="//http."
                          type="text"
                          style={{ backgroundColor: "#EFEFEF" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-md-4" style={{ justifyContent: "center" }}>
                <Col sm="12">
                  <Row style={{ justifyContent: "center" }}>
                    <Col md="5">
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="6"
                      >
                        Email
                      </Label>
                      <FormGroup className={{}}>
                        <Input
                          disabled
                          defaultValue={`${
                            previewModalData &&
                            previewModalData?.user_data?.email
                          }`}
                          // placeholder="ron.sharon@example.com"
                          type="text"
                          style={{ backgroundColor: "#EFEFEF" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="5">
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="10"
                      >
                        Hourly Rate
                      </Label>
                      <FormGroup
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Input
                          disabled
                          defaultValue={`${
                            previewModalData && previewModalData?.hourly_rate
                          }`}
                          type="text"
                          style={{ width: 97, backgroundColor: "#EFEFEF" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            marginLeft: "13px",
                            color: "#808080",
                            fontWeight: "600"
                          }}
                        >
                          /hr
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-md-4" style={{ justifyContent: "center" }}>
                <Col sm="12">
                  <Row style={{ justifyContent: "center" }}>
                    <Col md="5">
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="6"
                      >
                        Resume
                      </Label>
                      <FormGroup className={{}}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "4px",
                            backgroundColor: "rgba(58, 15, 125, 0.3)",
                            width: "157px",
                            height: "51px",
                            paddingLeft: "20px"
                          }}
                        >
                          <img
                            onClick={() => fileRef.current.click()}
                            style={{
                              height: "20px",
                              width: "16px",
                              marginRight: "4px"
                            }}
                            src={require("assets/img/uploadPdf.png")}
                          />
                          <input
                            type="file"
                            name="file"
                            ref={fileRef}
                            onChange={handleChange}
                            hidden
                          />
                          {isFilePicked ? (
                            <div>
                              <p style={{ margin: "0px", marginLeft: "5px" }}>
                                {selectedFile.name}
                              </p>
                            </div>
                          ) : (
                            "fileName.pdf"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="5"></Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className={"pl-md-5"}
                style={{ justifyContent: "center", marginTop: "23px" }}
              >
                <Col sm="12">
                  <Row style={{}}>
                    <Col md="5" className={"pr-md-0"}>
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="6"
                      >
                        Weekly Availability
                      </Label>
                      <FormGroup className={{}}>
                        <Input
                          disabled
                          defaultValue={`${
                            previewModalData &&
                            previewModalData?.user_availability
                          } hours per week`}
                          type="text"
                          style={{ backgroundColor: "#EFEFEF" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className={"pl-lg-0"} md="1"></Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className={"pl-md-5 mr-md-4"}
                style={{ justifyContent: "center", marginTop: "23px" }}
              >
                <Col sm="12">
                  <Row style={{}}>
                    <Col md="12">
                      <Label
                        style={{
                          color: "#808080",
                          padding: "0px",
                          marginBottom: "7px",
                          fontWeight: "600"
                        }}
                        sm="6"
                      >
                        Expert Bio
                      </Label>
                      <FormGroup className={{}}>
                        <Input
                          defaultValue={`${
                            previewModalData && previewModalData?.expert_bio
                          }`}
                          disabled
                          type="textarea"
                          style={{
                            maxWidth: "990px",
                            height: "109px",
                            backgroundColor: "#EFEFEF"
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center", marginTop: "50px" }}>
                <Col sm="12">
                  <Row style={{ justifyContent: "center" }}>
                    <div onClick={toggle}>
                      <div
                        className="mr-md-3"
                        style={{
                          display: "flex",
                          height: 54,
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px #3A0F7D solid",
                          padding: "16px 38px 16px 38px",
                          borderRadius: "30px",
                          color: "#3A0F7D",
                          fontWeight: "700",
                          fontSize: "16px",
                          // marginRight: "34px",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                          cursor: "pointer",
                          marginTop: "15px"
                        }}
                      >
                        {/* {props.requesting ? (
                          <div style={{ padding: "0px 45px 0px 45px" }}>
                            <Spinner size="sm" color="#3A0F7D" />
                          </div>
                        ) : (
                          <p style={{ margin: 0 }}>Deny the Request</p>
                        )} */}
                        <p style={{ margin: 0 }}>Deny the Request</p>
                      </div>
                    </div>
                    <div
                      onClick={() => handleAcceptRequest(previewModalData?.id)}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 54,
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px #3A0F7D solid",
                          padding: "16px 38px 16px 38px",
                          borderRadius: "30px",
                          color: "#FFFFFF",
                          backgroundColor: "#3A0F7D",
                          fontWeight: "700",
                          fontSize: "16px",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                          cursor: "pointer",
                          marginTop: "15px"
                        }}
                      >
                        {props.requesting ? (
                          <div style={{ padding: "0px 45px 0px 45px" }}>
                            <Spinner size="sm" />
                          </div>
                        ) : (
                          <p style={{ margin: 0 }}>Accept Request </p>
                        )}
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <div style={{ marginTop: "42px" }} onClick={toggle}>
                  <p
                    style={{
                      color: "#3A0F7D",
                      fontSize: "16px",
                      foontWeight: "700",
                      borderBottom: "1.5px solid #3A0F7D",
                      cursor: "pointer"
                    }}
                  >
                    Ask for rest of information
                  </p>
                </div>
              </Row>
            </div>
          </>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.ApproveAccounts.error,
  requesting: state.ApproveAccounts.requesting,
  allAccounts: state.ApproveAccounts.allAccounts
  // accessToken: state.LoginScreen.accessToken,
  // user: state.LoginScreen.user
})
const mapDispatchToProps = dispatch => ({
  pageRequest: page => dispatch(pageRequest(page)),
  patchAccountRequest: data => dispatch(patchAccountRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ApproveAccounts)
