import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap"
import Select from "react-select"
// import './style.scss';
import PaginationPack from "components/PaginationPack"
import Images from "utils/Images"
import toast, { Toaster } from "react-hot-toast"

import { getEngagementRequest, patchEngagementRequest } from "./redux/actions"
import { connect } from "react-redux"
import { Spinner } from "react-bootstrap"
import moment from "moment"
import { Tooltip } from "@mui/material"
import ReadMore from "components/ReadMore"

function EngagementRequest(props) {
  const {
    getEngagementRequest,
    allEngagements,
    patchEngagementRequest,
    requesting
  } = props

  useEffect(() => {
    getEngagementRequest(1)
  }, [])

  const handleAccept = item => {
    const data = {
      id: item.id,
      data: {
        accepted: true,
        rejected: false
      }
    }
    patchEngagementRequest(data)
  }

  const handleReject = item => {
    const data = {
      id: item.id,
      data: {
        accepted: false,
        rejected: true
      }
    }
    patchEngagementRequest(data)
  }

  const handlePageChange = page => {
    getEngagementRequest(page)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Engagement Request
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  borderless
                  style={{
                    border: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        padding: "25px"
                      }}
                    >
                      Area of Expertise
                    </th>
                    <th style={{ color: "#808080", textAlign: "center" }}>
                      Business Name
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Rate
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Start Date
                    </th>

                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        // paddingRight: "7%"
                        paddingRight: "61px"
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allEngagements && allEngagements?.results?.length ? (
                      allEngagements?.results.map(item => (
                        <>
                          <tr
                            style={{
                              // border: "3px solid",
                              // borderRadius: "50px !mportant",
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "25px",
                                borderBottomLeftRadius: "8px",
                                borderTopLeftRadius: "8px"
                              }}
                            >
                              {item.expertise_area}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {
                                item?.user_detail?.business_detail[0]
                                  ?.company_name
                              }
                            </td>
                            <td className="text-center">
                              {/* {item?.additional_info} */}
                              {/* {item.additional_info.length > 20 ? (
                                <>
                                  <Tooltip
                                    title={
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "black",
                                          fontSize: 14,
                                          textAlign: "center",
                                         
                                        }}
                                      >
                                        {item.additional_info}
                                      </p>
                                    }
                                    placement="top"
                                    arrow={true}
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflowX: "hidden",
                                        textOverflow: " ellipsis",
                                        maxWidth: 100,
                                        cursor: "pointer"
                                      }}
                                    >
                                      {item.additional_info}
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                <>{item.additional_info}</>
                              )} */}
                              <ReadMore
                                length={100}
                                item={item}
                                text={item.additional_info}
                              />
                            </td>
                            <td className="text-right">
                              {`$${item?.commitment} /hr`}
                            </td>
                            <td className="text-right">{`${moment(
                              item?.user_detail?.date_joined
                            ).format("MM-DD-YYYY")}`}</td>
                            <td
                              className="text-right pr-lg-3 "
                              // className="text-left "
                              style={{
                                paddingTop: "14px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px"
                              }}
                            >
                              <Button
                                onClick={() => handleReject(item)}
                                className="btn-round btn text-capitalize"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#3A0F7D",
                                  border: "1px solid  #3A0F7D",
                                  marginTop: 10,
                                  // display: "flex",
                                  // alignItems: "center",
                                  width: "107px"
                                }}
                              >
                                <img
                                  style={{
                                    height: "12px",
                                    width: "12px",
                                    // marginLeft: "20px",
                                    marginRight: "4px",
                                    marginTop: "-3px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.end}
                                />
                                Deny
                              </Button>
                              {!item?.accepted && (
                                <Button
                                  onClick={() => handleAccept(item)}
                                  className="btn-round btn text-capitalize"
                                  style={{
                                    marginTop: 10,
                                    backgroundColor: "#fff",
                                    color: "#3A0F7D",
                                    border: "1px solid  #3A0F7D",
                                    // display: "flex",
                                    // alignItems: "center",
                                    width: "110px"
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "12px",
                                      width: "12px",
                                      // marginLeft: "20px",
                                      marginRight: "4px",
                                      marginTop: "-3px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.accept}
                                  />
                                  Accept
                                </Button>
                              )}
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          // transform: "translate(-50%, -50%)",
                          left: "50%"
                          // backgroundColor: 'red'
                        }}
                      >
                        <h6 style={{ textAlign: "center" }}>No Data</h6>
                      </div>
                    )}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {allEngagements && allEngagements?.results.length > 0 ? (
                    requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={allEngagements?.page_size}
                          totalItemsCount={allEngagements?.total_objects}
                          activePage={allEngagements?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.EngagementRequest.error,
  requesting: state.EngagementRequest.requesting,
  allEngagements: state.EngagementRequest.allEngagements
})
const mapDispatchToProps = dispatch => ({
  getEngagementRequest: data => dispatch(getEngagementRequest(data)),
  patchEngagementRequest: data => dispatch(patchEngagementRequest(data))
  // loginAccessToken: data => dispatch(loginAccessToken(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(EngagementRequest)
