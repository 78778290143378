import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  GET_EXPERTIES_REQUEST,
  GET_EXPERTIES_SUCCESS,
  GET_EXPERTIES_FALUIRE,
  GET_UPSELL_DISCLAIMER,
  GET_ENGAGEMENT_HISTORY,
  GET_MY_CLIENT,
  ADD_TEAM,
  GET_ENGAGEMENT,
  ACCEPT_REQUEST,
  REQUEST_HOURS,
  ACCEPT_REQUEST_HOURS,
  POST_REQUEST_HOURS,
  GET_HISTORY_CSV,
  EXPERT_END_ENGAGEMENT_REQUEST
} from "./types"

// actions
import {
  getEngagement,
  getExperties,
  expertiesSuccess,
  expertiesFaluire,
  upsellExpertiesSuccess,
  upsellExpertiesFaluire,
  EngagementHistorySuccess,
  EngagementHistoryFaluire,
  getClientSuccess,
  addTeamSuccess,
  addTeamFailure,
  getEngagementSuccess,
  getEngagementFailure,
  acceptRequestSuccess,
  acceptRequestFailure,
  requestHours,
  requestHoursSuccess,
  requestHoursFailure,
  acceptRequestHoursSuccess,
  acceptRequestHoursFailure,
  postRequestHoursSuccess,
  postRequestHoursFailure,
  getHistoryCSVFailure,
  getHistoryCSVSuccess,
  expertEndEngagementSuccess,
  expertEndEngagementFailure
} from "./actions"

async function getExpertiesDataAPI(data) {
  const URL = `${BASE_URL}/api/v1/experties/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET",
    data
  }

  return XHR(URL, options)
}

function* getExpertiesData({ data }) {
  try {
    const response = yield call(getExpertiesDataAPI, data)
  } catch (e) {
    const { response } = e
  }
}

async function getUpsellRequestAPI() {
  const URL = `${BASE_URL}/api/v1/dashboard/upsell_disclaimer/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getUpsellRequest() {
  try {
    const response = yield call(getUpsellRequestAPI)
    yield put(upsellExpertiesSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(upsellExpertiesFaluire(response))
  }
}

async function getEngagementHistoryDataAPI(data) {
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/expert_engagement_history/?start_date=${data.start_date}&end_date=${data.end_date}&client=${data.client}&engagement=${data.engagement}&hour=${data.hour}&minute=${data.minute}&page=${data.page}`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getEngagementHistoryData({ data }) {
  try {
    const response = yield call(getEngagementHistoryDataAPI, data)
    yield put(EngagementHistorySuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(EngagementHistoryFaluire(response))
  }
}

async function getClientDataAPI() {
  const URL = `${BASE_URL}/api/v1/my_clients/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getClientData({}) {
  try {
    const response = yield call(getClientDataAPI)
    yield put(getClientSuccess(response.data))
  } catch (e) {
    const { response } = e
  }
}

async function addTeamAPI(data) {
  const URL = `${BASE_URL}/api/v1/dashboard/upsell/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* addTeamAPIData({ data, setModal1 }) {
  try {
    const response = yield call(addTeamAPI, data)
    yield put(addTeamSuccess(response.data))
    setModal1(false)
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(addTeamFailure(response?.data))
  }
}

async function getEngagementAPI(data) {
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/?page=${data}`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getEngagementData({ data }) {
  try {
    const response = yield call(getEngagementAPI, data)
    yield put(getEngagementSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getEngagementFailure(response))
  }
}

async function acceptRequestAPI(data) {
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/${data.id}/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.form
  }

  return XHR(URL, options)
}

function* acceptRequestData({ data, setModal }) {
  try {
    const response = yield call(acceptRequestAPI, data)
    yield put(acceptRequestSuccess(response.data))
    yield put(getEngagement(1))
    setModal()
  } catch (e) {
    const { response } = e
    yield put(acceptRequestFailure(response))
  }
}

async function requestHoursAPI(data) {
  const URL = `${BASE_URL}/api/v1/business/request_hours/?page=${data}`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* requestHoursData({ data }) {
  try {
    const response = yield call(requestHoursAPI, data)
    yield put(requestHoursSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(requestHoursFailure(response))
  }
}

async function acceptRequestHoursAPI(data) {
  const URL = `${BASE_URL}/api/v1/business/request_hours/${data.id}/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.form
  }

  return XHR(URL, options)
}

function* acceptRequestHoursData({ data, setModal }) {
  try {
    const response = yield call(acceptRequestHoursAPI, data)
    yield put(acceptRequestHoursSuccess(response.data))
    toast.success(`success`)
    yield put(requestHours(1))
    yield put(setModal(false))
  } catch (e) {
    const { response } = e
    yield put(acceptRequestHoursFailure(response))
  }
}

async function postRequestHoursAPI(data) {
  const URL = `${BASE_URL}/api/v1/business/request_hours/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postRequestHoursData({ data, setModal }) {
  try {
    const response = yield call(postRequestHoursAPI, data)
    yield put(postRequestHoursSuccess(response.data))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(postRequestHoursFailure(response?.data))
  }
}

async function getHistoryCSVFileAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/new_engagement/expert_engagement_csv/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getHistoryCSVFile() {
  try {
    const response = yield call(getHistoryCSVFileAPI)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.setAttribute("download", "Log History CSV.csv")
    link.href = url
    document.body.appendChild(link)
    link.click()
    yield put(getHistoryCSVSuccess(response?.data))
    toast.success("Success")
  } catch (e) {
    getHistoryCSVFailure(e.response)
  }
}

async function postEndEngagementAPI(data) {
  const accessToken = await localStorage.getItem("authToken")

  const URL = `${BASE_URL}/api/v1/business/end_engagement_request/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postEndEngagementFunction({ data, toggle1 }) {
  try {
    const response = yield call(postEndEngagementAPI, data)

    yield put(expertEndEngagementSuccess(response?.data))
    toast.success("success")
    yield put(toggle1())
  } catch (e) {
    const { response } = e
    yield put(expertEndEngagementFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_EXPERTIES_REQUEST, getExpertiesData),
  takeLatest(GET_UPSELL_DISCLAIMER, getUpsellRequest),
  takeLatest(GET_ENGAGEMENT_HISTORY, getEngagementHistoryData),
  takeLatest(GET_MY_CLIENT, getClientData),
  takeLatest(ADD_TEAM, addTeamAPIData),
  takeLatest(GET_ENGAGEMENT, getEngagementData),
  takeLatest(ACCEPT_REQUEST, acceptRequestData),
  takeLatest(REQUEST_HOURS, requestHoursData),
  takeLatest(ACCEPT_REQUEST_HOURS, acceptRequestHoursData),
  takeLatest(POST_REQUEST_HOURS, postRequestHoursData),
  takeLatest(GET_HISTORY_CSV, getHistoryCSVFile),
  takeLatest(EXPERT_END_ENGAGEMENT_REQUEST, postEndEngagementFunction)
])
