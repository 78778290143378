import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  POST_EDITPROFILE_DATA,
  POST_EDITPROFILE_DATA_SUCCESS,
  POST_EDITPROFILE_DATA_FAILURE,
  POST_USERROFILE_DATA
} from "./types"

import { loginSuccess } from "Containers/LoginScreen/redux/actions"

// actions
import {
  profileRequestSuccess,
  profileRequestFaluire,
  userProfileRequestFaluire
} from "./actions"

async function postUserProfileAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/user_profile/${data.id}/ `
  const options = {
    headers: {
      Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}
function* postUserProfile({ data }) {
  try {
    const response = yield call(postUserProfileAPI, data)
    yield put(profileRequestSuccess(response.data))
    yield put(loginSuccess(response?.data.user))
    localStorage.setItem("userAllData", JSON.stringify(response?.data))
    localStorage.setItem(
      "Profile_Picture",
      response?.data?.user?.profile_picture
    )
    localStorage.setItem(
      "linkedIn_Url",
      response?.data?.user?.linkedin_profile_url
    )

    localStorage.setItem("name", response?.data?.user?.name)
    toast.success("Success")
    yield put(
      push({
        pathname: "/Expert/MyProfile"
      })
    )
  } catch (e) {
    const { response } = e
    yield put(userProfileRequestFaluire(response?.data))
  }
}

export default all([takeLatest(POST_USERROFILE_DATA, postUserProfile)])
