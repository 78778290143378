import { all, call, delay, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../config/app"

// utils
import XHR from "../../../utils/XHR"

import {
  sendDeviceIdRequest,
  getNotficationDataRequest
} from "Containers/ExpertDesign/Screens/Notifications/redux/actions"

// types
import {
  LOGIN_REQUEST,
  LOGIN_VIA_LINKEDIN_REQUEST,
  LOGIN_VIA_GOOGLE_REQUEST
} from "./types"

// actions
import {
  loginSuccess,
  loginFaluire,
  loginViaLinkedInSuccess,
  loginViaLinkedInFaluire,
  loginViaGoogleSuccess,
  loginViaGoogleFaluire,
  loginAccessToken,
  loginProfileData,
  setInitialProfileValue
} from "./actions"

//firebase
import { fetchToken } from "../../../firebase"

function loginAPI(data) {
  const URL = `${BASE_URL}/api/v1/login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* login({ data }) {
  try {
    const response = yield call(loginAPI, data.data)
    toast.success(`Success`)
    localStorage.setItem("authToken", response?.data?.token)
    localStorage.setItem("user", response?.data?.user?.id.toString())
    localStorage.setItem("userType", response?.data?.user?.user_type)
    localStorage.setItem("email", response?.data?.user?.email)
    localStorage.setItem("name", response?.data?.user?.name)
    localStorage.setItem("profileData", response?.data?.profile)
    localStorage.setItem("profileid", response?.data?.profile?.id)

    yield put(loginSuccess(response?.data?.user))

    localStorage.setItem("userAllData", JSON.stringify(response?.data))

    localStorage.setItem(
      "Profile_Picture",
      response?.data?.user?.profile_picture
    )
    localStorage.setItem(
      "linkedIn_Url",
      response?.data?.user?.linkedin_profile_url
    )

    yield put(getNotficationDataRequest())

    yield put(loginProfileData(response?.data?.user?.user_type))
    try {
      const token = yield fetchToken()
      yield delay(3000)
      if (token) {
        yield put(
          sendDeviceIdRequest({
            registration_id: token,
            type: "web"
          })
        )
      }
    } catch (e) {}

    if (!response?.data?.profile) {
      if (response?.data?.user?.user_type == "Admin") {
        localStorage.setItem("accessToken", response?.data?.token)
        yield put(
          push({
            pathname: "/admin/time_tracker"
          })
        )
      } else {
        if (response?.data?.user?.user_type == "Business") {
          yield put(loginAccessToken(response?.data?.token))
          // yield put(setInitialProfileValue(response?.data))
          yield put(
            push({
              pathname: "/profile/BusinessHome"
            })
          )
        } else {
          if (response?.data?.user?.user_type == "Expert") {
            yield put(loginAccessToken(response?.data?.token))
            // yield put(setInitialProfileValue(response?.data))
            yield put()
            // push({
            //   pathname: "/profile/HomeIntialProfile"
            // })
          }
        }
      }
    } else {
      localStorage.setItem("accessToken", response?.data?.token)
      // yield put(loginAccessToken(response?.data?.token))
      if (response?.data?.user?.user_type === "Expert") {
        yield put(
          push({
            pathname: "/Expert/Engagements"
          })
        )
      } else {
        if (response?.data?.user?.user_type === "Business") {
          yield put(loginSuccess(response?.data?.profile))
          yield put(
            push({
              pathname: "/business/my_team"
              // pathname: "/admin/time_tracker"
            })
          )
        } else {
          if (response?.data?.user?.user_type === "Admin") {
            yield put(
              push({
                pathname: "/admin/HomeIntialProfile"
                // pathname: "/admin/time_tracker"
              })
            )
          }
        }
      }
    }
  } catch (e) {
    const { response } = e
    response?.data?.non_field_errors &&
      toast.error(`${response?.data?.non_field_errors[0]}`)
    yield put(loginFaluire(response?.data))
  }
}

function loginViaLinkedInAPI(data) {
  const URL = `${BASE_URL}/api/v1/login/linkedin/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* loginViaLinkedIn({ data }) {
  try {
    const response = yield call(loginViaLinkedInAPI, data.data)
    localStorage.setItem("authToken", response?.data?.token)
    localStorage.setItem("user", response?.data?.user?.id.toString())
    localStorage.setItem("email", response?.data?.user?.email)
    localStorage.setItem("name", response?.data?.user?.name)
    yield put(loginSuccess(response?.data?.user))
    // yield put(loginAccessToken(response?.data?.token))
    // yield put(loginViaLinkedInSuccess(response?.data))
    // localStorage.setItem("accessToken", response?.data)
    localStorage.setItem("userType", response?.data?.user?.user_type)
    localStorage.setItem("userAllData", JSON.stringify(response?.data))
    localStorage.setItem(
      "Profile_Picture",
      response?.data?.user?.profile_picture
    )
    localStorage.setItem(
      "linkedIn_Url",
      response?.data?.user?.linkedin_profile_url
    )

    yield put(getNotficationDataRequest())

    yield put(loginProfileData(response?.data?.user?.user_type))

    try {
      const token = yield fetchToken()
      yield delay(3000)
      if (token) {
        yield put(
          sendDeviceIdRequest({
            registration_id: token,
            type: "web"
          })
        )
      }
    } catch (e) {}

    if (!response?.data?.profile) {
      // if (response?.data?.user?.user_type == "Business") {

      //   yield put(
      //     push({
      //       pathname: "/profile/BusinessHome"
      //     }))
      // } else {
      //   response?.data?.user?.user_type == "Expert" && (
      //     yield put(
      //       push({
      //         pathname: "/profile/HomeIntialProfile"
      //       })
      //     )
      //   )
      // }
      if (response?.data?.user?.user_type == "Business") {
        yield put(loginAccessToken(response?.data?.token))
        // yield put(setInitialProfileValue(response?.data))
        yield put(
          push({
            pathname: "/profile/BusinessHome"
          })
        )
      } else {
        if (response?.data?.user?.user_type == "Expert") {
          yield put(loginAccessToken(response?.data?.token))
          // yield put(setInitialProfileValue(response?.data))
          yield put()
          // push({
          //   pathname: "/profile/HomeIntialProfile"
          // })
        }
      }
    } else {
      localStorage.setItem("accessToken", response?.data?.token)
      // yield put(loginAccessToken(response?.data?.token))
      if (response?.data?.user?.user_type === "Expert") {
        yield put(
          push({
            pathname: "/Expert/Engagements"
          })
        )
      } else {
        if (response?.data?.user?.user_type === "Business") {
          yield put(
            push({
              pathname: "/business/my_team"
              // pathname: "/admin/time_tracker"
            })
          )
        } else {
          if (response?.data?.user?.user_type === "Admin") {
            yield put(
              push({
                pathname: "/admin/time_tracker"
                // pathname: "/admin/time_tracker"
              })
            )
          }
        }
      }
    }

    // yield put(
    //   push({
    //     pathname: "/Expert/TimeTracker"
    //   })
    // )
    // localStorage.setItem('authToken', response?.data?.token);

    // yield put(loginViaFacebookSuccess(response));
  } catch (e) {
    const { response } = e
    yield put(loginViaLinkedInFaluire(response))
    toast.error(
      `${
        response?.data?.non_field_errors[0]
          ? response?.data?.non_field_errors[0]
          : "Unable to Login"
      }`
    )
  }
}

function loginViaGoogleAPI(data) {
  const URL = `${BASE_URL}/api/v1/login/google/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* loginViaGoogle({ data }) {
  try {
    const response = yield call(loginViaGoogleAPI, data.data)
    // localStorage.setItem("accessToken", response?.data)

    localStorage.setItem("authToken", response?.data?.token)
    localStorage.setItem("user", response?.data?.user?.id.toString())
    yield put(loginAccessToken(response?.data?.token))
    yield put(loginSuccess(response?.data?.user))
    // yield put(loginSuccess(response?.data?.user));
    localStorage.setItem("email", response?.data?.user?.email)
    localStorage.setItem("name", response?.data?.user?.name)
    localStorage.setItem("userType", response?.data?.user?.user_type)
    localStorage.setItem("userAllData", JSON.stringify(response?.data))

    localStorage.setItem(
      "Profile_Picture",
      response?.data?.user?.profile_picture
    )
    localStorage.setItem(
      "linkedIn_Url",
      response?.data?.user?.linkedin_profile_url
    )

    yield put(getNotficationDataRequest())

    yield put(loginProfileData(response?.data?.user?.user_type))

    try {
      const token = yield fetchToken()
      yield delay(3000)
      if (token) {
        yield put(
          sendDeviceIdRequest({
            registration_id: token,
            type: "web"
          })
        )
      }
    } catch (e) {}

    if (!response?.data?.profile) {
      if (response?.data?.user?.user_type == "Business") {
        yield put(loginAccessToken(response?.data?.token))
        // yield put(setInitialProfileValue(response?.data))
        yield put(
          push({
            pathname: "/profile/BusinessHome"
          })
        )
      } else {
        if (response?.data?.user?.user_type == "Expert") {
          yield put(loginAccessToken(response?.data?.token))
          // yield put(setInitialProfileValue(response?.data))
          yield put()
          // push({
          //   pathname: "/profile/HomeIntialProfile"
          // })
        }
      }
      // response?.data?.user?.user_type == "Business" ?
      //   yield put(
      //     push({
      //       pathname: "/profile/BusinessHome"
      //     })
      //   )
      //   :
      //   response?.data?.user?.user_type == "Expert" && (
      //     yield put(
      //       push({
      //         pathname: "/profile/HomeIntialProfile"
      //       })
      //     )
      //   )
    } else {
      localStorage.setItem("accessToken", response?.data?.token)
      // yield put(loginAccessToken(response?.data?.token))
      if (response?.data?.user?.user_type === "Expert") {
        yield put(
          push({
            pathname: "/Expert/Engagements"
          })
        )
      } else {
        if (response?.data?.user?.user_type === "Business") {
          yield put(
            push({
              pathname: "/business/my_team"
              // pathname: "/admin/time_tracker"
            })
          )
        } else {
          if (response?.data?.user?.user_type === "Admin") {
            yield put(
              push({
                pathname: "/admin/time_tracker"
                // pathname: "/admin/time_tracker"
              })
            )
          }
        }
      }
    }

    // yield put(
    //   push({
    //     pathname: "/Expert/TimeTracker"
    //   })
    // )
    // localStorage.setItem('authToken', response?.data?.key);
  } catch (e) {
    const { response } = e
    yield put(loginViaGoogleFaluire(e))
    toast.error(
      `${
        response?.data?.non_field_errors[0]
          ? response?.data?.non_field_errors[0]
          : "Unable to Login"
      }`
    )
  }
}

export default all([
  takeLatest(LOGIN_REQUEST, login),
  takeLatest(LOGIN_VIA_LINKEDIN_REQUEST, loginViaLinkedIn),
  takeLatest(LOGIN_VIA_GOOGLE_REQUEST, loginViaGoogle)
])
