import {
  PATCH_MYTEAM_REQUESTENGAGEMENT,
  PATCH_MYTEAM_REQUESTENGAGEMENT_SUCCESS,
  PATCH_MYTEAM_REQUESTENGAGEMENT_FALUIRE,
  GET_MYTEAM_FALUIRE,
  GET_MYTEAM_REQUEST,
  GET_MYTEAM_SUCCESS,
  BUSINESS_RATING_REQUEST,
  BUSINESS_RATING_SUCCESS,
  BUSINESS_RATING_FALUIRE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FALUIRE,
  GET_EXPERTIES_REQUEST,
  GET_EXPERTIES_SUCCESS,
  GET_EXPERTIES_FALUIRE,
  ADD_HOURS_REQUEST,
  ADD_HOURS_SUCCESS,
  ADD_HOURS_FALUIRE,
  POST_END_ENGAGEMENT_REQUEST,
  POST_END_ENGAGEMENT_SUCCESS,
  POST_END_ENGAGEMENT_FALUIRE
} from "./types"

export const myTeamRequestEngageRequest = data => ({
  type: PATCH_MYTEAM_REQUESTENGAGEMENT,
  data
})

export const myTeamRequestEngageSuccess = data => ({
  type: PATCH_MYTEAM_REQUESTENGAGEMENT_SUCCESS,
  data
})

export const myTeamRequestEngageFaluire = data => ({
  type: PATCH_MYTEAM_REQUESTENGAGEMENT_FALUIRE,
  data
})

export const getMyTeamRequest = data => ({
  type: GET_MYTEAM_REQUEST,
  data
})

export const getMyTeamSuccess = data => ({
  type: GET_MYTEAM_SUCCESS,
  data
})

export const getMyTeamFaluire = error => ({
  type: GET_MYTEAM_FALUIRE,
  error
})

export const businessRatingequest = (data, toggle) => ({
  type: BUSINESS_RATING_REQUEST,
  data,
  toggle
})

export const businessRatingSuccess = data => ({
  type: BUSINESS_RATING_SUCCESS,
  data
})

export const businessRatingFaluire = data => ({
  type: BUSINESS_RATING_FALUIRE,
  data
})

export const addTeamRequest = (data, toggle2) => ({
  type: ADD_TEAM_REQUEST,
  data,
  toggle2
})

export const addTeamSuccess = data => ({
  type: ADD_TEAM_SUCCESS,
  data
})

export const addTeamFaluire = data => ({
  type: ADD_TEAM_FALUIRE,
  data
})
export const getExpertiesRequest = data => ({
  type: GET_EXPERTIES_REQUEST,
  data
})

export const getExpertiesSuccess = data => ({
  type: GET_EXPERTIES_SUCCESS,
  data
})
export const getExpertiesFaluire = data => ({
  type: GET_EXPERTIES_FALUIRE,
  data
})

export const addHoursRequest = (data, resquestHourModal) => ({
  type: ADD_HOURS_REQUEST,
  data,
  resquestHourModal
})

export const addHoursSuccess = () => ({
  type: ADD_HOURS_SUCCESS
})
export const addHoursFaluire = data => ({
  type: ADD_HOURS_FALUIRE,
  data
})

export const postEndEngagementRequest = (data, endEngagementModal) => ({
  type: POST_END_ENGAGEMENT_REQUEST,
  data,
  endEngagementModal
})

export const postEndEngagementSuccess = () => ({
  type: POST_END_ENGAGEMENT_SUCCESS
})
export const postEndEngagementFaluire = data => ({
  type: POST_END_ENGAGEMENT_FALUIRE,
  data
})
