import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  GET_BILLING_REQUEST,
  GET_BILLING_HISTORY_CSV,
  GET_EXPERT_USER
} from "./types"

// actions
import {
  getBillingSuccess,
  getBillingFaluire,
  getBillingCSVFailure,
  getBillingCSVSuccess,
  getExpertUserSuccess,
  getExpertUserFaluire
} from "./actions"

async function getBillingAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/billing_history/?start_date=${data.start_date}&end_date=${data.end_date}&expert=${data.name}&page=${data.page}`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getBillingFunction({ data }) {
  try {
    const response = yield call(getBillingAPI, data)

    yield put(getBillingSuccess(response?.data))
    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e

    yield put(getBillingFaluire(response?.data))
  }
}
async function getHistoryCSVFileAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  console.log("data", data)
  const URL = `${BASE_URL}/api/v1/business/billing_history/billing_history_csv/?start_date=${data.start_date}&end_date=${data.end_date}&expert=${data.name}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getHistoryCSVFile({ data }) {
  try {
    const response = yield call(getHistoryCSVFileAPI, data)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.setAttribute("download", "Log History CSV.csv")
    link.href = url
    document.body.appendChild(link)
    link.click()
    yield put(getBillingCSVSuccess())
    toast.success("Success")
  } catch (e) {
    yield put(getBillingCSVFailure(e.response))
  }
}

async function getExpertUserAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/engagement_expert/`

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExpertUserFun({ data }) {
  try {
    const response = yield call(getExpertUserAPI, data)

    yield put(getExpertUserSuccess(response.data))
    console.log("response", response.data)
    // yield put(resourcesRequest({ page: 1, type: "" }))
    // yield put(getEngagementRequest(1))
    // toast.success("Engagement Request Done")
  } catch (e) {
    const { response } = e
    yield put(getExpertUserFaluire(response?.data))
  }
}

export default all([
  takeLatest(GET_BILLING_REQUEST, getBillingFunction),
  takeLatest(GET_BILLING_HISTORY_CSV, getHistoryCSVFile),
  takeLatest(GET_EXPERT_USER, getExpertUserFun)
])
