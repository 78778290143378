import React, { useState } from "react"

import useForm from "utils/useForm"
import { Link } from "react-router-dom"

import toast, { Toaster } from "react-hot-toast"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Spinner
} from "reactstrap"
import { connect } from "react-redux"

import { RequestAddtionalExpert, AddtionalExpertFaluire } from "./redux/actions"

function RequestExpert(props) {
  const { RequestAddtionalExpert, BackendError, AddtionalExpertFaluire } = props
  const [modal, setModal] = useState(false)
  const [hour, setHour] = useState({ value: 0 })

  const toggle = () => {
    setModal(!modal)
    AddtionalExpertFaluire(false)
    setState(stateSchema)
  }

  const stateSchema = {
    expertise: {
      value: "",
      error: ""
    },
    rate: {
      value: "",
      error: ""
    },
    descriptionOfEngagement: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    expertise: {
      required: true
      // validator: validator.email
    },
    rate: {
      required: true
      // validator: validator.email
    },
    descriptionOfEngagement: {
      required: true
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSubmit = async () => {
    const userId = await localStorage.getItem("user")

    const data = {
      expertise: state.expertise.value,
      preferred_rate: hour.value,
      description: state.descriptionOfEngagement.value,
      business: userId
    }
    RequestAddtionalExpert(data, toggle)
  }

  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )

  return (
    <>
      <div className="content">
        <div className="header text-left">
          <h3
            className="title"
            style={{
              color: "#CACACA",
              fontFamily: "Libre Caslon Text",
              fontWeight: "700",
              fontSize: "22px"
            }}
          >
            Request Expert
          </h3>
        </div>
        <Row>
          <Col md="12">
            <Card>
              <Row
                className="justify-content-center justify-content-sm-between m-0"
                style={{
                  alignItems: "center",
                  border: "2px solid #E2E2E2",
                  borderRadius: "10px"
                }}
              >
                <CardHeader>
                  <CardTitle
                    className="text-center"
                    style={{ marginTop: "0px" }}
                  >
                    Request more additional expertise to enrich your account
                  </CardTitle>
                </CardHeader>

                <CardFooter>
                  <Button
                    className="btn-round text-capitalize"
                    color="secondary"
                    style={{
                      backgroundColor: "#3A0F7D",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                    }}
                    onClick={toggle}
                  >
                    Request Expert
                  </Button>
                </CardFooter>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Row
                className="justify-content-center justify-content-sm-between m-0"
                style={{
                  alignItems: "center",
                  border: "2px solid #E2E2E2",
                  borderRadius: "10px"
                }}
              >
                <CardHeader>
                  <CardTitle
                    className="text-center"
                    style={{ marginTop: "0px" }}
                  >
                    Preview Requested Hours
                  </CardTitle>
                </CardHeader>

                <CardFooter>
                  <Link to="/business/Request_Hours">
                    <Button
                      className="btn-round text-capitalize"
                      color="secondary"
                      style={{
                        backgroundColor: "#3A0F7D",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                      }}
                      // onClick={toggle}
                    >
                      Requested hours
                    </Button>
                  </Link>
                </CardFooter>
              </Row>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Request Expertise
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Area of Expertise
                </Label>

                <Input
                  placeholder="Type here"
                  type="text"
                  style={{ color: "#000000" }}
                  value={state.expertise.value}
                  onChange={e => {
                    handleOnChange("expertise", e.target.value)
                    AddtionalExpertFaluire(false)
                  }}
                />
                {state.expertise.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.expertise.error}
                  </label>
                )}
                {BackendError && BackendError?.expertise && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.expertise[0]}
                  </label>
                )}
              </FormGroup>

              <div style={{ display: "flex" }}>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600"
                    }}
                  >
                    Rate
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Rate"
                    name="text"
                    type="number"
                    min="0"
                    style={{ color: "#000000", width: "120px" }}
                    value={hour?.value}
                    onChange={e => {
                      //handleOnChange("rate", e.target.value)
                      handleHourChange(e)
                      AddtionalExpertFaluire(false)
                    }}
                  />
                  {state.rate.error && (
                    <label style={{ color: "red", display: "flex" }}>
                      {state.rate.error}
                    </label>
                  )}
                </FormGroup>
                <h5
                  style={{
                    color: "#808080",
                    marginLeft: "14px",
                    fontSize: "14px",
                    marginTop: "42px",
                    fontWeight: "600"
                  }}
                >
                  /hr
                </h5>
              </div>
              {BackendError && BackendError?.preferred_rate && (
                <label
                  style={{
                    color: "red",
                    display: "flex",
                    marginBottom: "0px"
                  }}
                >
                  {BackendError?.preferred_rate[0]}
                </label>
              )}
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description of Engagement
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Description"
                  name="text"
                  type="textarea"
                  value={state.descriptionOfEngagement.value}
                  onChange={e => {
                    handleOnChange("descriptionOfEngagement", e.target.value)
                    AddtionalExpertFaluire(false)
                  }}
                />
                {state.descriptionOfEngagement.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.descriptionOfEngagement.error}
                  </label>
                )}
                {BackendError && BackendError?.description && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.description[0]}
                  </label>
                )}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnSubmit}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                width: "171px",
                height: "49px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              {props.requesting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Send Request"
              )}
              {/* Send Request */}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.RequestExpert.requesting,
  BackendError: state.RequestExpert.error
  // logourData: state.LogHour.logourData,
  // pagination: state.LogHour.pagination
})

const mapDispatchToProps = dispatch => ({
  RequestAddtionalExpert: (data, toggle) =>
    dispatch(RequestAddtionalExpert(data, toggle)),
  AddtionalExpertFaluire: data => dispatch(AddtionalExpertFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(RequestExpert)
