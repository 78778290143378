import {
  HOME_PROFILE_V2_REQUEST,
  HOME_PROFILE_V2_SUCCESS,
  HOME_PROFILE_V2_FALUIRE,
  GET_PROFILE_V2_REQUEST,
  GET_PROFILE_V2_SUCCESS,
  GET_PROFILE_V2_FALUIRE
} from "./types"
export const homeProfileV2Request = data => ({
  type: HOME_PROFILE_V2_REQUEST,
  data
})

export const homeProfileV2Success = data => ({
  type: HOME_PROFILE_V2_SUCCESS,
  data
})
export const homeProfileV2Faluire = data => ({
  type: HOME_PROFILE_V2_FALUIRE,
  data
})

export const getProfileV2Request = () => ({
  type: GET_PROFILE_V2_REQUEST,
})

export const getProfileV2Success = data => ({
  type: GET_PROFILE_V2_SUCCESS,
  data
})
export const getProfileV2Faluire = data => ({
  type: GET_PROFILE_V2_FALUIRE,
  data
})
