import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { GET_REFERRAL_REQUEST, UPDATE_REFERRAL_STATUS_REQUEST } from "./types"

// actions
import {
  getReferralSuccess,
  getReferralFaluire,
  updateReferralStatusFaluire,
  updateReferralStatusSuccess
} from "./actions"

async function getReferralAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/referrals/?page=${data.page}&referred_by=${data.type}`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getReferralFunction({ data }) {
  try {
    const response = yield call(getReferralAPI, data)

    yield put(getReferralSuccess(response?.data))
    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e

    yield put(getReferralFaluire(response?.data))
  }
}

async function updateStatusAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/referrals/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* updateStatusFunction({ data }) {
  try {
    const response = yield call(updateStatusAPI, data)
    yield put(updateReferralStatusSuccess(response?.data))

    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(updateReferralStatusFaluire(response?.data))
  }
}

export default all([
  takeLatest(GET_REFERRAL_REQUEST, getReferralFunction),
  takeLatest(UPDATE_REFERRAL_STATUS_REQUEST, updateStatusFunction)
])
