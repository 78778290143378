import React, { useEffect, useState } from "react"

// hooks
import validator from "utils/validation"
import useForm from "utils/useForm"
import toast, { Toaster } from "react-hot-toast"

import { connect } from "react-redux"
import {
  timeTrackerRequest,
  getEngagementRequest,
  timeTrackerRequestFaluire
} from "./redux/actions"

import { filterEngagementRequest } from "Containers/ExpertDesign/Screens/LogHour/redux/actions"

// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner
} from "reactstrap"
import Select from "react-select"

import moment from "moment"

function TimeTracker(props) {
  const {
    history,
    timeTrackerRequest,
    getEngagementRequest,
    engagementData,
    requesting,
    BackendError,
    timeTrackerSuccess,
    timeTrackerRequestFaluire,
    filterEngagementRequest,
    filteredEngagements
  } = props

  const [engagement, setEngagement] = useState({ value: "" })
  const [allEngagement, setAllEngagement] = useState(false)
  const [client, setClient] = useState(false)
  const [hour, setHour] = useState({ value: "0" })
  const [minutes, setMinutes] = useState({ value: 0, label: "0" })
  const [data, setData] = useState(false)
  const [discriptions, setDiscriptions] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)

  const [btnName, setBtnName] = useState("Submit")

  const stateSchema = {
    engagement: {
      value: "",
      error: ""
    },
    client: {
      value: "",
      error: ""
    },
    time: {
      value: "",
      error: ""
    },
    minutes: {
      value: "",
      error: ""
    },
    date: {
      value: "",
      error: ""
    },
    description: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    engagement: {
      required: false
      // validator: validator.email
    },
    client: {
      required: false
      // validator: validator.email
    },
    time: {
      required: false
      // validator: validator.email
    },
    minutes: {
      required: false
      // validator: validator.email
    },
    date: {
      required: true
    },
    description: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    setBtnName("Submit")
  }, [BackendError])

  const handleOnSubmit = async () => {
    setBtnName("Submitted")
    const userId = await localStorage.getItem("user")
    // if (
    //   // state.engagement.value &&
    //   // state.client.value &&

    // ) {

    // } else {
    //   toast.error("All fields are Require")
    // }

    const data = {
      date: state.date.value,
      hour: hour.value,
      minute: minutes.label,
      description: state.description.value,
      engagement_id: engagement.value,
      expert: userId
    }
    timeTrackerRequest(data, clearValue)
  }

  const handleSubmit = () => {}

  useEffect(() => {
    getEngagementRequest()
    filterEngagementRequest()
  }, [])

  const clearValue = () => {
    setState(stateSchema)
    setHour({ value: "0" })
    setMinutes({ value: 0, label: "0" })
    setEngagement("")
  }

  const handleHour = e => {
    timeTrackerRequestFaluire(false)
    setBtnName("Submit")
    handleHourChange(e)
  }

  const handleDatePicker = e => {
    handleOnChange("date", e.target.value)
    timeTrackerRequestFaluire(false)
    setBtnName("Submit")
  }

  let newD = moment(new Date()).format("YYYY-MM-DD")
  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state =>
        (value.length <= 2 &&
          value <= 24 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )

  return (
    <>
      <div className="content ">
        <Toaster position="top-center" />
        <Row style={{}}>
          <Col md="12">
            <Card className="px-3 mt-lg-4">
              <CardBody>
                <Form action="#" method="#">
                  {/* <CardTitle tag='h5'>Business Profile</CardTitle> */}
                  <Row>
                    <Col md="12" lg="4" sm="12" xs="12">
                      <label
                        md="3"
                        style={{
                          color: "#808080",
                          fontSize: "14px",
                          fontFamily: "Khula",
                          fontWeight: "600"
                        }}
                      >
                        Engagement
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        isSearchable={false}
                        // value={state.engagement.value}
                        // onChange={e =>
                        //   handleOnChange("engagement", e.target.value)
                        // }
                        value={engagement}
                        onChange={value => {
                          setEngagement(value)
                          timeTrackerRequestFaluire(false)
                          setBtnName("Submit")
                        }}
                        options={filteredEngagements && filteredEngagements}
                        placeholder="Select from below"
                      />
                      {BackendError && BackendError?.engagement && (
                        <label
                          style={{
                            color: "red",
                            display: "flex",
                            marginBottom: "0px"
                          }}
                        >
                          {BackendError?.engagement}
                        </label>
                      )}
                    </Col>

                    <Col
                      lg="5"
                      md="5"
                      sm="5"
                      className="m-0 "
                      style={{ paddingLeft: "8px" }}
                    >
                      <label
                        className=""
                        style={{
                          color: "#808080",
                          fontFamily: "Khula",
                          fontSize: "14px",
                          fontWeight: "600",
                          margin: "0px 8px 5px"
                        }}
                      >
                        Time
                      </label>

                      <Row className="ml-2" style={{ display: "flex" }}>
                        <FormGroup style={{ display: "flex" }}>
                          <Input
                            style={{ height: "55px", maxWidth: "95px" }}
                            // size="2"
                            // min="1"
                            // max="60"
                            // maxlength="2"
                            placeholder="hour"
                            type="number"
                            min="0"
                            autoComplete="off"
                            value={hour?.value}
                            onChange={e => handleHour(e)}
                            // onChange={e => setHour(e.target.value)}
                          />
                          <label
                            className="ml-2 mr-3"
                            style={{
                              margin: "0px",
                              fontFamily: "Khula",
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#808080",
                              marginTop: "15px"
                            }}
                          >
                            Hour
                          </label>
                        </FormGroup>

                        <FormGroup style={{ display: "flex" }}>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            style={{ width: "95px" }}
                            isSearchable={false}
                            value={minutes}
                            onChange={value => {
                              setMinutes(value)
                              timeTrackerRequestFaluire(false)
                              setBtnName("Submit")
                            }}
                            options={[
                              { value: 0, label: "0" },
                              { value: 1, label: "15" },
                              { value: 2, label: "30" },
                              { value: 3, label: "45" }
                            ]}
                            placeholder="Minute"
                          />
                          <label
                            className="ml-2"
                            style={{
                              margin: "0px",
                              fontFamily: "Khula",
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#808080",
                              marginTop: "15px"
                            }}
                          >
                            Minutes
                          </label>
                        </FormGroup>
                      </Row>
                      <div style={{ display: "flex" }}>
                        {BackendError && BackendError?.hour && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginRight: "10px"
                            }}
                          >
                            {BackendError?.hour[0]}
                          </label>
                        )}

                        {BackendError && BackendError?.minute && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.minute[0]}
                          </label>
                        )}
                      </div>
                    </Col>

                    <Col md="5">
                      <label
                        md="3"
                        style={{
                          color: "#808080",
                          fontWeight: "600",
                          fontFamily: "Khula",
                          fontSize: "14px"
                        }}
                      >
                        Date
                      </label>
                      {/* <input type="date" max={newD} /> */}
                      <Input
                        style={{ height: "55px" }}
                        placeholder="Type Here"
                        type="date"
                        value={state.date.value}
                        onChange={e => handleDatePicker(e)}
                        max={newD}
                        // onChange={e => setData(e.target.value)}
                      />
                      {BackendError && BackendError?.date && (
                        <label
                          style={{
                            color: "red",
                            display: "flex",
                            marginBottom: "0px"
                          }}
                        >
                          {BackendError?.date[0]}
                        </label>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <label
                        md="3"
                        style={{
                          marginTop: "20px",
                          fontSize: "14px",
                          fontFamily: "Khula",
                          color: "#808080",
                          fontWeight: "600"
                        }}
                      >
                        Description
                      </label>
                      <FormGroup>
                        <Input
                          placeholder="Type Here"
                          type="textarea"
                          value={state.description.value}
                          onChange={e => {
                            handleOnChange("description", e.target.value)
                            timeTrackerRequestFaluire(false)
                            setBtnName("Submit")
                          }}
                        />
                      </FormGroup>
                      {BackendError && BackendError?.description && (
                        <label
                          style={{
                            color: "red",
                            display: "flex",
                            marginBottom: "0px"
                          }}
                        >
                          {BackendError?.description[0]}
                        </label>
                      )}
                      {state.description.error && (
                        <label
                          style={{
                            color: "red",
                            display: "flex",
                            marginBottom: "0px"
                          }}
                        >
                          {state.description.error}
                        </label>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <div
                  style={{ display: "flex" }}
                  onClick={e => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <Button
                    className="text-capitalize"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#3A0F7D",

                      padding: "10px 65px 10px 65px",
                      borderRadius: "50px",
                      color: "white",
                      fontWeight: "700",
                      fontSize: "16px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                      marginBottom: "20px"
                    }}
                    disabled={disable}
                    onClick={() => handleOnSubmit()}
                  >
                    {props.timeTrackRequesting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  engagementData: state.TimeTracker.engagementData,
  timeTrackRequesting: state.TimeTracker.timeTrackRequesting,
  BackendError: state.TimeTracker.error,
  timeTrackerSuccess: state.TimeTracker.timeTrackerSuccess,
  filteredEngagements: state.LogHour.filteredEngagements

  // linkedInCode: state.LoginScreen.linkedInCode,
  // linkedInSuccess: state.LoginScreen.linkedInSuccess,
  // accessToken: state.LoginScreen.accessToken,
})

const mapDispatchToProps = dispatch => ({
  timeTrackerRequest: (data, clearValue) =>
    dispatch(timeTrackerRequest(data, clearValue)),
  getEngagementRequest: data => dispatch(getEngagementRequest(data)),
  timeTrackerRequestFaluire: data => dispatch(timeTrackerRequestFaluire(data)),
  filterEngagementRequest: data => dispatch(filterEngagementRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(TimeTracker)
