export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
export const DELETE_ACCOUNT_FALUIRE = "DELETE_ACCOUNT_FALUIRE"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FALUIRE = "CHANGE_PASSWORD_FALUIRE"
export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST"
export const FEEDBACK_REQUEST_SUCCESS = "FEEDBACK_REQUEST_SUCCESS"
export const FEEDBACK_REQUEST_FALUIRE = "FEEDBACK_REQUEST_FALUIRE"
export const TERMS_AND_CONDITION = "TERMS_AND_CONDITION"
export const TERMS_AND_CONDITION_SUCCESS = "TERMS_AND_CONDITION_SUCCESS"
export const PRIVICY_POLICY = "PRIVICY_POLICY"
export const PRIVICY_POLICY_SUCCESS = "PRIVICY_POLICY_SUCCESS"

export const NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS"
export const NOTIFICATION_SETTINGS_SUCCESS = "NOTIFICATION_SETTINGS_SUCCESS"
export const NOTIFICATION_SETTINGS_FALUIRE = "NOTIFICATION_SETTINGS_FALUIRE"
