import {
  GET_BILLING_REQUEST,
  GET_BILLING_SUCCESS,
  GET_BILLING_FALUIRE,
  GET_BILLING_HISTORY_CSV,
  GET_BILLING_HISTORY_CSV_FALUIRE,
  GET_BILLING_HISTORY_CSV_SUCCESS,
  GET_EXPERT_USER,
  GET_EXPERT_USER_SUCCESS,
  GET_EXPERT_USER_FALUIRE
} from "./types"

export const getBillingRequest = (data, toggle) => ({
  type: GET_BILLING_REQUEST,
  data,
  toggle
})

export const getBillingSuccess = data => ({
  type: GET_BILLING_SUCCESS,
  data
})

export const getBillingFaluire = data => ({
  type: GET_BILLING_FALUIRE,
  data
})

export const getBillingCSV = (data) => ({
  type: GET_BILLING_HISTORY_CSV,
  data
})

export const getBillingCSVSuccess = () => ({
  type: GET_BILLING_HISTORY_CSV_SUCCESS
})

export const getBillingCSVFailure = data => ({
  type: GET_BILLING_HISTORY_CSV_FALUIRE,
  data
})

export const getExpertUser = data => ({
  type: GET_EXPERT_USER,
  data
})

export const getExpertUserSuccess = data => ({
  type: GET_EXPERT_USER_SUCCESS,
  data
})
export const getExpertUserFaluire = data => ({
  type: GET_EXPERT_USER_FALUIRE,
  data
})
