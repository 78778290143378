import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { GET_RESOURCES_REQUEST } from "./types"

// actions
import { resourcesSuccess, resourcesFaluire } from "./actions"

async function resourcesDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/resources/?page=${data.page}&content=${data.type}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}
function* resourcesData({ data }) {
  try {
    const response = yield call(resourcesDataAPI, data)
    yield put(resourcesSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(resourcesFaluire(response?.data))
  }
}

export default all([takeLatest(GET_RESOURCES_REQUEST, resourcesData)])
