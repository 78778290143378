export const LOGHOUR_REQUEST = "LOGHOUR_REQUEST"
export const LOGHOUR_SUCCESS = "LOGHOUR_SUCCESS"
export const LOGHOUR_FALUIRE = "LOGHOUR_FALUIRE"

export const PAGE_REQUEST = "PAGE_REQUEST"
export const PAGE_SUCCESS = "PAGE_SUCCESS"
export const PAGE_FALUIRE = "PAGE_FALUIRE"

export const EXPORT_CSV_REQUEST = "EXPORT_CSV_REQUEST"
export const EXPORT_CSV_REQUEST_SUCCESS = "EXPORT_CSV_REQUEST_SUCCESS"
export const EXPORT_CSV_REQUEST_FALUIRE = "EXPORT_CSV_REQUEST_FALUIRE"

export const LOG_HOUR_SORT_REQUEST = "LOG_HOUR_SORT_REQUEST"
export const LOG_HOUR_SORT_SUCCESS = "LOG_HOUR_SORT_SUCCESS"
export const LOG_HOUR_SORT_FALUIRE = "LOG_HOUR_SORT_FALUIRE"

export const LOG_HOUR_FILTER_REQUEST = "LOG_HOUR_FILTER_REQUEST"
export const LOG_HOUR_FILTER_SUCCESS = "LOG_HOUR_FILTER_SUCCESS"
export const LOG_HOUR_FILTER_FALUIRE = "LOG_HOUR_FILTER_FALUIRE"

export const FILTER_CLIENTS_REQUEST = "FILTER_CLIENTS_REQUEST"
export const FILTER_CLIENTS_SUCCESS = "FILTER_CLIENTS_SUCCESS"
export const FILTER_CLIENTS_FALUIRE = "FILTER_CLIENTS_FALUIRE"

export const FILTER_ENGAGEMENTS_REQUEST = "FILTER_ENGAGEMENTS_REQUEST"
export const FILTER_ENGAGEMENTS_SUCCESS = "FILTER_ENGAGEMENTS_SUCCESS"
export const FILTER_ENGAGEMENTS_FALUIRE = "FILTER_ENGAGEMENTS_FALUIRE"
