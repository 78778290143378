export const ALL_EXPERT_REQUEST = "ALL_EXPERT_REQUEST"
export const ALL_EXPERT_SUCCESS = "ALL_EXPERT_SUCCESS"
export const ALL_EXPERT_FALUIRE = "ALL_EXPERT_FALUIRE"

export const SEARCH_EXPERT_REQUEST = "SEARCH_EXPERT_REQUEST"
export const SEARCH_EXPERT_SUCCESS = "SEARCH_EXPERT_SUCCESS"
export const SEARCH_EXPERT_FALUIRE = "SEARCH_EXPERT_FALUIRE"

export const PATCH_EXPERT_PROFILE = "PATCH_EXPERT_PROFILE"
export const PATCH_EXPERT_PROFILE_SUCCESS = "PATCH_EXPERT_PROFILE_SUCCESS"
export const PATCH_EXPERT_PROFILE_FALUIRE = "PATCH_EXPERT_PROFILE_FALUIRE"

export const GET_BUSINESS_NAMES = "GET_BUSINESS_NAMES"
export const GET_BUSINESS_NAMES_SUCCESS = "GET_BUSINESS_NAMES_SUCCESS"
export const GET_BUSINESS_NAMES_FALUIRE = "GET_BUSINESS_NAMES_FALUIRE"

export const SHARE_EXPERT_WITH_BUSINESS = "SHARE_EXPERT_WITH_BUSINESS"
export const SHARE_EXPERT_WITH_BUSINESS_SUCCESS =
  "SHARE_EXPERT_WITH_BUSINESS_SUCCESS"
export const SHARE_EXPERT_WITH_BUSINESS_FAILURE =
  "SHARE_EXPERT_WITH_BUSINESS_FAILURE"

export const DELETE_INDUSTRY_AND_EXPERTIES = "DELETE_INDUSTRY_AND_EXPERTIES"
export const DELETE_INDUSTRY_AND_EXPERTIES_SUCCESS =
  "DELETE_INDUSTRY_AND_EXPERTIES_SUCCESS"
export const DELETE_INDUSTRY_AND_EXPERTIES_FAILURE =
  "DELETE_INDUSTRY_AND_EXPERTIES_FAILURE"
