import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { REQUEST_ADDITIONAL_EXPERT } from "./types"

// actions
import { AddtionalExpertFaluire, AddtionalExpertSuccess } from "./actions"

async function postAdditionalExpertAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/expert_request/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postAdditionalExpert({ data, toggle }) {
  try {
    const response = yield call(postAdditionalExpertAPI, data)
    yield put(AddtionalExpertSuccess(response?.data))
    yield put(toggle())
  } catch (e) {
    const { response } = e
    yield put(AddtionalExpertFaluire(response?.data))
  }
}

export default all([
  takeLatest(REQUEST_ADDITIONAL_EXPERT, postAdditionalExpert)
])
