import { all, call, put, takeLatest } from "redux-saga/effects"
import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  ALL_EXPERT_REQUEST,
  PATCH_EXPERT_PROFILE,
  GET_BUSINESS_NAMES,
  SHARE_EXPERT_WITH_BUSINESS,
  SEARCH_EXPERT_REQUEST,
  DELETE_INDUSTRY_AND_EXPERTIES
} from "./types"

// actions
import {
  // pageRequest,
  // pageSuccess,
  // pageFaluire,
  // patchAccountSuccess,
  // patchAccountFailure,
  expertPageSuccess,
  expertPageFaluire,
  expertProfileSuccess,
  expertProfileFaluire,
  expertPageRequest,
  allBusinessNamesSuccess,
  allBusinessNamesFaluire,
  shareExpertDataFaluire,
  shareExpertDataSuccess,
  searchExpertDataSuccess,
  searchExpertDataFaluire,
  deleteExperienceDataSuccess,
  deleteExperienceDataFaluire
} from "./actions"

async function patchUpdateExpertAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/user_profile/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchUpdateExpert({ data }) {
  try {
    const response = yield call(patchUpdateExpertAPI, data)
    yield put(expertProfileSuccess(response?.data))
    yield put(expertPageRequest(1))
  } catch (e) {
    const { response } = e
    yield put(expertProfileSuccess(response))
  }
}

async function getExpertAccountsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/admin_expert_pagination/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExpertAccounts({ data }) {
  try {
    const response = yield call(getExpertAccountsAPI, data)
    yield put(expertPageSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(expertPageFaluire(response?.data))
  }
}

async function searchExpertDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/admin_expert_pagination/?search=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* searchExpertData({ data }) {
  try {
    const response = yield call(searchExpertDataAPI, data)
    yield put(searchExpertDataSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(searchExpertDataFaluire(response?.data))
  }
}

async function getBusinessNamesAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/admin_business/?search=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getBusinessNames({ data }) {
  try {
    const response = yield call(getBusinessNamesAPI, data)
    yield put(allBusinessNamesSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(allBusinessNamesFaluire(response?.data))
  }
}

async function postShareExpertDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/share_expert/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postShareExpertData({ data }) {
  try {
    const response = yield call(postShareExpertDataAPI, data)
    yield put(shareExpertDataSuccess(response))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(shareExpertDataFaluire(response))
  }
}

async function deleteExpertiseAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/delete_expertise/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* deleteExpertise({ data }) {
  try {
    const response = yield call(deleteExpertiseAPI, data)
    yield put(deleteExperienceDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(deleteExperienceDataFaluire(response?.data))
    toast.error("Some thing went wrong")
  }
}

export default all([
  takeLatest(ALL_EXPERT_REQUEST, getExpertAccounts),
  takeLatest(PATCH_EXPERT_PROFILE, patchUpdateExpert),
  takeLatest(GET_BUSINESS_NAMES, getBusinessNames),
  takeLatest(SHARE_EXPERT_WITH_BUSINESS, postShareExpertData),
  takeLatest(SEARCH_EXPERT_REQUEST, searchExpertData),
  takeLatest(DELETE_INDUSTRY_AND_EXPERTIES, deleteExpertise)
])
