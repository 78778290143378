import React, { useState, useEffect, useRef } from "react"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner,
  UncontrolledTooltip
} from "reactstrap"

import { Link } from "react-router-dom"

import Select from "react-select"

import { connect } from "react-redux"
import {
  homeProfileV2Request,
  homeProfileV2Faluire,
  homeProfileV2Success,
  getProfileV2Request
} from "./redux/actions"

import toast, { Toaster } from "react-hot-toast"

import Images from "utils/Images"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"

import { loginAccessToken } from "Containers/LoginScreen/redux/actions"
import Progressbar from "components/RangeSlider/Progressbar"
import { type } from "jquery"

const HomeInitialProfileV2 = props => {
  const {
    location,
    homeProfileV2Request,
    homeProfileV2Faluire,
    loginAccessToken,
    requesting,
    getProfileV2Request,
    data,
    BackendError,
    history,
    user
  } = props

  const [multipleSelect, setMultipleSelect] = useState([])
  const [multiSelect, setMultiSelect] = useState([])
  const [multiData, setMultiData] = useState([])
  const [multiDataName, setMultiDataName] = useState({})
  const [selectLabels, setSelectLabels] = useState([])

  const [hour, setHour] = useState({ value: 0 })
  const [singleSelect, setSingleSelect] = useState()

  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)

  const [horizontal, setHorizontal] = useState(10)

  const fileRef = useRef()

  const linkedIn = localStorage.getItem("linkedIn_Url")

  useEffect(() => {
    if (linkedIn == null || linkedIn == "") {
      history.push("/auth/welcome")
    }
  }, [])

  const handleChange = async e => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    setSelectedFile(base64)
    setSingleSelect(file)

    setIsFilePicked(true)
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const stateSchema = {
    hourRate: {
      value: "",
      error: ""
    },
    bio: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    hourRate: {
      required: true
      // validator: validator.email
    },
    bio: {
      required: true
      // validator: validator.email
    }
  }

  useEffect(() => {
    props.getProfileV2Request()
  }, [])

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onSelectOptions = e => {
    const res = Array.isArray(e) ? e.map(x => x.value) : []
    setMultipleSelect(res)

    let multiple = []
    res.forEach(item => {
      multiple.push(item)
    })
    setMultiSelect(multiple)
  }

  const handleComplete = async () => {
    const userId = await localStorage.getItem("user")
    const data = {
      user: userId,
      resume: selectedFile,
      hourly_rate: state.hourRate?.value,
      expert_bio: state.bio.value,
      user_availability: horizontal,
      expertise: selectLabels
      // vertical_section: multiSelect
    }
    homeProfileV2Request(data)
  }

  useEffect(() => {
    setState(stateSchema)
    homeProfileV2Faluire(false)
    let tempArr = []
    data &&
      data.user_expertise.map((item, index) =>
        tempArr.push({ value: item?.id, label: item?.label })
      )
    setMultiData(tempArr)
  }, [data])

  // const handelHour = e => {
  //   handleHourChange(e)
  // }
  // const handleHourChange = ({ target: { value } }) =>
  //   setHour(
  //     state =>
  //       (value.length <= 2 &&
  //         value <= 24 &&
  //         value >= 0 &&
  //         !isNaN(Number(value)) && { value }) ||
  //       state
  //   )

  return (
    <>
      <div className="login-page">
        <Container>
          <div
            className="mb-md-n5 ml-lg-n5"
            style={{ zIndex: 2, position: "relative", display: "flex" }}
          >
            <Button
              className="btn-round btn"
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                border: "1.5px solid #FFFFFF"
              }}
              onClick={() => {
                props.history.goBack()
              }}
            >
              <img
                style={{
                  height: 10,
                  marginTop: "-4px",
                  marginRight: "20px",

                  borderRadius: "0px"
                }}
                src={require("assets/img/back_vector.png")}
              />
              Back
            </Button>
          </div>

          <Toaster position="top-center" />
          <div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "0px",
                marginBottom: "47px"
              }}
            >
              <img
                style={{ width: "140px", height: "40px" }}
                src={Images.logo_name}
                alt="Logo"
              />
            </div>

            <div style={{ justifyContent: "center", display: "flex" }}>
              <p
                style={{
                  fontFamily: "Libre Caslon Text",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "white"
                }}
              >
                Complete Your Profile
              </p>
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "30px",
                marginBottom: "0px"
              }}
            >
              <img
                style={{ width: "90px", height: "25px" }}
                src={Images.profile_page_2}
                alt="Logo"
              />
            </div>
          </div>

          <Row>
            <Col className="ml-auto mr-auto" lg="8" md="8" sm="8" xs="8">
              <Form action="" className="form" method="">
                <Card className="card-login" style={{ marginTop: "30px" }}>
                  <CardHeader></CardHeader>
                  <CardBody>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <div style={{ width: "400px" }}>
                        <div>
                          <label
                            style={{
                              display: "flex",
                              marginTop: "10px",
                              fontFamily: "Khula",
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "#808080"
                            }}
                          >
                            Resume
                          </label>
                          <div
                            style={{
                              backgroundColor: "rgba(58, 15, 125, 0.3)",
                              width: "100%",
                              height: "55px",
                              border: "1px black dotted",
                              cursor: "pointer"
                            }}
                            onClick={() => fileRef.current.click()}
                          >
                            <input
                              type="file"
                              name="file"
                              ref={fileRef}
                              onChange={handleChange}
                              hidden
                            />
                            {isFilePicked ? (
                              <div>
                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "52px",
                                    color: "#6434AD"
                                  }}
                                >
                                  {singleSelect?.name}
                                </p>
                              </div>
                            ) : (
                              <p
                                style={{
                                  // textAlign: "center",
                                  // paddingTop: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "52px",
                                  color: "#6434AD"
                                }}
                              >
                                + Add Resume
                              </p>
                            )}
                          </div>
                          {/* <img style={{ width: '90px', height: '25px' }} src={Images.add_resume} alt="Logo" /> */}
                        </div>
                        <div style={{ maxWidth: "110px", marginTop: "25px" }}>
                          <label
                            style={{
                              display: "flex",
                              marginTop: "10px",
                              fontFamily: "Khula",
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "#808080"
                            }}
                          >
                            Hourly Rate
                          </label>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Input
                              placeholder="Rate"
                              type="number"
                              min="0"
                              value={state.hourRate?.value}
                              onChange={e =>
                                handleOnChange("hourRate", e.target.value)
                              }
                              style={{ height: "55px" }}
                            />
                            <p
                              style={{
                                alignSelf: "center",
                                margin: 0,
                                paddingLeft: "12px",
                                fontFamily: "Khula",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#808080"
                              }}
                            >
                              /hr
                            </p>
                          </div>
                        </div>
                        {BackendError && BackendError?.hourly_rate && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.hourly_rate[0]}
                          </label>
                        )}
                        <div style={{ marginTop: "25px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                fontFamily: "Khula",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#808080"
                              }}
                            >
                              Expert Bio
                            </label>
                            <div>
                              <Button
                                className="btn-round btn-icon btn-icon-mini btn-neutral"
                                color="info"
                                id="tooltip792337830"
                                title=""
                                type="button"
                              >
                                <img
                                  style={{
                                    height: 16,
                                    width: 16,
                                    marginLeft: 7
                                  }}
                                  src={Images.info}
                                />
                              </Button>
                              <div>
                                <UncontrolledTooltip
                                  style={{}}
                                  delay={0}
                                  target="tooltip792337830"
                                  placement="bottom"
                                  modifiers={{
                                    preventOverflow: {
                                      boundariesElement: "window"
                                    }
                                  }}
                                >
                                  Professional Roles, Experience and
                                  Achievements
                                </UncontrolledTooltip>
                              </div>
                            </div>{" "}
                          </div>
                          <Input
                            placeholder="Type here"
                            type="textarea"
                            value={state.bio.value}
                            onChange={e =>
                              handleOnChange("bio", e.target.value)
                            }
                            style={{ height: "170px" }}
                          />
                          {/* </InputGroup> */}
                        </div>
                        {/* <div style={{ marginTop: "25px" }}>
                        <label style={{ display: "flex", marginTop: "10px" }}>
                          Vertical Section
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder="Choose"
                          name="multipleSelect"
                          closeMenuOnSelect={false}
                          isMulti
                          // value={multipleSelect}
                          value={tempArry.filter(obj =>
                            multipleSelect.includes(obj.value)
                          )} // set selected values
                          // options={data} // set list of the data
                          onChange={onSelectOptions}
                          // onChange={
                          //   value => setMultipleSelect(value)
                          //   // this.setState({multipleSelect: value })
                          // }
                          options={tempArry}
                          // options={tmpArr?.map((item, index) => {
                          //   return {
                          //     label: item.name,
                          //     value: item.id,
                          //     key: index
                          //   }
                          // })}
                        />
                      </div> */}
                        {/* <div style={{ marginTop: "25px" }}>
                        <label style={{ display: "flex", marginTop: "10px" }}>
                          Weekly Availability
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={singleSelect}
                          onChange={value => setSingleSelect(value)}
                          options={[
                            {
                              value: "",
                              label: "Single Option",
                              isDisabled: true
                            },
                            { value: "1", label: "10-20 hours per week" },
                            { value: "2", label: "30-40 hours per week" },
                            { value: "3", label: "40+ hours per week" }
                          ]}
                          placeholder="Single Select"
                        />
                      </div> */}
                        <div>
                          <label
                            style={{
                              display: "flex",
                              marginTop: "10px",
                              fontFamily: "Khula",
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "#808080"
                            }}
                          >
                            Area of Expertise
                          </label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Choose Expertise"
                            name="multipleSelect"
                            // closeMenuOnSelect={false}
                            isMulti
                            // value={multiDataName}
                            // onChange={(value) =>
                            //   setMultiDataName({ multipleSelect: value })
                            // }
                            onChange={e => {
                              setSelectLabels(e.map(a => a.value))
                            }}
                            options={data && data?.user_expertise}
                          />
                        </div>
                        <div style={{ marginTop: 30 }}>
                          <p
                            style={{
                              fontFamily: "khula",
                              color: "#808080",
                              fontSize: "14px",
                              fontWeight: "600"
                            }}
                          >
                            Weekly Availability (Hour)
                          </p>
                        </div>
                        <Progressbar
                          horizontal={horizontal}
                          setHorizontal={setHorizontal}
                        />

                        {/* <Col lg="5" md="6" sm="3">

                      </Col> */}
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-evenly",
                        paddingTop: "30px",
                        marginLeft: 10,
                        marginRight: 10
                      }}
                    >
                      {/* <Link to="/auth/register"> */}
                      <div
                        // style={{width:'209px', height: '54px'}}
                        onClick={e => {
                          e.preventDefault()
                          handleComplete()
                        }}
                      >
                        <Button
                          className="btn-round text-capitalize"
                          color="secondary"
                          style={{
                            width: "160px",
                            backgroundColor: "#3A0F7D",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                          }}
                        >
                          {requesting ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Complete Profile"
                          )}
                        </Button>
                        {/* <p
                        style={{
                          backgroundColor: "#3A0F7D",
                          padding: "15px 40px 15px 40px",
                          borderRadius: "50px",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "16px",
                          cursor: "pointer"
                        }}
                      >
                        {requesting ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Complete Profile"
                        )}
                      </p> */}
                      </div>
                      {/* </Link> */}
                    </div>
                    {/* <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        style={{ backgroundColor: '#3A0F7D', width: '40%', marginLeft: '20px', marginRight: '50px', TextDecoration: 'none' }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Sign Up
                      </Button>
                    </Link>
                    <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        outline
                        style={{ color: '#3A0F7D', borderColor: '#3A0F7D', width: '40%', }}
                        onClick={(e) => e.preventDefault()}
                        href="/auth/login"
                      >
                        Log In
                      </Button>
                    </Link> */}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>

        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/simple_bg.png")})`
          }}
        ></div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.HomeInitialProfileV2.error,
  requesting: state.HomeInitialProfileV2.requesting,
  data: state.HomeInitialProfileV2.data
})
const mapDispatchToProps = dispatch => ({
  homeProfileV2Request: data => dispatch(homeProfileV2Request(data)),
  homeProfileV2Faluire: data => dispatch(homeProfileV2Faluire(data)),
  getProfileV2Request: data => dispatch(getProfileV2Request(data)),
  loginAccessToken: data => dispatch(loginAccessToken(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeInitialProfileV2)
// export default HomeInitialProfileV2
