export const GET_PUSH_NOTIFICATION = "GET_PUSH_NOTIFICATION"
export const GET_PUSH_NOTIFICATION_SUCCESS = "GET_PUSH_NOTIFICATION_SUCCESS"
export const GET_PUSH_NOTIFICATION_FALUIRE = "GET_PUSH_NOTIFICATION_FALUIRE"

export const EDIT_PUSH_NOTIFICATION = "EDIT_PUSH_NOTIFICATION"
export const EDIT_PUSH_NOTIFICATION_SUCCESS = "EDIT_PUSH_NOTIFICATION_SUCCESS"
export const EDIT_PUSH_NOTIFICATION_FALUIRE = "EDIT_PUSH_NOTIFICATION_FALUIRE"

export const CREATE_PUSH_NOTIFICATION = "CREATE_PUSH_NOTIFICATION"
export const CREATE_PUSH_NOTIFICATION_SUCCESS =
  "CREATE_PUSH_NOTIFICATION_SUCCESS"
export const CREATE_PUSH_NOTIFICATION_FALUIRE =
  "CREATE_PUSH_NOTIFICATION_FALUIRE"

export const DELETE_PUSH_NOTIFICATION = "DELETE_PUSH_NOTIFICATION"
export const DELETE_PUSH_NOTIFICATION_SUCCESS =
  "DELETE_PUSH_NOTIFICATION_SUCCESS"
export const DELETE_PUSH_NOTIFICATION_FALUIRE =
  "DELETE_PUSH_NOTIFICATION_FALUIRE"

export const GET_ALL_USER = "GET_ALL_USER"
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS"
export const GET_ALL_USER_FALUIRE = "GET_ALL_USER_FALUIRE"
