import React, { useEffect, useState } from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner
} from "reactstrap"

import BuninessImageUpload from "components/CustomUpload/BuninessImageUpload"

import { Link } from "react-router-dom"

import Images from "../../../utils/Images"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"

import { connect } from "react-redux"

import ImageInput from "components/ImageInput/ImageInput"
import { postHomeDataRequest, postHomeDataFaluire } from "./redux/actions"

const BusinessHome = props => {
  const {
    history,
    postHomeDataRequest,
    requesting,
    BackendError,
    postHomeDataFaluire
  } = props

  const [selectedFile, setselectedFile] = useState("")
  const [image, setImage] = useState("")
  const stateSchema = {
    fullname: {
      value: "",
      error: ""
    },
    companyname: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },

    invicemail: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    },
    ConfirmPassword: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullname: {
      required: true
      // validator: validator.email
    },
    companyname: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    invicemail: {
      required: true,
      validator: validator.email
    },
    password: {
      required: true
      //   validator: validator.password,
    },
    ConfirmPassword: {
      required: true
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const defultEmail = localStorage.getItem("email")

  const defaultName = localStorage.getItem("name")

  const Token = localStorage.getItem("authToken")

  useEffect(() => {
    if (Token == null) {
      history.push("/auth/welcome")
    }
  }, [])

  useEffect(() => {
    defaultName !== "null" && handleOnChange("fullname", defaultName)
    if (defultEmail !== "null") {
      handleOnChange("email", defultEmail)
      handleOnChange("invicemail", defultEmail)
    }
  }, [])

  const ProfileComplete = async () => {
    const userId = await localStorage.getItem("user")
    const data = {
      name: state.fullname.value,
      logo: selectedFile,
      company_name: state.companyname.value,
      associated_email: state.email.value,
      invoices_email: state.invicemail.value,
      user: userId
    }
    postHomeDataRequest(data)
  }
  return (
    <div className="full-page1" style={{}}>
      <Container>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "0px",
            marginBottom: "47px"
          }}
        >
          <img
            style={{ width: "140px", height: "40px" }}
            src={Images.logo_name}
            alt="Logo"
          />
          {/* <h1>Complete Your Profile</h1> */}
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          {/* <img style={{ width: '140px', height: '40px' }} src={Images.logo_name} alt="Logo" /> */}
          <p
            style={{
              fontFamily: "Libre Caslon Text",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "22px",
              lineHeight: "27px",
              color: "white"
            }}
          >
            Complete Your Profile
          </p>
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "30px",
            marginBottom: "0px"
          }}
        >
          {/* <img
            style={{ width: "90px", height: "25px" }}
            src={Images.profile_page_1}
            alt="Logo"
          /> */}
          {/* <h1>Complete Your Profile</h1> */}
        </div>
        <Row>
          <Col className="ml-auto mr-auto" lg="8" md="8" sm="8" xs="8">
            <Form action="" className="form" method="">
              <Card className="card-login" style={{ marginTop: "30px" }}>
                <CardHeader>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px"
                    }}
                  >
                    <BuninessImageUpload setselectedFile={setselectedFile} />
                  </div>
                </CardHeader>
                <CardBody>
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <div style={{ width: "400px" }}>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "Khula",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#808080"
                          }}
                        >
                          Full Name
                        </label>
                        <Input
                          placeholder="Type name"
                          // placeholderColor="#D0D0D0"
                          placeholderTextColor="#D0D0D0"
                          type="text"
                          value={state.fullname.value}
                          onChange={e =>
                            handleOnChange("fullname", e.target.value)
                          }
                          style={{ height: "55px", fontSize: 15 }}
                        />

                        {state.fullname.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.fullname.error}
                          </label>
                        )}
                        {BackendError && BackendError?.name && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.name[0]}
                          </label>
                        )}
                      </div>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "Khula",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#808080"
                          }}
                        >
                          Company Name
                        </label>
                        <Input
                          placeholder="Type name"
                          placeholderTextColor="#D0D0D0"
                          type="text"
                          onChange={e => {
                            handleOnChange("companyname", e.target.value)
                            postHomeDataFaluire(false)
                          }}
                          style={{ height: "55px", fontSize: 15 }}
                        />

                        {state.companyname.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.companyname.error}
                          </label>
                        )}
                        {BackendError && BackendError?.company_name && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.company_name[0]}
                          </label>
                        )}
                      </div>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "Khula",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#808080"
                          }}
                        >
                          Associated Email
                        </label>
                        {/* <InputGroup> */}
                        <Input
                          placeholder="ron.sharon@example.com"
                          placeholderTextColor="#D0D0D0"
                          type="email"
                          value={state.email.value}
                          onChange={e => {
                            handleOnChange("email", e.target.value)
                            postHomeDataFaluire(false)
                          }}
                          style={{ height: "55px", fontSize: 15 }}
                        />
                        {/* </InputGroup> */}
                        {state.email.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.email.error}
                          </label>
                        )}
                        {BackendError && BackendError?.associated_email && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.associated_email[0]}
                          </label>
                        )}
                      </div>
                      <div>
                        <label
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            fontFamily: "Khula",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#808080"
                          }}
                        >
                          Email for Invoices
                        </label>
                        {/* <InputGroup> */}
                        <Input
                          placeholder="ron.sharon@example.com"
                          placeholderTextColor="#D0D0D0"
                          type="email"
                          value={state.invicemail.value}
                          onChange={e => {
                            handleOnChange("invicemail", e.target.value)
                            postHomeDataFaluire(false)
                          }}
                          style={{ height: "55px", fontSize: 15 }}
                        />
                        {/* </InputGroup> */}
                        {state.invicemail.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.invicemail.error}
                          </label>
                        )}
                        {BackendError && BackendError?.invoices_email && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.invoices_email[0]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-evenly",
                      paddingBottom: "30px",
                      marginLeft: 10,
                      marginRight: 10
                    }}
                  >
                    <Link
                      to="/profile/HomeInitialProfileV2"
                      className="text-decoration-none"
                    >
                      <div
                        // style={{width:'209px', height: '54px'}}
                        onClick={e => {
                          e.preventDefault()
                          ProfileComplete()
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#3A0F7D",
                            padding: "15px 21px 15px 35px",
                            borderRadius: "50px",
                            color: "white",
                            fontWeight: "700",
                            fontSize: "16px"
                          }}
                        >
                          {requesting ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            `Continue`
                          )}
                          <img
                            src={Images.forward_vector}
                            style={{ marginLeft: "48px" }}
                          />
                        </Button>
                      </div>
                    </Link>
                  </div>
                  {/* <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        style={{ backgroundColor: '#3A0F7D', width: '40%', marginLeft: '20px', marginRight: '50px', TextDecoration: 'none' }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Sign Up
                      </Button>
                    </Link>
                    <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        outline
                        style={{ color: '#3A0F7D', borderColor: '#3A0F7D', width: '40%', }}
                        onClick={(e) => e.preventDefault()}
                        href="/auth/login"
                      >
                        Log In
                      </Button>
                    </Link> */}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/simple_bg.png")})`
        }}
      />
    </div>
    // </div >
  )
}

const mapStateToProps = state => ({
  requesting: state.BusinessHome.requesting,
  BackendError: state.BusinessHome.error
  // logourData: state.LogHour.logourData,
  // pagination: state.LogHour.pagination
})

const mapDispatchToProps = dispatch => ({
  postHomeDataRequest: data => dispatch(postHomeDataRequest(data)),
  postHomeDataFaluire: data => dispatch(postHomeDataFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(BusinessHome)
