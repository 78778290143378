import {
  GET_EDUCATIONAL_REQUEST,
  GET_EDUCATIONAL_SUCCESS,
  GET_EDUCATIONAL_FALUIRE
} from "./types"

const initialState = {
  educationalData: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EDUCATIONAL_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_EDUCATIONAL_SUCCESS:
      return {
        ...state,
        requesting: false,
        educationalData: action.data
      }

    case GET_EDUCATIONAL_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}
