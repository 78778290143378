import React, { useState, useEffect, useRef } from "react"
import useForm from "utils/useForm"
import validator from "utils/validation"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"
import Select from "react-select"
// import './style.scss';

// import ReactPaginate from 'react-paginate';

import Images from "utils/Images"
import { Link } from "react-router-dom"

import toast, { Toaster } from "react-hot-toast"

import {
  getUpsellExperties,
  getClient,
  addTeam,
  addTeamFailure
} from "./redux/actions"
import { getProfileV2Request } from "../../../../Containers/HomeInitialProfileV2/redux/actions"
import { connect } from "react-redux"
import { async } from "@firebase/util"

function Engagement(props) {
  const {
    getUpsellExperties,
    upsellText,
    engagementsHistory,
    getClient,
    clientData,
    data,
    getProfileV2Request,
    BackendError,
    addTeamFailure
  } = props

  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)

  const [expertDescription, setExpertDescription] = useState("")
  const [expertArea, setExpertArea] = useState("")
  const [clientName, setClientName] = useState("")
  const [isAdd, setAdd] = useState(false)

  const [modal1, setModal1] = useState(false)
  const toggle1 = () => {
    setModal1(!modal1)
    setState(stateSchema)
    addTeamFailure(false)
  }

  let PageSize = 10
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [role, setRole] = useState(false)
  const [roles, setRoles] = useState(false)

  const fileRef = useRef()

  const handleChange = e => {
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
  }

  useEffect(() => {
    getUpsellExperties()
    getClient()
    getProfileV2Request()
  }, [])

  const stateSchema = {
    client: {
      value: "",
      error: ""
    },
    expertise: {
      value: "",
      error: ""
    },
    description: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    client: {
      required: true
    },
    expertise: {
      required: true
    },
    description: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map(item => (
            <div>
              <h3>Item #{item}</h3>
            </div>
          ))}
      </>
    )
  }

  const handleSubmit = async () => {
    const userId = await localStorage.getItem("user")
    let apiData = {
      expert: userId,
      expertise: state.expertise.value,
      description: state.description.value,
      client: state.client.value
    }
    props.addTeam(apiData, setModal1)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <CardTitle
                      tag="h4"
                      style={{
                        fontFamily: "Libre Caslon Text",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#CACACA"
                      }}
                    >
                      Engagements
                    </CardTitle>
                  </Col>

                  {/* <div style={{ width: '200px' }}> */}
                  <Col
                    md="6"
                    sm="6"
                    lg="6"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "auto"
                    }}
                  >
                    <Button
                      className="btn-round text-capitalize"
                      style={{
                        border: "2px #3A0F7D solid",
                        backgroundColor: "#FFFF",
                        width: "161px",
                        color: "#3A0F7D",
                        fontWeight: "700",
                        fontSize: "12px",
                        cursor: "pointer",
                        whiteSpace: "nowrap"
                      }}
                      onClick={toggle1}
                    >
                      + Grow Your Team
                    </Button>

                    {/* </div> */}
                  </Col>
                  {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
                </Row>
              </CardHeader>

              <CardBody>
                <Card style={{ padding: "7px", border: "2px solid #E2E2E2" }}>
                  <Row
                    className="justify-content-center justify-content-sm-between m-0"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      borderRadius: "10px"
                    }}
                  >
                    <Col lg="9" md="8" sm="8">
                      <CardHeader>
                        <CardTitle style={{ marginTop: "0px" }}>
                          Preview Active Engagements
                        </CardTitle>
                      </CardHeader>
                    </Col>
                    <Col lg="3" md="4" sm="4" className="p-0">
                      <CardFooter
                        className="    justify-content-lg-end justify-content-center "
                        style={{
                          display: "flex"
                        }}
                      >
                        <Link to="/Expert/Engagements_active">
                          <Button
                            className="btn-round text-capitalize
                      "
                            color="secondary"
                            style={{
                              backgroundColor: "#3A0F7D",
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                              height: "49px",
                              whiteSpace: "nowrap"
                            }}
                          >
                            Active Engagements
                          </Button>
                        </Link>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>

                <Card style={{ padding: "7px", border: "2px solid #E2E2E2" }}>
                  <Row
                    className="justify-content-center justify-content-sm-between m-0"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      borderRadius: "10px"
                    }}
                  >
                    <Col lg="9" md="8" sm="8">
                      <CardHeader>
                        <CardTitle style={{ marginTop: "0px" }}>
                          Preview Requested Hours
                        </CardTitle>
                      </CardHeader>
                    </Col>
                    <Col lg="3" md="4" sm="4" className="p-0">
                      <CardFooter
                        className="justify-content-lg-end justify-content-center "
                        style={{
                          display: "flex"
                        }}
                      >
                        <Link to="/Expert/Engagements_requested_hours">
                          <Button
                            className="btn-round text-capitalize
                      "
                            color="secondary"
                            style={{
                              backgroundColor: "#3A0F7D",
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                              height: "49px",
                              whiteSpace: "nowrap"
                            }}
                          >
                            Requested hours
                          </Button>
                        </Link>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>

                <Card style={{ padding: "7px", border: "2px solid #E2E2E2" }}>
                  <Row
                    className="justify-content-center justify-content-sm-between m-0"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      borderRadius: "10px"
                    }}
                  >
                    <Col lg="9" md="8" sm="8">
                      <CardHeader>
                        <CardTitle style={{ marginTop: "0px" }}>
                          Preview Engagement History
                        </CardTitle>
                      </CardHeader>
                    </Col>
                    <Col lg="3" md="4" sm="4" className="p-0">
                      <CardFooter
                        className=" justify-content-lg-end justify-content-center "
                        style={{
                          display: "flex"
                        }}
                      >
                        <Link to="/Expert/Engagements_history">
                          <Button
                            className="btn-round text-capitalize
                      "
                            color="secondary"
                            style={{
                              backgroundColor: "#3A0F7D",
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                              height: "49px",
                              whiteSpace: "nowrap"
                            }}
                          >
                            Engagement History
                          </Button>
                        </Link>
                      </CardFooter>
                    </Col>
                  </Row>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={modal1}
        toggle1={toggle1}
        // className={className}
        // external={externalCloseBtn}
      >
        <Toaster position="top-center" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px 20px 0px 25px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Grow Your Team
          </h5>

          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div
          style={{
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            margin: "0px 20px 0px 25px"
          }}
        >
          <p
            style={{
              fontSize: "15.5px",
              fontWeight: "500",
              lineHeight: "20px",
              fontFamily: "Khula",
              color: "#000000"
            }}
          >
            Does your client need additional marketing expertise? Let us know
            here!
          </p>
        </div>
        <div style={{ marginLeft: "25px", marginRight: "23px" }}>
          <Form>
            <div style={{}}>
              {/* <label style={{ display: "flex", color: "#808080" }}>
                Client
              </label> */}
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: 28
                }}
              >
                List of Clients
              </Label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="singleSelect"
                value={state.client.value.value}
                onChange={value => {
                  handleOnChange("client", value.value)
                }}
                options={
                  clientData.length > 0
                    ? clientData.map(item => ({
                        value: item.value,
                        label: item.label
                      }))
                    : []
                }
                placeholder="Select Client"
              />
            </div>
            {BackendError?.client && (
              <label style={{ color: "red" }}>{BackendError?.client[0]}</label>
            )}
            <div style={{}}>
              {/* <label
                style={{ display: "flex", color: "#808080", marginTop: 28 }}
              >
                Expertise Needed
              </label> */}
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: 28
                }}
              >
                Expertise Needed
              </Label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="singleSelect"
                value={state.expertise.value.value}
                onChange={value => {
                  handleOnChange("expertise", value.value)
                }}
                options={
                  data?.user_expertise &&
                  data?.user_expertise?.length &&
                  data.user_expertise.map(item => ({
                    value: item.value,
                    label: item.label
                  }))
                }
                placeholder="Expertise Needed"
              />
            </div>
            {BackendError?.expertise && (
              <label style={{ color: "red" }}>
                {BackendError?.expertise[0]}
              </label>
            )}
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: 28
                }}
              >
                Description
              </Label>
              <Input
                id="exampleText"
                placeholder="Type here"
                name="text"
                type="textarea"
                value={state.description.value}
                onChange={e => {
                  handleOnChange("description", e.target.value)
                }}
              />
            </FormGroup>
            {BackendError
              ? BackendError?.description && (
                  <label style={{ color: "red" }}>
                    {BackendError?.description[0]}
                  </label>
                )
              : state.description.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {state.description.error}
                  </label>
                )}
            {/* <p style={{}}>
              *Upselling additional experts is placed at a $500 incentive.
            </p> */}

            {/* <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: 28
                }}
              >
                Referral Page Link
              </Label>
              <div
                style={{
                  backgroundColor: "rgba(58, 15, 125, 0.3)",
                  width: "100%",
                  height: "55px",
                  border: "1px black dotted"
                }}
                onClick={() => fileRef.current.click()}
              >
                <input
                  type="file"
                  name="file"
                  ref={fileRef}
                  onChange={handleChange}
                  hidden
                />
                {isFilePicked ? (
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        paddingTop: "20px",
                        color: "#6434AD"
                      }}
                    >
                      {selectedFile.name}
                    </p>
                  </div>
                ) : (
                  <p
                    style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                      height:"52px",
                      color: "#6434AD"
                    }}
                  >
                    + Add Referal Link
                  </p>
                )}
              </div>
            </FormGroup> */}
            {/* <div style={{ marginTop: "20px", marginTop: "26px" }}>
              <p>
                {upsellText ? upsellText?.disclaimer : ""}
              </p>
            </div> */}
          </Form>
        </div>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={toggle1}
            className="btn-round  text-capitalize"
            style={{
              backgroundColor: "#fff",
              color: "#3A0F7D",
              border: "1px solid  #3A0F7D",
              marginRight: "10px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            className="btn-round text-capitalize"
            // disable={disable}
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            {props.teamRequesting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Notify Account Manager"
            )}
          </Button>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.Engagement.requesting,
  upsellText: state.Engagement.upsellText,
  engagementsHistory: state.Engagement.engagementsHistory,
  clientData: state.Engagement.clientData,
  teamRequesting: state.Engagement.teamRequesting,
  data: state.HomeInitialProfileV2.data,
  BackendError: state.Engagement.error
})

const mapDispatchToProps = dispatch => ({
  getUpsellExperties: () => dispatch(getUpsellExperties()),
  getClient: () => dispatch(getClient()),
  getProfileV2Request: () => dispatch(getProfileV2Request()),
  addTeam: (data, setModal1) => dispatch(addTeam(data, setModal1)),
  addTeamFailure: data => dispatch(addTeamFailure(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Engagement)
