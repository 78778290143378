import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

import toast from "react-hot-toast"

// types
import {
  BUSINESS_LOG_HOUR_REQUEST,
  BUSINESS_LOG_HOUR_PAGE_REQUEST,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST
} from "./types"

// actions
import {
  logOurSuccess,
  logOurFaluire,
  pageSuccess,
  pageFaluire,
  exportCSVSuccess,
  exportCSVFaluire
} from "./actions"

async function getLogourDataAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/expert_timelog/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getLogourData() {
  try {
    const response = yield call(getLogourDataAPI)
    yield put(logOurSuccess(response?.data))
  } catch (e) {
    yield put(logOurFaluire(e.response))
  }
}

async function paginationAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/expert_timelog/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* pagination({ data }) {
  try {
    const response = yield call(paginationAPI, data)
    yield put(pageSuccess(response?.data))
  } catch (e) {
    yield put(pageFaluire(e.response))
  }
}

async function getExportCSVDataAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/expert_timelog/time_log_csv/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExportCSVData() {
  try {
    const response = yield call(getExportCSVDataAPI)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.setAttribute("download", "Log History CSV.csv")
    link.href = url
    document.body.appendChild(link)
    link.click()
    yield put(exportCSVSuccess(response?.data))
    toast.success("Success")
  } catch (e) {
    yield put(exportCSVFaluire(e.response))
  }
}

export default all([
  takeLatest(BUSINESS_LOG_HOUR_PAGE_REQUEST, pagination),
  takeLatest(BUSINESS_LOG_HOUR_REQUEST, getLogourData),
  takeLatest(BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST, getExportCSVData)
])
