import {
  GET_EXPERTIES_REQUEST,
  GET_EXPERTIES_SUCCESS,
  GET_EXPERTIES_FALUIRE,
  GET_UPSELL_DISCLAIMER,
  GET_UPSELL_DISCLAIMER_SUCCESS,
  GET_UPSELL_DISCLAIMER_FALUIRE,
  GET_ENGAGEMENT_HISTORY,
  GET_ENGAGEMENT_HISTORY_SUCCESS,
  GET_ENGAGEMENT_HISTORY_FALUIRE,
  GET_MY_CLIENT,
  GET_MY_CLIENT_SUCCESS,
  GET_MY_CLIENT_FALUIRE,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FALUIRE,
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENT_FALUIRE,
  ACCEPT_REQUEST,
  ACCEPT_REQUEST_SUCCESS,
  ACCEPT_REQUEST_FALUIRE,
  REQUEST_HOURS,
  REQUEST_HOURS_SUCCESS,
  REQUEST_HOURS_FALUIRE,
  ACCEPT_REQUEST_HOURS,
  ACCEPT_REQUEST_HOURS_SUCCESS,
  ACCEPT_REQUEST_HOURS_FALUIRE,
  POST_REQUEST_HOURS,
  POST_REQUEST_HOURS_SUCCESS,
  POST_REQUEST_HOURS_FALUIRE,
  GET_HISTORY_CSV,
  GET_HISTORY_CSV_SUCCESS,
  GET_HISTORY_CSV_FALUIRE,
  HISTORY_FILTER_REQUEST,
  HISTORY_FILTER_SUCCESS,
  HISTORY_FILTER_FALUIRE,
  EXPERT_END_ENGAGEMENT_REQUEST,
  EXPERT_END_ENGAGEMENT_SUCCESS,
  EXPERT_END_ENGAGEMENT_FALUIRE
} from "./types"

const initialState = {
  expertiesData: false,
  requesting: false,
  error: false,
  upsellText: false,
  engagementsHistory: false,
  clientData: false,
  teamRequesting: false,
  acceptRequesting: false,
  engagementData: false,
  acceptedResult: false,
  requestHoursRequesting: false,
  hoursResult: false,
  acceptedHoursResult: false,
  acceptRequestHoursRequesting: false,
  postedHoursResult: false,
  postRequestHoursRequesting: false,
  csvRequesting: false,
  filtrRequesting: false,
  endEngagementRequesting: false,
  endEngageSuccess: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPERTIES_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_EXPERTIES_SUCCESS:
      return {
        ...state,
        requesting: false,
        expertiesData: action.data
      }

    case GET_EXPERTIES_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_UPSELL_DISCLAIMER:
      return {
        ...state,
        requesting: true
      }

    case GET_UPSELL_DISCLAIMER_SUCCESS:
      return {
        ...state,
        requesting: false,
        upsellText: action.data
      }

    case GET_UPSELL_DISCLAIMER_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_ENGAGEMENT_HISTORY:
      return {
        ...state,
        requesting: true,
        error: action.data
      }

    case GET_ENGAGEMENT_HISTORY_SUCCESS:
      return {
        ...state,
        requesting: false,
        engagementsHistory: action.data
      }

    case GET_ENGAGEMENT_HISTORY_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_MY_CLIENT:
      return {
        ...state,
        requesting: true
      }

    case GET_MY_CLIENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        clientData: action.data
      }

    case GET_MY_CLIENT_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    case ADD_TEAM:
      return {
        ...state,
        teamRequesting: true
      }

    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        teamRequesting: false
      }

    case ADD_TEAM_FALUIRE:
      return {
        ...state,
        teamRequesting: false,
        error: action.data
      }

    case GET_ENGAGEMENT:
      return {
        ...state,
        requesting: true
      }

    case GET_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        engagementData: action.data
      }

    case GET_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        requesting: false
      }
      return {
        ...state,
        requesting: false
      }

    case ACCEPT_REQUEST:
      return {
        ...state,
        acceptRequesting: true
      }

    case ACCEPT_REQUEST_SUCCESS:
      return {
        ...state,
        acceptRequesting: false,
        acceptedResult: action.data
      }

    case ACCEPT_REQUEST_FALUIRE:
      return {
        ...state,
        // acceptedResult: false,
        acceptRequesting: false
      }

    case REQUEST_HOURS:
      return {
        ...state,
        requestHoursRequesting: true
      }

    case REQUEST_HOURS_SUCCESS:
      return {
        ...state,
        hoursResult: action.data,
        requestHoursRequesting: false
      }

    case REQUEST_HOURS_FALUIRE:
      return {
        ...state,
        // hoursResult: false,
        requestHoursRequesting: false
      }

    case ACCEPT_REQUEST_HOURS:
      return {
        ...state,
        acceptRequestHoursRequesting: true
      }

    case ACCEPT_REQUEST_HOURS_SUCCESS:
      return {
        ...state,
        acceptRequestHoursRequesting: false,
        acceptedHoursResult: action.data
      }

    case ACCEPT_REQUEST_HOURS_FALUIRE:
      return {
        ...state,
        // acceptRequestHoursRequesting: false,
        acceptRequestHoursRequesting: false
      }

    case POST_REQUEST_HOURS:
      return {
        ...state,
        postRequestHoursRequesting: true
      }

    case POST_REQUEST_HOURS_SUCCESS:
      return {
        ...state,
        postRequestHoursRequesting: false,
        postedHoursResult: action.data
      }

    case POST_REQUEST_HOURS_FALUIRE:
      return {
        ...state,
        // acceptRequestHoursRequesting: false,
        error: action.data,
        postRequestHoursRequesting: false
      }

    case GET_HISTORY_CSV:
      return {
        ...state,
        csvRequesting: true
      }

    case GET_HISTORY_CSV_SUCCESS:
      return {
        ...state,
        csvRequesting: false
        // postedHoursResult: action.data
      }

    case GET_HISTORY_CSV_FALUIRE:
      return {
        ...state,
        // acceptRequestHoursRequesting: false,
        error: action.data,
        csvRequesting: false
      }

    case HISTORY_FILTER_REQUEST:
      return {
        ...state,
        filtrRequesting: true
      }

    case HISTORY_FILTER_SUCCESS:
      return {
        ...state,
        filtrRequesting: false,
        engagementsHistory: action.data
      }

    case HISTORY_FILTER_FALUIRE:
      return {
        ...state,
        error: action.data,
        filtrRequesting: false
      }

    case EXPERT_END_ENGAGEMENT_REQUEST:
      return {
        ...state,
        endEngagementRequesting: true
      }

    case EXPERT_END_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        endEngagementRequesting: false,
        endEngageSuccess: action.data
      }

    case EXPERT_END_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        error: action.data,
        endEngagementRequesting: false
      }

    default:
      return state
  }
}
