import {
  GET_PUSH_NOTIFICATION,
  GET_PUSH_NOTIFICATION_SUCCESS,
  GET_PUSH_NOTIFICATION_FALUIRE,
  CREATE_PUSH_NOTIFICATION,
  CREATE_PUSH_NOTIFICATION_SUCCESS,
  CREATE_PUSH_NOTIFICATION_FALUIRE,
  EDIT_PUSH_NOTIFICATION,
  EDIT_PUSH_NOTIFICATION_SUCCESS,
  EDIT_PUSH_NOTIFICATION_FALUIRE,
  DELETE_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION_SUCCESS,
  DELETE_PUSH_NOTIFICATION_FALUIRE,
  GET_ALL_USER,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FALUIRE
} from "./types"

const initialState = {
  requesting: false,
  error: false,
  getPushNotifications: false,
  createNotificationRequesting: false,
  createNotification: false,
  getAllUsers: false,
  deleteRequest: false,
  editNotification: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PUSH_NOTIFICATION:
      return {
        ...state,
        requesting: true
      }

    case GET_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        getPushNotifications: action.data
      }

    case GET_PUSH_NOTIFICATION_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    case CREATE_PUSH_NOTIFICATION:
      return {
        ...state,
        createNotificationRequesting: true
      }

    case CREATE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        createNotificationRequesting: false,
        createNotification: action.data
      }

    case CREATE_PUSH_NOTIFICATION_FALUIRE:
      return {
        ...state,
        createNotificationRequesting: false,
        error: action.data
      }

    case EDIT_PUSH_NOTIFICATION:
      return {
        ...state,
        createNotificationRequesting: true
      }

    case EDIT_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        createNotificationRequesting: false,
        editNotification: action.data
      }

    case EDIT_PUSH_NOTIFICATION_FALUIRE:
      return {
        ...state,
        createNotificationRequesting: false,
        error: action.data
      }

    case DELETE_PUSH_NOTIFICATION:
      return {
        ...state,
        deleteRequest: true
      }

    case DELETE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteRequest: false
      }

    case DELETE_PUSH_NOTIFICATION_FALUIRE:
      return {
        ...state,
        deleteRequest: false
      }

    case GET_ALL_USER:
      return {
        ...state,
        requesting: true
      }

    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        requesting: false,
        getAllUsers: action.data
      }

    case GET_ALL_USER_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}
