import React, { useState } from "react"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table
} from "reactstrap"

import Images from "utils/Images"

function Listofallusers() {
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const [image, setImage] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => setModal2(!modal2)
  const toggle3 = () => setModal3(!modal3)
  const toggle4 = () => setModal4(!modal4)

  const dummyData = [
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    },
    {
      Name: "Expert Name goes here.."
    }
  ]

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    List Of All Users
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}

                  <div
                    className="header"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      marginTop: "-10px"
                    }}
                  >
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        // caret
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                      >
                        <Button
                          onClick={() => {
                            setImage(!image)
                          }}
                          className="btn-round btn text-capitalize"
                          style={{
                            backgroundColor: "#fff",
                            color: "#3A0F7D",
                            border: "1px solid  #3A0F7D",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <img
                            style={{
                              height: "11px",
                              width: "12px",
                              // marginLeft: "20px",
                              marginRight: "4px"
                            }}
                            src={Images.filtericon}
                          />
                          filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                      >
                        <Row style={{ margin: 10 }}>
                          <InputGroup style={{}}>
                            <Input
                              placeholder="Search"
                              type="text"
                              // onChange={e => handleOnChange("password", e.target.value)}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <img
                                  style={{
                                    height: 16,
                                    width: 16,
                                    marginLeft: "20px"
                                    // marginRight: "12px"
                                  }}
                                  src={require("assets/img/search.png")}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <h6
                            style={{
                              color: "#808080",
                              fontSize: "14px",
                              marginTop: "20px"
                            }}
                          >
                            Custom Time
                          </h6>
                          <div style={{ display: "flex" }}>
                            <InputGroup style={{ margin: 5 }}>
                              <Input
                                placeholder="-From"
                                type="date"
                                // onChange={e => handleOnChange("password", e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup style={{ margin: 5 }}>
                              <Input
                                placeholder="-To"
                                type="date"
                                // onChange={e => handleOnChange("password", e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "30px"
                            }}
                          >
                            <Button
                              className="btn-link text-capitalize"
                              color="secondary"
                              style={{ color: "#3A0F7D" }}
                            >
                              <img
                                style={{
                                  height: 12,
                                  width: 12,
                                  // marginLeft: "20px"
                                  marginRight: "5px"
                                }}
                                src={require("assets/img/cross.png")}
                              />
                              Clear
                            </Button>
                            <img
                              style={{
                                height: 42,
                                width: 115,
                                marginLeft: "20px",
                                marginRight: "5px"
                              }}
                              src={require("assets/img/apply.png")}
                            />
                          </div>
                        </Row>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        // caret
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                      >
                        <Button
                          className="btn-round text-capitalize"
                          // color="secondary"
                          style={{
                            backgroundColor: "#3A0F7D",
                            // width: 160,
                            marginLeft: "-20px",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          More Action
                          {image === false ? (
                            <img
                              style={{
                                width: "9px",

                                marginLeft: "10px",
                                marginTop: "-3px"
                                // marginRight: "5px"
                                // backgroundColor: "#3A0F7D"
                              }}
                              src={Images.down}
                            />
                          ) : (
                            <img
                              style={{
                                width: "9px",

                                marginLeft: "10px",
                                marginTop: "-3px"
                                // marginRight: "5px"
                                // backgroundColor: "#3A0F7D"
                              }}
                              src={require("assets/img/up.png")}
                            />
                          )}
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                      >
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle1}
                          // onClick={e => e.preventDefault()}
                        >
                          Request Hours
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle2}
                          // onClick={e => e.preventDefault()}
                        >
                          Request Engagement
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle3}
                          // onClick={e => e.preventDefault()}
                        >
                          End Engagement
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle4}
                          // onClick={e => e.preventDefault()}
                        >
                          Manage Engagement Hour
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {/* </div> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-80px",
                    borderCollapse: "collapse"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Expert
                    </th>
                    {/* <th style={{ color: "#808080" }}>Project Exhibits</th>
                    <th style={{ color: "#808080" }}>Expert Fullname</th>
                    <th style={{ color: "#808080" }}>Document Type</th> */}

                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        paddingRight: "50px"
                      }}
                    >
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            marginLeft: "25px",
                            borderRadius: "8px",
                            border: "3px solid transparent"
                          }}
                        >
                          <td
                            className="border-1"
                            style={{
                              padding: "25px",
                              borderBottomLeftRadius: "8px",
                              borderTopLeftRadius: "8px"
                            }}
                          >
                            {item.Name}
                          </td>

                          <td
                            className="text-right"
                            style={{
                              paddingRight: "25px",
                              paddingTop: "14px",
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px"
                            }}
                          >
                            <Button
                              className="btn-round btn text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                color: "#3A0F7D",
                                border: "1px solid  #3A0F7D",
                                display: "flex",
                                alignItems: "center",
                                float: "right"
                              }}
                            >
                              <img
                                style={{
                                  height: "11px",
                                  width: "12px",
                                  // marginLeft: "20px",
                                  marginRight: "4px",
                                  marginTop: "-3px"
                                }}
                                src={require("assets/img/star.png")}
                              />
                              Rate
                            </Button>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  <PaginationPack />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Listofallusers
