import React, { useEffect, useState, useMemo } from "react"
import ReactDOM from "react-dom"
import { Switch, Route, Router, Redirect } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
import { store, history } from "./Redux/store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import RouteGuard from "./RouterGuard"
import { connect } from "react-redux"

import ForegroundNotification from "Containers/ForegroundNotification"
import { notificationDataRequest } from "Containers/ForegroundNotification/redux/actions"
import { getNotficationDataRequest } from "Containers/ExpertDesign/Screens/Notifications/redux/actions"

import { Toast } from "react-bootstrap"

import AuthLayout from "layouts/Auth.js"
import AuthProfile from "layouts/AuthProfile.js"
import AdminLayout from "layouts/Admin.js"
import BusinessLayout from "layouts/Business.js"
import ExpertLayout from "layouts/Expert.js"
import NotFound from "Containers/NotFound"

import "bootstrap/dist/css/bootstrap.css"
import "assets/scss/paper-dashboard.scss?v=1.2.0"
import "assets/demo/demo.css"
import "perfect-scrollbar/css/perfect-scrollbar.css"

import { setDeviceId } from "Containers/LoginScreen/redux/actions"

// import { onMessageListener, fetchToken } from "./firebase"
import toast from "react-hot-toast"

const tempToken = localStorage.getItem("authToken")
const accessToken = localStorage.getItem("accessToken")

const App = props => {
  const {
    profileData,
    token,
    initialValue,
    setDeviceId,
    notificationDataRequest,
    getNotficationDataRequest
  } = props

  const [notification, setNotification] = useState({ title: "", body: "" })
  const [isTokenFound, setTokenFound] = useState(false)
  const [tokenValue, setTokenValue] = useState("")

  return (
    <>
      <ForegroundNotification />

      <Router history={history}>
        <Switch>
          {(token || tempToken) && (
            <RouteGuard
              path="/profile"
              component={props => <AuthProfile {...props} />}
              isInitalProfile
            />
          )}

          {!token && (
            <RouteGuard
              path="/auth"
              component={props => <AuthLayout {...props} />}
            />
          )}

          {profileData == "Admin" && (
            <RouteGuard
              path="/admin"
              component={props => <AdminLayout {...props} />}
              isProtected
            />
          )}

          {profileData == "Business" && (
            <RouteGuard
              path="/business"
              component={props => <BusinessLayout {...props} />}
              isProtected
            />
          )}
          {profileData == "Expert" && (
            <RouteGuard
              path="/Expert"
              component={props => <ExpertLayout {...props} />}
              isProtected
            />
          )}

          {profileData === "Expert" ? (
            <Redirect to="/Expert/Engagements" />
          ) : profileData === "Business" ? (
            <Redirect to="/business/my_team" />
          ) : profileData === "Admin" ? (
            <Redirect to="/admin/time_tracker" />
          ) : (
            <Redirect to="/auth/welcome" />
          )}
        </Switch>
      </Router>
    </>
  )
}

const mapStateToProps = state => ({
  profileData: state.LoginScreen.profileData,
  token: state.LoginScreen.accessToken,
  initialValue: state.LoginScreen.initialProfile
})

const mapDispatchToProps = dispatch => ({
  setDeviceId: data => dispatch(setDeviceId(data)),
  notificationDataRequest: data => dispatch(notificationDataRequest(data)),
  getNotficationDataRequest: data => dispatch(getNotficationDataRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(App)
