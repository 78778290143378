import React, { useState, useEffect } from "react"
import PaginationPack from "components/PaginationPack"
import { connect, useSelector } from "react-redux"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"

import Select from "react-select"

import { resourcesRequest } from "../redux/actions"

import Images from "utils/Images"
import ReadMore from "components/ReadMore"

function LearningResources(props) {
  const { requesting, resourceData, resourcesRequest } = props

  useEffect(() => {
    resourcesRequest({ page: 1, type: "RESOURCES" })
  }, [])

  const handlePageChange = page => {
    resourcesRequest({ page: page, type: "RESOURCES" })
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  /> */}
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Resources
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="p-0 m-0">
                  {requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : resourceData && resourceData?.results?.length > 0 ? (
                    resourceData?.results.map(item => (
                      <>
                        {/* <Col lg="1" md="3" sm="6" className="p-1"></Col> */}
                        <Col lg="3" md="3" sm="4" className="p-1">
                          <div
                            className="cards"
                            style={{
                              backgroundColor: "white",
                              padding: 10,
                              borderRadius: 8,
                              //   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                              border: "1px solid #E2E2E2",
                              maxWidth: "250px",
                              marginTop: 16
                            }}
                          >
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                              href={item.url}
                            >
                              <img
                                style={{
                                  marginRight: "20px",
                                  cursor: "pointer",
                                  height: "180px",
                                  borderRadius: 8,
                                  width: "100%"
                                }}
                                src={item.image}
                              />
                              {item.title === "" ? (
                                <div style={{ height: 40 }}></div>
                              ) : (
                                <p
                                  style={{
                                    marginTop: 15,
                                    textAlign: "center",
                                    color: "#000000",
                                    fontSize: 16,
                                    fontFamily: "khula",
                                    fontWeight: "bold",
                                    lineHeight: "24px"
                                  }}
                                >
                                  {item.title}
                                </p>
                              )}
                            </a>
                            <p
                              style={{
                                marginTop: 15,
                                textAlign: "center",
                                color: "#000000",
                                fontSize: 15,
                                fontFamily: "khula",
                                fontWeight: "500",
                                lineHeight: "24px"
                              }}
                            >
                              <ReadMore
                                length={100}
                                item={item}
                                text={item.details}
                              />
                            </p>
                          </div>
                        </Col>
                      </>
                    ))
                  ) : (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                      </div>
                    </>
                  )}
                </Row>
                <div className="mt-4 d-flex justify-content-center">
                  {resourceData && resourceData?.results?.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={resourceData.page_size}
                        totalItemsCount={resourceData?.total_objects}
                        activePage={resourceData?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.Resource.requesting,
  resourceData: state.Resource.resourceData
})

const mapDispatchToProps = dispatch => ({
  resourcesRequest: data => dispatch(resourcesRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(LearningResources)
