import {
  HOME_PROFILE_V2_REQUEST,
  HOME_PROFILE_V2_SUCCESS,
  HOME_PROFILE_V2_FALUIRE,
  GET_PROFILE_V2_REQUEST,
  GET_PROFILE_V2_SUCCESS,
  GET_PROFILE_V2_FALUIRE
} from "./types"
const initialState = {
  user: false,
  requesting: false,
  error: false,
  userType: false,
  data: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case HOME_PROFILE_V2_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case HOME_PROFILE_V2_SUCCESS:
      return {
        ...state,
        requesting: false,
        user: action.data
      }

    case HOME_PROFILE_V2_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_PROFILE_V2_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_PROFILE_V2_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }

    case GET_PROFILE_V2_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}
