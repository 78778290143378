export const DOWNLOAD_CONTRACT_REQUEST = "DOWNLOAD_CONTRACT_REQUEST"
export const DOWNLOAD_CONTRACT_SUCCESS = "DOWNLOAD_CONTRACT_SUCCESS"
export const DOWNLOAD_CONTRACT_FALUIRE = "DOWNLOAD_CONTRACT_FALUIRE"

export const GET_INDUSTRIAL_EXPERIENCE_REQUEST =
  "GET_INDUSTRIAL_EXPERIENCE_REQUEST"
export const GET_INDUSTRIAL_EXPERIENCE_SUCCESS =
  "GET_INDUSTRIAL_EXPERIENCE_SUCCESS"
export const GET_INDUSTRIAL_EXPERIENCE_FALUIRE =
  "GET_INDUSTRIAL_EXPERIENCE_FALUIRE"

export const ADDITIONAL_EXPERTIES_REQUEST = "ADDITIONAL_EXPERTIES_REQUEST"
export const ADDITIONAL_EXPERTIES_SUCCESS = "ADDITIONAL_EXPERTIES_SUCCESS"
export const ADDITIONAL_EXPERTIES_FALUIRE = "ADDITIONAL_EXPERTIES_FALUIRE"

export const GET_EXPERT_PROFILE_REQUEST = "GET_EXPERT_PROFILE_REQUEST"
export const GET_EXPERT_PROFILE_SUCCESS = "GET_EXPERT_PROFILE_SUCCESS"
export const GET_EXPERT_PROFILE_FALUIRE = "GET_EXPERT_PROFILE_FALUIRE"
