import React, { useState, useRef, useEffect } from "react"
import PictureUpload from "components/CustomUpload/PictureUpload.js"
// hooks
import validator from "utils/validation"
import useForm from "utils/useForm"
import toast, { Toaster } from "react-hot-toast"

import {
  profileRequest,
  userProfileRequest,
  userProfileRequestFaluire
} from "./redux/actions"

import Progressbar from "components/RangeSlider/Progressbar"
//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Spinner
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"

// import './style.scss';

// import ReactPaginate from 'react-paginate';

import Images from "utils/Images"
import { connect } from "react-redux"
import ImageInput from "components/ImageInput/ImageInput"

function EditProfile(props) {
  const {
    profileRequest,
    userProfileRequest,
    history,
    requesting,
    BackendError
  } = props
  const [selectedFile, setSelectedFile] = useState("")
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [horizontal, setHorizontal] = useState(0)
  const [isChange, setIsChange] = useState(false)
  const [selectedPic, setselectedPic] = useState("")
  const [allData, setAllData] = useState(false)
  const [fileName, setFileName] = useState("")
  const [selectLabels, setSelectLabels] = useState([])
  const [multiData, setMultiData] = useState(false)
  const [hour, setHour] = useState({ value: "0" })

  const [image, setImage] = useState("")

  const fileRef = useRef()

  const handleChange = async e => {
    const file = e.target.files[0]
    setFileName(file?.name)
    const base64 = await convertBase64(file)
    setSelectedFile(base64)
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const stateSchema = {
    fullname: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    link: {
      value: "",
      error: ""
    },
    hour: {
      value: "",
      error: ""
    },
    bio: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    fullname: {
      required: true
      // validator: validator.email
    },
    email: {
      required: true
      //   validator: validator.password,
    },
    link: {
      required: true
      //   validator: validator.password,
    },
    hour: {
      required: true
      //   validator: validator.password,
    },
    bio: {
      required: true
      //   validator: validator.password,
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const defultEmail = localStorage.getItem("email")
  const defaultName = localStorage.getItem("name")
  const defultPicture = localStorage.getItem("Profile_Picture")
  const defultLinkedIn = localStorage.getItem("linkedIn_Url")

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = async () => {
    const newData = await localStorage.getItem("userAllData")
    setAllData(JSON.parse(newData))
  }

  useEffect(() => {
    props.userProfileRequestFaluire(false)
    defultLinkedIn !== "null" && handleOnChange("link", defultLinkedIn)
    defultPicture !== "null" && setselectedPic(defultPicture)
    defultEmail !== "null" && handleOnChange("email", defultEmail)
    defaultName !== "null" && handleOnChange("fullname", defaultName)
    allData?.profile?.expert_bio &&
      handleOnChange("bio", allData?.profile?.expert_bio)
    allData?.profile?.hourly_rate &&
      setHour({ value: allData?.profile?.hourly_rate })
    allData?.profile?.user_availability &&
      setHorizontal(allData?.profile?.user_availability)
  }, [allData])

  const handleOnSubmit = async () => {
    const userId = await localStorage.getItem("user")

    if (isChange) {
      const data = {
        data: {
          user: userId,
          resume: selectedFile,
          hourly_rate: hour.value,
          expert_bio: state.bio.value,
          user_availability: horizontal,
          linkedin_profile_url: state.link.value,
          name: state.fullname.value,
          profile_picture: selectedPic
        },
        id: allData?.profile?.id
      }
      userProfileRequest(data)
    } else {
      const data = {
        data: {
          user: userId,
          resume: selectedFile,
          hourly_rate: hour.value,
          expert_bio: state.bio.value,
          user_availability: horizontal,
          linkedin_profile_url: state.link.value,
          name: state.fullname.value
        },
        id: allData?.profile?.id
      }
      userProfileRequest(data)
    }
  }

  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <CardTitle
                tag="h4"
                style={{
                  fontFamily: "Libre Caslon Text",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#CACACA"
                }}
              >
                Edit Profile
              </CardTitle>
              {/* <div style={{ width: '200px' }}> */}

              {/* </div> */}
            </div>
            <Card
              className="card-plain"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardBody>
                <Toaster position="top-center" />

                <>
                  <div style={{ margin: "20px" }}>
                    <Row>
                      <Col
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            // background:"red",
                            // height:"192px",width:"182px",
                            borderRadius: "50%"
                          }}
                        >
                          <PictureUpload
                            setselectedFile={setselectedPic}
                            avatar={defultPicture}
                            setIsChange={setIsChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3"></Col>
                      <Col lg="6">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "17px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Full Name
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="Type name"
                            type="text"
                            style={{ height: "55px" }}
                            value={state.fullname.value}
                            onChange={e =>
                              handleOnChange("fullname", e.target.value)
                            }
                          />
                        </FormGroup>
                        {state.fullname.error && (
                          <label style={{ color: "red", display: "flex" }}>
                            {state.fullname.error}
                          </label>
                        )}
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "7px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Email
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="Type email"
                            type="email"
                            disabled
                            style={{ height: "55px" }}
                            value={state.email.value}
                            onChange={e =>
                              handleOnChange("email", e.target.value)
                            }
                          />

                          <div>
                            <label
                              style={{
                                display: "flex",
                                marginBottom: "7px",
                                marginTop: "17px",
                                fontSize: "14px",
                                fontFamily: "Khula",
                                fontWeight: "600"
                              }}
                            >
                              Resume
                            </label>
                            <div
                              style={{
                                backgroundColor: "rgba(58, 15, 125, 0.3)",
                                width: "100%",
                                height: "55px",
                                border: "1px black dotted",
                                cursor: "pointer"
                              }}
                              onClick={() => fileRef.current.click()}
                            >
                              <input
                                type="file"
                                name="file"
                                ref={fileRef}
                                onChange={handleChange}
                                hidden
                              />
                              {fileName ? (
                                <div>
                                  <p
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "52px",
                                      color: "#6434AD"
                                    }}
                                  >
                                    {fileName}
                                  </p>
                                </div>
                              ) : (
                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "52px",
                                    color: "#6434AD"
                                  }}
                                >
                                  + Add Resume
                                </p>
                              )}
                            </div>
                            {/* <img style={{ width: '90px', height: '25px' }} src={Images.add_resume} alt="Logo" /> */}
                          </div>
                        </FormGroup>
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          LinkedIn Profile Link
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="https://"
                            type="url"
                            style={{ height: "55px" }}
                            value={state.link.value}
                            onChange={e =>
                              handleOnChange("link", e.target.value)
                            }
                          />
                        </FormGroup>
                        {state.link.error && (
                          <label style={{ color: "red", display: "flex" }}>
                            {state.link.error}
                          </label>
                        )}

                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="10"
                        >
                          Hourly Rate
                        </Label>
                        <FormGroup
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <Input
                            min="0"
                            placeholder="Hour"
                            type="number"
                            style={{ width: 97, height: "55px" }}
                            value={hour?.value}
                            onChange={e => {
                              // handleOnChange("hour", e.target.value)
                              handleHourChange(e)
                              props.userProfileRequestFaluire(false)
                            }}
                          />
                          <p
                            style={{
                              margin: 0,
                              marginLeft: "13px",
                              color: "#808080",
                              fontSize: "14px",
                              fontFamily: "Khula",
                              fontWeight: "600"
                            }}
                          >
                            /hr
                          </p>
                        </FormGroup>
                        {state.hour.error && (
                          <label style={{ color: "red", display: "flex" }}>
                            {state.hour.error}
                          </label>
                        )}
                        {BackendError.hourly_rate && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError.hourly_rate}
                          </label>
                        )}

                        <label
                          style={{
                            display: "flex",
                            marginBottom: "7px",
                            marginTop: "17px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                        >
                          Weekly Availability
                        </label>
                        <Progressbar
                          horizontal={horizontal}
                          setHorizontal={setHorizontal}
                        />

                        <label
                          style={{
                            display: "flex",
                            marginBottom: "7px",
                            marginTop: "76px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                        >
                          Expert Bio
                        </label>
                        {/* <InputGroup> */}
                        <Input
                          placeholder="Type here"
                          type="textarea"
                          style={{ height: "188px", padding: "10px" }}
                          value={state.bio.value}
                          onChange={e => handleOnChange("bio", e.target.value)}
                        />
                        {state.bio.error && (
                          <label style={{ color: "red", display: "flex" }}>
                            {state.bio.error}
                          </label>
                        )}
                      </Col>
                    </Row>

                    <CardFooter>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          paddingTop: "30px",
                          // marginLeft: 10,
                          // marginRight: 10,
                          justifyContent: "center"
                        }}
                      >
                        <div
                          style={{ marginRight: "20px" }}
                          onClick={() => history.goBack()}
                        >
                          <Button
                            // onClick={() => history.goBack()}
                            className=" text-capitalize rounded-pill"
                            style={{
                              border: "2px #3A0F7D solid",
                              // padding: '15px 65px 15px 65px',
                              height: "49px",
                              backgroundColor: "white",
                              color: "#3A0F7D"
                            }}
                          >
                            Cancel
                          </Button>
                          {/* <p
                              className="font-weight-bolder px-4 py-2"
                              style={{
                                backgroundColor: "#FFFFF",
                                // padding: "15px 30px 15px 30px",
                                borderRadius: "30px",
                                color: "#3A0F7D",
                                fontWeight: "700",
                                fontSize: "16px",
                                border: "2px solid #3A0F7D",
                                boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                                textAlign: "center",
                                margin: 0
                              }}
                            >
                              Cancel
                            </p> */}
                        </div>
                        <div
                          // style={{width:'209px', height: '54px'}}
                          onClick={handleOnSubmit}
                        >
                          <Button
                            className="btn-round text-capitalize"
                            color="secondary"
                            style={{
                              width: "134px",
                              height: "49px",
                              backgroundColor: "#3A0F7D",
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                            }}
                          >
                            {requesting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save Changes"
                            )}
                          </Button>
                        </div>
                      </div>
                    </CardFooter>
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.EditProfile.requesting,
  BackendError: state.EditProfile.error
})

const mapDispatchToProps = dispatch => ({
  userProfileRequest: data => dispatch(userProfileRequest(data)),
  profileRequest: data => dispatch(profileRequest(data)),
  userProfileRequestFaluire: data => dispatch(userProfileRequestFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
