import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
import toast from "react-hot-toast"

// config
import { BASE_URL } from "../../../config/app"

// utils
import XHR from "../../../utils/XHR"

// types
import { SIGNUP_REQUEST } from "./types"

// actions
import { registerSuccess, registerFaluire } from "./actions"

function signUpAPI(data) {
  const URL = `${BASE_URL}/api/v1/signup/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* signUp({ data }) {
  try {
    const response = yield call(signUpAPI, data)
    yield put(registerSuccess())
    toast.success(`Success`)
    yield put(
      push({
        pathname: "/auth/login"
      })
    )
  } catch (e) {
    const { response } = e
    yield put(registerFaluire(response?.data))
  }
}

export default all([takeLatest(SIGNUP_REQUEST, signUp)])
