import {
  POST_BUSINESS_HOME_REQUEST,
  POST_BUSINESS_HOME_SUCCESS,
  POST_BUSINESS_HOME_FAILURE
} from "./types"

export const postHomeDataRequest = data => ({
  type: POST_BUSINESS_HOME_REQUEST,
  data
})

export const postHomeDataSuccess = data => ({
  type: POST_BUSINESS_HOME_SUCCESS,
  data
})
export const postHomeDataFaluire = data => ({
  type: POST_BUSINESS_HOME_FAILURE,
  data
})
