export const ADD_CONTENT_RESOURCE = "ADD_CONTENT_RESOURCE"
export const ADD_CONTENT_RESOURCE_SUCCESS = "ADD_CONTENT_RESOURCE_SUCCESS"
export const ADD_CONTENT_RESOURCE_FALUIRE = "ADD_CONTENT_RESOURCE_FALUIRE"

export const EDIT_CONTENT_RESOURCE = "EDIT_CONTENT_RESOURCE"
export const EDIT_CONTENT_RESOURCE_SUCCESS = "EDIT_CONTENT_RESOURCE_SUCCESS"
export const EDIT_CONTENT_RESOURCE_FALUIRE = "EDIT_CONTENT_RESOURCE_FALUIRE"

export const DELETE_CONTENT_RESOURCE = "DELETE_CONTENT_RESOURCE"
export const DELETE_CONTENT_RESOURCE_SUCCESS = "DELETE_CONTENT_RESOURCE_SUCCESS"
export const DELETE_CONTENT_RESOURCE_FALUIRE = "DELETE_CONTENT_RESOURCE_FALUIRE"
