import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FALUIRE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FALUIRE,
  FEEDBACK_REQUEST,
  FEEDBACK_REQUEST_SUCCESS,
  FEEDBACK_REQUEST_FALUIRE,
  TERMS_AND_CONDITION,
  TERMS_AND_CONDITION_SUCCESS,
  PRIVICY_POLICY,
  PRIVICY_POLICY_SUCCESS,
  NOTIFICATION_SETTINGS,
  NOTIFICATION_SETTINGS_SUCCESS,
  NOTIFICATION_SETTINGS_FALUIRE
} from "./types"

export const DeleteAccountRequest = data => ({
  type: DELETE_ACCOUNT,
  data
})

export const DeleteAccountSuccess = data => ({
  type: DELETE_ACCOUNT_SUCCESS,
  data
})

export const DeleteAccountFaluire = data => ({
  type: DELETE_ACCOUNT_FALUIRE,
  data
})

export const changePassRequest = data => ({
  type: CHANGE_PASSWORD,
  data
})

export const changePassSuccess = data => ({
  type: CHANGE_PASSWORD_SUCCESS,
  data
})

export const changePassFaluire = data => ({
  type: CHANGE_PASSWORD_FALUIRE,
  data
})

export const feedbackRequest = data => ({
  type: FEEDBACK_REQUEST,
  data
})

export const feedbackSuccess = data => ({
  type: FEEDBACK_REQUEST_SUCCESS,
  data
})

export const feedbackFaluire = data => ({
  type: FEEDBACK_REQUEST_FALUIRE,
  data
})
export const getTermsAndCondition = () => ({
  type: TERMS_AND_CONDITION
})

export const getTermsAndConditionSucess = data => ({
  type: TERMS_AND_CONDITION_SUCCESS,
  data
})
export const getPrivicyPolicy = data => ({
  type: PRIVICY_POLICY,
  data
})
export const getPrivicyPolicySucess = data => ({
  type: PRIVICY_POLICY_SUCCESS,
  data
})

export const notificationSettings = data => ({
  type: NOTIFICATION_SETTINGS,
  data
})

export const notificationSettingsSuccess = data => ({
  type: NOTIFICATION_SETTINGS_SUCCESS,
  data
})

export const notificationSettingsFaluire = data => ({
  type: NOTIFICATION_SETTINGS_FALUIRE,
  data
})
