import {
  GET_RESOURCES_REQUEST,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FALUIRE
} from "./types"

export const resourcesRequest = data => ({
  type: GET_RESOURCES_REQUEST,
  data
})

export const resourcesSuccess = data => ({
  type: GET_RESOURCES_SUCCESS,
  data
})

export const resourcesFaluire = data => ({
  type: GET_RESOURCES_FALUIRE,
  data
})
