import {
  GET_ACTIVE_ENGAGEMENT_REQUEST,
  GET_ACTIVE_ENGAGEMENT_SUCCESS,
  GET_ACTIVE_ENGAGEMENT_FALUIRE,
  PATCH_ACTIVE_ENGAGEMENT_REQUEST,
  PATCH_ACTIVE_ENGAGEMENT_SUCCESS,
  PATCH_ACTIVE_ENGAGEMENT_FALUIRE,
  PATCH_END_ENGAGEMENT_REQUEST,
  PATCH_END_ENGAGEMENT_SUCCESS,
  PATCH_END_ENGAGEMENT_FALUIRE,
  GET_ENGAGEMENT_OPPORTUNITY,
  GET_ENGAGEMENT_OPPORTUNITY_SUCCESS,
  GET_ENGAGEMENT_OPPORTUNITY_FALUIRE,
  GET_ENGAGEMENT_HISTORY_REQUEST,
  GET_ENGAGEMENT_HISTORY_SUCCESS,
  GET_ENGAGEMENT_HISTORY_FALUIRE,
  CREATE_ADMIN_ENGAGEMENT_REQUEST,
  CREATE_ADMIN_ENGAGEMENT_SUCCESS,
  CREATE_ADMIN_ENGAGEMENT_FALUIRE,
  GET_ADMIN_BUSINESS_SEARCH_REQUEST,
  GET_ADMIN_BUSINESS_SEARCH_SUCCESS,
  GET_ADMIN_BUSINESS_SEARCH_FALUIRE,
  GET_ADMIN_EXPERT_SEARCH_REQUEST,
  GET_ADMIN_EXPERT_SEARCH_SUCCESS,
  GET_ADMIN_EXPERT_SEARCH_FALUIRE
} from "./types"

export const getActiveEngagementRequest = data => ({
  type: GET_ACTIVE_ENGAGEMENT_REQUEST,
  data
})

export const getActiveEngagementSuccess = data => ({
  type: GET_ACTIVE_ENGAGEMENT_SUCCESS,
  data
})
export const getActiveEngagementFaluire = data => ({
  type: GET_ACTIVE_ENGAGEMENT_FALUIRE,
  data
})

export const patchActiveEngagementRequest = data => ({
  type: PATCH_ACTIVE_ENGAGEMENT_REQUEST,
  data
})

export const patchActiveEngagementSuccess = data => ({
  type: PATCH_ACTIVE_ENGAGEMENT_SUCCESS,
  data
})
export const patchActiveEngagementFaluire = data => ({
  type: PATCH_ACTIVE_ENGAGEMENT_FALUIRE,
  data
})

export const patchEndEngagementRequest = data => ({
  type: PATCH_END_ENGAGEMENT_REQUEST,
  data
})

export const patchEndEngagementSuccess = data => ({
  type: PATCH_END_ENGAGEMENT_SUCCESS,
  data
})
export const patchEndEngagementFaluire = () => ({
  type: PATCH_END_ENGAGEMENT_FALUIRE
})

export const postEngagementRequest = data => ({
  type: CREATE_ADMIN_ENGAGEMENT_REQUEST,
  data
})

export const postEngagementSuccess = data => ({
  type: CREATE_ADMIN_ENGAGEMENT_SUCCESS,
  data
})

export const postEngagementFaluire = data => ({
  type: CREATE_ADMIN_ENGAGEMENT_FALUIRE,
  data
})

export const getEngagementOpportunityRequest = data => ({
  type: GET_ENGAGEMENT_OPPORTUNITY,
  data
})

export const getEngagementOpportunitySuccess = data => ({
  type: GET_ENGAGEMENT_OPPORTUNITY_SUCCESS,
  data
})

export const getEngagementOpportunityFaluire = data => ({
  type: GET_ENGAGEMENT_OPPORTUNITY_FALUIRE,
  data
})

export const getEngagementHistroryRequest = data => ({
  type: GET_ENGAGEMENT_HISTORY_REQUEST,
  data
})

export const getEngagementHistrorySuccess = data => ({
  type: GET_ENGAGEMENT_HISTORY_SUCCESS,
  data
})

export const getEngagementHistroryFaluire = data => ({
  type: GET_ENGAGEMENT_HISTORY_FALUIRE,
  data
})

export const getBusinessDataRequest = data => ({
  type: GET_ADMIN_BUSINESS_SEARCH_REQUEST,
  data
})

export const getBusinessDataSuccess = data => ({
  type: GET_ADMIN_BUSINESS_SEARCH_SUCCESS,
  data
})

export const getBusinessDataFaluire = data => ({
  type: GET_ADMIN_BUSINESS_SEARCH_FALUIRE,
  data
})

export const getExpertDataRequest = data => ({
  type: GET_ADMIN_EXPERT_SEARCH_REQUEST,
  data
})

export const getExpertDataSuccess = data => ({
  type: GET_ADMIN_EXPERT_SEARCH_SUCCESS,
  data
})

export const getExpertDataFaluire = data => ({
  type: GET_ADMIN_EXPERT_SEARCH_FALUIRE,
  data
})
