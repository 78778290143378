import {
  GET_ENGAGEMENT_REQUEST_REQUEST,
  GET_ENGAGEMENT_REQUEST_SUCCESS,
  GET_ENGAGEMENT_REQUEST_FALUIRE,
  PATCH_ENGAGEMENT_REQUEST,
  PATCH_ENGAGEMENT_SUCCESS,
  PATCH_ENGAGEMENT_FALUIRE
} from "./types"

export const getEngagementRequest = data => ({
  type: GET_ENGAGEMENT_REQUEST_REQUEST,
  data
})

export const getEngagementSuccess = data => ({
  type: GET_ENGAGEMENT_REQUEST_SUCCESS,
  data
})
export const getEngagementFaluire = data => ({
  type: GET_ENGAGEMENT_REQUEST_FALUIRE,
  data
})


export const patchEngagementRequest = data => ({
  type: PATCH_ENGAGEMENT_REQUEST,
  data
})

export const patchEngagementSuccess = data => ({
  type: PATCH_ENGAGEMENT_SUCCESS,
  data
})
export const patchEngagementFaluire = data => ({
  type: PATCH_ENGAGEMENT_FALUIRE,
  data
})
