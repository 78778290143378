import {
  GET_BUSINESS_PROFILE,
  GET_BUSINESS_PROFILE_FAILURE,
  GET_BUSINESS_PROFILE_SUCCESS,
  PATCH_BUSINESS_PROFILE,
  PATCH_BUSINESS_PROFILE_FAILURE,
  PATCH_BUSINESS_PROFILE_SUCCESS
} from "./types"

export const getBusinessUser = data => ({
  type: GET_BUSINESS_PROFILE,
  data
})

export const getBusinessUserSuccess = data => ({
  type: GET_BUSINESS_PROFILE_SUCCESS,
  data
})

export const getBusinessUserFailure = data => ({
  type: GET_BUSINESS_PROFILE_FAILURE,
  data
})

export const patchBusinessUser = data => ({
  type: PATCH_BUSINESS_PROFILE,
  data
})

export const patchBusinessUserSuccess = data => ({
  type: PATCH_BUSINESS_PROFILE_SUCCESS,
  data
})

export const patchBusinessUserFailure = data => ({
  type: PATCH_BUSINESS_PROFILE_FAILURE,
  data
})
