export const PATCH_MYTEAM_REQUESTENGAGEMENT = "PATCH_MYTEAM_REQUESTENGAGEMENT"
export const PATCH_MYTEAM_REQUESTENGAGEMENT_SUCCESS =
  "PATCH_MYTEAM_REQUESTENGAGEMENT_SUCCESS"
export const PATCH_MYTEAM_REQUESTENGAGEMENT_FALUIRE =
  "PATCH_MYTEAM_REQUESTENGAGEMENT_FALUIRE"

export const GET_MYTEAM_REQUEST = "GET_MYTEAM_REQUEST"
export const GET_MYTEAM_SUCCESS = "GET_MYTEAM_SUCCESS"
export const GET_MYTEAM_FALUIRE = "GET_MYTEAM_FALUIRE"

export const BUSINESS_RATING_REQUEST = "BUSINESS_RATING_REQUEST"
export const BUSINESS_RATING_SUCCESS = "BUSINESS_RATING_SUCCESS"
export const BUSINESS_RATING_FALUIRE = "BUSINESS_RATING_FALUIRE"

export const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST"
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS"
export const ADD_TEAM_FALUIRE = "ADD_TEAM_FALUIRE"

export const GET_EXPERTIES_REQUEST = "GET_EXPERTIES_REQUEST"
export const GET_EXPERTIES_SUCCESS = "GET_EXPERTIES_SUCCESS"
export const GET_EXPERTIES_FALUIRE = "GET_EXPERTIES_FALUIRE"

export const ADD_HOURS_REQUEST = "ADD_HOURS_REQUEST"
export const ADD_HOURS_SUCCESS = "ADD_HOURS_SUCCESS"
export const ADD_HOURS_FALUIRE = "ADD_HOURS_FALUIRE"

export const POST_END_ENGAGEMENT_REQUEST = "POST_END_ENGAGEMENT_REQUEST"
export const POST_END_ENGAGEMENT_SUCCESS = "POST_END_ENGAGEMENT_SUCCESS"
export const POST_END_ENGAGEMENT_FALUIRE = "POST_END_ENGAGEMENT_FALUIRE"
