import {
  ADD_CONTENT_RESOURCE,
  ADD_CONTENT_RESOURCE_SUCCESS,
  ADD_CONTENT_RESOURCE_FALUIRE,
  EDIT_CONTENT_RESOURCE,
  EDIT_CONTENT_RESOURCE_SUCCESS,
  EDIT_CONTENT_RESOURCE_FALUIRE,
  DELETE_CONTENT_RESOURCE,
  DELETE_CONTENT_RESOURCE_SUCCESS,
  DELETE_CONTENT_RESOURCE_FALUIRE
} from "./types"

export const addContentResourse = data => ({
  type: ADD_CONTENT_RESOURCE,
  data
})

export const addContentResourseSuccess = data => ({
  type: ADD_CONTENT_RESOURCE_SUCCESS,
  data
})
export const addContentResourseFaluire = data => ({
  type: ADD_CONTENT_RESOURCE_FALUIRE,
  data
})

export const editContentResourse = data => ({
  type: EDIT_CONTENT_RESOURCE,
  data
})

export const editContentResourseSuccess = data => ({
  type: EDIT_CONTENT_RESOURCE_SUCCESS,
  data
})
export const editContentResourseFaluire = data => ({
  type: EDIT_CONTENT_RESOURCE_FALUIRE,
  data
})

export const deleteContentResourse = data => ({
  type: DELETE_CONTENT_RESOURCE,
  data
})

export const deleteContentResourseSuccess = data => ({
  type: DELETE_CONTENT_RESOURCE_SUCCESS,
  data
})
export const deleteContentResourseFaluire = data => ({
  type: DELETE_CONTENT_RESOURCE_FALUIRE,
  data
})
