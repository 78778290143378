import {
  REQUEST_ADDITIONAL_EXPERT,
  REQUEST_ADDITIONAL_EXPERT_SUCCESS,
  REQUEST_ADDITIONAL_EXPERT_FALUIRE,
} from "./types"

const initialState = {
  requesting: false,
  error: false,

}

export default (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_ADDITIONAL_EXPERT:
      return {
        ...state,
        requesting: true
      }

    case REQUEST_ADDITIONAL_EXPERT_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
      }

    case REQUEST_ADDITIONAL_EXPERT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    // case TOGGLE_MODAL:
    //   return {
    //     ...state,
    //     visible: action.data
    //   }

    // case FEEDBACK_PAGE_REQUEST:
    //   return {
    //     ...state,
    //     requesting: true
    //   }

    // case FEEDBACK_PAGE_SUCCESS:
    //   return {
    //     ...state,
    //     error: false,
    //     requesting: false,
    //     allAccounts: action.page,
    //     pagination: action.page
    //   }

    // case FEEDBACK_PAGE_FALUIRE:
    //   return {
    //     ...state,
    //     requesting: false,
    //     error: action.error
    //   }

    default:
      return state
  }
}
