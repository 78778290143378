export const GET_ALL_TIMETRACKING_LOGS_REQUEST =
  "GET_ALL_TIMETRACKING_LOGS_REQUEST"
export const GET_ALL_TIMETRACKING_LOGS_SUCCESS =
  "GET_ALL_TIMETRACKING_LOGS_SUCCESS"
export const GET_ALL_TIMETRACKING_LOGS_FALUIRE =
  "GET_ALL_TIMETRACKING_LOGS_FALUIRE"

export const ADMIN_EXPORT_CSV_REQUEST = "ADMIN_EXPORT_CSV_REQUEST"
export const ADMIN_EXPORT_CSV_REQUEST_SUCCESS =
  "ADMIN_EXPORT_CSV_REQUEST_SUCCESS"
export const ADMIN_EXPORT_CSV_REQUEST_FALUIRE =
  "ADMIN_EXPORT_CSV_REQUEST_FALUIRE"

export const FILTER_TIMETRACKING_LOGS_REQUEST =
  "FILTER_TIMETRACKING_LOGS_REQUEST"
export const FILTER_TIMETRACKING_LOGS_SUCCESS =
  "FILTER_TIMETRACKING_LOGS_SUCCESS"
export const FILTER_TIMETRACKING_LOGS_FALUIRE =
  "FILTER_TIMETRACKING_LOGS_FALUIRE"

export const PATCH_TIMETRACKING_LOGS_REQUEST = "PATCH_TIMETRACKING_LOGS_REQUEST"
export const PATCH_TIMETRACKING_LOGS_SUCCESS = "PATCH_TIMETRACKING_LOGS_SUCCESS"
export const PATCH_TIMETRACKING_LOGS_FALUIRE = "PATCH_TIMETRACKING_LOGS_FALUIRE"

export const GET_BILLING_PERIODS_REQUEST = "GET_BILLING_PERIODS_REQUEST"
export const GET_BILLING_PERIODS_SUCCESS = "GET_BILLING_PERIODS_SUCCESS"
export const GET_BILLING_PERIODS_FALUIRE = "GET_BILLING_PERIODS_FALUIRE"
