import { all, call, put, takeLatest } from "redux-saga/effects"
import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  GET_ALL_TIMETRACKING_LOGS_REQUEST,
  ADMIN_EXPORT_CSV_REQUEST,
  FILTER_TIMETRACKING_LOGS_REQUEST,
  PATCH_TIMETRACKING_LOGS_REQUEST,
  GET_BILLING_PERIODS_REQUEST
} from "./types"

// actions
import {
  timetrackingLogsSuccess,
  timetrackingLogsFailure,
  exportCSVSuccess,
  exportCSVFaluire,
  filterTimeTrackLogsSuccess,
  filterTimeTrackLogsFaluire,
  patchTimeTrackLogsSuccess,
  patchTimeTrackLogsFaluire,
  timetrackingLogsRequest,
  getBillingPeriodsSuccess,
  getBillingPeriodsFaluire
} from "./actions"

async function getTimeTrackingDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/time_tracking/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getTimeTrackingData({ data }) {
  try {
    const response = yield call(getTimeTrackingDataAPI, data)
    yield put(timetrackingLogsSuccess(response?.data))
  } catch (e) {
    yield put(timetrackingLogsFailure(e.response))
  }
}

async function getExportCSVDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/time_tracking/time_logs_csv/?expert=${data.expert}&client=${data.client}&start_date=${data.start_date}&end_date=${data.end_date}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExportCSVData({ data }) {
  try {
    const response = yield call(getExportCSVDataAPI, data)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.setAttribute("download", "Log History CSV.csv")
    link.href = url
    document.body.appendChild(link)
    link.click()
    yield put(exportCSVSuccess(response?.data))
  } catch (e) {
    yield put(exportCSVFaluire(e.response))
  }
}

async function filterTimeTrackingDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/time_tracking/?expert=${data.expert}&client=${data.client}&start_date=${data.start_date}&end_date=${data.end_date}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* filterTimeTrackingData({ data }) {
  try {
    const response = yield call(filterTimeTrackingDataAPI, data)
    yield put(filterTimeTrackLogsSuccess(response?.data))
  } catch (e) {
    yield put(filterTimeTrackLogsFaluire(e.response))
  }
}

async function patchTimeTrackingAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/time_tracking/${data?.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchTimeTracking({ data }) {
  try {
    const response = yield call(patchTimeTrackingAPI, data)
    yield put(patchTimeTrackLogsSuccess(response?.data))
    yield put(timetrackingLogsRequest(1))
  } catch (e) {
    yield put(patchTimeTrackLogsFaluire(e.response?.data))
  }
}

async function getBillingPeriodsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/billing_periods/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getBillingPeriods({ data }) {
  try {
    const response = yield call(getBillingPeriodsAPI, data)
    yield put(getBillingPeriodsSuccess(response?.data))
  } catch (e) {
    yield put(getBillingPeriodsFaluire(e.response?.data))
  }
}

export default all([
  takeLatest(GET_ALL_TIMETRACKING_LOGS_REQUEST, getTimeTrackingData),
  takeLatest(FILTER_TIMETRACKING_LOGS_REQUEST, filterTimeTrackingData),
  takeLatest(ADMIN_EXPORT_CSV_REQUEST, getExportCSVData),
  takeLatest(PATCH_TIMETRACKING_LOGS_REQUEST, patchTimeTracking),
  takeLatest(GET_BILLING_PERIODS_REQUEST, getBillingPeriods)
])
