import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Spinner
} from "reactstrap"
import Select from "react-select"
import moment from "moment"

import PaginationPack from "components/PaginationPack"
import Images from "utils/Images"
import { connect } from "react-redux"
import { getEngagementOpportunityRequest } from "../ActiveEngagment/redux/actions"
function EngagementOpportunity(props) {
  const { opportunity } = props
  useEffect(() => {
    props.getEngagementOpportunityRequest(1)
  }, [])

  const handlePageChange = page => {
    props.getEngagementOpportunityRequest(page)
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Engagement Opportunity
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        padding: "25px"
                      }}
                    >
                      Engagement
                    </th>

                    <th
                      className="text-right pr-lg-5"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Date
                    </th>
                  </tr>

                  {props.requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "90%",
                        // transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : opportunity && opportunity?.results.length > 0 ? (
                    <tbody>
                      {opportunity?.results &&
                        opportunity?.results.map(item => (
                          <>
                            <tr
                              style={{
                                // border: "3px solid",
                                // borderRadius: "50px !mportant",
                                backgroundColor: "white",
                                marginLeft: "25px",
                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  padding: "25px",
                                  borderBottomLeftRadius: "8px",
                                  borderTopLeftRadius: "8px"
                                }}
                              >
                                {item.business_name}
                              </td>

                              <td
                                style={{
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px"
                                }}
                                className="text-right pr-lg-3"
                              >
                                {moment(item.created).format("MM-DD-YYYY")}
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))}
                    </tbody>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {opportunity && opportunity?.results.length > 0 ? (
                    props.requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={opportunity?.page_size}
                          totalItemsCount={opportunity?.total_objects}
                          activePage={opportunity?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  opportunity: state.ActiveEngagement.opportunity,
  requesting: state.ActiveEngagement.requesting
})

const mapDispatchToProps = dispatch => ({
  getEngagementOpportunityRequest: data =>
    dispatch(getEngagementOpportunityRequest(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementOpportunity)
