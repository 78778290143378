import {
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FALUIRE,
  TOGGLE_MODAL,
  FEEDBACK_PAGE_REQUEST,
  FEEDBACK_PAGE_SUCCESS,
  FEEDBACK_PAGE_FALUIRE
} from "./types"

export const postFeedBackRequest = data => ({
  type: POST_FEEDBACK_REQUEST,
  data
})

export const postFeedBackSuccess = data => ({
  type: POST_FEEDBACK_SUCCESS,
  data
})

export const postFeedBackFaluire = data => ({
  type: POST_FEEDBACK_FALUIRE,
  data
})

export const toggleModal = data => ({
  type: TOGGLE_MODAL,
  data
})

export const pageRequest = page => ({
  type: FEEDBACK_PAGE_REQUEST,
  page
})

export const pageSuccess = page => ({
  type: FEEDBACK_PAGE_SUCCESS,
  page
})

export const pageFaluire = error => ({
  type: FEEDBACK_PAGE_FALUIRE,
  error
})
