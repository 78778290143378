import {
  GET_EDUCATIONAL_REQUEST,
  GET_EDUCATIONAL_SUCCESS,
  GET_EDUCATIONAL_FALUIRE
} from "./types"

export const educationalDataRequest = data => ({
  type: GET_EDUCATIONAL_REQUEST,
  data
})

export const educationalDataSuccess = data => ({
  type: GET_EDUCATIONAL_SUCCESS,
  data
})

export const educationalDataFaluire = data => ({
  type: GET_EDUCATIONAL_FALUIRE,
  data
})
