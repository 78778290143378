import { GET_BUSINESS_PROFILE, PATCH_BUSINESS_PROFILE } from "./types"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"

// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"
import {
  getBusinessUserSuccess,
  getBusinessUserFailure,
  patchBusinessUserFailure,
  patchBusinessUserSuccess
} from "./action"

import { loginSuccess } from "../../../LoginScreen/redux/actions"

async function patchBusinessDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business_profile/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchBusinessData({ data }) {
  try {
    const response = yield call(patchBusinessDataAPI, data)
    yield put(loginSuccess(response?.data))
    yield put(patchBusinessUserSuccess(response))
    yield put(
      push({
        pathname: "/business/business_profile"
      })
    )
  } catch (e) {
    const { response } = e
    yield put(patchBusinessUserFailure(response?.data))
  }
}

async function getBusinessDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business_profile/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getBusinessData({ data }) {
  try {
    const response = yield call(getBusinessDataAPI, data)
    yield put(getBusinessUserSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getBusinessUserFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_BUSINESS_PROFILE, getBusinessData),
  takeLatest(PATCH_BUSINESS_PROFILE, patchBusinessData)
])
