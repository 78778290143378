import {
  POST_TIMETRACKER_DATA,
  POST_TIMETRACKER_DATA_SUCCESS,
  POST_TIMETRACKER_DATA_FAILURE,
  GET_ENGAGEMENT_DATA,
  GET_ENGAGEMENT_DATA_SUCCESS,
  GET_ENGAGEMENT_DATA_FAILURE
} from "./types"

export const timeTrackerRequest = (data, clearValue) => ({
  type: POST_TIMETRACKER_DATA,
  data,
  clearValue
})

export const timeTrackerRequestSuccess = data => ({
  type: POST_TIMETRACKER_DATA_SUCCESS,
  data
})
export const timeTrackerRequestFaluire = data => ({
  type: POST_TIMETRACKER_DATA_FAILURE,
  data
})

export const getEngagementRequest = () => ({
  type: GET_ENGAGEMENT_DATA
})

export const getEngagementSuccess = data => ({
  type: GET_ENGAGEMENT_DATA_SUCCESS,
  data
})
export const getEngagementFaluire = data => ({
  type: GET_ENGAGEMENT_DATA_FAILURE,
  data
})
