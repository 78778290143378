import {
  GET_ACTIVE_ENGAGEMENT_REQUEST,
  GET_ACTIVE_ENGAGEMENT_SUCCESS,
  GET_ACTIVE_ENGAGEMENT_FALUIRE,
  PATCH_ACTIVE_ENGAGEMENT_REQUEST,
  PATCH_ACTIVE_ENGAGEMENT_SUCCESS,
  PATCH_ACTIVE_ENGAGEMENT_FALUIRE,
  PATCH_END_ENGAGEMENT_REQUEST,
  PATCH_END_ENGAGEMENT_SUCCESS,
  PATCH_END_ENGAGEMENT_FALUIRE,
  GET_ENGAGEMENT_OPPORTUNITY,
  GET_ENGAGEMENT_OPPORTUNITY_SUCCESS,
  GET_ENGAGEMENT_OPPORTUNITY_FALUIRE,
  GET_ENGAGEMENT_HISTORY_REQUEST,
  GET_ENGAGEMENT_HISTORY_SUCCESS,
  GET_ENGAGEMENT_HISTORY_FALUIRE,
  CREATE_ADMIN_ENGAGEMENT_REQUEST,
  CREATE_ADMIN_ENGAGEMENT_SUCCESS,
  CREATE_ADMIN_ENGAGEMENT_FALUIRE,
  GET_ADMIN_BUSINESS_SEARCH_REQUEST,
  GET_ADMIN_BUSINESS_SEARCH_SUCCESS,
  GET_ADMIN_BUSINESS_SEARCH_FALUIRE,
  GET_ADMIN_EXPERT_SEARCH_REQUEST,
  GET_ADMIN_EXPERT_SEARCH_SUCCESS,
  GET_ADMIN_EXPERT_SEARCH_FALUIRE
} from "./types"
const initialState = {
  allEngagements: false,
  requesting: false,
  detailRequest: false,
  endRequest: false,
  successEndEngagement: false,
  error: false,
  patchActiveSuccess: false,
  postSuccess: false,
  expertSearch: false,
  businessSearch: false,
  opportunity: false,
  engagementHistory: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_ENGAGEMENT_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ACTIVE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        allEngagements: action.data
      }

    case GET_ACTIVE_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case PATCH_ACTIVE_ENGAGEMENT_REQUEST:
      return {
        ...state,
        detailRequest: true
      }

    case PATCH_ACTIVE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        detailRequest: false,
        patchActiveSuccess: action.data
      }

    case PATCH_ACTIVE_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        detailRequest: false,
        error: action.data
      }

    case PATCH_END_ENGAGEMENT_REQUEST:
      return {
        ...state,
        endRequest: true
      }

    case PATCH_END_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        endRequest: false,
        successEndEngagement: action.data
      }

    case PATCH_END_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        endRequest: false,
        error: action.data
      }

    case CREATE_ADMIN_ENGAGEMENT_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case CREATE_ADMIN_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        postSuccess: action.data
      }

    case CREATE_ADMIN_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_ENGAGEMENT_OPPORTUNITY:
      return {
        ...state,
        requesting: true
      }

    case GET_ENGAGEMENT_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        opportunity: action.data
      }

    case GET_ENGAGEMENT_OPPORTUNITY_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_ENGAGEMENT_HISTORY_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ENGAGEMENT_HISTORY_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        engagementHistory: action.data
      }

    case GET_ENGAGEMENT_HISTORY_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_ADMIN_BUSINESS_SEARCH_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ADMIN_BUSINESS_SEARCH_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        businessSearch: action.data
      }

    case GET_ADMIN_BUSINESS_SEARCH_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_ADMIN_EXPERT_SEARCH_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ADMIN_EXPERT_SEARCH_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        expertSearch: action.data
      }

    case GET_ADMIN_EXPERT_SEARCH_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
