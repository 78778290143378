import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../../../config/app"

// utils
import XHR from "../../../../../utils/XHR"

// types
import {
  GET_ENGAGEMENT_REQUEST_REQUEST,
  PATCH_ENGAGEMENT_REQUEST
} from "./types"

// actions
import {
  getEngagementRequest,
  getEngagementSuccess,
  getEngagementFaluire,
  patchEngagementSuccess,
  patchEngagementFaluire
} from "./actions"

async function patchEngagementAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/business/request_engagement/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchEngagement({ data }) {
  try {
    const response = yield call(patchEngagementAPI, data)
    yield put(getEngagementRequest(1))
    yield put(patchEngagementSuccess(response.data))
    toast.success("success")
  } catch (e) {
    const { response } = e
    yield put(patchEngagementFaluire(response?.data))
  }
}

async function getEngagementsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/request_engagement/?page=${data}`

  const options = {
    headers: {
      Authorization: `Token ${accessToken}`
    },
    method: "GET",
    data
  }

  return XHR(URL, options)
}
function* getEngagements({ data }) {
  try {
    const response = yield call(getEngagementsAPI, data)
    yield put(getEngagementSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getEngagementFaluire(response?.data))
  }
}

export default all([
  takeLatest(PATCH_ENGAGEMENT_REQUEST, patchEngagement),
  takeLatest(GET_ENGAGEMENT_REQUEST_REQUEST, getEngagements)
])
