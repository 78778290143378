const validator = {
    name: {
        regEx: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
        error: 'Only aplhabetic letters are allowed with spaces in between.',
    },
    email: {
        regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        error: 'Invalid email address.',
    },
    phone: {
        regEx: /^\d+$/,
        error: 'Enter a valid phone number without a + sign.',
    },
    password: {
        regEx: /(?=^.{8,16}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        error:
            'Must be 8 characters long containing a combination of special characters, numbers, uppercase and lowercase letters',
    },
    numeric: {
        regEx: /^\d+$/,
        error: 'Only numeric digits allowed.',
    },
    url:{
        regEx:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_\+.~#?&//=]*)/
        ,
        error:"Enter valid url "
    }
};

export default validator;
