import React, { useState, useRef, useEffect } from "react"
import PictureUpload from "components/CustomUpload/PictureUpload.js"
import Modal from "react-bootstrap/Modal"
// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap"
import Select from "react-select"

import toast, { Toaster } from "react-hot-toast"

// import './style.scss';

// import ReactPaginate from 'react-paginate';

import { Link } from "react-router-dom"
import Images from "utils/Images"
import { connect } from "react-redux"
import useForm from "utils/useForm"
import validator from "utils/validation"
import {
  patchBusinessUser,
  getBusinessUser,
  patchBusinessUserFailure
} from "../BusinessProfile/redux/action"
import BuninessImageUpload from "components/CustomUpload/BuninessImageUpload"
import ImageInput from "components/ImageInput/ImageInput"

function BusinessEditProfile(props) {
  const {
    history,
    patchBusinessUser,
    getBusinessUser,
    BusinessProfileData,
    BackendError,
    requesting,
    patchBusinessUserFailure
  } = props

  const [selectedPic, setselectedPic] = useState("")
  const [isChange, setIsChange] = useState(false)

  useEffect(() => {
    getBusinessUser()
  }, [])

  const stateSchema = {
    companyName: {
      value: "",
      error: ""
    },
    companyWebsite: {
      value: "",
      error: ""
    },
    AssociatedEmail: {
      value: "",
      error: ""
    },
    poc: {
      value: "",
      error: ""
    },
    invoiceEmail: {
      value: "",
      error: ""
    },
    blurb: {
      value: "",
      error: ""
    }
  }

  useEffect(() => {
    if (BusinessProfileData) {
      handleOnChange(
        "companyName",
        BusinessProfileData?.company_name
          ? BusinessProfileData?.company_name
          : ""
      )
      handleOnChange(
        "companyWebsite",
        BusinessProfileData?.website ? BusinessProfileData?.website : ""
      )
      handleOnChange(
        "AssociatedEmail",
        BusinessProfileData?.associated_email
          ? BusinessProfileData?.associated_email
          : ""
      )
      handleOnChange(
        "poc",
        BusinessProfileData?.user_name ? BusinessProfileData?.user_name : ""
      )
      handleOnChange(
        "invoiceEmail",
        BusinessProfileData?.invoices_email
          ? BusinessProfileData?.invoices_email
          : ""
      )
      handleOnChange(
        "blurb",
        BusinessProfileData?.blrub ? BusinessProfileData?.blrub : ""
      )
    }
  }, [BusinessProfileData])

  const validationStateSchema = {
    companyName: {
      required: true
    },
    companyWebsite: {
      required: true
    },
    AssociatedEmail: {
      required: true,
      validator: validator.email
    },
    poc: {
      required: true
    },
    invoiceEmail: {
      required: true
    },
    blurb: {
      required: true
      // validator: validator.url
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )
  const [image, setImage] = useState("")

  const handleSaveChange = async () => {
    const userId = await localStorage.getItem("profileid")

    patchBusinessUserFailure(false)
    if (isChange) {
      const data = {
        id: userId,
        data: {
          company_name: state.companyName.value,
          associated_email: state.AssociatedEmail.value,
          invoices_email: state.invoiceEmail.value,
          website: state.companyWebsite.value,
          poc: state.poc.value,
          blrub: state.blurb.value,
          logo: selectedPic
        }
      }
      patchBusinessUser(data)
    } else {
      const data = {
        id: userId,
        data: {
          company_name: state.companyName.value,
          associated_email: state.AssociatedEmail.value,
          invoices_email: state.invoiceEmail.value,
          website: state.companyWebsite.value,
          poc: state.poc.value,
          blrub: state.blurb.value
        }
      }
      patchBusinessUser(data)
    }
  }

  return (
    <>
      <Toaster position="top-center" />
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <CardTitle
                tag="h4"
                style={{
                  fontFamily: "Libre Caslon Text",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#CACACA"
                }}
              >
                Edit Profile
              </CardTitle>
              {/* <div style={{ width: '200px' }}> */}

              {/* </div> */}
            </div>
            <Card
              className="card-plain"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardBody>
                <Toaster position="top-center" />

                <>
                  <div style={{ margin: "20px" }}>
                    <Row>
                      <Col
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            // background:"red",
                            // height:"192px",width:"182px",
                            borderRadius: "50%"
                          }}
                        >
                          {/* <BuninessImageUpload setselectedFile={setselectedFile} /> */}
                          <PictureUpload
                            setselectedFile={setselectedPic}
                            avatar={
                              BusinessProfileData?.logo
                                ? BusinessProfileData?.logo
                                : Images.dummy_avatar
                            }
                            setIsChange={setIsChange}
                          />
                          {/* <ImageInput
                            imageData={image.photo?.src}
                            defaultPic={Images.default_picture}
                            type="admin"
                            name="photo"
                            label="Add Photo"
                            showPreview
                            onChange={files => {
                              setImage(files, "admin")
                              setIsChange(true)
                            }}
                          /> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3"></Col>
                      <Col lg="6">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "17px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Company Name
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder={"Type name"}
                            type="text"
                            value={`${state.companyName.value}`}
                            onChange={e => {
                              handleOnChange("companyName", e.target.value)
                              patchBusinessUserFailure(false)
                            }}
                            style={{ height: "55px" }}
                          />
                        </FormGroup>
                        {state.companyName.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.companyName.error}
                          </label>
                        )}
                        {BackendError.company_name && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError.company_name}
                          </label>
                        )}

                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "7px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Company Website
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="Enter company url"
                            type="url"
                            value={`${state.companyWebsite.value}`}
                            onChange={e => {
                              patchBusinessUserFailure(false)
                              handleOnChange("companyWebsite", e.target.value)
                            }}
                            style={{ height: "55px" }}
                          />
                        </FormGroup>
                        {/* {state.companyWebsite.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.companyWebsite.error}
                          </label>
                        )} */}

                        {BackendError.website && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError.website}
                          </label>
                        )}

                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Associated Email
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="Enter Your Email"
                            type="email"
                            value={`${state.AssociatedEmail.value}`}
                            onChange={e => {
                              handleOnChange("AssociatedEmail", e.target.value)
                              patchBusinessUserFailure(false)
                            }}
                            style={{ height: "55px" }}
                          />
                        </FormGroup>
                        {state.AssociatedEmail.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.AssociatedEmail.error}
                          </label>
                        )}
                        {BackendError.associated_email && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError.associated_email}
                          </label>
                        )}

                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Point of Contact
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="POC"
                            type="url"
                            value={`${state.poc.value}`}
                            onChange={e =>
                              handleOnChange("poc", e.target.value)
                            }
                            style={{ height: "55px" }}
                          />
                        </FormGroup>
                        {/* {state.poc.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.poc.error}
                          </label>
                        )} */}

                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Email for Invoice
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="Enter your email"
                            type="url"
                            value={`${state.invoiceEmail.value}`}
                            onChange={e => {
                              handleOnChange("invoiceEmail", e.target.value)
                              patchBusinessUserFailure(false)
                            }}
                            style={{ height: "55px" }}
                          />
                        </FormGroup>
                        {/* {state.invoiceEmail.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.invoiceEmail.error}
                          </label>
                        )} */}
                        {BackendError.invoices_email && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError.invoices_email}
                          </label>
                        )}

                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontFamily: "Khula",
                            fontWeight: "600"
                          }}
                          sm="6"
                        >
                          Company Information
                        </Label>
                        <FormGroup className={{}}>
                          <Input
                            placeholder="Enter company information"
                            type="text"
                            value={`${state.blurb.value}`}
                            onChange={e =>
                              handleOnChange("blurb", e.target.value)
                            }
                            style={{ height: "55px" }}
                          />
                        </FormGroup>
                        {state.blurb.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {state.blurb.error}
                          </label>
                        )}
                      </Col>
                    </Row>

                    <CardFooter>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          paddingTop: "30px",
                          // marginLeft: 10,
                          // marginRight: 10,
                          justifyContent: "center"
                        }}
                      >
                        <div style={{ marginRight: "20px" }}>
                          <Button
                            // onClick={() => history.goBack()}
                            className=" text-capitalize "
                            style={{
                              border: "2px #3A0F7D solid",
                              // padding: '15px 65px 15px 65px',
                              height: "49px",
                              backgroundColor: "white",
                              borderRadius: 30,
                              color: "#3A0F7D"
                            }}
                            onClick={() => {
                              patchBusinessUserFailure(false)
                              history.push("/business/business_profile")
                            }}
                          >
                            Cancel
                          </Button>
                          {/* <p
                              className="font-weight-bolder px-4 py-2"
                              style={{
                                backgroundColor: "#FFFFF",
                                // padding: "15px 30px 15px 30px",
                                borderRadius: "30px",
                                color: "#3A0F7D",
                                fontWeight: "700",
                                fontSize: "16px",
                                border: "2px solid #3A0F7D",
                                boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                                textAlign: "center",
                                margin: 0
                              }}
                            >
                              Cancel
                            </p> */}
                        </div>
                        <div
                        // style={{width:'209px', height: '54px'}}
                        //   onClick={handleOnSubmit}
                        >
                          <Button
                            onClick={handleSaveChange}
                            className="btn-round text-capitalize"
                            color="secondary"
                            style={{
                              width: "134px",
                              height: "49px",
                              backgroundColor: "#3A0F7D",
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                            }}
                          >
                            {requesting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save Changes"
                            )}
                            {/* Save Changes */}
                          </Button>
                          {/* <p
                            className="font-weight-bolder px-4 py-2"
                            style={{
                              backgroundColor: "#3A0F7D",
                              // padding: "15px 30px 15px 30px",
                              borderRadius: "50px",
                              color: "white",
                              cursor: "pointer",
                              fontSize: "16px",
                              boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.25)",
                              textAlign: "center",
                              margin: 0
                            }}
                            onClick={handleOnSubmit}
                          >
                            Save Changes
                          </p> */}
                        </div>
                      </div>
                      {/* <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        style={{ backgroundColor: '#3A0F7D', width: '40%', marginLeft: '20px', marginRight: '50px', TextDecoration: 'none' }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Sign Up
                      </Button>
                    </Link>
                    <Link to="/auth/register">
                      <Button
                        className="btn-round"
                        outline
                        style={{ color: '#3A0F7D', borderColor: '#3A0F7D', width: '40%', }}
                        onClick={(e) => e.preventDefault()}
                        href="/auth/login"
                      >
                        Log In
                      </Button>
                    </Link> */}
                    </CardFooter>
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  patchdata: state.BusinessProfile.patchProfiledata,
  requesting: state.BusinessProfile.requesting,
  BackendError: state.BusinessProfile.error,
  BusinessProfileData: state.BusinessProfile.BusinessProfileData
  // industries: state.Profile.industries,
  // data: state.HomeInitialProfileV2.data
})

const mapDispatchToProps = dispatch => ({
  patchBusinessUser: data => dispatch(patchBusinessUser(data)),
  getBusinessUser: () => dispatch(getBusinessUser()),
  patchBusinessUserFailure: data => dispatch(patchBusinessUserFailure(data))
  // getProfileV2Request: () => dispatch(getProfileV2Request()),
  // getIndustryExperience: () => dispatch(getIndustryExperience()),
  // postAdditionalExperties: (data, toggle) =>
  //   dispatch(postAdditionalExperties(data, toggle))
})
export default connect(mapStateToProps, mapDispatchToProps)(BusinessEditProfile)
