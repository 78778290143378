import React, { useState, useEffect } from "react"
import { format } from "date-fns"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Row,
  Col,
  Card,
  Table,
  Label,
  Modal,
  Form,
  Input,
  Button,
  CardBody,
  Spinner,
  CardTitle,
  CardHeader,
  FormGroup,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Dropdown
} from "reactstrap"

import Switch from "react-bootstrap-switch"
import Images from "utils/Images"

import "./style.scss"
import useForm from "utils/useForm"
import moment from "moment"

import PaginationPack from "components/PaginationPack"

import ReactTable from "components/ReactTable/ReactTable.js"

import { getEngagementHistory } from "../../Engagement/redux/actions"
import { getEngagementRequest } from "../../../../TimeTracker/redux/actions"
import {
  logOurRequest,
  exportCSVRequest,
  pageRequest,
  filterRequest,
  sortRequest,
  filterClientsRequest,
  filterEngagementRequest
} from "../../LogHour/redux/actions"
import { connect } from "react-redux"
import Select from "react-select"
import { Drawer } from "@mui/material"
import ReadMore from "components/ReadMore"

function ExpertEngagementHistory(props) {
  const {
    logOurRequest,
    logHourData,
    exportCSVRequest,
    requesting,
    csvrequesting,
    getEngagementHistory,
    getEngagementRequest,
    engagementData,
    filteredClients,
    filterClientsRequest,
    filterEngagementRequest,
    filteredEngagements,
    sortRequesting
  } = props

  const [date, setDate] = useState("")
  const [EndDate, setendDate] = useState("")
  const [engagement, setEngagement] = useState("")
  const [client, setClient] = useState("")
  const [minute, setMinute] = useState("")
  const [image, setImage] = useState(false)
  const [total, setTotal] = useState([])
  const [dropDpown1, setDropDown1] = useState(false)
  const [dropDpown2, setDropDown2] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    getEngagementRequest()
    filterClientsRequest()
    filterEngagementRequest()
  }, [])

  const [newTableData, setNewTableData] = useState([])
  useEffect(() => {
    setTableData(
      newTableData.map((prop, key) => {
        return {
          id: key,
          name: prop[0],
          position: prop[1],
          office: prop[2],
          age: prop[3],
          actions: prop[4]
        }
      })
    )
  }, [newTableData])

  const [isAnyFilter, setAnyFilter] = useState(false)
  const [show, setShow] = useState()
  const [show3, setShow3] = useState()
  const [show4, setShow4] = useState()
  const [show5, setShow5] = useState()
  const [hour, setHour] = useState({ value: "0" })
  const [minutes, setMinutes] = useState({ value: 0, label: "0" })

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => setShow(!show)
  const toggle3 = () => setShow3(!show3)
  const toggle4 = () => setShow4(!show4)

  const toggle5 = () => setShow5(!show5)

  const stateSchema = {
    hour: {
      value: "",
      error: ""
    },
    minutes: {
      value: "",
      error: ""
    },
    date: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    hour: {
      required: true
      // validator: validator.email
    },
    minute: {
      required: true
      // validator: validator.email
    },
    date: {
      required: true
      // validator: validator.email
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    const data = {
      start_date: "",
      end_date: "",
      client: "",
      engagement: "",
      hour: "",
      minute: ""
    }
    logOurRequest(data)
  }, [])

  useEffect(() => {
    let tempArray = []
    if (logHourData && logHourData.results.length) {
      logHourData.results.map(item => {
        let arr = []
        arr[0] = item.user_engagement?.description
        arr[1] = item?.company

        arr[2] = <ReadMore length={100} item={item} text={item.description} />
        arr[3] = `${item?.hour} hour ${item?.minute} minutes`
        arr[4] = item?.date ? moment(item.date).format("MM-DD-YYYY") : "N/A"

        tempArray.push(arr)
      })
    }

    setNewTableData(tempArray)
  }, [logHourData])

  const handlePageChange = page => {
    props.pageRequest(page)
  }

  const onFilterSuccess = () => {
    setShow(true)
    setShow3(true)
    setShow4(true)
    setShow5(false)
  }

  const handleDateClear = () => {
    setDate("")
    setendDate("")
    setHour("")
    setMinutes("")
    setEngagement(false)
    setClient(false)
    setImage(!image)
    setDropDown1(false)

    setShow(false)
    setShow3(false)
    setShow4(false)
    setShow5(false)
    setState(stateSchema)

    // setTimeout(() => {
    if (isAnyFilter) {
      const data = {
        start_date: "",
        end_date: "",
        client: "",
        engagement: "",
        hour: "",
        minute: ""
      }
      logOurRequest(data)
      setImage(!image)
      setDropDown1(false)
      setDropDown2(false)
    } else {
      setImage(!image)
      setDropDown1(false)
      setDropDown2(false)
    }
  }

  const handleFilterConfirm = () => {
    if (isAnyFilter) {
      const data = {
        start_date: date ? date : "",
        end_date: EndDate ? EndDate : "",
        client: client ? client?.value : "",
        engagement: engagement ? engagement?.value : "",
        hour: hour.value ? hour.value : "",
        minute: minutes.value ? minutes.value : ""
      }
      logOurRequest(data)
      setImage(!image)
      setDropDown1(false)
      setDropDown2(false)
    } else {
      setImage(!image)
      setDropDown1(false)
      setDropDown2(false)
    }
  }

  const handleExportCSV = () => {
    exportCSVRequest()
  }

  const handleMinutesChange = ({ target: { value } }) =>
    setMinutes(
      state =>
        (value.length <= 2 &&
          value <= 60 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )

  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state =>
        (value.length <= 2 &&
          value <= 24 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader className={{ padding: 0 }}>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Col lg="6" md="6" sm="6">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px"
                      }}
                    >
                      <img
                        onClick={() => props.history.goBack()}
                        style={{
                          height: "40px",
                          width: "40px",
                          marginRight: "20px",
                          cursor: "pointer"
                        }}
                        src={Images.back_button}
                      />
                      <CardTitle
                        tag="h4"
                        style={{
                          fontFamily: "Libre Caslon Text",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "22px",
                          lineHeight: "27px",
                          color: "#CACACA"
                        }}
                      >
                        Engagement History
                      </CardTitle>
                    </div>
                  </Col>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      margin: "0px"
                    }}
                  >
                    <Button
                      onClick={() => handleExportCSV()}
                      className="btn-round btn px-2 py-3 text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        width: "220px",
                        height: "42px",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px"
                      }}
                    >
                      {csvrequesting ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          <img
                            style={{
                              height: 13,
                              marginRight: "13px",
                              borderRadius: "0px"
                            }}
                            src={require("assets/img/LogLight.png")}
                          />
                          Export Excel, CSV
                        </>
                      )}
                    </Button>

                    <Button
                      onClick={() => setOpen(true)}
                      className="btn-round btn  text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        width: "109px",
                        height: "42px",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px"
                      }}
                    >
                      <img
                        style={{
                          height: "12px",
                          width: "14px",
                          borderRadius: "0px",
                          // marginLeft: "20px",
                          marginRight: "7px",
                          marginTop: "-3px"
                        }}
                        src={require("assets/img/filtericon.png")}
                      />
                      Filter
                    </Button>
                  </Row>
                </Row>
              </CardHeader>
              <CardBody>
                <div style={{}}>
                  <Table
                    // borderless
                    responsive="md"
                    style={{
                      border: "3px solid transparent"

                      // marginTop: "-80px"
                    }}
                  >
                    {requesting ? (
                      <div
                        style={{
                          height: " 200px",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : logHourData && logHourData?.results.length > 0 ? (
                      <ReactTable
                        data={tableData}
                        columns={[
                          {
                            Header: "Engagements",
                            accessor: "name"
                          },
                          {
                            Header: "Client",
                            accessor: "position"
                          },
                          {
                            Header: "Discription",
                            accessor: "office",
                            sortable: false,
                            filterable: false
                          },
                          {
                            Header: "Time",
                            accessor: "age",
                            sortable: false,
                            filterable: false
                          },
                          {
                            Header: "Date",
                            accessor: "actions"
                          }
                        ]}
                        className="-striped -highlight primary-pagination"
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </Table>
                </div>
                <div className="pt-4 d-flex justify-content-center">
                  {logHourData && logHourData?.results.length > 0 ? (
                    requesting ? null : (
                      // <div className="pt-4 d-flex justify-content-center">
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={logHourData?.page_size}
                        totalItemsCount={logHourData?.total_objects}
                        activePage={logHourData?.current_page_number}
                      />
                      // </div>
                    )
                  ) : null}
                </div>
                {/* {logHourData &&
                !logHourData?.results.length ? null : requesting ? null : (
                  <div className="pt-4 d-flex justify-content-center">
                    <PaginationPack
                      onChange={handlePageChange}
                      itemsCountPerPage={logHourData?.page_size}
                      totalItemsCount={logHourData?.total_objects}
                      activePage={logHourData?.current_page_number}
                    />
                  </div>
                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 30 }}>
        <Drawer
          classes="mt-3"
          variant="temporary"
          // anchor="right"

          // variant="right"
          open={open}
          anchor={"right"}
          onClose={() => {
            setOpen(false)
            // handleDateClear()
          }}
          style={{ marginTop: 20 }}
        >
          <div className=" " style={{ borderRadius: "0px", width: 275 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "20px"
              }}
            >
              <h5
                style={{
                  color: "#CACACA",
                  fontSize: "22px",
                  fountFimly: "Libre Caslon Text",
                  fontWeight: "700",
                  marginRight: "10px"
                }}
              >
                Filter
              </h5>
              <img
                onClick={() => {
                  setOpen(false)
                  // handleDateClear()
                }}
                style={{
                  height: 15,
                  width: 15,
                  marginTop: "-10px",
                  marginLeft: "20px",
                  cursor: "pointer"
                }}
                src={require("assets/img/cros.png")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "80vh"
              }}
            >
              <div style={{}}>
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    paddingBottom: 10
                  }}
                  className="my-2"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Label
                      style={{
                        fontSize: 15,
                        fontFamily: "khula",
                        margin: 0
                      }}
                    >
                      Date
                    </Label>

                    <img
                      style={{
                        height: 15,
                        width: 15,

                        marginLeft: "20px",
                        cursor: "pointer"
                      }}
                      onClick={toggle2}
                      src={show == true ? Images.mines : Images.plus}
                    />
                  </div>

                  {show && (
                    <>
                      <Form>
                        <FormGroup>
                          <Label
                            style={{
                              color: "#808080",
                              fontSize: 14,
                              fontFamily: "khula",
                              fontWeight: "600",
                              marginTop: 14
                            }}
                          >
                            From
                          </Label>

                          <Input
                            placeholder="Expert Name goes here.."
                            type="date"
                            value={date}
                            style={{ color: "#000000" }}
                            onChange={e => {
                              setDate(e.target.value)
                              setAnyFilter(true)
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label
                            style={{
                              color: "#808080",
                              fontSize: 14,
                              fontFamily: "khula",
                              fontWeight: "600",
                              marginTop: 10
                            }}
                          >
                            To
                          </Label>
                          <Input
                            id="exampleText"
                            name="text"
                            type="date"
                            value={EndDate}
                            onChange={e => {
                              setendDate(e.target.value)
                              setAnyFilter(true)
                            }}
                          />
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    paddingBottom: 10
                  }}
                  className="my-2"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Label
                      style={{
                        fontSize: 15,
                        fontFamily: "khula",
                        margin: 0
                      }}
                    >
                      Time
                    </Label>

                    <img
                      style={{
                        height: 15,
                        width: 15,
                        marginLeft: "20px",
                        cursor: "pointer"
                      }}
                      onClick={toggle3}
                      src={show3 == true ? Images.mines : Images.plus}
                    />
                  </div>

                  {show3 && (
                    <>
                      <Form
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <FormGroup
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Input
                            placeholder="00"
                            type="number"
                            min="0"
                            onChange={e => {
                              handleHourChange(e)
                              setAnyFilter(true)
                              //handleHour(e)
                              //setHour(e.target.value)
                            }}
                            value={hour.value}
                            style={{
                              color: "#000000",
                              width: 60,
                              height: 48,
                              border: "1px solid #808080",
                              fontSize: "15px"
                            }}
                          />
                          <Label
                            style={{
                              color: "#808080",
                              fontSize: 14,
                              fontFamily: "khula",
                              fontWeight: "600",
                              marginLeft: 10,
                              marginBottom: 0
                            }}
                          >
                            /Hour
                          </Label>
                        </FormGroup>
                        <FormGroup
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Input
                            style={{
                              color: "#000000",
                              width: 60,
                              height: 48,
                              border: "1px solid #808080",
                              fontSize: "15px"
                            }}
                            placeholder="00"
                            type="number"
                            min="0"
                            autoComplete="off"
                            value={minutes.value}
                            onChange={e => {
                              //setMinute(e.target.value)
                              handleMinutesChange(e)
                              setAnyFilter(true)
                              //handelMinutes(e)
                            }}
                            // onChange={e => setMinutes(e.target.value)}
                          />
                          <Label
                            style={{
                              color: "#808080",
                              fontSize: 14,
                              fontFamily: "khula",
                              fontWeight: "600",
                              marginLeft: 10,
                              marginBottom: 0
                            }}
                          >
                            /Minute
                          </Label>

                          {/* <Input
                        placeholder="Expert Name goes here.."
                        type="date"
                        style={{ color: "#000000" }}
                      /> */}
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    paddingBottom: 10
                  }}
                  className="my-2"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Label
                      style={{
                        fontSize: 15,
                        fontFamily: "khula",
                        margin: 0
                      }}
                    >
                      Engagement
                    </Label>

                    <img
                      // onClick={toggle}
                      style={{
                        height: 15,
                        width: 15,

                        marginLeft: "20px",
                        cursor: "pointer"
                        // marginRight: "12px"
                      }}
                      onClick={toggle5}
                      src={show5 == true ? Images.mines : Images.plus}
                    />
                  </div>

                  {show5 && (
                    <>
                      <Form>
                        <FormGroup>
                          <Label
                            style={{
                              color: "#808080",
                              fontSize: 14,
                              fontFamily: "khula",
                              fontWeight: "600",
                              marginTop: 14
                            }}
                          >
                            Engagement Name
                          </Label>

                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            isSearchable={false}
                            value={engagement}
                            onChange={value => {
                              setEngagement(value)
                              setAnyFilter(true)
                            }}
                            options={filteredEngagements && filteredEngagements}
                            placeholder="-Select from below"
                          />
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    paddingBottom: 10
                  }}
                  className="my-2"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Label
                      style={{
                        fontSize: 15,
                        fontFamily: "khula",
                        margin: 0
                      }}
                    >
                      Client
                    </Label>

                    <img
                      // onClick={toggle}
                      style={{
                        height: 15,
                        width: 15,

                        marginLeft: "20px",
                        cursor: "pointer"
                        // marginRight: "12px"
                      }}
                      onClick={toggle4}
                      src={show4 == true ? Images.mines : Images.plus}
                    />
                  </div>

                  {show4 && (
                    <>
                      <Form>
                        <FormGroup>
                          <Label
                            style={{
                              fontFamily: "khula",
                              marginTop: 14,
                              color: "rgb(128, 128, 128)",
                              fontSize: "14px",
                              fontWeight: "600"
                            }}
                          >
                            Client Name
                          </Label>

                          {/* <FormGroup> */}
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            isSearchable={false}
                            value={client}
                            onChange={value => {
                              setClient(value)
                              setAnyFilter(true)
                            }}
                            options={filteredClients && filteredClients}
                            plxaceholder="-Select from below"
                          />
                          {/* </FormGroup> */}
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#fff"
                }}
              >
                <div
                  className="mx-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    //  position:"absolute",

                    paddingBottom: 20
                  }}
                >
                  {}
                  <Button
                    onClick={() => {
                      handleDateClear()
                      setOpen(false)
                    }}
                    className="btn-round btn text-capitalize
              "
                    style={{
                      backgroundColor: "#fff",
                      color: "#3A0F7D",
                      //   border: "1px solid  #3A0F7D",
                      marginRight: "11px",
                      //   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                      display: "flex",

                      alignItems: "center",
                      marginLeft: "0px",
                      marginRight: "0px"
                    }}
                  >
                    <img
                      onClick={toggle}
                      style={{
                        height: 10,
                        width: 10,

                        marginRight: "5px"
                      }}
                      src={require("assets/img/cross.png")}
                    />
                    Clear
                  </Button>

                  <Button
                    onClick={() => {
                      handleFilterConfirm()
                      // onFilterSuccess()
                      setOpen(false)
                    }}
                    className="btn-round text-capitalize
              "
                    // color="secondary"
                    style={{
                      backgroundColor: "#ffff",
                      border: "1px solid #3A0F7D",
                      color: "#3A0F7D",
                      //   width: "154px",
                      //   height: "49px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                    }}
                  >
                    {requesting ? <Spinner size="sm" /> : "Apply"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.LogHour.requesting,
  logHourData: state.LogHour.logourData,
  pagination: state.LogHour.pagination,
  csvrequesting: state.LogHour.csvrequesting,
  engagementData: state.TimeTracker.engagementData,
  filteredClients: state.LogHour.filteredClients,
  filteredEngagements: state.LogHour.filteredEngagements,
  sortRequesting: state.LogHour.sortRequesting
})

const mapDispatchToProps = dispatch => ({
  logOurRequest: data => dispatch(logOurRequest(data)),
  pageRequest: data => dispatch(pageRequest(data)),
  exportCSVRequest: () => dispatch(exportCSVRequest()),
  getEngagementHistory: data => dispatch(getEngagementHistory(data)),
  filterRequest: data => dispatch(filterRequest(data)),
  sortRequest: data => dispatch(sortRequest(data)),
  getEngagementRequest: data => dispatch(getEngagementRequest(data)),
  filterClientsRequest: data => dispatch(filterClientsRequest(data)),
  filterEngagementRequest: data => dispatch(filterEngagementRequest(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertEngagementHistory)
