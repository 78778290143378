import {
  GET_ENGAGEMENT_REQUEST_REQUEST,
  GET_ENGAGEMENT_REQUEST_SUCCESS,
  GET_ENGAGEMENT_REQUEST_FALUIRE,
  PATCH_ENGAGEMENT_REQUEST,
  PATCH_ENGAGEMENT_SUCCESS,
  PATCH_ENGAGEMENT_FALUIRE
} from "./types"
const initialState = {
  allEngagements: false,
  requesting: false,
  error: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ENGAGEMENT_REQUEST_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ENGAGEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        allEngagements: action.data
      }

    case GET_ENGAGEMENT_REQUEST_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case PATCH_ENGAGEMENT_REQUEST:
      return {
        ...state
        // requesting: true
      }

    case PATCH_ENGAGEMENT_SUCCESS:
      return {
        ...state
        // requesting: false,
        // allEngagements: action.data
      }

    case PATCH_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        // requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
