import {
  GET_RESOURCES_REQUEST,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FALUIRE
} from "./types"

const initialState = {
  resourceData: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCES_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_RESOURCES_SUCCESS:
      return {
        ...state,
        requesting: false,
        resourceData: action.data
      }

    case GET_RESOURCES_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
