import React, { useEffect, useState } from "react"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'
import Images from "utils/Images"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner
} from "reactstrap"
import Select from "react-select"

import useForm from "utils/useForm"
import validator from "utils/validation"
import {
  myTeamRequestEngageRequest,
  myTeamRequestEngageFaluire
} from "./redux/actions"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"

function MyTeam(props) {
  const {
    myTeamRequestEngageRequest,
    myTeamRequestEngageFaluire,
    BackendError,
    requesting,
    RequestEngagement
  } = props

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const [image, setImage] = useState(false)
  const [commitment, setCommitment] = useState({ value: "0" })
  const [isSubmit, setIsSubmit] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => {
    setIsSubmit(false)
    setModal2(!modal2)
    myTeamRequestEngageFaluire(false)
    setState(stateSchema)
  }
  const toggle3 = () => setModal3(!modal3)
  const toggle4 = () => setModal4(!modal4)

  useEffect(() => {
    RequestEngagement && toggle2()
  }, [RequestEngagement])

  const dummyData = [
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    },
    {
      Name: "Expert Name goes here..",
      Country: "470",
      City: "47/hr",
      Salary: "29 Jul - 30 Oct",
      Actions: "30 Oct"
    }
  ]

  const stateSchema = {
    search: {
      value: "",
      error: ""
    },
    from: {
      value: "",
      error: ""
    },
    to: {
      value: "",
      error: ""
    },
    ratingDescription: {
      value: "",
      error: ""
    },
    nameOfExpert: {
      value: "",
      error: ""
    },
    Engagement: {
      value: "",
      error: ""
    },
    amountOfHours: {
      value: "",
      error: ""
    },
    requestHoursDescription: {
      value: "",
      error: ""
    },
    areaOfExpertise: {
      value: "",
      error: ""
    },
    digitalMarketing: {
      value: "",
      error: ""
    },
    additionalInformation: {
      value: "",
      error: ""
    },
    weeklyCommitment: {
      value: 0,
      error: ""
    },
    scheduleLink: {
      value: "",
      error: ""
    },
    endEngagementDescription: {
      value: "",
      error: ""
    },
    searchExpert: {
      value: "",
      error: ""
    },
    engagementHourDescription: {
      value: "",
      error: ""
    },
    hourLimit: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    search: {
      required: true
      // validator: validator.email
    },
    from: {
      required: true
      // validator: validator.email
    },
    to: {
      required: true
      // validator: validator.email
    },
    ratingDescription: {
      required: true
      // validator: validator.email
    },

    nameOfExpert: {
      required: true
      // validator: validator.email
    },
    Engagement: {
      required: true
      // validator: validator.email
    },
    amountOfHours: {
      required: true
      // validator: validator.email
    },
    requestHoursDescription: {
      required: true
      // validator: validator.email
    },
    areaOfExpertise: {
      required: true
      // validator: validator.email
    },
    digitalMarketing: {
      required: true
      // validator: validator.email
    },
    additionalInformation: {
      required: true
      // validator: validator.email
    },
    weeklyCommitment: {
      required: true
      // validator: validator.email
    },
    scheduleLink: {
      required: true
      // validator: validator.email
    },
    endEngagementDescription: {
      required: true
      // validator: validator.email
    },
    searchExpert: {
      required: true
      // validator: validator.email
    },
    engagementHourDescription: {
      required: true
      // validator: validator.email
    },
    hourLimit: {
      required: true
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  // on filter Data

  const handleHour = e => {
    handleHourChange(e)
  }

  const handleHourChange = ({ target: { value } }) =>
    setCommitment(
      state =>
        (value.length <= 2 &&
          value <= 24 &&
          value >= 0 &&
          !isNaN(Number(value)) && { value }) ||
        state
    )

  const handleOnFilter = () => {
    if (state.search.value && state.from.value && state.to.value) {
    } else {
      toast.error("All fields Require")
    }
  }
  // On Expert Rating Modal
  const handleOnExpertRating = () => {
    if (state.ratingDescription.value) {
    } else {
      toast.error("Rating Description field is Require")
    }
  }

  // On Request Hours Modal
  const handleOnRequestHours = () => {
    if (
      state.nameOfExpert.value &&
      state.Engagement.value &&
      state.amountOfHours.value &&
      state.requestHoursDescription.value
    ) {
    } else {
      toast.error("All field are Require")
    }
  }

  // On Request Engagement Modal
  const handleOnRequestEngagement = async () => {
    const userId = localStorage.getItem("user")

    // if (
    //   state.areaOfExpertise.value &&
    //   state.digitalMarketing.value &&
    //   state.additionalInformation.value &&
    //   state.weeklyCommitment.value
    // ) {
    const data = {
      expertise_area: state.areaOfExpertise.value,
      digital_marketing: state.digitalMarketing.value,
      additional_info: state.additionalInformation.value,
      commitment: state.weeklyCommitment.value,
      user: userId
    }
    myTeamRequestEngageRequest(data)
    // if(!BackendError )
    // }
    setIsSubmit(true)
  }

  // On End Engagement
  const handleOnEndEngagement = () => {
    if (state.scheduleLink.value && state.endEngagementDescription.value) {
    } else {
      toast.error("All field are Require")
    }
  }

  // On Manage Engagement Hour
  const handleOnManageEngagementHour = () => {
    if (
      state.searchExpert.value &&
      state.engagementHourDescription.value &&
      state.hourLimit.value
    ) {
    } else {
      toast.error("All field are Require")
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "0px"
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    My Team
                  </CardTitle>

                  <div
                    className="header"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      marginTop: "-10px"
                    }}
                  >
                    <UncontrolledDropdown>
                      <Toaster position="top-center" />
                      <DropdownToggle
                        aria-haspopup={true}
                        // caret
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                      >
                        <Button
                          onClick={() => {
                            setImage(!image)
                          }}
                          className="btn-round btn"
                          style={{
                            backgroundColor: "#fff",
                            color: "#3A0F7D",
                            border: "1px solid  #3A0F7D",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <img
                            style={{
                              height: "11px",
                              width: "12px",
                              // marginLeft: "20px",
                              marginRight: "4px"
                            }}
                            src={Images.filtericon}
                          />
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                      >
                        <div style={{}} className="m-md-3 m-2">
                          <InputGroup
                            className="no-border"
                            style={{
                              border: "1px solid #DDDDDD",
                              borderRadius: "5px"
                            }}
                          >
                            <Input
                              defaultValue=""
                              placeholder="Search..."
                              type="text"
                              value={state.search.value}
                              onChange={e =>
                                handleOnChange("search", e.target.value)
                              }
                              style={{ backgroundColor: "white" }}
                              // onChange={e => handleOnChange("password", e.target.value)}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                style={{ backgroundColor: "white" }}
                              >
                                <img
                                  style={{
                                    height: "19px"

                                    // marginLeft: "20px",
                                    // marginRight: "4px",
                                    // marginTop: "-3px"
                                  }}
                                  src={require("assets/img/search.png")}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>

                          <h6
                            style={{
                              color: "#808080",
                              fontSize: "14px",
                              marginTop: "20px"
                            }}
                          >
                            Custom Time
                          </h6>
                          <div style={{ display: "flex" }}>
                            <Input
                              placeholder="-From"
                              type="date"
                              value={state.from.value}
                              onChange={e =>
                                handleOnChange("from", e.target.value)
                              }
                              style={{ maxWidth: "100px", marginRight: 20 }}
                              // onChange={e => handleOnChange("password", e.target.value)}
                            />

                            <Input
                              placeholder="-To"
                              type="date"
                              value={state.to.value}
                              onChange={e =>
                                handleOnChange("to", e.target.value)
                              }
                              style={{ maxWidth: "100px" }}

                              // onChange={e => handleOnChange("password", e.target.value)}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "20px"
                            }}
                          >
                            <Button
                              className="btn-link"
                              color="secondary"
                              style={{
                                color: "#3A0F7D",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <img
                                style={{
                                  height: 12,
                                  width: 12,
                                  // marginLeft: "20px"
                                  marginRight: "5px"
                                }}
                                src={require("assets/img/cross.png")}
                              />
                              Clear
                            </Button>
                            <img
                              style={{
                                height: 42,
                                width: 115,
                                marginLeft: "20px",
                                marginRight: "5px"
                              }}
                              onClick={handleOnFilter}
                              src={require("assets/img/apply.png")}
                            />
                          </div>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        // caret
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                      >
                        <Button
                          className="btn-round mr-md-n2"
                          // color="secondary"
                          style={{
                            backgroundColor: "#3A0F7D",
                            // width: 160,
                            // marginLeft: "-18px",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          More Action
                          <img
                            style={{
                              width: "9px",
                              marginLeft: "10px",
                              marginTop: "-3px"
                              // marginRight: "5px"
                              // backgroundColor: "#3A0F7D"
                            }}
                            src={Images.down}
                          />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                      >
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle1}
                          // onClick={e => e.preventDefault()}
                        >
                          Request Hours
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle2}
                          // onClick={e => e.preventDefault()}
                        >
                          Request Engagement
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle3}
                          // onClick={e => e.preventDefault()}
                        >
                          End Engagement
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15
                          }}
                          href="#"
                          onClick={toggle4}
                          // onClick={e => e.preventDefault()}
                        >
                          Manage Engagement Hour
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Row>
              </CardHeader>
              <CardBody style={{ marginTop: "-85px" }}>
                <Table
                  responsive="md"
                  style={{
                    borderTop: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Expert Name
                    </th>
                    <th style={{ color: "#808080" }}>Hours</th>
                    <th style={{ color: "#808080" }}>Rate</th>
                    <th
                      style={{
                        color: "#808080",
                        textAlign: "center"
                      }}
                    >
                      Time Frame
                    </th>

                    <th className="text-center" style={{ color: "#808080" }}>
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            marginLeft: "25px",
                            border: "3px solid transparent"
                          }}
                        >
                          <td
                            style={{
                              padding: "25px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px"
                            }}
                          >
                            {item.Name}
                          </td>
                          <td>{item.Country}</td>
                          <td>{item.City}</td>
                          <td
                            className="text-center"
                            style={{
                              // paddingRight: "52px",
                              paddingLeft: "10px"
                            }}
                          >
                            {item.Salary}
                          </td>
                          <td
                            className="text-center"
                            style={
                              {
                                // paddingRight: "52px",
                                // paddingTop: "14px",
                                // borderTopRightRadius: "10px",
                                // borderBottomRightRadius: "10px",
                              }
                            }
                          >
                            <UncontrolledDropdown>
                              <DropdownToggle
                                aria-haspopup={true}
                                // caret
                                color="default"
                                data-toggle="dropdown"
                                id="navbarDropdownMenuLink"
                                nav
                              >
                                <img
                                  style={{
                                    // width: "9px",
                                    height: "8px",
                                    marginLeft: "10px",
                                    height: "5px"
                                    // marginTop: "-3px"
                                    // marginRight: "5px"
                                    // backgroundColor: "#3A0F7D"
                                  }}
                                  src={Images.threedots}
                                />
                              </DropdownToggle>
                              <DropdownMenu
                                persist
                                aria-labelledby="navbarDropdownMenuLink"
                                right
                              >
                                <DropdownItem
                                  style={{
                                    color: "#3A0F7D",
                                    fontFamily: "Khula",
                                    fontSize: 15
                                  }}
                                  href="#"
                                  // onClick={toggle1}
                                  // onClick={e => e.preventDefault()}
                                >
                                  <img
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                      marginLeft: "10px",
                                      marginRight: "18px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.LogLight}
                                  />
                                  Export to CSV
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    color: "#3A0F7D",
                                    fontFamily: "Khula",
                                    fontSize: 15
                                  }}
                                  href="#"
                                  // onClick={toggle2}
                                  // onClick={e => e.preventDefault()}
                                >
                                  <img
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                      marginLeft: "10px",
                                      marginRight: "18px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.star}
                                  />
                                  Rate
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    color: "#3A0F7D",
                                    fontFamily: "Khula",
                                    fontSize: 15
                                  }}
                                  href="#"
                                  // onClick={toggle3}
                                  // onClick={e => e.preventDefault()}
                                >
                                  <img
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                      marginLeft: "10px",
                                      marginRight: "18px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.EngagementInfo}
                                  />
                                  Engagement Info
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    color: "#3A0F7D",
                                    fontFamily: "Khula",
                                    fontSize: 15
                                  }}
                                  href="#"
                                  // onClick={toggle4}
                                  // onClick={e => e.preventDefault()}
                                >
                                  <img
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                      marginLeft: "10px",
                                      marginRight: "18px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.EditProfileInfo}
                                  />
                                  Edit Profile Info
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table>

                {/* <Table
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-80px"
                  }}
                >
                  {/* <thead> 
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Expert
                    </th>
                    {/* <th style={{ color: "#808080" }}>Project Exhibits</th>
                    <th style={{ color: "#808080" }}>Expert Fullname</th>
                    <th style={{ color: "#808080" }}>Document Type</th> 

                    <th
                      className="text-right"
                      style={{ color: "#808080", paddingRight: "5%" }}
                    >
                      Action
                    </th>
                  </tr>
                  {/* </thead> 
                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            marginLeft: "25px",
                            border: "3px solid transparent"
                          }}
                        >
                          <td
                            style={{
                              padding: "25px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px"
                            }}
                          >
                            {item.Name}
                          </td>

                          <td
                            className="text-right"
                            style={{
                              paddingRight: "25px",
                              paddingTop: "14px",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px"
                            }}
                          >
                            <Button
                              className="btn-round btn"
                              style={{
                                backgroundColor: "#fff",
                                color: "#3A0F7D",
                                border: "1px solid  #3A0F7D"
                              }}
                              onClick={toggle}
                            >
                              <img
                                className="mr-lg-1"
                                style={{
                                  height: "12px",
                                  width: "12px"
                                  // marginLeft: "20px",
                                }}
                                src={require("assets/img/star.png")}
                              />
                              Rate
                            </Button>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table> */}
                <div className="pt-4 d-flex justify-content-center">
                  <PaginationPack />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Expert Rating
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert
                </Label>
                <Input
                  disable
                  defaultValue="Expert Name goes here.."
                  // placeholder="Expert Name goes here.."
                  type="text"
                  style={{
                    backgroundColor: "#EFEFEF"
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name="text"
                  type="textarea"
                  value={state.ratingDescription.value}
                  onChange={e =>
                    handleOnChange("ratingDescription", e.target.value)
                  }
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round btn "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // toggle()
                handleOnExpertRating()
              }}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Submit
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Request Hours
            </h5>
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Name of Expert
                </Label>
                <Input
                  placeholder="Type name"
                  type="text"
                  value={state.nameOfExpert.value}
                  onChange={e => handleOnChange("nameOfExpert", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Engagement
                </Label>
                <Input
                  placeholder="Type engagement"
                  type="text"
                  value={state.Engagement.value}
                  onChange={e => handleOnChange("Engagement", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Amount of Hours to Increase
                </Label>
                <Input
                  placeholder="Type engagement"
                  type="text"
                  value={state.amountOfHours.value}
                  onChange={e =>
                    handleOnChange("amountOfHours", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name="text"
                  type="textarea"
                  value={state.requestHoursDescription.value}
                  onChange={e =>
                    handleOnChange("requestHoursDescription", e.target.value)
                  }
                />
              </FormGroup>
              <div style={{}}>
                <FormGroup check>
                  <Label check style={{}}>
                    <Input defaultChecked type="checkbox" />
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup>
                <h4
                  style={{
                    marginTop: "-12px",
                    marginLeft: "34px",
                    color: "#000000",
                    fontSize: "14px",
                    fountFimly: "khula",
                    fontWeight: "400"
                  }}
                >
                  Ongoing Engagement
                </h4>
              </div>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle1}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnRequestHours}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Submit
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal2}
          toggle2={toggle2}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Request Engagement
            </h5>
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                {isSubmit ? (
                  <p style={{ color: "green" }}>
                    Thanks! Your Account Manager will contact you shortly!
                  </p>
                ) : null}

                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Area of Expertise
                </Label>
                <Input
                  placeholder="Type area of expertise"
                  type="text"
                  // state.digitalMarketing.value &&
                  // state.additionalInformation.value &&
                  // state.weeklyCommitment.value
                  value={state.areaOfExpertise.value}
                  onChange={e => {
                    handleOnChange("areaOfExpertise", e.target.value)
                    myTeamRequestEngageFaluire(false)
                  }}
                />
                {state.areaOfExpertise.error && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {state.areaOfExpertise.error}
                  </Label>
                )}
                {BackendError.expertise_area && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {BackendError.expertise_area[0]}
                  </Label>
                )}
              </FormGroup>
              <FormGroup>
                {/* <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Digital Marketing
                </Label>
                <Input
                  placeholder="Type here"
                  type="text"
                  // state.additionalInformation.value &&
                  // state.weeklyCommitment.value
                  value={state.digitalMarketing.value}
                  onChange={e => {
                    handleOnChange("digitalMarketing", e.target.value)
                    myTeamRequestEngageFaluire(false)
                  }
                  }
                /> */}
                {/* {state.digitalMarketing.error &&
                  <Label
                    style={{
                      color: "red",

                    }}
                  >
                    {state.digitalMarketing.error}
                  </Label>}
                {BackendError.digital_marketing &&
                  <Label
                    style={{
                      color: "red",

                    }}
                  >
                    {BackendError.digital_marketing[0]}
                  </Label>} */}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Weekly Commitment
                </Label>
                <Input
                  placeholder="Type here"
                  type="number"
                  min="0"
                  value={commitment?.value}
                  onChange={e => {
                    handleHour(e)
                    myTeamRequestEngageFaluire(false)
                  }}
                />
                {state.weeklyCommitment.error && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {state.weeklyCommitment.error}
                  </Label>
                )}
                {BackendError.commitment && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {BackendError.commitment[0]}
                  </Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Additional Information
                </Label>
                <Input
                  placeholder="Type here"
                  type="textarea"
                  value={state.additionalInformation.value}
                  onChange={e => {
                    handleOnChange("additionalInformation", e.target.value)
                    myTeamRequestEngageFaluire(false)
                  }}
                />
                {state.additionalInformation.error && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {state.additionalInformation.error}
                  </Label>
                )}
                {BackendError.additional_info && (
                  <Label
                    style={{
                      color: "red"
                    }}
                  >
                    {BackendError.additional_info[0]}
                  </Label>
                )}
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle2}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnRequestEngagement}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              {requesting ? <Spinner size="sm" /> : "Submit"}
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal3}
          toggle3={toggle3}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              End Engagement
            </h5>
            <img
              onClick={toggle3}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Link to Schedule a call
                </Label>
                <Input
                  placeholder="https://"
                  type="text"
                  value={state.scheduleLink.value}
                  onChange={e => handleOnChange("scheduleLink", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name="text"
                  type="textarea"
                  value={state.endEngagementDescription.value}
                  onChange={e =>
                    handleOnChange("endEngagementDescription", e.target.value)
                  }
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle3}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnEndEngagement}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Submit
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={modal4}
          // toggle4={toggle4}
          // className={className}
          // external={externalCloseBtn}
        >
          <Toaster position="top-center" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Manage Engagement Hour
            </h5>
            <img
              onClick={toggle4}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Search Expert
              </Label>
              <InputGroup
                className="no-border"
                style={{
                  border: "1px solid black",
                  borderRadius: "5px"
                }}
              >
                <Input
                  defaultValue=""
                  placeholder="Search..."
                  type="text"
                  style={{ backgroundColor: "white" }}
                  value={state.searchExpert.value}
                  onChange={e => handleOnChange("searchExpert", e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText style={{ backgroundColor: "white" }}>
                    <img
                      style={{
                        height: "19px",
                        width: "25px"
                        // marginLeft: "20px",
                        // marginRight: "4px",
                        // marginTop: "-3px"
                      }}
                      src={require("assets/img/search.png")}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Description
                </Label>
                <Input
                  id="exampleText"
                  placeholder="Type here"
                  name="text"
                  type="textarea"
                  value={state.engagementHourDescription.value}
                  onChange={e =>
                    handleOnChange("engagementHourDescription", e.target.value)
                  }
                />
              </FormGroup>
              <div style={{ display: "flex" }}>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      fontWeight: "600"
                    }}
                  >
                    Hour Limit
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="120"
                    name="text"
                    type="text"
                    value={state.hourLimit.value}
                    onChange={e => handleOnChange("hourLimit", e.target.value)}
                    style={{ color: "#000000", width: "120px" }}
                  />
                </FormGroup>
                <h5
                  style={{
                    color: "#808080",
                    marginLeft: "14px",
                    fontSize: "14px",
                    marginTop: "42px",
                    fontWeight: "600"
                  }}
                >
                  /hr
                </h5>
              </div>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle4}
              className="btn-round btn"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "11px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnManageEngagementHour}
              className="btn-round"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.MyTeam.requesting,
  BackendError: state.MyTeam.error,
  RequestEngagement: state.MyTeam.RequestEngagement
})
const mapDispatchToProps = dispatch => ({
  myTeamRequestEngageRequest: data =>
    dispatch(myTeamRequestEngageRequest(data)),
  myTeamRequestEngageFaluire: data => dispatch(myTeamRequestEngageFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(MyTeam)
