import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  ADD_CONTENT_RESOURCE,
  EDIT_CONTENT_RESOURCE,
  DELETE_CONTENT_RESOURCE
} from "./types"

// actions
import {
  addContentResourseSuccess,
  addContentResourseFaluire,
  editContentResourseSuccess,
  editContentResourseFaluire,
  deleteContentResourseSuccess,
  deleteContentResourseFaluire
} from "./actions"
import { resourcesRequest } from "Containers/ExpertDesign/Screens/ResourceArea/redux/actions"

async function editContentResourseAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/dashboard/resources/${data.id}/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.formData
  }

  return XHR(URL, options)
}

function* editContentResourseFun({ data }) {
  try {
    const response = yield call(editContentResourseAPI, data)

    yield put(editContentResourseSuccess(response.data))
    yield put(resourcesRequest({ page: 1, type: "" }))
    // yield put(getEngagementRequest(1))
    // toast.success("Engagement Request Done")
  } catch (e) {
    const { response } = e
    yield put(editContentResourseFaluire(response?.data))
  }
}

async function addContentResourseAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/resources/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* addContentResourseFun({ data }) {
  try {
    const response = yield call(addContentResourseAPI, data)

    yield put(addContentResourseSuccess(response.data))
    yield put(resourcesRequest({ page: 1, type: "" }))
  } catch (e) {
    const { response } = e

    yield put(addContentResourseFaluire(response?.data))
  }
}

async function deleteResourseAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  // const userId = await sessionStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/dashboard/resources/${data.id}/`

  const options = {
    headers: {
      Accept: "application/json",

      Authorization: `Token ${accessToken}`
    },
    method: "DELETE",
    data
  }

  return XHR(URL, options)
}

function* deleteContentResourseFun({ data }) {
  try {
    const response = yield call(deleteResourseAPI, data)
    yield put(deleteContentResourseSuccess(response))

    yield put(resourcesRequest({ page: 1, type: "" }))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(deleteContentResourseFaluire(response?.data))
  }
}

export default all([
  takeLatest(EDIT_CONTENT_RESOURCE, editContentResourseFun),
  takeLatest(ADD_CONTENT_RESOURCE, addContentResourseFun),
  takeLatest(DELETE_CONTENT_RESOURCE, deleteContentResourseFun)
])
