import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
// import storageSession from "redux-persist/lib/storage/session"

import { connectRouter } from "connected-react-router"

import ForegroundNotification from "Containers/ForegroundNotification/redux/reducer"

import Notifications from "Containers/ExpertDesign/Screens/Notifications/redux/reducer"
import LoginScreen from "Containers/LoginScreen/redux/reducer"
import RegisterScreen from "Containers/RegisterScreen/redux/reducer"
import ForgotScreen from "Containers/ForgotScreen/redux/reducer"
import Settings from "Containers/Settings/redux/reducer"
import HomeIntialProfile from "Containers/HomeIntialProfile/redux/reducer"
import HomeInitialProfileV2 from "Containers/HomeInitialProfileV2/redux/reducer"
import TimeTracker from "Containers/TimeTracker/redux/reducer"

import ExpertRefer from "Containers/ExpertDesign/Screens/ExpertRefer/redux/reducer"
import LogHour from "Containers/ExpertDesign/Screens/LogHour/redux/reducer"
import ApproveAccounts from "Containers/AdminDesign/ApproveAccounts/redux/reducer"
import Feedback from "Containers/AdminDesign/Feedback/redux/reducer"
import EditProfile from "Containers/ExpertDesign/Screens/EditProfile/redux/reducer"
import Engagement from "Containers/ExpertDesign/Screens/Engagement/redux/reducer"
import Profile from "Containers/ExpertDesign/Screens/MyProfile/redux/reducer"
import EducationalCenter from "Containers/ExpertDesign/Screens/EducationalCenter/redux/reducer"
import Resource from "Containers/ExpertDesign/Screens/ResourceArea/redux/reducer"

// Business
import RequestExpert from "Containers/BusinessDesign/RequestExpert/redux/reducer"
import BusinessHome from "Containers/BusinessDesign/BusinessHome/redux/reducer"
import MyTeam from "Containers/BusinessDesign/MyTeam/redux/reducer"
import SignedContracts from "Containers/BusinessDesign/SignedContracts/redux/reducer"
import BillingHistory from "Containers/BusinessDesign/NewMyTeam/BillingHistory/redux/reducer"
import Benefits from "Containers/BusinessDesign/Benefits/redux/reducer"
import reffer from "Containers/BusinessDesign/Refer/redux/reducer"
import BusinessLogHour from "Containers/BusinessDesign/BusinessLogHistory/redux/reducer"

// Admin
import Business from "Containers/AdminDesign/Business/redux/reducer"
import ExpertsScreen from "Containers/AdminDesign/ExpertsScreen/redux/reducer"
import AdminTimeTracking from "Containers/AdminDesign/AdminTimeTracking/redux/reducer"
import EngagementRequest from "Containers/AdminDesign/Engagements/EngagementRequest/redux/reducer"
// import AdminEngagement from "Containers/AdminDesign/Engagements/redux/reducer"
import ActiveEngagement from "Containers/AdminDesign/Engagements/ActiveEngagment/redux/reducer"
import Referrals from "Containers/AdminDesign/Referrals/redux/reducer"
// import Engagements from "Containers/AdminDesign/Engagements/redux/reducer"
import AddContent from "Containers/AdminDesign/AddContent/redux/reducer"
import PushNotification from "Containers/AdminDesign/PushNotification/redux/reducer"
//Business profile data
import BusinessProfile from "Containers/BusinessDesign/BusinessProfile/redux/reducer"

const signInPersistConfig = {
  key: "LoginScreen",
  storage,
  timeout: null
}

export const combinedReducers = history => ({
  LoginScreen: persistReducer(signInPersistConfig, LoginScreen),

  // LoginScreen,
  RegisterScreen,
  ForgotScreen,
  ForegroundNotification,
  Settings,
  HomeIntialProfile,
  HomeInitialProfileV2,
  TimeTracker,
  ExpertRefer,
  LogHour,
  ApproveAccounts,
  Feedback,
  EditProfile,
  BusinessHome,
  RequestExpert,
  Business,
  Engagement,
  ExpertsScreen,
  AdminTimeTracking,
  MyTeam,
  Notifications,
  EngagementRequest,
  // AdminEngagement,
  ActiveEngagement,
  Profile,
  EducationalCenter,
  Resource,
  BusinessProfile,
  SignedContracts,
  Referrals,
  BillingHistory,
  Benefits,
  // Engagements,
  AddContent,
  PushNotification,
  reffer,
  BusinessLogHour
})
