import React, { useState, useEffect } from "react"

import PaginationPack from "components/PaginationPack"

import { connect } from "react-redux"

import { Link } from "react-router-dom"
//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Spinner
} from "reactstrap"

import sample from "components/Pdf/sample.pdf"
import Images from "utils/Images"
import { downloadContractRequest, getContractRequest } from "./redux/actions"

function SignedContracts(props) {
  const { Contracts, requesting, DownloadData } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    props.getContractRequest()
  }, [])

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Signed Contract
                  </CardTitle>

                  {/* <Button
                    className="btn-round btn text-capitalize"
                    style={{
                      backgroundColor: "#fff",
                      color: "#3A0F7D",
                      border: "1px solid  #3A0F7D",
                      width: "220px",
                      height: "49px",
                      marginTop: "0px",
                      borderRedius: "0px"
                    }}
                  >
                    <img
                      //   onClick={toggle}
                      style={{
                        height: 13,
                        width: 13,
                        marginTop: "-4px",
                        // marginLeft: "20px"
                        marginRight: "16px",
                        borderRadius: "0px"
                      }}
                      src={Images.LogLight}
                    />
                    Expport Excel, CSV
                    {/* </Link>
                  </Button> */}
                  {/* </div> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        // padding: "25px",
                        paddingTop: "40px",
                        paddingLeft: "22px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Contracts
                    </th>
                    {/* <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Project Exhibits
                    </th>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Expert Fullname
                    </th>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Document Type
                    </th> */}

                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        paddingTop: "40px",
                        paddingRight: "55px"
                      }}
                    >
                      Action
                    </th>
                  </tr>
                  {requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: 150,
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : Contracts && Contracts.signature_requests.length > 0 ? (
                    <tbody>
                      {Contracts?.signature_requests &&
                        Contracts.signature_requests.map(item => (
                          <>
                            <tr
                              style={{
                                // border: "3px solid",
                                // borderRadius: "50px !mportant",
                                backgroundColor: "white",
                                marginLeft: "25px",
                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  padding: "25px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                  width: "50%"
                                }}
                              >
                                {item.title}
                              </td>
                              {/* <td>{item.message}</td>
                              {item.signatures &&
                                item.signatures.map(items => (
                                  <td>{items.signer_name}</td>
                                ))} */}
                              {/* <td
                                style={{
                                  paddingLeft: "12px"
                                  // backgroundColor: "red"
                                }}
                              >
                                {"pdf"}
                              </td> */}
                              <td
                                className="text-right pr-2"
                                style={{
                                  paddingTop: "14px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px"
                                }}
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "white"
                                  }}
                                  target="_blank"
                                  rel="noreferrer"
                                ></Link>
                                <Button
                                  className="btn-round btn text-capitalize
                              "
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#3A0F7D",
                                    border: "1px solid  #3A0F7D",

                                    width: "max-content"
                                  }}
                                  onClick={() =>
                                    props.downloadContractRequest(
                                      item.signature_request_id
                                    )
                                  }
                                >
                                  <img
                                    style={{
                                      height: "11px",
                                      width: "12px",
                                      marginRight: "4px",
                                      marginTop: "-3px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.LogLight}
                                  />
                                  Export to PDF
                                </Button>
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))}
                    </tbody>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(-50%, -50%)",
                        left: "50%",
                        height: 10
                      }}
                    >
                      <h6 className="m-0">No Data Found</h6>
                    </div>
                  )}
                </Table>
                {/* {Contracts && Contracts.signature_requests.length < 9 ? null : (
                  <div className="pt-4 d-flex justify-content-center">
                    <PaginationPack />
                  </div>
                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
          style={{ maxWidth: "994px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Contract Details
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Client Agreement
                </Label>
                <Input
                  defaultValue="Client agreement title..."
                  disabled
                  placeholder="Client agreement title..."
                  type="text"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Project Exhibitions
                </Label>
                <Input
                  defaultValue="Lorem ipsum dolor sit amet,"
                  disabled
                  placeholder="Lorem ipsum dolor sit amet,"
                  type="textarea"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Expert FullName
                </Label>
                <Input
                  defaultValue="Expert fullname goes here.."
                  disabled
                  id="exampleText"
                  placeholder="Expert fullname goes here.."
                  name="text"
                  type="text"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Document Type
                </Label>
                <Input
                  defaultValue="Type of document goes here..."
                  disabled
                  id="exampleText"
                  placeholder="Type of document goes here..."
                  name="text"
                  type="text"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex"
              //   justifyContent: "center",
              //   alignItems: "center"
            }}
          >
            <Button
              onClick={toggle}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={sample}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  //   onClick={toggle}
                  style={{
                    height: 13,
                    width: 13,
                    marginTop: "-4px",
                    marginRight: "16px",
                    borderRadius: "0px"
                  }}
                  src={require("assets/img/csvdark.png")}
                />
                Expport Excel, CSV
              </Link>
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.SignedContracts.requesting,
  Contracts: state.SignedContracts.Contracts,
  DownloadData: state.SignedContracts.DownloadData
})
const mapDispatchToProps = dispatch => ({
  getContractRequest: data => dispatch(getContractRequest(data)),
  downloadContractRequest: data => dispatch(downloadContractRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(SignedContracts)
// export default SignedContracts
