import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Spinner
} from "reactstrap"
import PaginationPack from "components/PaginationPack"
import { connect } from "react-redux"
import Images from "utils/Images"
import moment from "moment"
import { getEngagementHistroryRequest } from "../ActiveEngagment/redux/actions"
import { Tooltip } from "@mui/material"
import ReadMore from "components/ReadMore"

function EngagementsHistory(props) {
  const { engagementHistory, requesting } = props

  let PageSize = 10

  useEffect(() => {
    props.getEngagementHistroryRequest(1)
  }, [])

  const handlePageChange = page => {
    props.getEngagementHistroryRequest(page)
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA",
                      whiteSpace: "nowrap"
                    }}
                  >
                    Engagements History
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        padding: "25px"
                      }}
                    >
                      Experts
                    </th>
                    <th style={{ color: "#808080" }}>Company Name</th>
                    <th
                      className="text-center"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Status
                    </th>

                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Expert Rate
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Business Rate
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Rate
                    </th>
                    <th
                      className="text-right"
                      style={{
                        color: "#808080"
                      }}
                    >
                      StartDate
                    </th>
                    {/* 
                    <th
                      className="text-right"
                      style={{
                        color: "#808080",
                        paddingRight: "74px"
                      }}
                    >
                      Actions
                    </th> */}
                  </tr>
                  {/* </thead> */}

                  {requesting ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "90%",
                        // transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        style={{}}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : engagementHistory &&
                    engagementHistory?.results?.length > 0 ? (
                    <tbody>
                      {engagementHistory?.results.map(item => (
                        <>
                          <tr
                            style={{
                              // border: "3px solid",
                              // borderRadius: "50px !mportant",
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td style={{ paddingLeft: "25px" }}>
                              {item.expert_name}
                            </td>

                            <td>{item.company}</td>
                            <td className="text-center">
                              <ReadMore
                                length={100}
                                item={item}
                                text={item.description}
                              />
                              {/* {item.description} */}
                              {/* {item.description.legth > 20 ? (
                                <>
                                  <Tooltip
                                    title={
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "black",
                                          fontSize: 14,
                                          textAlign: "center",
                                         
                                        }}
                                      >
                                        {item.description}
                                      </p>
                                    }
                                    placement="top"
                                    arrow={true}
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflowX: "hidden",
                                        textOverflow: " ellipsis",
                                        maxWidth: 200,
                                        cursor: "pointer"
                                      }}
                                    >
                                      {item.description}
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                <>{item.description}</>
                              )} */}
                            </td>
                            <td className="text-center">
                              {item.end ? "InActive" : "Active"}
                            </td>
                            <td className="text-right">
                              ${item.expert_rate}/hr
                            </td>
                            <td className="text-right">
                              ${item.business_rate}/hr
                            </td>
                            <td className="text-right">
                              ${item.rate_per_bill}/hr
                            </td>
                            <td className="text-right">
                              {moment(item.agreement_start_date).format(
                                "MM-DD-YYYY"
                              )}
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))}
                    </tbody>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: 120,
                        transform: "translate(-50%, -50%)",
                        left: "50%"
                      }}
                    >
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {engagementHistory &&
                  engagementHistory?.results?.length > 0 ? (
                    requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={engagementHistory?.page_size}
                          totalItemsCount={engagementHistory?.total_objects}
                          activePage={engagementHistory?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  engagementHistory: state.ActiveEngagement.engagementHistory,
  requesting: state.ActiveEngagement.requesting
})

const mapDispatchToProps = dispatch => ({
  getEngagementHistroryRequest: data =>
    dispatch(getEngagementHistroryRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(EngagementsHistory)
