export const BUSINESS_LOG_HOUR_REQUEST = "BUSINESS_LOG_HOUR_REQUEST"
export const BUSINESS_LOG_HOUR_SUCCESS = "BUSINESS_LOG_HOUR_SUCCESS"
export const BUSINESS_LOG_HOUR_FALUIRE = "BUSINESS_LOG_HOUR_FALUIRE"

export const BUSINESS_LOG_HOUR_PAGE_REQUEST = "BUSINESS_LOG_HOUR_PAGE_REQUEST"
export const BUSINESS_LOG_HOUR_PAGE_SUCCESS = "BUSINESS_LOG_HOUR_PAGE_SUCCESS"
export const BUSINESS_LOG_HOUR_PAGE_FALUIRE = "BUSINESS_LOG_HOUR_PAGE_FALUIRE"

export const BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST =
  "BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST"
export const BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_SUCCESS =
  "BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_SUCCESS"
export const BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_FALUIRE =
  "BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_FALUIRE"
