import React, { Component, useState } from "react"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import "./index.css"

const Progressbar = props => {
  const { horizontal, setHorizontal, min, max } = props

  const handleChangeHorizontal = value => {
    setHorizontal(value)
  }
  const horizontalLabels = {
    // 0: 0,
    // 100: 40
  }
  const formatkg = value => value
  return (
    <div
      className="slider custom-labels"
      // style={{ backgroundColor: "red" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <p style={{ color: "#D0D0D0" }}>0</p>
        <p style={{ color: "#D0D0D0" }}>40</p>
      </div>
      <div style={{ marginTop: "-14px" }}>
        <Slider
          min={0}
          max={40}
          value={horizontal}
          labels={horizontalLabels}
          format={formatkg}
          tooltip={true}
          // handleLabel={horizontal}
          onChange={handleChangeHorizontal}
        />
      </div>
    </div>
  )
}

export default Progressbar
