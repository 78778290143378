import React from "react"
import classnames from "classnames"
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap"

import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg"
import { Link } from "react-router-dom"
import Images from "utils/Images"
import { connect } from "react-redux"

import { resetAllStates } from "Containers/LoginScreen/redux/actions"

import { getNotficationDataRequest } from "Containers/ExpertDesign/Screens/Notifications/redux/actions"

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      profileData: false
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor)
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open")
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      })
    } else {
      this.setState({
        color: "navbar-transparent"
      })
    }
  }

  async componentWillMount() {
    const newData = localStorage.getItem("userAllData")
    const allData = JSON.parse(newData)
    this.setState({ profileData: allData })
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open")
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    }
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white"
    } else {
      newState["color"] = "navbar-transparent"
    }
    this.setState(newState)
  }
  handleLogout = e => {
    sessionStorage.clear()
    localStorage.clear()
    this.props.resetAllStates()
    setTimeout(() => {
      this.props.history.push("/auth/welcome")
    }, 1000)
  }

  // async componentDidUpdate() {
  //   setTimeout(() => {
  //     this.props.getNotficationDataRequest()
  //   }, 30000)
  // }
  kFormatter = num => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num)
  }

  render() {
    return (
      <>
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                {/* <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button> */}
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {/* <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
                <span className="d-none d-md-block">
                  Paper Dashboard PRO React
                </span>
                <span className="d-block d-md-none">PD PRO React</span>
              </NavbarBrand> */}
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Form>
                {/* <InputGroup className="no-border" >
                  <Input defaultValue="" placeholder="Search..." type="text" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup> */}
              </Form>
              <Nav navbar>
                {/* <NavItem>
                  <NavLink
                    className="btn-magnify"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-layout-11" />
                    <p>
                      <span className="d-lg-none d-md-block">Stats</span>
                    </p>
                  </NavLink>
                </NavItem> */}
                <NavItem style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="btn-rotate"
                    href="/admin/Settings"
                    onClick={e => {
                      e.preventDefault()
                      this.props.history.push("/admin/Settings")
                    }}
                  >
                    {/* <Link to="/admin/Settings"> */}
                    <i
                      style={{ fontSize: "22px" }}
                      className="nc-icon nc-settings-gear-65"
                      // style={{ fontSize: "20px" }}
                    />
                    {/* </Link> */}
                    <p>
                      <span className="d-lg-none d-md-block">Settings</span>
                    </p>
                  </NavLink>
                </NavItem>
                <NavItem style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className=""
                    href="/admin/Notifications"
                    onClick={e => {
                      e.preventDefault()
                      this.props.history.push("/admin/Notifications")
                    }}
                  >
                    {/* <Link to="/admin/Notifications"> */}
                    <span style={{}}>
                      <i
                        className="nc-icon nc-bell-55"
                        style={{ fontSize: "22px" }}
                      />
                      {
                        <span
                          style={{
                            position: "absolute",
                            top: "0px",
                            marginLeft: "-10px"
                          }}
                        >
                          {/* <Spinner size="sm" /> */}

                          <p
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "50%",
                              padding: "3px",
                              height: 27,
                              width: 27,
                              // marginBottom: "-20px",

                              fontSize: "12px",
                              marginBottom: 0,
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            {this.props.allNotifications
                              ? this.kFormatter(
                                  this.props.allNotifications?.unread
                                )
                              : 0}
                          </p>
                        </span>
                      }
                    </span>
                    {/* </Link> */}
                    <p>
                      <span className="d-lg-none d-md-block">
                        Notifications
                      </span>
                    </p>
                  </NavLink>
                </NavItem>
                {/* <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <p>
                      <i className="nc-icon nc-bell-55" />
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else here
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <UncontrolledDropdown className="btn-rotate" nav>
                  <div className="photo"></div>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <img
                      src={
                        this.state.profileData?.user?.profile_picture
                          ? this.state.profileData?.user?.profile_picture
                          : Images.dummy_avatar
                      }
                      alt="Avatar"
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%"
                      }}
                    />
                    <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                      {this.state.profileData?.user?.name}
                    </p>
                    {/* <i className="nc-icon nc-bell-55" />  */}
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    {/* <DropdownItem
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <img
                        style={{
                          height: "13px",

                          // marginLeft: "20px",
                          marginRight: "4px"
                        }}
                        src={Images.dummy_avatar}
                      />
                      <Link to="/admin/MyProfile">Profile</Link>
                    </DropdownItem> */}

                    {/* <DropdownItem
                      href="#"
                      onClick={e => e.preventDefault()}
                    >
                      <Link to="/admin/EidtProfile">Eidt Profile</Link>
                    </DropdownItem> */}

                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        this.handleLogout(e)
                      }}
                    >
                      <img
                        style={{
                          height: "13px",

                          // marginLeft: "20px",
                          marginRight: "4px"
                        }}
                        src={Images.logout}
                      />
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <NavItem>
                  <div className='sidebar-wrapper' ref='sidebar'>
                    <div className='user'>
                      <div className='photo'>
                        <img src={avatar} alt='Avatar' style={{ height: '50px', width: '50px', }} />
                      </div>
                      <div className='info'>
                        <a
                          href='#'
                          data-toggle='collapse'
                          aria-expanded={this.state.openAvatar}
                          onClick={() =>
                            this.setState({ openAvatar: !this.state.openAvatar })
                          }
                        >
                          <span>
                            Chet Faker
                            <b className='caret' />
                          </span>
                        </a>
                        <DropdownMenu
                          persist
                          aria-labelledby="navbarDropdownMenuLink"
                          right
                        >
                          <DropdownItem
                            href="#"
                            onClick={(e) => e.preventDefault()}
                          >
                            Action
                          </DropdownItem>
                          <DropdownItem
                            href="#"
                            onClick={(e) => e.preventDefault()}
                          >
                            Another action
                          </DropdownItem>
                          <DropdownItem
                            href="#"
                            onClick={(e) => e.preventDefault()}
                          >
                            Something else here
                          </DropdownItem>
                        </DropdownMenu>
                        <Collapse isOpen={this.state.openAvatar}>
                          <ul className='nav'>
                            <li>
                              <NavLink to='/admin/user-profile' activeClassName=''>
                                <span className='sidebar-mini-icon'>MP</span>
                                <span className='sidebar-normal'>My Profile</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='/admin/user-profile' activeClassName=''>
                                <span className='sidebar-mini-icon'>EP</span>
                                <span className='sidebar-normal'>Edit Profile</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='/admin/user-profile' activeClassName=''>
                                <span className='sidebar-mini-icon'>S</span>
                                <span className='sidebar-normal'>Settings</span>
                              </NavLink>
                            </li>
                          </ul>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </NavItem> */}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

const mapStateToProps = state => ({
  allNotifications: state.Notifications.allNotifications
})

const mapDispatchToProps = dispatch => ({
  resetAllStates: () => dispatch(resetAllStates()),
  getNotficationDataRequest: () => dispatch(getNotficationDataRequest())
})
export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar)

// export default AdminNavbar
