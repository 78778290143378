import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { BENEFITS_REQUEST } from "./types"

// actions
import { benefitsSuccess, benefitsFaluire } from "./actions"

async function benefitsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/benefits/?page=${data}`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* benefitsFunction({ data }) {
  try {
    const response = yield call(benefitsAPI, data)
    yield put(benefitsSuccess(response?.data))
    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e
    yield put(benefitsFaluire(response?.data))
  }
}

export default all([takeLatest(BENEFITS_REQUEST, benefitsFunction)])
