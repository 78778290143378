import {
    HOME_PROFILE_REQUEST,
    HOME_PROFILE_SUCCESS,
    HOME_PROFILE_FALUIRE,
} from './types';
const initialState = {
    user: false,
    requesting: false,
    error: false,
    userType: false,

};
export default (state = initialState, action) => {
    switch (action.type) {
        case HOME_PROFILE_REQUEST:
            return {
                ...state,
                requesting: true,
            };

        case HOME_PROFILE_SUCCESS:
            return {
                ...state,
                requesting: false,
                user: action.data
            };

        case HOME_PROFILE_FALUIRE:
            return {
                ...state,
                requesting: false,
                error: action.data
            };
        default:
            return state;
    }
};