import {
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FALUIRE,
  TOGGLE_MODAL,
  FEEDBACK_PAGE_REQUEST,
  FEEDBACK_PAGE_SUCCESS,
  FEEDBACK_PAGE_FALUIRE
} from "./types"

const initialState = {
  allAccounts: false,
  pagination: false,
  feedbackReply: false,
  requesting: false,
  replyRequesting: false,
  visible: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_FEEDBACK_REQUEST:
      return {
        ...state,
        replyRequesting: true
      }

    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        error: false,
        replyRequesting: false,
        feedbackReply: action.data
      }

    case POST_FEEDBACK_FALUIRE:
      return {
        ...state,
        replyRequesting: false,
        error: action.data
      }

    case TOGGLE_MODAL:
      return {
        ...state,
        visible: action.data
      }

    case FEEDBACK_PAGE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case FEEDBACK_PAGE_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allAccounts: action.page,
        pagination: action.page
      }

    case FEEDBACK_PAGE_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }

    default:
      return state
  }
}
