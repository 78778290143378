import {
  GET_BILLING_REQUEST,
  GET_BILLING_SUCCESS,
  GET_BILLING_FALUIRE,
  GET_BILLING_HISTORY_CSV,
  GET_BILLING_HISTORY_CSV_FALUIRE,
  GET_BILLING_HISTORY_CSV_SUCCESS,
  GET_EXPERT_USER,
  GET_EXPERT_USER_SUCCESS,
  GET_EXPERT_USER_FALUIRE
} from "./types"

const initialState = {
  requesting: false,
  error: false,
  billingHistory: false,
  filterSuccess: false,
  csvRequesting: false,
  expertUsers: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLING_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_BILLING_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        billingHistory: action.data,
        filterSuccess: action.data
      }

    case GET_BILLING_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_BILLING_HISTORY_CSV:
      return {
        ...state,
        csvRequesting: true
      }

    case GET_BILLING_HISTORY_CSV_SUCCESS:
      return {
        ...state,
        csvRequesting: false
      }

    case GET_BILLING_HISTORY_CSV_FALUIRE:
      return {
        ...state,
        error: action.data,
        csvRequesting: false
      }

    case GET_EXPERT_USER:
      return {
        ...state,
        requesting: true
      }

    case GET_EXPERT_USER_SUCCESS:
      return {
        ...state,
        requesting: false,
        expertUsers: action.data
      }

    case GET_EXPERT_USER_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}
