import {
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FALUIRE,
  DOWNLOAD_CONTRACT_REQUEST,
  DOWNLOAD_CONTRACT_SUCCESS,
  DOWNLOAD_CONTRACT_FALUIRE
} from "./types"

export const getContractRequest = data => ({
  type: GET_CONTRACT_REQUEST,
  data
})

export const getContractSuccess = data => ({
  type: GET_CONTRACT_SUCCESS,
  data
})

export const getContractFaluire = () => ({
  type: GET_CONTRACT_FALUIRE
})

export const downloadContractRequest = data => ({
  type: DOWNLOAD_CONTRACT_REQUEST,
  data
})

export const downloadContractSuccess = data => ({
  type: DOWNLOAD_CONTRACT_SUCCESS,
  data
})

export const downloadContractFaluire = data => ({
  type: DOWNLOAD_CONTRACT_FALUIRE,
  data
})
