import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../config/app"

// utils
import XHR from "../../../utils/XHR"

// types
import {
  HOME_PROFILE_V2_REQUEST,
  HOME_PROFILE_V2_SUCCESS,
  HOME_PROFILE_V2_FALUIRE,
  GET_PROFILE_V2_REQUEST,
  GET_PROFILE_V2_SUCCESS,
  GET_PROFILE_V2_FALUIRE
} from "./types"

// actions
import {
  homeProfileV2Request,
  homeProfileV2Success,
  homeProfileV2Faluire,
  getProfileV2Request,
  getProfileV2Success,
  getProfileV2Faluire
} from "./actions"

import { loginAccessToken, loginSuccess } from "../../LoginScreen/redux/actions"

async function homeProfileV2API(data) {
  const accessToken = await localStorage.getItem("authToken")

  const userId = await localStorage.getItem("user")
  const URL = `${BASE_URL}/api/v1/user_profile/`
  const options = {
    headers: {
      Accept: "application/json",

      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* homeProfileV2({ data }) {
  const usertype = localStorage.getItem("userType")
  const accessToken = localStorage.getItem("authToken")

  try {
    const response = yield call(homeProfileV2API, data)
    yield put(homeProfileV2Success(response.data))
    localStorage.setItem("userAllData", JSON.stringify(response?.data))
    yield put(loginAccessToken(accessToken))
    localStorage.setItem("accessToken", accessToken)
    yield put(loginSuccess(response?.data.user))

    if (usertype === "Expert") {
      yield put(
        push({
          pathname: "/Expert/Engagements"
        })
      )
    } else if (usertype === "Business") {
      yield put(
        push({
          pathname: "/business/my_team"
        })
      )
    } else {
      yield put(
        push({
          pathname: "/admin/time_tracker"
        })
      )
    }
  } catch (e) {
    const { response } = e
    yield put(homeProfileV2Faluire(response?.data))
  }
}

async function getProfileV2API() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/get_expertise/`

  const options = {
    headers: {
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}
function* getProfileV2() {
  try {
    const response = yield call(getProfileV2API)
    localStorage.setItem("data", response?.data)
    yield put(getProfileV2Success(response.data))
  } catch (e) {
    const { response } = e
  }
}

export default all([
  takeLatest(HOME_PROFILE_V2_REQUEST, homeProfileV2),
  takeLatest(GET_PROFILE_V2_REQUEST, getProfileV2)
])
