import {
  ALL_BUSINESS_REQUEST,
  ALL_BUSINESS_SUCCESS,
  ALL_BUSINESS_FALUIRE,
  PATCH_BUSINESS_PROFILE,
  PATCH_BUSINESS_PROFILE_SUCCESS,
  PATCH_BUSINESS_PROFILE_FALUIRE,
  SHARE_BUSINESS_WITH_EXPERT,
  SHARE_BUSINESS_WITH_EXPERT_SUCCESS,
  SHARE_BUSINESS_WITH_EXPERT_FAILURE,
  GET_EXPERTS_NAMES,
  GET_EXPERTS_NAMES_SUCCESS,
  GET_EXPERTS_NAMES_FALUIRE,
  SEARCH_BUSINESS_REQUEST,
  SEARCH_BUSINESS_SUCCESS,
  SEARCH_BUSINESS_FALUIRE,
  ONBOARDING_LINK_REQUEST,
  ONBOARDING_LINK_SUCCESS,
  ONBOARDING_LINK_FAILURE
} from "./types"

export const businessPageRequest = data => ({
  type: ALL_BUSINESS_REQUEST,
  data
})

export const businessPageSuccess = data => ({
  type: ALL_BUSINESS_SUCCESS,
  data
})

export const businessPageFaluire = data => ({
  type: ALL_BUSINESS_FALUIRE,
  data
})

export const patchBusinessProfile = data => ({
  type: PATCH_BUSINESS_PROFILE,
  data
})

export const businessProfileSuccess = data => ({
  type: PATCH_BUSINESS_PROFILE_SUCCESS,
  data
})

export const businessProfileFaluire = data => ({
  type: PATCH_BUSINESS_PROFILE_FALUIRE,
  data
})

export const getAllExpertsNames = data => ({
  type: GET_EXPERTS_NAMES,
  data
})

export const allExpertsNamesSuccess = data => ({
  type: GET_EXPERTS_NAMES_SUCCESS,
  data
})

export const allExpertsNamesFaluire = data => ({
  type: GET_EXPERTS_NAMES_FALUIRE,
  data
})

export const postShareBusinessData = data => ({
  type: SHARE_BUSINESS_WITH_EXPERT,
  data
})

export const shareBusinessDataSuccess = data => ({
  type: SHARE_BUSINESS_WITH_EXPERT_SUCCESS,
  data
})

export const shareBusinessDataFaluire = data => ({
  type: SHARE_BUSINESS_WITH_EXPERT_FAILURE,
  data
})

export const searchBusinessData = data => ({
  type: SEARCH_BUSINESS_REQUEST,
  data
})

export const searchBusinessDataSuccess = data => ({
  type: SEARCH_BUSINESS_SUCCESS,
  data
})

export const searchBusinessDataFaluire = data => ({
  type: SEARCH_BUSINESS_FALUIRE,
  data
})

export const onBoardingLinkRequest = data => ({
  type: ONBOARDING_LINK_REQUEST,
  data
})

export const onBoardingLinkSuccess = data => ({
  type: ONBOARDING_LINK_SUCCESS,
  data
})

export const onBoardingLinkFaluire = data => ({
  type: ONBOARDING_LINK_FAILURE,
  data
})
