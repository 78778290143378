import {
  ALL_ACCOUNT_PAGE_REQUEST,
  ALL_ACCOUNT_PAGE_SUCCESS,
  ALL_ACCOUNT_PAGE_FALUIRE,
  PATCH_ACCOUNTS_REQUEST,
  PATCH_ACCOUNTS_SUCCESS,
  PATCH_ACCOUNTS_FALUIRE
} from "./types"

export const patchAccountRequest = data => ({
  type: PATCH_ACCOUNTS_REQUEST,
  data
})

export const patchAccountSuccess = data => ({
  type: PATCH_ACCOUNTS_SUCCESS,
  data
})

export const patchAccountFailure = data => ({
  type: PATCH_ACCOUNTS_FALUIRE,
  data
})

export const pageRequest = page => ({
  type: ALL_ACCOUNT_PAGE_REQUEST,
  page
})

export const pageSuccess = page => ({
  type: ALL_ACCOUNT_PAGE_SUCCESS,
  page
})

export const pageFaluire = error => ({
  type: ALL_ACCOUNT_PAGE_FALUIRE,
  error
})
