import { all, call, put, takeLatest } from "redux-saga/effects"
import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"
// types
import {
  PATCH_MYTEAM_REQUESTENGAGEMENT,
  GET_MYTEAM_REQUEST,
  BUSINESS_RATING_REQUEST,
  ADD_TEAM_REQUEST,
  GET_EXPERTIES_REQUEST,
  ADD_HOURS_REQUEST,
  POST_END_ENGAGEMENT_REQUEST
} from "./types"

// actions
import {
  myTeamRequestEngageSuccess,
  myTeamRequestEngageFaluire,
  getMyTeamSuccess,
  getMyTeamFaluire,
  businessRatingSuccess,
  businessRatingFaluire,
  addTeamSuccess,
  addTeamFaluire,
  getExpertiesSuccess,
  getExpertiesFaluire,
  addHoursSuccess,
  addHoursFaluire,
  postEndEngagementSuccess,
  postEndEngagementFaluire
} from "./actions"

async function patchRequestHourAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/request_engagement/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* patchRequestEngagement({ data }) {
  try {
    const response = yield call(patchRequestHourAPI, data)

    yield put(myTeamRequestEngageSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(myTeamRequestEngageFaluire(response?.data))
  }
}

async function getMyTeamAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/my_team/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getMyTeamFunction({ data }) {
  try {
    const response = yield call(getMyTeamAPI, data)
    yield put(getMyTeamSuccess(response?.data))
    // toast.success("Replied Successfully !")
  } catch (e) {
    const { response } = e
    yield put(getMyTeamFaluire(response?.data))
    // let err = e?.response?.data?.feedback
    //   ? "Feedback field is required"
    //   : e?.response?.data?.reply
    //     ? "Reply field is required"
    //     : e?.response?.data?.detail
    //       ? e.response?.data.detail
    //       : "Something went wrog."
    // if (e.message == "Network Error") {
    //   // toast.error("Connection Error")
    //   yield put(postFeedBackFaluire("Connection Error"))
    // } else {
    //   // toast.error(err)
    //   yield put(postFeedBackFaluire(err))
    // }
  }
}

async function businessRatingApi(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/rating/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* businessRatingFunction({ data, toggle }) {
  try {
    const response = yield call(businessRatingApi, data)
    yield put(businessRatingSuccess(response))
    yield put(toggle(false))
  } catch (e) {
    const { response } = e
    yield put(businessRatingFaluire(response))
  }
}

async function addTeamApi(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/request_engagement/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* addTeamFunction({ data, toggle2 }) {
  try {
    const response = yield call(addTeamApi, data)
    yield put(addTeamSuccess(response))
    toast.success("Success")
    yield put(toggle2(false))
    yield put(getMyTeamSuccess(response))
    yield put(getExpertiesSuccess(response))
  } catch (e) {
    const { response } = e
    yield put(addTeamFaluire(response))
  }
}

async function getExpertiesApi() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/get_expertise/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExpertiesFunction({ data }) {
  try {
    const response = yield call(getExpertiesApi, data)
    yield put(getExpertiesSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getExpertiesFaluire(response?.data))
  }
}

async function addHoursApi(data) {
  const accessToken = await localStorage.getItem("authToken")

  const URL = `${BASE_URL}/api/v1/business/request_hours/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* addHoursFunction({ data, resquestHourModal }) {
  try {
    const response = yield call(addHoursApi, data)

    yield put(addHoursSuccess(response?.data))

    yield put(resquestHourModal(false))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(addHoursFaluire(response?.data))
  }
}

async function endEngagementApi(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business/end_engagement_request/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* endEngagementFunction({ data, endEngagementModal }) {
  try {
    const response = yield call(endEngagementApi, data)

    yield put(postEndEngagementSuccess(response?.data))
    // toast.success("Request hours are added successfully!")
    toast.success("Success")
    yield put(endEngagementModal(false))
  } catch (e) {
    const { response } = e
    yield put(postEndEngagementFaluire(response?.data))
  }
}

export default all([
  takeLatest(PATCH_MYTEAM_REQUESTENGAGEMENT, patchRequestEngagement),
  takeLatest(GET_MYTEAM_REQUEST, getMyTeamFunction),
  takeLatest(BUSINESS_RATING_REQUEST, businessRatingFunction),
  takeLatest(ADD_TEAM_REQUEST, addTeamFunction),
  takeLatest(GET_EXPERTIES_REQUEST, getExpertiesFunction),
  takeLatest(ADD_HOURS_REQUEST, addHoursFunction),
  takeLatest(POST_END_ENGAGEMENT_REQUEST, endEngagementFunction)
])
