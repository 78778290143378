import {
  BUSINESS_LOG_HOUR_REQUEST,
  BUSINESS_LOG_HOUR_SUCCESS,
  BUSINESS_LOG_HOUR_FALUIRE,
  BUSINESS_LOG_HOUR_PAGE_REQUEST,
  BUSINESS_LOG_HOUR_PAGE_SUCCESS,
  BUSINESS_LOG_HOUR_PAGE_FALUIRE,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_SUCCESS,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_FALUIRE
} from "./types"

const initialState = {
  logourData: false,
  requesting: false,
  error: false,
  csvrequesting: false,
  exportCSV: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_LOG_HOUR_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case BUSINESS_LOG_HOUR_SUCCESS:
      return {
        ...state,
        requesting: false,
        logourData: action.data
      }

    case BUSINESS_LOG_HOUR_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    case BUSINESS_LOG_HOUR_PAGE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case BUSINESS_LOG_HOUR_PAGE_SUCCESS:
      return {
        ...state,
        requesting: false,
        logourData: action.data
      }

    case BUSINESS_LOG_HOUR_PAGE_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST:
      return {
        ...state,
        csvrequesting: true
      }

    case BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_SUCCESS:
      return {
        ...state,
        csvrequesting: false,
        exportCSV: action.data
      }

    case BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_FALUIRE:
      return {
        ...state,
        error: action.data,
        csvrequesting: false
      }

    default:
      return state
  }
}
