import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"

import toast from "react-hot-toast"
// config

import { BASE_URL } from "../../../../../config/app"

// utils
import XHR from "../../../../../utils/XHR"

// types
import {
  GET_NOTIFICATION_DATA_REQUEST,
  POST_DEVICE_ID_REQUEST,
  PATCH_NOTIFICATION_REQUEST,
  POST_CLOSE_PERIOD_REQUEST,
  POST_HOUR_REMINDER_REQUEST
} from "./types"

// actions
import {
  sendDeviceIdSuccess,
  sendDeviceIdFaluire,
  getNotficationDataSuccess,
  getNotficationDataFaluire,
  patchNotficationSuccess,
  patchNotficationFaluire,
  getNotficationDataRequest
} from "./actions"

async function postDeviceIdAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/user_fcm_device_add/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* postDeviceId({ data }) {
  try {
    const response = yield call(postDeviceIdAPI, data)
    yield put(sendDeviceIdSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(sendDeviceIdFaluire(response?.data))
  }
}

async function patchNotificationDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/notification/notification_list/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchNotificationData({ data }) {
  try {
    const response = yield call(patchNotificationDataAPI, data)
    yield put(patchNotficationSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(patchNotficationFaluire(response?.data))
  }
}

async function getNotificationsAPI() {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/notification/notification_list/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}
function* getNotifications() {
  try {
    const response = yield call(getNotificationsAPI)
    yield put(getNotficationDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getNotficationDataFaluire(response?.data))
  }
}

async function closePeriodAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/close_period/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* closePeriod({ data }) {
  try {
    const response = yield call(closePeriodAPI, data)
    toast.success(`success`)
  } catch (e) {
    const { response } = e
    toast.error(`${response?.data?.Message}`)
  }
}

async function hourReminderAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/hour_reminder/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}
function* hourReminder({ data }) {
  try {
    const response = yield call(hourReminderAPI, data)
    toast.success(`${response?.data?.Message}`)
  } catch (e) {
    const { response } = e
    toast.error(`${response?.data?.Message}`)
  }
}

export default all([
  takeLatest(POST_DEVICE_ID_REQUEST, postDeviceId),
  takeLatest(PATCH_NOTIFICATION_REQUEST, patchNotificationData),
  takeLatest(GET_NOTIFICATION_DATA_REQUEST, getNotifications),
  takeLatest(POST_CLOSE_PERIOD_REQUEST, closePeriod),
  takeLatest(POST_HOUR_REMINDER_REQUEST, hourReminder)
])
