import { initializeApp } from "firebase/app"
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from "firebase/messaging"
import toast from "react-hot-toast"

const REACT_APP_APIKEY = "AIzaSyBiATXGicB11rMC96tjQuLoOwk-VtR4fRw"
const REACT_APP_AUTH_DOMAIN = "growtal-app.firebaseapp.com"
const REACT_APP_PROJECT_ID = "growtal-app"
const REACT_APP_STORAGE_BUCKET = "growtal-app.appspot.com"
const REACT_APP_MESSAGING_SENDER_ID = "412013044637"
const REACT_APP_APP_ID = "1:412013044637:web:b65052f8a83026f6da8574"
const REACT_APP_MEASUREMENT_ID = "G-MQ9Q765D4S"

const FIREBASE_CONFIG = {
  apiKey: REACT_APP_APIKEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
}

const firebaseApp = initializeApp(FIREBASE_CONFIG)

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp)
    }
    console.log("Firebase not supported this browser")
    return null
  } catch (err) {
    console.log(err)
    return null
  }
})()

// const messaging = getMessaging(firebaseApp)

export default messaging

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload)
    })
  })

export const fetchToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BAniH9vuyk3AVK3FeH-ffPCyYfP8W3noUJ2LRS37qhMZhlwglsjTo84P6LF7Fa6ikAkiWRqhP0VeHQlwVaM2OCU"
  })
}
