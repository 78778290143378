export const GET_NOTIFICATION_DATA_REQUEST = "GET_NOTIFICATION_DATA_REQUEST"
export const GET_NOTIFICATION_DATA_SUCCESS = "GET_NOTIFICATION_DATA_SUCCESS"
export const GET_NOTIFICATION_DATA_FAILURE = "GET_NOTIFICATION_DATA_FAILURE"

export const PATCH_NOTIFICATION_REQUEST = "PATCH_NOTIFICATION_REQUEST"
export const PATCH_NOTIFICATION_SUCCESS = "PATCH_NOTIFICATION_SUCCESS"
export const PATCH_NOTIFICATION_FAILURE = "PATCH_NOTIFICATION_FAILURE"

export const POST_DEVICE_ID_REQUEST = "POST_DEVICE_ID_REQUEST"
export const POST_DEVICE_ID_SUCCESS = "POST_DEVICE_ID_SUCCESS"
export const POST_DEVICE_ID_FAILURE = "POST_DEVICE_ID_FAILURE"

export const POST_CLOSE_PERIOD_REQUEST = "POST_CLOSE_PERIOD_REQUEST"
export const POST_CLOSE_PERIOD_SUCCESS = "POST_CLOSE_PERIOD_SUCCESS"

export const POST_HOUR_REMINDER_REQUEST = "POST_HOUR_REMINDER_REQUEST"
export const POST_HOUR_REMINDER_SUCCESS = "POST_HOUR_REMINDER_SUCCESS"
