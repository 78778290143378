import React, { useState, useEffect } from "react"
import { Toast } from "react-bootstrap"
import { connect } from "react-redux"
import { notificationDataRequest } from "./redux/actions"

import { onMessageListener } from "../../firebase"

import { onMessage } from "firebase/messaging"
import messaging from "../../firebase"

import { getNotficationDataRequest } from "Containers/ExpertDesign/Screens/Notifications/redux/actions"

const ForegroundNotification = props => {
  const [show, setShow] = useState(false)
  const [messageData, setMessageData] = useState(null)

  useEffect(() => {
    onMessage(messaging, payload => {
      setMessageData(payload.notification)
      setShow(true)
      props.getNotficationDataRequest()
    })
  }, [messaging])

  return (
    <>
      {messageData && (
        <Toast
          onClose={e => {
            setShow(false)
            setMessageData(null)
          }}
          show={show}
          delay={3000}
          autohide
          animation
          style={{
            position: "absolute",
            zIndex: 999,
            top: "15%",
            right: "20px",
            // margin: '0px auto',
            minWidth: 200
            // backgroundColor: 'red'
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{messageData.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{messageData.body}</Toast.Body>
        </Toast>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  // userData: state.LoginScreen.user,
  // requesting: state.LoginScreen.requesting,
  // BackendError: state.LoginScreen.error,
  // deviceID: state.LoginScreen.deviceID
  // loginaccessToken: state.LoginScreen.accessToken,
  // linkedInCode: state.LoginScreen.linkedInCode,
  // linkedInSuccess: state.LoginScreen.linkedInSuccess,
  // accessToken: state.LoginScreen.accessToken,
})

const mapDispatchToProps = dispatch => ({
  getNotficationDataRequest: () => dispatch(getNotficationDataRequest())
  // loginViaLinkedInRequest: data => dispatch(loginViaLinkedInRequest(data)),
  // loginViaGoogleRequest: data => dispatch(loginViaGoogleRequest(data)),
  // loginFaluire: data => dispatch(loginFaluire(data))
  // linkedInCodeData: data => dispatch(linkedInCodeData(data)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForegroundNotification)

// export default ForegroundNotification
