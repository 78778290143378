export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGING_FALUIRE = "LOGING_FALUIRE"
export const LOGIN_VIA_LINKEDIN_REQUEST = "LOGIN_VIA_LINKEDIN_REQUEST"
export const LOGIN_VIA_LINKEDIN_SUCCESS = "LOGIN_VIA_LINKEDIN_SUCCESS"
export const LOGIN_VIA_LINKEDIN_FALUIRE = "LOGIN_VIA_LINKEDIN_FALUIRE"
export const LOGIN_VIA_GOOGLE_REQUEST = "LOGIN_VIA_GOOGLE_REQUEST"
export const LOGIN_VIA_GOOGLE_SUCCESS = "LOGIN_VIA_GOOGLE_SUCCESS"
export const LOGIN_VIA_GOOGLE_FALUIRE = "LOGIN_VIA_GOOGLE_FALUIRE"
export const LOGIN_ACCESS_TOKEN = "LOGIN_ACCESS_TOKEN"
export const LOGIN_PROFILE_DATA = "LOGIN_PROFILE_DATA"
export const RESET_STATE = "RESET_STATE"
export const LINKEDIN_CODE = "LINKEDIN_CODE"
export const INITIAL_PROFILE_CHECK = "INITIAL_PROFILE_CHECK"
export const SET_DEVICE_ID = "SET_DEVICE_ID"
export const NOTFICATION_SETTING_TOGGLE ="NOTFICATION_SETTING_TOGGLE"