import {
  GET_REFERRAL_REQUEST,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FALUIRE,
  UPDATE_REFERRAL_STATUS_REQUEST,
  UPDATE_REFERRAL_STATUS_SUCCESS,
  UPDATE_REFERRAL_STATUS_FALUIRE
} from "./types"
const initialState = {
  requesting: false,
  error: false,
  updateRequesting: false,
  referralData: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRAL_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_REFERRAL_SUCCESS:
      return {
        ...state,
        error: false,
        referralData: action.data,
        requesting: false
      }

    case GET_REFERRAL_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case UPDATE_REFERRAL_STATUS_REQUEST:
      return {
        ...state,
        updateRequesting: true
      }

    case UPDATE_REFERRAL_STATUS_SUCCESS:
      return {
        ...state,
        error: false,
        updateRequesting: false
      }

    case UPDATE_REFERRAL_STATUS_FALUIRE:
      return {
        ...state,
        updateRequesting: false,
        error: action.data
      }

    default:
      return state
  }
}
