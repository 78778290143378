import { GET_NOTIFICATION_DATA } from "./types"
const initialState = {
  messageData: false,
  requesting: false,
  error: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_DATA:
      return {
        ...state,
        requesting: true,
        messageData: action.data
      }

    default:
      return state
  }
}
