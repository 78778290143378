import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Spinner
} from "reactstrap"
import Select from "react-select"
import moment from "moment"
// import './style.scss';
import Switch from "react-switch"

import {
  getNotficationDataRequest,
  patchNotficationRequest
} from "./redux/actions"

import { notificationSettings } from "Containers/Settings/redux/actions"

// import ReactPaginate from 'react-paginate';

import Images from "utils/Images"
// import Switch from "react-bootstrap-switch"
import { connect } from "react-redux"

function Notifications(props) {
  const {
    getNotficationDataRequest,
    patchNotficationRequest,
    allNotifications,
    requesting,
    notificationSettings,
    notifiySetting
  } = props

  useEffect(() => {
    getNotficationDataRequest()
  }, [])

  const handleClickNotification = item => {
    const data = {
      id: item.id,
      data: {
        read: true
      }
    }
    patchNotficationRequest(data)
  }
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [openSwitch, SetopenSwitch] = useState(false)
  const [openSwitch1, SetopenSwitch1] = useState(
    notifiySetting ? notifiySetting.notification_email : true
  )

  const [openSwitch2, SetopenSwitch2] = useState(
    notifiySetting ? notifiySetting.notification_app : true
  )

  const [image, setImage] = useState(false)
  const toggle = () => setModal(!modal)
  const toggle1 = () => {
    setModal1(!modal1)
  }
  const toggle2 = () => setModal2(!modal2)
  const toggle3 = () => setModal3(!modal3)
  const toggle4 = () => setModal4(!modal4)

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)

  let PageSize = 10

  useEffect(() => {
    getNotficationDataRequest()
  }, [])
  const handleChange = () => SetopenSwitch(!openSwitch)
  const handleChange1 = e => {
    const data = {
      notification_email: e
    }
    notificationSettings(data)
    SetopenSwitch1(!openSwitch1)
  }
  const handleChange2 = e => {
    const data = {
      notification_app: e
    }
    notificationSettings(data)
    SetopenSwitch2(!openSwitch2)
  }

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map(item => (
            <div>
              <h3>Item #{item}</h3>
            </div>
          ))}
      </>
    )
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline"
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Notification
                  </CardTitle>
                  <div
                    className="header"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      marginTop: "-10px"
                    }}
                  >
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                        className="px-0 py-2"
                      >
                        <div
                          className="p-2 rounded-pill"
                          // color="secondary"
                          style={{
                            backgroundColor: "white",
                            height: 42,
                            border: "2px solid #3A0F7D",
                            color: "#3A0F7D",
                            display: "flex",
                            borderRadius: "8px",
                            alignItems: "center"
                          }}
                        >
                          {/* <div className="mr-1"> */}
                          <i
                            style={{
                              alignSelf: "center",
                              marginRight: 4
                            }}
                            className="nc-icon nc-settings-gear-65 "
                          />
                          {/* </div> */}
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: 12
                            }}
                            className="mr-lg-3"
                          >
                            {" "}
                            Notification Settings
                          </span>
                          <img
                            className="mx-2"
                            style={{
                              // width: "9px",
                              height: "6px",
                              alignSelf: " center"

                              // objectFit: "cover"

                              // marginRight: "5px"
                              // backgroundColor: "#3A0F7D"
                            }}
                            src={require("assets/img/colorUp.png")}
                          />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu
                        className="p-lg-2 p-sm-2"
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        style={{
                          backgroundColor: "white",
                          maxWidth: "300px",
                          padding: 10
                        }}
                      >
                        {/* <div
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15,
                            display: "flex",
                            Width: "90%",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div>
                            <p
                              className="pr-5 "
                              style={{ margin: "0px", width: "max-content ", fontSize:15}}
                            >
                              Receive via SMS
                            </p>
                          </div>
                          <div>
                            <Switch
                              onChange={handleChange}
                              checked={openSwitch}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="3A0F7D"
                              width={48}
                              offColor="#808080"
                              onColor="#3A0F7D"
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div> */}
                        <div
                          className="mt-lg-3"
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15,
                            display: "flex",
                            // Width: "90%",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div>
                            <p
                              className="pr-lg-5 "
                              style={{
                                margin: "0px",
                                width: "max-content ",
                                fontSize: 15
                              }}
                            >
                              Receive via Email
                            </p>
                          </div>
                          <div>
                            <Switch
                              onChange={handleChange1}
                              checked={openSwitch1}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="3A0F7D"
                              width={48}
                              offColor="#808080"
                              onColor="#3A0F7D"
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div>
                        <div
                          className="mt-lg-3"
                          style={{
                            color: "#3A0F7D",
                            fontFamily: "Khula",
                            fontSize: 15,
                            display: "flex",
                            // Width: "90%",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div>
                            <p
                              className="pr-lg-5 "
                              style={{
                                margin: "0px",
                                fontSize: "15px",
                                fontWeight: "400",
                                width: "max-content ",
                                fontSize: 15
                              }}
                            >
                              Receive only in-app
                            </p>
                          </div>
                          <div>
                            <Switch
                              onChange={handleChange2}
                              checked={openSwitch2}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="3A0F7D"
                              width={48}
                              offColor="#808080"
                              onColor="#3A0F7D"
                              uncheckedIcon={false}
                              checkedIcon={false}
                            />
                          </div>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        // padding: "25px",
                        height: "40px",
                        paddingLeft: "22px"
                      }}
                    >
                      {/* Notification Details */}
                    </th>

                    {/* <th
                      className="text-right"
                      style={{
                        color: "#808080",

                        paddingRight: "72px"
                      }}
                    >
                      Date
                    </th> */}
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    <>
                      {requesting ? (
                        <div
                          style={{
                            position: "absolute",
                            top: "90%",
                            // transform: "translate(-50%, -50%)",
                            left: "50%"
                            // backgroundColor: 'red'
                          }}
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            style={{}}
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      ) : allNotifications &&
                        allNotifications?.notification.length > 0 ? (
                        allNotifications?.notification.map(item => (
                          <>
                            <tr
                              // onClick={() => handleClickNotification(item)}
                              style={{
                                // border: "3px solid",
                                // borderRadius: "50px !mportant",
                                backgroundColor: "white",
                                marginLeft: "25px",
                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  display: "flex",
                                  padding: "25px",
                                  border: "3px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px"
                                }}
                              >
                                {item.read ? (
                                  <img
                                    style={{
                                      width: "17px",
                                      height: "20px",
                                      marginRight: "20px"
                                    }}
                                    src={Images.Read_Icon}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      width: "17px",
                                      height: "20px",
                                      marginRight: "20px"
                                    }}
                                    src={Images.Unread_Icon}
                                  />
                                )}
                                <div style={{}}>
                                  <span
                                    style={{ color: "#E9463A", marginRight: 5 }}
                                  >
                                    {`${item?.data?.name1}`}
                                  </span>
                                  <span>{item?.data?.text}</span>
                                  <span
                                    style={{
                                      color: "#E9463A",
                                      paddingLeft: "5px"
                                    }}
                                  >
                                    {item?.data?.name2}
                                  </span>
                                </div>
                              </td>

                              <td
                                className="text-right"
                                // className="text-left "
                                style={{
                                  paddingTop: "14px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                  paddingRight: "36px"
                                }}
                              >
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    aria-haspopup={true}
                                    // caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="navbarDropdownMenuLink"
                                    nav
                                  >
                                    <img
                                      style={{
                                        height: "20px",
                                        marginRight: "30px"
                                      }}
                                      src={Images.menu_icon}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    persist
                                    aria-labelledby="navbarDropdownMenuLink"
                                    right
                                  >
                                    <DropdownItem
                                      style={{
                                        color: "#3A0F7D",
                                        fontFamily: "Khula",
                                        fontSize: 15
                                      }}
                                      href="#"
                                      // onClick={toggle1}
                                      onClick={e => {
                                        e.preventDefault()
                                        handleClickNotification(item)
                                        toggle1()
                                      }}
                                      // onClick={() => }
                                    >
                                      Mark as Read
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            transform: "translate(-50%, -50%)",
                            left: "50%"
                          }}
                        >
                          <h6 style={{ textAlign: "center" }}>No Data</h6>
                        </div>
                      )}
                    </>
                  </tbody>
                </Table>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: "10px",
                    marginTop: "-40px"
                    // marginBottom: "25px"
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "25px",
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{
                        width: "17px",
                        height: "20px",
                        marginRight: "20px"
                      }}
                      src={Images.Unread_Icon}
                    />
                    <p style={{ marginBottom: "0px" }}>
                      To enrich your profile learn from more resources
                    </p>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingRight: "25px"
                    }}
                  >
                    <div
                      style={{
                        // backgroundColor: '#3A0F7D',
                        padding: "15px",
                        marginBottom: "0px",
                        borderRadius: "50px",
                        color: "white",
                        fontWeight: "700",
                        fontSize: "16px"
                      }}
                    ></div>
                  </div>

                  <UncontrolledDropdown>
                    <DropdownToggle
                      aria-haspopup={true}
                      // caret
                      color="default"
                      data-toggle="dropdown"
                      id="navbarDropdownMenuLink"
                      nav
                    >
                      <img
                        style={{ height: "20px", marginRight: "30px" }}
                        src={Images.menu_icon}
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      persist
                      aria-labelledby="navbarDropdownMenuLink"
                      right
                    >
                      <DropdownItem
                        style={{
                          color: "#3A0F7D",
                          fontFamily: "Khula",
                          fontSize: 15
                        }}
                        href="#"
                        onClick={toggle1}
                      // onClick={e => e.preventDefault()}
                      >
                        Mark as Read
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  allNotifications: state.Notifications.allNotifications,
  requesting: state.Notifications.requesting,
  BackendError: state.Notifications.error,
  notifiySetting: state.LoginScreen.notificationSetting

  // linkedInCode: state.LoginScreen.linkedInCode,
  // linkedInSuccess: state.LoginScreen.linkedInSuccess,
  // accessToken: state.LoginScreen.accessToken,
})

const mapDispatchToProps = dispatch => ({
  getNotficationDataRequest: () => dispatch(getNotficationDataRequest()),
  patchNotficationRequest: data => dispatch(patchNotficationRequest(data)),
  notificationSettings: data => dispatch(notificationSettings(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
