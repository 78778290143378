import {
  GET_NOTIFICATION_DATA_REQUEST,
  GET_NOTIFICATION_DATA_SUCCESS,
  GET_NOTIFICATION_DATA_FAILURE,
  POST_DEVICE_ID_REQUEST,
  POST_DEVICE_ID_SUCCESS,
  POST_DEVICE_ID_FAILURE,
  PATCH_NOTIFICATION_REQUEST,
  PATCH_NOTIFICATION_SUCCESS,
  PATCH_NOTIFICATION_FAILURE,
  POST_CLOSE_PERIOD_REQUEST,
  POST_CLOSE_PERIOD_SUCCESS,
  POST_HOUR_REMINDER_REQUEST,
  POST_HOUR_REMINDER_SUCCESS
} from "./types"
const initialState = {
  allNotifications: false,
  patchRequesting: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_DEVICE_ID_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_DEVICE_ID_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: false
      }

    case POST_DEVICE_ID_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_NOTIFICATION_DATA_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        allNotifications: action.data
      }

    case GET_NOTIFICATION_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case PATCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        patchRequesting: true
      }

    case PATCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        patchRequesting: false,
        allNotifications: action.data
      }

    case POST_CLOSE_PERIOD_REQUEST:
      return {
        ...state,
        postRequesting: true
      }

    case POST_CLOSE_PERIOD_SUCCESS:
      return {
        ...state,
        postRequesting: false
        // allNotifications: action.data
      }

    case POST_HOUR_REMINDER_REQUEST:
      return {
        ...state,
        postRequesting: true
      }

    case POST_HOUR_REMINDER_SUCCESS:
      return {
        ...state,
        postRequesting: false
        // allNotifications: action.data
      }

    case PATCH_NOTIFICATION_FAILURE:
      return {
        ...state,
        patchRequesting: false,
        error: action.data
      }

    default:
      return state
  }
}
