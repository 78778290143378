import {
  GET_REFERRAL_REQUEST,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FALUIRE,
  UPDATE_REFERRAL_STATUS_REQUEST,
  UPDATE_REFERRAL_STATUS_SUCCESS,
  UPDATE_REFERRAL_STATUS_FALUIRE
} from "./types"

export const getReferralRequest = data => ({
  type: GET_REFERRAL_REQUEST,
  data
})

export const getReferralSuccess = data => ({
  type: GET_REFERRAL_SUCCESS,
  data
})

export const getReferralFaluire = data => ({
  type: GET_REFERRAL_FALUIRE,
  data
})

export const updateReferralStatusRequest = data => ({
  type: UPDATE_REFERRAL_STATUS_REQUEST,
  data
})

export const updateReferralStatusSuccess = data => ({
  type: UPDATE_REFERRAL_STATUS_SUCCESS,
  data
})

export const updateReferralStatusFaluire = data => ({
  type: UPDATE_REFERRAL_STATUS_FALUIRE,
  data
})
