import {
  ADD_CONTENT_RESOURCE,
  ADD_CONTENT_RESOURCE_SUCCESS,
  ADD_CONTENT_RESOURCE_FALUIRE,
  EDIT_CONTENT_RESOURCE,
  EDIT_CONTENT_RESOURCE_SUCCESS,
  EDIT_CONTENT_RESOURCE_FALUIRE,
  DELETE_CONTENT_RESOURCE,
  DELETE_CONTENT_RESOURCE_SUCCESS,
  DELETE_CONTENT_RESOURCE_FALUIRE
} from "./types"
const initialState = {
  requesting: false,
  error: false,
  deleteRequest: false,
  addRequest: false,
  successResource: false,
  editRequest: false,
  editSuccess: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTENT_RESOURCE:
      return {
        ...state,
        addRequest: true
      }

    case ADD_CONTENT_RESOURCE_SUCCESS:
      return {
        ...state,
        addRequest: false,
        successResource: action.data
      }

    case ADD_CONTENT_RESOURCE_FALUIRE:
      return {
        ...state,
        addRequest: false,
        error: action.data
      }

    case EDIT_CONTENT_RESOURCE:
      return {
        ...state,
        editRequest: true
      }

    case EDIT_CONTENT_RESOURCE_SUCCESS:
      return {
        ...state,
        editRequest: false,
        editSuccess: action.data
      }

    case EDIT_CONTENT_RESOURCE_FALUIRE:
      return {
        ...state,
        editRequest: false,
        error: action.data
      }

    case DELETE_CONTENT_RESOURCE:
      return {
        ...state,
        deleteRequest: true
      }

    case DELETE_CONTENT_RESOURCE_SUCCESS:
      return {
        ...state,
        deleteRequest: false
      }

    case DELETE_CONTENT_RESOURCE_FALUIRE:
      return {
        ...state,
        deleteRequest: false
      }

    default:
      return state
  }
}
