import {
  LOGHOUR_REQUEST,
  LOGHOUR_SUCCESS,
  LOGHOUR_FALUIRE,
  PAGE_REQUEST,
  PAGE_SUCCESS,
  PAGE_FALUIRE,
  EXPORT_CSV_REQUEST,
  EXPORT_CSV_REQUEST_SUCCESS,
  EXPORT_CSV_REQUEST_FALUIRE,
  LOG_HOUR_SORT_REQUEST,
  LOG_HOUR_SORT_SUCCESS,
  LOG_HOUR_SORT_FALUIRE,
  LOG_HOUR_FILTER_REQUEST,
  LOG_HOUR_FILTER_FALUIRE,
  LOG_HOUR_FILTER_SUCCESS,
  FILTER_CLIENTS_REQUEST,
  FILTER_CLIENTS_SUCCESS,
  FILTER_CLIENTS_FALUIRE,
  FILTER_ENGAGEMENTS_REQUEST,
  FILTER_ENGAGEMENTS_SUCCESS,
  FILTER_ENGAGEMENTS_FALUIRE
} from "./types"

const initialState = {
  logourData: false,
  requesting: false,
  csvrequesting: false,
  error: false,
  exportCSV: false,
  pagination: false,
  filterRequesting: false,
  filteredResult: false,
  sortRequesting: false,
  sortedResult: false,
  filteredClients: false,
  filteredEngagements: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGHOUR_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case LOGHOUR_SUCCESS:
      return {
        ...state,
        requesting: false,
        logourData: action.data
      }

    case LOGHOUR_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    case PAGE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case PAGE_SUCCESS:
      return {
        ...state,
        requesting: false,
        pagination: action.data,
        logourData: action.data
      }

    case PAGE_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case EXPORT_CSV_REQUEST:
      return {
        ...state,
        csvrequesting: true
      }

    case EXPORT_CSV_REQUEST_SUCCESS:
      return {
        ...state,
        csvrequesting: false,
        exportCSV: action.data
      }

    case EXPORT_CSV_REQUEST_FALUIRE:
      return {
        ...state,
        error: action.data,
        csvrequesting: false
      }

    case LOG_HOUR_SORT_REQUEST:
      return {
        ...state,
        sortRequesting: true
      }

    case LOG_HOUR_SORT_SUCCESS:
      return {
        ...state,
        sortRequesting: false,
        sortedResult: action.data
      }

    case LOG_HOUR_SORT_FALUIRE:
      return {
        ...state,
        error: action.data,
        sortRequesting: false
      }

    case LOG_HOUR_FILTER_REQUEST:
      return {
        ...state,
        filtrRequesting: true
      }

    case LOG_HOUR_FILTER_SUCCESS:
      return {
        ...state,
        filtrRequesting: false,
        filteredResult: action.data
      }

    case LOG_HOUR_FILTER_FALUIRE:
      return {
        ...state,
        error: action.data,
        filtrRequesting: false
      }

    case FILTER_CLIENTS_REQUEST:
      return {
        ...state,
        filtrRequesting: true
      }

    case FILTER_CLIENTS_SUCCESS:
      return {
        ...state,
        filtrRequesting: false,
        filteredClients: action.data
      }

    case FILTER_CLIENTS_FALUIRE:
      return {
        ...state,
        error: action.data,
        filtrRequesting: false
      }

    case FILTER_ENGAGEMENTS_REQUEST:
      return {
        ...state,
        filtrRequesting: true
      }

    case FILTER_ENGAGEMENTS_SUCCESS:
      return {
        ...state,
        filtrRequesting: false,
        filteredEngagements: action.data
      }

    case FILTER_ENGAGEMENTS_FALUIRE:
      return {
        ...state,
        error: action.data,
        filtrRequesting: false
      }

    default:
      return state
  }
}
