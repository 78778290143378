import React, { useEffect, useState } from "react"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"

import PaginationPack from "components/PaginationPack"

import Images from "utils/Images"
import { Link } from "react-router-dom"

import useForm from "../../../../../utils/useForm"
import validator from "../../../../../utils/validation"
import { connect } from "react-redux"

import toast, { Toaster } from "react-hot-toast"
import Select from "react-select"
import {
  getEngagement,
  acceptRequest,
  postRequestHours,
  postRequestHoursFailure,
  expertEndEngagementRequest,
  expertEndEngagementFailure
} from "../redux/actions"
import { async } from "@firebase/util"

function ActiveEngagements(props) {
  const {
    engagementData,
    requesting,
    BackendError,
    postRequestHoursFailure,
    endEngageSuccess,
    postedHoursResult
  } = props
  const [modal1, setModal1] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [business, setBusiness] = useState(false)
  const [amount, setAmount] = useState(false)
  const [hour, setHour] = useState({ value: 0 })
  const [engagement, setEngagement] = useState(false)

  const [increaseHour, setIncreaseHour] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle1 = () => {
    setModal1(!modal1)
    setState(stateSchema)
    props.expertEndEngagementFailure(false)
  }
  const toggle2 = () => {
    setHour({ value: 0 })
    setModal(false)
    setModal2(!modal2)
    setState(stateSchema)
    postRequestHoursFailure(false)
  }

  const stateSchema = {
    hours: {
      value: "",
      error: ""
    },
    description: {
      value: "",
      error: ""
    },
    engagement: {
      value: "",
      error: ""
    },
    reasonText: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    hours: {
      required: true
      // validator: validator.email
    },
    description: {
      required: true
      //   validator: validator.password,
    },
    engagement: {
      required: true
      //   validator: validator.password,
    },
    reasonText: {
      required: true
      //   validator: validator.password,
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSubmit = () => {
    if (state.reasonText.value) {
      toast.success("Success")
    } else {
      // toast.error("All Fields are required")
    }
  }

  const handelHour = e => {
    handleHourChange(e)
    postRequestHoursFailure(false)
  }

  const onEndEngagement = async () => {
    const userId = await localStorage.getItem("user")
    const apiData = {
      agreed_engagement: engagement?.id,
      user: userId,
      description: state.reasonText.value
    }

    props.expertEndEngagementRequest(apiData, toggle1)
  }
  const handleHourChange = ({ target: { value } }) =>
    setHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )

  useEffect(() => {
    props.getEngagement(1)
  }, [])

  useEffect(() => {
    if (postedHoursResult) {
      setModal2(false)
      setModal(false)
      setState(stateSchema)
      postRequestHoursFailure(false)
    }
  }, [postedHoursResult])

  useEffect(() => {
    if (endEngageSuccess) {
      setModal1(false)
      setState(stateSchema)
      props.expertEndEngagementFailure(false)
    }
  }, [endEngageSuccess])

  const handlePageChange = page => {
    props.getEngagement(page)
  }

  const handleRequestHour = () => {
    const data = {
      hours: hour.value,
      description: state.description.value,
      engagement: business.id,
      increase_hour: increaseHour ? increaseHour?.label : "",
      request_to: business?.business_detail.id
    }
    props.postRequestHours(data)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />

        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "20px",
                      cursor: "pointer"
                    }}
                    src={Images.back_button}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Active Engagements
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  responsive
                  // borderless
                  style={{
                    borderTop: "3px solid transparent"

                    // marginTop: "-80px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", padding: "25px" }}>
                      Engagements
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {/* {dummyData.map(item => ( */}
                    <>
                      {requesting ? (
                        <div
                          style={{
                            height: " 200px",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            style={{}}
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      ) : engagementData &&
                        engagementData.results.length > 0 ? (
                        engagementData.results.map(item => {
                          return (
                            <>
                              <tr
                                style={{
                                  backgroundColor: "white",
                                  borderBottomLeftRadius: "8px",
                                  borderTopLeftRadius: "8px",
                                  marginLeft: "25px",
                                  borderTop: "3px solid transpernet"

                                  // border: "3px solid white"
                                  // border: "3px solid transparent"
                                }}
                              >
                                <td
                                  style={{
                                    padding: "25px",
                                    padding: "25px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                    borderTop: "3px solid transparent"

                                    // borderRight: "3px solid white"
                                  }}
                                >
                                  {item?.description}
                                </td>

                                <td
                                  className="text-right"
                                  style={{
                                    paddingRight: "25px",
                                    paddingTop: "14px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    borderLeft: "3px solid white",
                                    borderTop: "3px solid transparent"
                                  }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "end" }}
                                  >
                                    {item.accepted ? (
                                      <Button
                                        className="btn-round text-capitalize"
                                        style={{
                                          backgroundColor: "#fff",
                                          color: "#3A0F7D",
                                          border: "1px solid  #3A0F7D",
                                          whiteSpace: "nowrap",
                                          height: 40,
                                          width: 132
                                        }}
                                        onClick={() => {
                                          setBusiness(item)
                                          toggle2()
                                        }}
                                      >
                                        Request Hours
                                      </Button>
                                    ) : null}
                                    {item.accepted ? (
                                      <Button
                                        className="btn-round text-capitalize"
                                        style={{
                                          backgroundColor: "#fff",
                                          color: "#3A0F7D",
                                          border: "1px solid  #3A0F7D",
                                          whiteSpace: "nowrap",
                                          height: 40,
                                          width: 81
                                        }}
                                        onClick={() => {
                                          toggle1()
                                          setEngagement(item)
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "11px",
                                            width: "12px",
                                            // marginLeft: "20px",
                                            marginTop: "-3px",
                                            marginRight: "4px"
                                          }}
                                          src={Images.end}
                                        />
                                        End
                                      </Button>
                                    ) : null}
                                    {item.accepted ? null : (
                                      <Button
                                        className="btn-round text-capitalize"
                                        style={{
                                          backgroundColor: "#fff",
                                          color: "#3A0F7D",
                                          border: "1px solid  #3A0F7D",
                                          whiteSpace: "nowrap",
                                          height: 40,
                                          width: 119
                                        }}
                                        onClick={() => {
                                          const data = {
                                            id: item.id,
                                            form: { accepted: true }
                                          }
                                          props.acceptRequest(data, toggle)
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "11px",
                                            width: "12px",
                                            // marginLeft: "20px",
                                            marginRight: "4px"
                                          }}
                                          src={Images.accept}
                                        />
                                        Accept
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr style={{ height: 12 }}></tr>
                            </>
                          )
                        })
                      ) : (
                        <h6 style={{ textAlign: "center" }}>No Data</h6>
                      )}
                    </>
                    {/* ))} */}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {engagementData && engagementData?.results?.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={engagementData?.page_size}
                        totalItemsCount={engagementData?.total_objects}
                        activePage={engagementData?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal1} toggle={toggle1} style={{ maxWidth: "995px" }}>
        <Toaster position="top-center" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 15px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px",
              marginBottom: " 0px"
            }}
          >
            Request to End Engagement
          </h5>
          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              // marginTop: "-10px",
              marginLeft: "20px"
              // marginRight: "12px"
            }}
            src={Images.cros}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Reason for ending Engagement
              </Label>
              <Input
                placeholder="Type here.."
                type="textarea"
                // style={{ backgroundColor: "#EFEFEF" }}
                value={state.reasonText.value}
                onChange={e => {
                  handleOnChange("reasonText", e.target.value)

                  props.expertEndEngagementFailure(false)
                }}
              />
              {BackendError
                ? BackendError?.description && (
                    <label style={{ color: "red", marginTop: "10px" }}>
                      {BackendError?.description}
                    </label>
                  )
                : state.reasonText.error && (
                    <label style={{ color: "red", marginTop: "10px" }}>
                      {state.reasonText.error}
                    </label>
                  )}
            </FormGroup>
            <Label
              style={{
                color: "#808080",
                fontSize: 14,
                fontFamily: "khula"
              }}
            >
              Hitting submit will notify GrowTal of your request to end this
              engagement. A representative will contact you to discuss details
              and timing.
            </Label>
          </Form>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "30px",
            marginLeft: 10,
            marginRight: 10,
            marginTop: "20px"
          }}
        >
          <Link to="#">
            <div>
              <Button
                className="btn-round btn text-capitalize"
                style={{
                  backgroundColor: "#fff",
                  color: "#3A0F7D",
                  border: "1px solid  #3A0F7D"
                }}
                onClick={toggle1}
              >
                Cancel
              </Button>
            </div>
          </Link>
          <div style={{ marginLeft: "12px", marginRight: "12px" }}></div>

          <Button
            // onClick={toggle}
            className="btn-round text-capitalize"
            style={{
              backgroundColor: "#3A0F7D",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
            onClick={onEndEngagement}
          >
            {props.endEngagementRequesting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
        {/* <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Reason for ending Engagement
              </Label>
              <Input
                placeholder="Type here.."
                type="textarea"
                style={{ backgroundColor: "#EFEFEF" }}
                value={state.reasonText.value}
                onChange={e => handleOnChange("reasonText", e.target.value)}
              />
              <Label
                style={{
                  marginTop: "30px",
                  // color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Setup a call with Growtal Team
              </Label>
            </FormGroup>

            <Row>
              <Col lg="6" style={{}}>
                <FormGroup style={{}}>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula"
                    }}
                  >
                    Date
                  </Label>
                  <Input
                    className="col-md-12 col-lg-12"
                    id="exampleText"
                    placeholder="Expert fullname goes here.."
                    name="text"
                    type="date"
                    style={{ backgroundColor: "#EFEFEF" }}
                    value={state.date.value}
                    onChange={e => handleOnChange("date", e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup style={{}}>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula"
                    }}
                  >
                    Slot
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Type of document goes here..."
                    name="text"
                    type="time"
                    style={{ backgroundColor: "#EFEFEF" }}
                    value={state.time.value}
                    onChange={e => handleOnChange("time", e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div> */}

        {/* <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "30px",
            marginLeft: 10,
            marginRight: 10,
            marginTop: "60px"
          }}
        >
          <Link to="#">
            <div>
              <Button
                className="btn-round btn text-capitalize"
                style={{
                  backgroundColor: "#fff",
                  color: "#3A0F7D",
                  border: "1px solid  #3A0F7D",

                  height: "49px",
                  width: "max-content"
                }}
                onClick={toggle1}
              >
                Cancel
              </Button>
            </div>
          </Link>
          <div style={{ marginLeft: "12px", marginRight: "12px" }}></div>
          <Link to="#">
            <div
              // style={{width:'209px', height: '54px'}}
              onClick={() => handleOnSubmit()}
            >
              <Button
                // onClick={toggle}
                className="btn-round text-capitalize"
                style={{
                  backgroundColor: "#3A0F7D",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                  height: "49px"
                }}
              >
                Submit
              </Button>
            </div>
          </Link>
        </div> */}
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggle}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <img
            onClick={toggle}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px"
              // marginRight: "12px"
            }}
            src={Images.cros}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            onClick={toggle}
            style={{
              height: 100,
              width: 100,
              alignSelf: "center"
              // marginRight: "12px"
            }}
            src={Images.feedbackTick}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            fontFamily: "Libre Caslon Text",
            color: "#CACACA"
          }}
        >
          Congratulations!
        </p>
        <p
          style={{
            // display: "flex",
            // justifyContent: "center",
            padding: "30px",
            textAlign: "center",
            fontSize: "15px",
            fontFamily: "Libre Caslon Text",
            color: "#000000",
            marginTop: "-16px"
          }}
        >
          {`Congratulations on your engagement, in order to get paid you will need to start logging hours. You can find that in the Time Tracker tab.`}
        </p>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={toggle}
            className="btn-round text-capitalize"
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              width: "132px",
              marginTop: "-40px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            Continue
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 15px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px",
              marginBottom: " 0px"
            }}
          >
            Request Hours
          </h5>
          <img
            onClick={toggle2}
            style={{
              height: 15,
              width: 15,
              // marginTop: "-10px",
              marginLeft: "20px"
              // marginRight: "12px"
            }}
            src={Images.cros}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Name of Business
              </Label>
              <Input
                placeholder={business.business_name}
                type="text"
                style={{}}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Engagement
              </Label>
              <Input
                placeholder={business.description}
                type="text"
                style={{}}
                disabled={true}
                // value={state.reasonText.value}
                // onChange={e => handleOnChange("reasonText", e.target.value)}
              />
            </FormGroup>
          </Form>
          <FormGroup>
            <Label
              style={{
                color: "#808080",
                fontSize: 14,
                fontFamily: "khula"
              }}
            >
              Amount of hour to increase
            </Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                placeholder="Type here.."
                type="number"
                min="0"
                style={{ width: 115 }}
                value={hour?.value}
                onChange={e => handelHour(e)}
              />
              <p
                className="m-0 ml-2"
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                /hr
              </p>
            </div>
            {}
            {BackendError
              ? BackendError?.hours && (
                  <label style={{ color: "red", marginTop: "10px" }}>
                    {BackendError?.hours[0]}
                  </label>
                )
              : hour?.value === "" && (
                  <label style={{ color: "red", marginTop: "10px" }}>
                    {"Field is required"}
                  </label>
                )}
          </FormGroup>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Description
              </Label>
              <Input
                placeholder="Type here!"
                type="textarea"
                style={{ backgroundColor: "#FFFFFF" }}
                onChange={e => {
                  handleOnChange("description", e.target.value)
                  postRequestHoursFailure(false)
                }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {state.description.error && (
                  <label style={{ color: "red", marginTop: "10px" }}>
                    {state.description.error}
                  </label>
                )}
                {BackendError && BackendError?.description && (
                  <label style={{ color: "red", marginTop: "5px" }}>
                    {BackendError?.description[0]}
                  </label>
                )}
              </div>
            </FormGroup>
          </Form>
          <div style={{}}>
            {/* <label style={{ display: "flex", color: "#808080" }}>
                Client
              </label> */}
            <Label
              style={{
                color: "#808080",
                fontSize: 14,
                fontFamily: "khula"
              }}
            >
              Increase Hours Weekly or One-Time, Please Select Below
            </Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              name="singleSelect"
              value={increaseHour}
              onChange={value => setIncreaseHour(value)}
              options={[
                {
                  value: "",
                  label: "Single Option",
                  isDisabled: true
                },
                // { value: "2", label: "hourly" },
                { value: "1", label: "Weekly" },
                { value: "2", label: "One-Time" }
              ]}
              placeholder="Select Time"
            />
          </div>
          {/* <p
            style={{
              fontSize: 15,
              fontWeight: "400",
              marginTop: 23,
              marginBottom: 0
            }}
          >
            *Upselling additional experts is placed at a $500 incentive.
          </p> */}
        </div>
        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={toggle2}
            className="btn-round text-capitalize"
            // color="secondary"
            style={{
              backgroundColor: "white",
              width: "132px",
              border: "1px solid #3A0F7D",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              color: "#3A0F7D"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleRequestHour()}
            className="btn-round ml-lg-2 text-capitalize"
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              width: "132px",

              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            {props.postRequestHoursRequesting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Request"
            )}
            {/* Request */}
          </Button>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  engagementData: state.Engagement.engagementData,
  requesting: state.Engagement.requesting,
  BackendError: state.Engagement.error,
  postRequestHoursRequesting: state.Engagement.postRequestHoursRequesting,
  endEngageSuccess: state.Engagement.endEngageSuccess,
  endEngagementRequesting: state.Engagement.endEngagementRequesting,
  postedHoursResult: state.Engagement.postedHoursResult
})

const mapDispatchToProps = dispatch => ({
  getEngagement: data => dispatch(getEngagement(data)),
  acceptRequest: (data, toggle) => dispatch(acceptRequest(data, toggle)),
  postRequestHours: data => dispatch(postRequestHours(data)),
  postRequestHoursFailure: data => dispatch(postRequestHoursFailure(data)),
  expertEndEngagementRequest: (data, toggle1) =>
    dispatch(expertEndEngagementRequest(data, toggle1)),
  expertEndEngagementFailure: (data, toggle1) =>
    dispatch(expertEndEngagementFailure(data, toggle1))
})
export default connect(mapStateToProps, mapDispatchToProps)(ActiveEngagements)
