import React from "react"

// hooks

//Components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap"

import { Link } from "react-router-dom"

function Referrals() {
  return (
    <>
      <div className="content">
         <Card className="card-plain">
              <CardHeader>
                
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
               Referrals
                  </CardTitle>
                  </CardHeader>
                  </Card>
        <div style={{ }}>
          <Row>
            <Col md="12">
              <Card>
                <Row
                  className="justify-content-center justify-content-sm-between m-0"
                  style={{
                    alignItems: "center",
                    border: "2px solid #E2E2E2",
                    borderRadius: "10px"
                  }}
                >
                  <CardHeader>
                    <CardTitle
                      className="text-center"
                      style={{ marginTop: "0px" }}
                    >
                     Manage your referrals
                    </CardTitle>
                  </CardHeader>

                  <CardFooter>
                    <a
                      style={{textDecoration: "none"}}
                      href="https://dash.partnerstack.com/handshake?_gl=1%2a8u8p42%2a_ga%2aOTMwNzM1MTc0LjE2NjM3MTcx[…]ga_WZ4FP3QZ3P%2aMTY2NjkxOTk3OC4zLjAuMTY2NjkxOTk3OC4wLjAuMA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        className="btn-round text-capitalize"
                        color="secondary"
                        style={{
                          backgroundColor: "#3A0F7D",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                        }}
                        //   onClick={toggle}
                      >
                        Referral Management
                      </Button>
                    </a>
                  </CardFooter>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{}}>
          <Row>
            <Col md="12">
              <Card>
                <Row
                  className="justify-content-center justify-content-sm-between m-0"
                  style={{
                    alignItems: "center",
                    border: "2px solid #E2E2E2",
                    borderRadius: "10px"
                  }}
                >
                  <CardHeader>
                    <CardTitle
                      className="text-center"
                      style={{ marginTop: "0px" }}
                    >
                      Check the Expert Referral History
                    </CardTitle>
                  </CardHeader>

                  <CardFooter>
                    <Link to="/admin/referrals_expert_history">
                      <Button
                        className="btn-round text-capitalize"
                        color="secondary"
                        style={{
                          backgroundColor: "#3A0F7D",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                        }}
                        // onClick={toggle2}
                      >
                        Expert Referral History
                      </Button>
                    </Link>
                  </CardFooter>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{}}>
          <Row>
            <Col md="12">
              <Card>
                <Row
                  className="justify-content-center justify-content-sm-between m-0"
                  style={{
                    alignItems: "center",
                    border: "2px solid #E2E2E2",
                    borderRadius: "10px"
                  }}
                >
                  <CardHeader>
                    <CardTitle
                      className="text-center"
                      style={{ marginTop: "0px" }}
                    >
                      Check the Business Referral History
                    </CardTitle>
                  </CardHeader>

                  <CardFooter>
                    <Link to="/admin/referrals_business">
                      <Button
                        className="btn-round text-capitalize"
                        color="secondary"
                        style={{
                          backgroundColor: "#3A0F7D",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                        }}
                      >
                        Business Referral History
                      </Button>
                    </Link>
                  </CardFooter>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
export default Referrals
