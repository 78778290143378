export const BASE_URL = process.env.REACT_APP_API_BASE_URL

// export const BASE_URL = "https://app.growtal.com"
// export const BASE_URL = "https://growtal-35169.botics.co"
// export const BASE_URL = "https://c5b1-111-88-86-53.in.ngrok.io"
// export const BASE_URL = "http://192.168.0.179:8000"
// export const BASE_URL = "http://localhost"

// firbase config
// export const FIREBASE_CONFIG = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// }
