import {
  POST_EDITPROFILE_DATA,
  POST_EDITPROFILE_DATA_SUCCESS,
  POST_EDITPROFILE_DATA_FAILURE,
  POST_USERROFILE_DATA,
  POST_USERROFILE_DATA_SUCCESS,
  POST_USERROFILE_DATA_FAILURE
} from "./types"

export const profileRequest = data => ({
  type: POST_EDITPROFILE_DATA,
  data
})

export const profileRequestSuccess = data => ({
  type: POST_EDITPROFILE_DATA_SUCCESS,
  data
})
export const profileRequestFaluire = data => ({
  type: POST_EDITPROFILE_DATA_FAILURE,
  data
})

export const userProfileRequest = data => ({
  type: POST_USERROFILE_DATA,
  data
})

export const userProfileRequestSuccess = data => ({
  type: POST_USERROFILE_DATA_SUCCESS,
  data
})
export const userProfileRequestFaluire = data => ({
  type: POST_USERROFILE_DATA_FAILURE,
  data
})