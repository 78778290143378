import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import PaginationPack from "components/PaginationPack"

import { connect } from "react-redux"
import { getReferRequest } from "Containers/ExpertDesign/Screens/ExpertRefer/redux/actions"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Spinner
} from "reactstrap"
import Select from "react-select"
import { data } from "jquery"
// import "./style.scss"

// import ReactPaginate from 'react-paginate';

import moment from "moment"

function BusinessReferralHistory(props) {
  const { getReferRequest, referData, requesting } = props

  const [getData, setGetData] = useState(false)
  useEffect(() => {
    referData && setGetData(referData?.results)
  }, [referData])

  useEffect(() => {
    getReferRequest(1)
  }, [])

  const handlePageChange = page => {
    getReferRequest(page)
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <div style={{ display: "flex" }}>
                  <img
                    onClick={() => props.history.goBack()}
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "19px",
                      cursor: "pointer"
                    }}
                    src={require("assets/img/backbtn.png")}
                  />
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Referral History
                  </CardTitle>
                </div>
              </CardHeader>
              <CardBody>
                <div>
                  <Table
                    responsive
                    style={{
                      border: "3px solid transparent",
                      borderRadius: "8px"
                      // minHeight: 400
                    }}
                  >
                    <thead>
                      <tr
                        className="w-100"
                        style={{
                          border: "3px solid transparent"
                        }}
                      >
                        <th
                          style={{
                            color: "#808080",
                            padding: "25px",
                            backgroundColor: "#f4f3ef",
                            whiteSpace: "nowrap"
                          }}
                          className="w-25"
                        >
                          Referral Type
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            width: "20%",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            width: "30%",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Link
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            width: "30%",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Email
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            width: "30%",
                            paddingRight: 80,
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Discription
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            paddingRight: "80px",
                            backgroundColor: "#f4f3ef"
                          }}
                          className="text-right w-25"
                        >
                          Date
                        </th>
                      </tr>
                    </thead>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : referData && referData?.results.length > 0 ? (
                      <tbody>
                        {referData?.results.map(item => (
                          <>
                            <tr
                              style={{
                                backgroundColor: "white",
                                marginLeft: "25px",

                                border: "3px solid transparent"
                              }}
                            >
                              <td
                                style={{
                                  padding: "25px",
                                  borderBottomLeftRadius: "10px",
                                  borderTopLeftRadius: "10px"
                                }}
                              >
                                {item.referral_type}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.web_url}</td>
                              <td>{item.email}</td>
                              <td>{item.description}</td>
                              <td
                                className="text-right"
                                style={{
                                  paddingRight: "25px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px"
                                }}
                              >
                                {moment(item.date).format("MM-DD-YYYY")}
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))}
                      </tbody>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </Table>
                </div>

                {referData && referData?.results.length > 0 ? (
                  requesting ? null : (
                    <div className="pt-4 d-flex justify-content-center">
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={referData?.page_size}
                        totalItemsCount={referData?.total_objects}
                        activePage={referData?.current_page_number}
                      />
                    </div>
                  )
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <div style={{ backgroundColor: "red" }}></div>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.ExpertRefer.requesting,
  referData: state.ExpertRefer.referData
})
const mapDispatchToProps = dispatch => ({
  getReferRequest: data => dispatch(getReferRequest(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessReferralHistory)
