import React, { useState, useEffect } from "react"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"
import Select from "react-select"
// import './style.scss';

import moment from "moment"

// import ReactPaginate from 'react-paginate';

import { Link } from "react-router-dom"
import Images from "utils/Images"
import Switch from "react-bootstrap-switch"
import { connect } from "react-redux"

import {
  getNotficationDataRequest,
  patchNotficationRequest,
  hourReminderRequest,
  closePeriodRequest
} from "Containers/ExpertDesign/Screens/Notifications/redux/actions"
import { Toaster } from "react-hot-toast"

function Notifications(props) {
  const {
    getNotficationDataRequest,
    patchNotficationRequest,
    allNotifications,
    requesting,
    closePeriodRequest,
    hourReminderRequest
  } = props

  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)

  const [image, setImage] = useState(false)

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => setModal2(!modal2)
  const toggle3 = () => setModal3(!modal3)
  const toggle4 = () => setModal4(!modal4)

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)

  function utcToLocalTime(utcTime) {
    let t = utcTime;
    let date = t.split("T")[0];
    date = date.split("-");
    let time = t.split("T")[1];
    time = time.split(":");
    time[2] = Math.floor(Number(time[2].slice(0, time[2].length-1)));
    let pm = false;
    if (time[0] >= 12) {
      pm = true;
      time[0] -= 12;
    }
    date = date.join('/');
    time = time.join(":");
    console.log(date);
    let output = `${date} ${time} ${pm ? "PM" : "AM"} UTC`;
    console.log(output, '---------------------------------------');
    return output;
  }



  useEffect(() => {
    getNotficationDataRequest()
  }, [])

  const handleClickNotification = item => {
    const data = {
      id: item.id,
      data: {
        read: true
      }
    }
    patchNotficationRequest(data)
  }

  const handleRequestHoure = () => {
    const data = {}
    hourReminderRequest()
    toggle1()
  }

  const handleCloseBilling = () => {
    const data = {}
    closePeriodRequest()
    toggle2()
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader style={{}}>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline"
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Notification
                  </CardTitle>
                  <Row
                    className="header"
                    style={{
                      display: "flex",
                      alignItems: "center",

                      marginTop: "-10px"
                    }}
                  >
                    <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        maxWidth: "fit-conntent",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={toggle2}
                    >
                      Close Billing Period
                    </Button>
                    <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        maxWidth: "fit-conntent",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={toggle1}
                    >
                      Hour Reminder
                    </Button>
                    {/* <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        maxWidth: "fit-conntent",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={toggle}
                    >
                      Onboarding
                    </Button> */}
                  </Row>
                </Row>
              </CardHeader>

              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        // padding: "25px",

                        paddingLeft: "22px"
                      }}
                    >
                      Notification Details
                    </th>

                    <th
                      className="text-right"
                      style={{
                        color: "#808080",

                        paddingRight: "72px"
                      }}
                    >
                      Date
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "90%",
                          // transform: "translate(-50%, -50%)",
                          left: "50%"
                          // backgroundColor: 'red'
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : allNotifications &&
                      allNotifications?.notification.length > 0 ? (
                      allNotifications?.notification.map(item => (
                        <>
                          <tr
                            onClick={() => handleClickNotification(item)}
                            style={{
                              // border: "3px solid",
                              // borderRadius: "50px !mportant",
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent",
                              cursor: "pointer"
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                padding: "25px",
                                border: "3px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px"
                              }}
                            >
                              {item.read ? (
                                <img
                                  style={{
                                    width: "17px",
                                    height: "20px",
                                    marginRight: "20px"
                                  }}
                                  src={Images.Read_Icon}
                                />
                              ) : (
                                <img
                                  style={{
                                    width: "17px",
                                    height: "20px",
                                    marginRight: "20px"
                                  }}
                                  src={Images.Unread_Icon}
                                />
                              )}
                              <div style={{}}>
                                <span
                                  style={{ color: "#E9463A", marginRight: 5 }}
                                >
                                  {`${item?.data?.name1}`}
                                </span>
                                <span>{item?.data?.text}</span>
                                <span
                                  style={{
                                    color: "#E9463A",
                                    paddingLeft: "5px"
                                  }}
                                >
                                  {item?.data?.name2}
                                </span>
                              </div>
                            </td>

                            <td
                              className="text-right"
                              // className="text-left "
                              style={{
                                paddingTop: "14px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                paddingRight: "36px"
                              }}
                            >
                              <p>
                                {moment(new Date(utcToLocalTime(item?.created))).format("MM-DD-YYYY")}
                                                             </p>
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <h6 style={{ textAlign: "center" }}>No Data</h6>
                      </div>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          toggle={toggle}
          // className={className}
          // external={externalCloseBtn}
          //   style={{ maxWidth: "994px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Onboarding Link
            </h5>
            <img
              onClick={toggle}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Email
                </Label>
                <Input
                  placeholder="Type email"
                  type="email"
                  style={{
                    fontFamily: "Khula",
                    fontWeight: "400",
                    fontSize: "15px",
                    placeholderColor: "#D0D0D0",
                    height: "49px",
                    border: "1px solid"
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Message
                </Label>
                <Input
                  placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                  type="textarea"
                  style={{ border: "1px solid" }}
                />
              </FormGroup>
            </Form>
          </div>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize
                              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                // display: "flex",
                // alignItems: "center",
                height: "49px",
                width: "max-content"
              }}
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button
              onClick={toggle}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              Save Changes
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          // className={className}
          // external={externalCloseBtn}
          style={{}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              // onClick={toggle1}
              style={{
                height: 100,
                width: 100,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/EnterHours.png")}
            />
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              fontSize: "22px",
              fontFamily: "Libre Caslon Text",
              color: "#CACACA"
            }}
          >
            Close Billing Period Reminder
          </p>
          <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              padding: "30px",
              textAlign: "center",
              fontSize: "15px",
              fontFamily: "Libre Caslon Text",
              color: "#000000"
            }}
          >
            Are you sure you want to send a 1 hour reminder that the billing
            period is closing?
          </p>
          {/* <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              // padding: "30px",
              marginTop: "-45px",
              textAlign: "center",
              fontSize: "15px",
              fontFamily: "Libre Caslon Text",
              color: "#000000"
            }}
          >
            closing billing hour.
          </p> */}
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle1}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              No, I don’t
            </Button>
            <Button
              onClick={handleRequestHoure}
              className="btn-round text-capitalize
              "
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                // width: "132px",
                marginLeft: "20px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              Yes, I want to
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modal2}
          toggle2={toggle2}
          // className={className}
          // external={externalCloseBtn}
          style={{}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <img
              onClick={toggle2}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              // onClick={toggle1}
              style={{
                height: 100,
                width: 100,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/closingHour.png")}
            />
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              fontSize: "22px",
              fontFamily: "Libre Caslon Text",
              color: "#CACACA"
            }}
          >
            {/* Enter hours for previous billing period! */}
            Close Billing Period
          </p>
          {/* <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "-18px",
              fontSize: "22px",
              fontFamily: "Libre Caslon Text",
              color: "#CACACA"
            }}
          >
            Week!
          </p> */}
          <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              padding: "30px",
              textAlign: "center",
              fontSize: "15px",
              marginTop: "-18px",
              fontFamily: "Libre Caslon Text",
              color: "#000000"
            }}
          >
            Are you sure you want to close the billing period?
          </p>

          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              onClick={toggle2}
              className="btn-round btn text-capitalize
              "
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              No, I don’t
            </Button>
            <Button
              onClick={handleCloseBilling}
              className="btn-round text-capitalize
              "
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                // width: "132px",
                marginLeft: "20px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px"
              }}
            >
              Yes, I want to
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  allNotifications: state.Notifications.allNotifications,
  requesting: state.Notifications.requesting,
  BackendError: state.Notifications.error
  // linkedInCode: state.LoginScreen.linkedInCode,
  // linkedInSuccess: state.LoginScreen.linkedInSuccess,
  // accessToken: state.LoginScreen.accessToken,
})

const mapDispatchToProps = dispatch => ({
  getNotficationDataRequest: () => dispatch(getNotficationDataRequest()),
  patchNotficationRequest: data => dispatch(patchNotficationRequest(data)),
  hourReminderRequest: data => dispatch(hourReminderRequest(data)),
  closePeriodRequest: data => dispatch(closePeriodRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
