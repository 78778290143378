import React, { useState } from "react"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap"
import Select from "react-select"
import Images from "utils/Images"

function HistoricalView() {
  const [businessName, setBusinessName] = useState(false)
  const [category, setCategory] = useState(false)
  const [city, setCity] = useState(false)
  const [branches, setBranches] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [position, setPosition] = useState(false)
  const [mobileNo, setMobileNo] = useState(false)
  const [modal, setModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  let PageSize = 10

  const toggle = () => setModal(!modal)

  const handleSubmit = () => {}
  const dummyData = [
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    },
    {
      Name: "Entrie name goes here.."
    }
  ]

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Historical View
                  </CardTitle>
                  {/* <div style={{ width: '200px' }}> */}
                  <Button
                    onClick={toggle}
                    className="btn-round btn text-capitalize
                    "
                    style={{
                      backgroundColor: "#fff",
                      color: "#3A0F7D",
                      border: "1px solid  #3A0F7D",
                      // width: "220px",
                      height: "49px",
                      marginTop: "0px"
                    }}
                  >
                    <img
                      //   onClick={toggle}
                      style={{
                        height: 13,
                        width: 13,
                        marginTop: "-4px",
                        // marginLeft: "20px"
                        marginRight: "6px",
                        borderRadius: "0px"
                      }}
                      src={Images.LogLight}
                    />
                    Expport Excel, CSV
                  </Button>
                  {/* </div> */}
                </Row>
                {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
              </CardHeader>
              <CardBody>
                <Table
                responsive="md"
                  style={{
                    border: "3px solid transparent",
                    marginTop: "-20px"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th style={{ color: "#808080", paddingTop: "40px" }}>
                      Expert Entries
                    </th>
                    {/* <th style={{ color: "#808080" }}>Project Exhibits</th>
                    <th style={{ color: "#808080" }}>Expert Fullname</th>
                    <th style={{ color: "#808080" }}>Document Type</th> */}

                    <th
                      className="text-center"
                      style={{
                        color: "#808080",
                        
                        paddingTop: "40px"
                      }}
                    >
                      Action
                    </th>
                  </tr>
                  {/* </thead> */}

                  <tbody>
                    {dummyData.map(item => (
                      <>
                        <tr
                          style={{
                            backgroundColor: "white",
                            marginLeft: "25px",
                            border: "3px solid transparent"
                          }}
                        >
                          <td
                            style={{
                              padding: "25px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px"
                            }}
                          >
                            {item.Name}
                          </td>

                          <td
                            className="text-right"
                            style={{
                              paddingRight: "25px",
                              paddingTop: "14px",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px"
                            }}
                          >
                            <Button
                              className="btn-round btn text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                color: "#3A0F7D",
                                border: "1px solid  #3A0F7D",
                                width:"max-content"
                              }}
                              onClick={toggle}
                            >
                              <img
                                style={{
                                  height: "11px",
                                  width: "12px",
                                  // marginLeft: "20px",
                                  marginRight: "4px",
                                  marginTop: "-3px",
                                  borderRadius: "0px"
                                }}
                                src={Images.LogLight}
                              />
                              Export to CSV
                            </Button>
                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}></tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  <PaginationPack />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default HistoricalView
