import React, { useEffect, useState } from "react"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  Modal
} from "reactstrap"
import Images from "utils/Images"

import {
  loginRequest,
  loginViaLinkedInRequest,
  loginViaGoogleRequest,
  linkedInCodeData,
  loginFaluire
} from "./redux/actions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"
import { BASE_URL } from "config/app"

import "./style.css"

import toast, { Toaster } from "react-hot-toast"

import { OS, currentBrowser } from "utils/platform"

// import FacebookLogin from 'react-facebook-login';

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { LinkedIn } from "react-linkedin-login-oauth2"

import { GoogleLogin } from "react-google-login"
import { gapi } from "gapi-script"

const LoginScreen = props => {
  const clientId =
    "1048984404625-3kjheoht0ribe92j49a50kbnmettv0eu.apps.googleusercontent.com"
  useEffect(() => {
    function start() {
      if(gapi.client){
        gapi.client.init({
          clientId: clientId,
          scope: ""
        })
      } 
    }
    gapi.load("client:auth2", start)
  }, [])

  const {
    history,
    loginRequest,
    userData,
    loginViaLinkedInRequest,
    loginViaGoogleRequest,
    requesting,
    BackendError,
    loginFaluire,
    deviceID
    // loginaccessToken,
    // linkedInCodeData,
    // linkedInCode,
    // linkedInSuccess,
    // accessToken
  } = props

  const [isAppleBtn, setAppleBtn] = useState(false)

  const [login, setLogin] = useState(false)
  // const [windowState, setWindowState] = useState(false)

  const [FbData, setFbData] = useState(false)
  const [linkedInToken, setlinkedInToken] = useState(false)
  const [picture, setPicture] = useState("")
  const [check, setCheck] = useState(false)
  const [isUserType, setIsUserType] = useState(true)
  const [modalType, setModalType] = useState("")
  const [socialType, setSocialType] = useState(false)

  const [modal1, setModal1] = useState(false)

  const toggle1 = () => {
    setModal1(!modal1)
  }

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    },
    password: {
      required: true
      //   validator: validator.password,
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handlelogin = () => {
    if (state.email.value && state.password.value) {
      loginFaluire(false)
    }
    const data = {
      device_id: deviceID,
      data: {
        username: state.email.value,
        password: state.password.value
      }
    }
    loginRequest(data)
    // } else {
    //   toast.error("Both Fields are required")
    // }
  }

  useEffect(() => {
    if (socialType == "google") {
    } else {
      socialType == "linkedin" &&
        window.location.replace(
          `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=77s7tpyzrmsjq8&scope=r_liteprofile+r_emailaddress&state=1234&redirect_uri=${BASE_URL}/auth/login`
        )
    }
  }, [modalType])

  // useEffect(() => {
  //   OS(window) == "MacOS" && setAppleBtn(true)
  // }, [])

  // useEffect(() => {
  //   linkedInCode && responseLinkedIn(linkedInCode)
  //   // linkedInCode && setTimeout(() => {
  //   //   window.close()
  //   // }, 1000)
  //   // const elementId = document.getElementById('test');
  //   //   if (elementId) {
  //   //     elementId.innerHTML = "Text Xchanmge"
  //   //   }
  // }, [linkedInCode])

  const responseFacebook = response => {
    setFbData(response)
    // setPicture(response.picture.data.url);
    // if (response.accessToken) {
    //   setLogin(true);
    //   loginViaFacebookRequest({ access_token: response.accessToken })
    // } else {
    //   setLogin(false);
    // }
  }

  // useEffect(() => {
  //   if (FbData?.accessToken) {
  //     // setLogin(true);
  //     loginViaFacebookRequest({ access_token: FbData.accessToken })
  //   } else {
  //     // setLogin(false);
  //   }
  // }, [FbData])

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.close()
  //   }, 1000)
  // }, [linkedInSuccess])

  useEffect(() => {
    const query = new URLSearchParams(props.location.search)
    const token = query.get("code")
    token && responseLinkedIn(token)
    if (window.opener && window.opener !== window) {
      const query = new URLSearchParams(props.location.search)
      const token = query.get("code")
      // token && linkedInCodeData(token)
      setTimeout(() => {
        window.close()
      }, 1000)
    }
    // else {
    //   alert("linkedInCode", linkedInCode)
    //   token && setTimeout(() => {
    //     window.location.reload()
    //   }, 5000)
    // }
  }, [])

  const responseLinkedIn = token => {
    const data = {
      device_id: deviceID,
      data: {
        code: token,
        user_type: modalType
      }
    }
    // setTimeout(() => {
    loginViaLinkedInRequest(data)
    // }, 1000)
  }

  const responseGoogle = res => {
    const data = {
      device_id: deviceID,
      data: {
        access_token: res.accessToken,
        user_type: modalType
      }
    }
    loginViaGoogleRequest(data)
  }
  return (
    <>
      <Toaster position="top-center" />
      <div className="register-page">
        <Container>
          <Row style={{ justifyContent: "space-between", marginBottom: 20 }}>
            <Col className="mr-auto ml-atuo" lg="6" md="6">
              <img src={require("assets/img/left_images.png")} />
            </Col>

            <Col className="m-0" lg="6" md="6">
              <h5
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  fontFamily: "Libre Caslon Text",
                  fontSize: "30px",
                  fontWeight: "700"
                }}
              >
                Login
              </h5>
              <Card
                className="card-signup text-center"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginBottom: "0px"
                }}
              >
                <CardBody style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <Form action="" className="form" method="">
                    <label
                      style={{
                        display: "flex",
                        paddingTop: "30px",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Email
                    </label>
                    <InputGroup
                      className="no-border"
                      style={{
                        border: "1.5px solid #808080",
                        borderRadius: "5px"
                      }}
                    >
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{ backgroundColor: "white", borderRadius: 8 }}
                        >
                          <img
                            style={{
                              height: "15px",
                              width: "19px"
                            }}
                            src={Images.email_logo}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Type email"
                        type="email"
                        style={{ backgroundColor: "white", height: "55px" }}
                        onChange={e => {
                          handleOnChange("email", e.target.value)
                          loginFaluire(false)
                        }}
                      />
                    </InputGroup>

                    {BackendError
                      ? BackendError?.username && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.username[0]}
                          </label>
                        )
                      : state.email.error && (
                          <label style={{ color: "red", display: "flex" }}>
                            {state.email.error}
                          </label>
                        )}
                    <label
                      style={{
                        display: "flex",
                        fontFamily: "Khula",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#808080"
                      }}
                    >
                      Password
                    </label>
                    <InputGroup
                      className="no-border"
                      style={{
                        border: "1.5px solid #808080",
                        borderRadius: "5px"
                      }}
                    >
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{ backgroundColor: "white", borderRadius: 8 }}
                        >
                          <img
                            style={{
                              height: "20px",
                              width: "18px"
                            }}
                            src={Images.password_logo}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Type password"
                        type="password"
                        style={{ backgroundColor: "white", height: "55px" }}
                        onChange={e => {
                          handleOnChange("password", e.target.value)
                          loginFaluire(false)
                        }}
                      />
                    </InputGroup>

                    {BackendError
                      ? BackendError?.password && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              marginBottom: "0px"
                            }}
                          >
                            {BackendError?.password[0]}
                          </label>
                        )
                      : state.password.error && (
                          <label style={{ color: "red", display: "flex" }}>
                            {state.password.error}
                          </label>
                        )}
                    <FormGroup
                      check
                      // className="text-left"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={() => setCheck(!check)}
                          />
                          <span
                            className="form-check-sign"
                            style={{
                              height: "10px",
                              backgroundColor: "#3A0F7D"
                            }}
                          />
                          <span
                            style={{
                              color: "#808080",
                              fontFamily: "Khula",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "22px",
                              margin: "0px",
                              whiteSpace: "nowrap"
                            }}
                          >
                            Remember me
                          </span>
                        </Label>
                        <Label style={{ alignSelf: "end" }}>
                          <Link
                            style={{ textDecoration: "none" }}
                            to="/auth/forgot"
                          >
                            <p
                              className="p-lg-0 pr-2 "
                              style={{
                                color: "#3A0F7D",
                                fontFamily: "Khula",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "26px",
                                wordBreak: "break-spaces"
                              }}
                            >
                              Forgot Password
                            </p>
                          </Link>
                        </Label>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter style={{ padding: "0px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    onClick={e => {
                      e.preventDefault()
                      handlelogin()
                    }}
                  >
                    {/* <div style={{ cursor: "pointer" }}>
                      <p
                        style={{
                          backgroundColor: "#3A0F7D",
                          padding: "15px 85px 15px 85px",
                          borderRadius: "50px",
                          color: "white",
                          fontWeight: "bold"
                        }}
                      >
                        {requesting ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Login"
                        )}
                      </p>

                    </div> */}
                    <Button
                      className="btn-round text-capitalize"
                      color="secondary"
                      style={{
                        width: "209px",
                        backgroundColor: "#3A0F7D",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                        height: "54px",
                        fontSize: "16px"
                      }}
                    >
                      {requesting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </div>
                  {/* <Button
                    style={{ backgroundColor: '#3A0F7D', width: '45%', paddingTop: '18px', paddingBottom: '18px' }}
                    className="btn-round"
                    color=""
                    href="#"
                    onClick={(e) => { e.preventDefault(); handlelogin() }}
                  >
                    Login
                  </Button> */}
                  <div
                    className="my-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        borderBottom: "1px #808080 solid",
                        width: "35%"
                      }}
                    />
                    <p
                      style={{
                        alignSelf: "center",
                        margin: "0px",
                        color: "#808080",
                        fontFamily: "Khula",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22px"
                      }}
                    >
                      or login with
                    </p>
                    <div
                      style={{
                        borderBottom: "1px #808080 solid",
                        width: "35%"
                      }}
                    />
                  </div>
                  <div className="social" style={{}}>
                    {/* <FacebookLogin
                      appId="2124993807656772"
                      autoLoad={false}
                      callback={responseFacebook}
                      render={renderProps => (
                        <img onClick={renderProps.onClick} src={require("assets/img/facebook_img.png")} />
                        // <button >This is my custom FB button</button>
                      )}
                    /> */}

                    {/* <LinkedIn
                      clientId="77s7tpyzrmsjq8"
                      scope={"r_liteprofile+r_emailaddress"}
                      redirectUri={`http://localhost:3000/auth/login`}
                    >
                      {({ linkedInLogin }) => (
                        <img
                          onClick={linkedInLogin}
                          src={Images.linkedIn_social}
                          style={{
                            height: "60px",
                            width: "60px",
                            backgroundColor: "white",
                            cursor: "pointer"
                          }}
                        />
                      )}
                    </LinkedIn> */}

                    {isUserType ? (
                      <img
                        onClick={() =>
                          window.location.replace(
                            `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=77s7tpyzrmsjq8&scope=r_liteprofile+r_emailaddress&state=1234&redirect_uri=${BASE_URL}/auth/login`
                          )
                        }
                        src={Images.linkedIn_social}
                        style={{
                          height: "60px",
                          width: "60px",
                          backgroundColor: "white",
                          cursor: "pointer"
                        }}
                      />
                    ) : (
                      <img
                        onClick={() => {
                          setModal1(true)
                          setSocialType("linkedin")
                        }}
                        src={Images.linkedIn_social}
                        style={{
                          height: "60px",
                          width: "60px",
                          backgroundColor: "white",
                          cursor: "pointer"
                        }}
                      />
                    )}

                    {/* <LinkedIn
                      clientId="77s7tpyzrmsjq8"
                      onFailure={handleFailure}
                      onSuccess={handleSuccess}
                      redirectUri={`https://www.google.com`}
                    >
                      <img src={Images.linkedIn_social} style={{ height: '60px', width: '60px', backgroundColor: 'white' }} />
                    </LinkedIn> */}

                    {/* <img onClick={requestProfile} src={Images.linkedIn_social} style={{ height: '60px', width: '60px', backgroundColor: 'white' }} /> */}

                    {isUserType ? (
                      <GoogleLogin
                        clientId={clientId}
                        render={renderProps => (
                          // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                          <img
                            onClick={renderProps.onClick}
                            style={{
                              marginLeft: "20px",

                              cursor: "pointer"
                            }}
                            src={require("assets/img/google_img.png")}
                          />
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        // onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    ) : (
                      <img
                        onClick={() => {
                          setModal1(true)
                          setSocialType("google")
                        }}
                        style={{
                          marginLeft: "20px",

                          cursor: "pointer"
                        }}
                        src={require("assets/img/google_img.png")}
                      />
                    )}

                    {/* {isAppleBtn && (
                      <img src={require("assets/img/apple_img.png")} />
                    )} */}
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <p style={{ fontSize: "16px" }}>
                      Don’t have an account yet?{" "}
                      <Link
                        to="/auth/register"
                        style={{
                          color: "#3A0F7D",
                          textDecoration: "none",
                          fontSize: "16px"
                        }}
                      >
                        Create New Account
                      </Link>
                    </p>
                  </div>
                </CardFooter>

                {/* <FacebookLogin
                  appId="2124993807656772"
                  autoLoad={true}
                  fields="name,email,picture"
                  scope="public_profile,user_friends"
                  callback={responseFacebook}
                  icon="fa-facebook" /> */}
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/auth_bg.png")})`
          }}
        >
          <img
            src={Images.logo_name}
            style={{ bottom: 30, position: "absolute", right: 30 }}
          />
        </div>
        <Modal
          isOpen={modal1}
          toggle1={toggle1}
          centered={true}
          // className={className}
          // external={externalCloseBtn}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",

                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              UserType
            </h5>
            <img
              onClick={toggle1}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px"
                // marginRight: "12px"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>

          <h4 style={{ textAlign: "center" }}>Please Select Your Role Type</h4>

          <div
            style={{
              margin: "50px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center"
            }}
          >
            <Button
              onClick={() => {
                toggle1()
                setModalType("Expert")
              }}
              className="btn-round  text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "10px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Expert
            </Button>
            <Button
              onClick={() => {
                toggle1()
                setModalType("Business")
              }}
              className="btn-round  text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                marginRight: "10px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Business
            </Button>
            {/* <Button
              // onClick={() => handleSubmit()}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
              }}
            >
              Business
            </Button> */}
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  userData: state.LoginScreen.user,
  requesting: state.LoginScreen.requesting,
  BackendError: state.LoginScreen.error,
  deviceID: state.LoginScreen.deviceID
  // loginaccessToken: state.LoginScreen.accessToken,
  // linkedInCode: state.LoginScreen.linkedInCode,
  // linkedInSuccess: state.LoginScreen.linkedInSuccess,
  // accessToken: state.LoginScreen.accessToken,
})

const mapDispatchToProps = dispatch => ({
  loginRequest: data => dispatch(loginRequest(data)),
  loginViaLinkedInRequest: data => dispatch(loginViaLinkedInRequest(data)),
  loginViaGoogleRequest: data => dispatch(loginViaGoogleRequest(data)),
  loginFaluire: data => dispatch(loginFaluire(data))
  // linkedInCodeData: data => dispatch(linkedInCodeData(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)

const styles = {
  tooggleStyle: {
    backgroundColor: "transparent",
    padding: 0
  }
}
