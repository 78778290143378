import React, { useState, useEffect, useRef } from "react"
//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "reactstrap"
import { Link } from "react-router-dom"
import useForm from "utils/useForm"
import validator from "utils/validation"
import toast, { Toaster } from "react-hot-toast"
import Select from "react-select"

import moment from "moment"
import { resourcesRequest } from "../../ExpertDesign/Screens/ResourceArea/redux/actions"
import InfiniteDropDown from "components/InfiniteDropDown"
// import { DateRangePicker } from "react-date-range"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"

import InfiniteScroll from "react-infinite-scroll-component"

import {
  getBusinessDataRequest,
  getExpertDataRequest,
  postEngagementRequest,
  postEngagementFaluire
} from "./ActiveEngagment/redux/actions"

import { connect } from "react-redux"

function Engagement(props) {
  const {
    getBusinessDataRequest,
    getExpertDataRequest,
    postEngagementRequest,
    requesting,
    expertSearch,
    businessSearch,
    BackendError,
    postSuccess,
    postEngagementFaluire
  } = props

  const [value, onChange] = useState(new Date())

  const [allBusinessNames, setAllBusinessNames] = useState([])
  const [allExpertsNames, setAllExpertsNames] = useState([])
  const [selectBusinessNames, setSelectBusinessNames] = useState(false)
  const [selectExpertNames, setSelectExpertNames] = useState(false)
  const [expertHour, setExpertHour] = useState({ value: "0" })
  const [businessHour, setBusinessHour] = useState({ value: "0" })
  const [agreedHour, setAgreedHour] = useState({ value: "0" })

  var testArray = []

  useEffect(() => {
    getBusinessDataRequest("")
    getExpertDataRequest("")
  }, [])

  // useEffect(() => {
  //   if (expertSearch) {
  //     handleExpertNamesData()
  //   }
  //   if (businessSearch) {
  //     handleBusinessNamesData()
  //   }
  // }, [businessSearch, expertSearch])

  const handleBusinessNamesData = () => {
    let tempArray = []
    // if (businessSearch) {
    businessSearch &&
      businessSearch.forEach((item, index) => {
        tempArray.push({
          value: index,
          label: `${item?.company_name}`,
          businessEmail: `${item?.business_data?.email}`,
          id: `${item?.business_data?.id}`
        })
      })
    // }

    setAllBusinessNames(tempArray)
    testArray = tempArray
  }

  const handleExpertNamesData = () => {
    let tempArray = []
    if (expertSearch) {
      expertSearch &&
        expertSearch.forEach((item, index) => {
          tempArray.push({
            value: index,
            label: `${item?.expert_data?.name}`,
            expertEmail: `${item?.expert_data?.email}`,
            id: `${item?.expert_data?.id}`
          })
        })
    }
    setAllExpertsNames(tempArray)
  }

  const handleBusinessNameSearch = val => {
    // if (val.length > 3) {
    // setTimeout(() => {
    getBusinessDataRequest(val)
    // }, 2000)
    // }
  }

  const handleExpertNameSearch = val => {
    // if (val.length > 3) {
    // setTimeout(() => {
    getExpertDataRequest(val)
    // }, 2000)
    // }
  }

  const [role, setRole] = useState(false)
  const [roles, setRoles] = useState(false)
  const [modal1, setModal1] = useState(false)
  const toggle1 = () => {
    setModal1(!modal1)
    postEngagementFaluire(false)

    setExpertHour("")
    setBusinessHour("")
    setAgreedHour("")
    onChange("")
    setSelectBusinessNames("")
    setSelectExpertNames("")
    setState(stateSchema)
  }

  useEffect(() => {
    postSuccess && setModal1(false)
  }, [postSuccess])

  const stateSchema = {
    businessName: {
      value: "",
      error: ""
    },
    expertName: {
      value: "",
      error: ""
    },
    expertHourly: {
      value: "",
      error: ""
    },
    businessHourly: {
      value: "",
      error: ""
    },

    hourlyAgreed: {
      value: "",
      error: ""
    },
    engagementDate: {
      value: "",
      error: ""
    },
    engagementBio: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    businessName: {
      required: true
    },
    expertName: {
      required: true
    },
    expertHourly: {
      required: true
    },
    businessHourly: {
      required: true
    },

    hourlyAgreed: {
      required: true
    },
    engagementDate: {
      required: true
    },
    engagementBio: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const handleOnFilter = () => {
    const data = {
      expert_rate: expertHour.value,
      business_rate: businessHour.value,
      rate_per_bill: agreedHour.value,
      agreement_start_date: `${moment(value).format("YYYY-MM-DD")}`,
      description: state.engagementBio.value,
      business: selectBusinessNames?.value,
      expert: selectExpertNames?.value,
      title: state.engagementBio.value
    }
    postEngagementRequest(data)
  }

  const handleExpertHour = ({ target: { value } }) => {
    setExpertHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )
  }
  const handleBusinessHour = ({ target: { value } }) => {
    setBusinessHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )
  }
  const handleAgreedHour = ({ target: { value } }) => {
    setAgreedHour(
      state => (value >= 0 && !isNaN(Number(value)) && { value }) || state
    )
  }
  const fetchMoreData = () => {}

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col lg="6">
                    <CardTitle
                      tag="h4"
                      style={{
                        fontFamily: "Libre Caslon Text",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#CACACA"
                      }}
                    >
                      Engagements
                    </CardTitle>
                  </Col>

                  {/* <div style={{ width: '200px' }}> */}
                  <Col
                    lg="6"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "auto"
                    }}
                  >
                    <Button
                      className="btn-round text-capitalize"
                      style={{
                        border: "2px #3A0F7D solid",
                        backgroundColor: "#FFFF",
                        width: "200px",
                        color: "#3A0F7D",
                        fontWeight: "700",
                        fontSize: "12px",
                        cursor: "pointer"
                      }}
                      onClick={toggle1}
                    >
                      + Create Engagement
                    </Button>

                    {/* </div> */}
                  </Col>
                  {/* <p className="card-category">
                                    Here is a subtitle for this table
                                </p> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Row
                  className="justify-content-center justify-content-sm-between"
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: "10px",
                    marginBottom: "25px",
                    paddingLeft: "20px"
                  }}
                >
                  <p
                    className="mt-sm-2"
                    style={{ padding: "0px", margin: "0px", fontSize: "15px" }}
                  >
                    Check all the active engagements
                  </p>
                  <div
                    className="mr-lg-3"
                    style={{
                      alignItems: "center",
                      paddingTop: "16px",
                      paddingBottom: "16px"
                    }}
                  >
                    <Link to="/admin/engagements_active">
                      <Button
                        className="text-capitalize"
                        style={{
                          backgroundColor: "#3A0F7D",
                          // padding: "8px 25px 8px 25px",
                          marginBottom: "0px",
                          borderRadius: "50px",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "14px",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                          margin: "0px"
                        }}
                      >
                        Active Engagements
                      </Button>
                    </Link>
                  </div>
                </Row>

                <Row
                  className=" justify-content-center justify-content-sm-between"
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: "10px",
                    marginBottom: "25px",
                    paddingLeft: "20px"
                  }}
                >
                  <Col sm="4" md="4">
                    <p
                      className="mt-sm-2"
                      style={{
                        padding: "0px",
                        margin: "0px",
                        fontSize: "15px"
                      }}
                    >
                      Check all the requests for engagements
                    </p>
                  </Col>
                  <Col sm="8" md="8">
                    <Row
                      className=" justify-content-md-end justify-content-center"
                      style={{
                        alignItems: "center",

                        paddingTop: "16px",
                        paddingBottom: "16px",
                        display: "flex"
                      }}
                    >
                      <Link to="/admin/engagements_Request">
                        <Button
                          className=" text-capitalize"
                          style={{
                            backgroundColor: "#3A0F7D",
                            // padding: "8px 25px 8px 25px",
                            marginBottom: "0px",
                            borderRadius: "50px",
                            color: "white",
                            fontWeight: "700",
                            fontSize: "14px",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                            margin: "10px"
                          }}
                        >
                          Engagement Requests
                        </Button>
                      </Link>
                      <Link to="/admin/engagements_opportunity">
                        <Button
                          className="ml-sm-2 text-capitalize"
                          style={{
                            backgroundColor: "#3A0F7D",
                            // padding: "8px 25px 8px 25px",
                            marginBottom: "0px",
                            borderRadius: "50px",
                            color: "white",

                            fontSize: "14px",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                            margin: "0px"
                          }}
                        >
                          Engagement Opportunities
                        </Button>
                      </Link>
                    </Row>
                  </Col>
                </Row>

                <Row
                  className="justify-content-center justify-content-sm-between"
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: "10px",
                    paddingLeft: "20px"
                  }}
                >
                  <p
                    style={{
                      padding: "0px",
                      marginBottom: "0px",
                      fontSize: "15px"
                    }}
                  >
                    Check all the engagements history
                  </p>
                  <div
                    className="mr-lg-3"
                    style={{
                      alignItems: "center",
                      paddingTop: "16px",
                      paddingBottom: "16px"
                    }}
                  >
                    <Link to="/admin/engagements_history">
                      <Button
                        className="ml-sm-2 text-capitalize"
                        style={{
                          backgroundColor: "#3A0F7D",
                          // padding: "8px 25px 8px 25px",
                          marginBottom: "0px",
                          borderRadius: "50px",
                          color: "white",

                          fontSize: "14px",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                          margin: "0px"
                        }}
                      >
                        Engagements History
                      </Button>
                    </Link>
                  </div>
                </Row>
                {/* <div style={{ height: "100px" }}>
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={modal1}
        toggle={toggle1}
        // className={className}
        // external={externalCloseBtn}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Create Engagement
          </h5>
          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: "10px"
                }}
              >
                Business Name
              </Label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="pageSelect"
                search={true}
                // isSearchable={false}
                value={selectBusinessNames}
                // onInputChange={val => handleBusinessNameSearch(val)}
                onChange={value => {
                  setSelectBusinessNames(value)
                  postEngagementFaluire(false)
                  // shareBusinessDataFaluire(false)
                }}
                options={businessSearch && businessSearch}
                placeholder="Business name"
              />
              {/* <Input
                placeholder="Business Name"
                type="text"
                value={state.businessName.value}
                onChange={e => handleOnChange("businessName", e.target.value)}
              /> */}
              {state.businessName.error && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {state.businessName.error}
                </Label>
              )}
              {BackendError && BackendError?.business && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {BackendError?.business[0]}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: "10px"
                }}
              >
                Expert Name
              </Label>
              <div>
                {/* <InfiniteDropDown listingData={allExpertsNames} /> */}
              </div>

              {/* <InfiniteScroll
                dataLength={allExpertsNames.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                style={{ height: "100px" }}
              >
                {allExpertsNames.map((i, index) => (
                  <div key={index}>div - #{index}</div>
                ))}
              </InfiniteScroll> */}
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="pageSelect"
                search={true}
                value={selectExpertNames}
                // onInputChange={val => handleExpertNameSearch(val)}
                onChange={value => {
                  setSelectExpertNames(value)
                  postEngagementFaluire(false)
                  // shareBusinessDataFaluire(false)
                }}
                options={expertSearch && expertSearch}
                placeholder="Expert name"
              />
              {/* <Input
                placeholder="Expert Name"
                type="text"
                value={state.expertName.value}
                onChange={e => handleOnChange("expertName", e.target.value)}
              /> */}
              {state.expertName.error && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {state.expertName.error}
                </Label>
              )}
              {BackendError && BackendError?.expert && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {BackendError?.expert[0]}
                </Label>
              )}
            </FormGroup>
            <Row className="p-0 m-0">
              <Col lg="6" className="p-0 m-0">
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "10px"
                    }}
                  >
                    Expert Hourly Rate
                  </Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                      placeholder="Rate"
                      type="number"
                      min="0"
                      style={{ width: "100px" }}
                      value={expertHour.value}
                      onChange={e => {
                        //handleOnChange("expertHourly", e.target.value)
                        handleExpertHour(e)
                        postEngagementFaluire(false)
                      }}
                    />
                    <span
                      style={{
                        marginLeft: 15,
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula"
                      }}
                    >
                      /hr
                    </span>
                  </div>
                  {state.expertHourly.error && (
                    <Label
                      style={{
                        color: "red"
                      }}
                    >
                      {state.expertHourly.error}
                    </Label>
                  )}
                  {BackendError && BackendError?.expert_rate && (
                    <Label
                      style={{
                        color: "red"
                      }}
                    >
                      {BackendError?.expert_rate[0]}
                    </Label>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6" className="p-0 m-0">
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "10px"
                    }}
                  >
                    Business Hourly Rate
                  </Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                      placeholder="Rate"
                      type="number"
                      min="0"
                      style={{ width: "100px" }}
                      value={businessHour.value}
                      onChange={e => {
                        //handleOnChange("businessHourly", e.target.value)
                        handleBusinessHour(e)
                        postEngagementFaluire(false)
                      }}
                    />{" "}
                    <span
                      style={{
                        marginLeft: 15,
                        color: "#808080",
                        fontSize: 14,
                        fontFamily: "khula"
                      }}
                    >
                      /hr
                    </span>
                  </div>
                  {state.businessHourly.error && (
                    <Label
                      style={{
                        color: "red"
                      }}
                    >
                      {state.businessHourly.error}
                    </Label>
                  )}
                  {BackendError && BackendError?.business_rate && (
                    <Label
                      style={{
                        color: "red"
                      }}
                    >
                      {BackendError?.business_rate[0]}
                    </Label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: "10px"
                }}
              >
                Hours agreed upon per billing period
              </Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder="Rate"
                  type="number"
                  min="0"
                  style={{ width: "100px" }}
                  value={agreedHour.value || "0"}
                  onChange={e => {
                    //handleOnChange("hourlyAgreed", e.target.value)
                    handleAgreedHour(e)
                    postEngagementFaluire(false)
                  }}
                />
                <span
                  style={{
                    marginLeft: 15,
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula"
                  }}
                >
                  /hr
                </span>
              </div>
              {state.hourlyAgreed.error && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {state.hourlyAgreed.error}
                </Label>
              )}
              {BackendError && BackendError?.rate_per_bill && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {BackendError?.rate_per_bill[0]}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: "10px"
                }}
              >
                Engagement Start Date
              </Label>
              {console.log(
                "Date.now()...........",
                moment(Date.now()).format("YYYY-MM-DD")
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #808080",
                  borderRadius: 8
                }}
              >
                <Input
                  type="date"
                  value={value}
                  min={moment(Date.now()).format("YYYY-MM-DD")}
                  onChange={e => onChange(e.target.value)}
                />
              </div>{" "}
              {state.engagementDate.error && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {state.engagementDate.error}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  marginTop: "10px"
                }}
              >
                Engagement Name
              </Label>
              <Input
                id="exampleText"
                placeholder="Type Engagement Name"
                name="text"
                type="textarea"
                value={state.engagementBio.value}
                onChange={e => {
                  handleOnChange("engagementBio", e.target.value)
                  postEngagementFaluire(false)
                }}
              />
              {state.engagementBio.error && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {state.engagementBio.error}
                </Label>
              )}
              {BackendError && BackendError?.description && (
                <Label
                  style={{
                    color: "red"
                  }}
                >
                  {BackendError?.description[0]}
                </Label>
              )}
            </FormGroup>
          </Form>
        </div>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={toggle1}
            className="btn-round text-capitalize"
            style={{
              backgroundColor: "#fff",
              color: "#3A0F7D",
              border: "1px solid  #3A0F7D",
              width: "132px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: "49px",
              marginRight: 20
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleOnFilter()}
            className="btn-round text-capitalize"
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              width: "132px",
              height: "49px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
            }}
          >
            {requesting ? <Spinner size="sm" color="#3A0F7D" /> : "Create"}
          </Button>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  BackendError: state.ActiveEngagement.error,
  requesting: state.ActiveEngagement.requesting,
  expertSearch: state.ActiveEngagement.expertSearch,
  businessSearch: state.ActiveEngagement.businessSearch,
  postSuccess: state.ActiveEngagement.postSuccess
})
const mapDispatchToProps = dispatch => ({
  getBusinessDataRequest: data => dispatch(getBusinessDataRequest(data)),
  getExpertDataRequest: data => dispatch(getExpertDataRequest(data)),
  postEngagementRequest: data => dispatch(postEngagementRequest(data)),
  postEngagementFaluire: data => dispatch(postEngagementFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Engagement)
