import {
  POST_BUSINESS_HOME_REQUEST,
  POST_BUSINESS_HOME_SUCCESS,
  POST_BUSINESS_HOME_FAILURE
} from "./types"

const initialState = {
  requesting: false,
  referData: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case POST_BUSINESS_HOME_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_BUSINESS_HOME_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: false,
      }

    case POST_BUSINESS_HOME_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
