import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "../../../../../config/app"

// utils
import XHR from "../../../../../utils/XHR"

// types
import { GET_EDUCATIONAL_REQUEST } from "./types"

// actions
import { educationalDataSuccess, educationalDataFaluire } from "./actions"

async function getEducaionalDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/dashboard/education/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getEducaionalData({ data }) {
  try {
    const response = yield call(getEducaionalDataAPI, data)
    yield put(educationalDataSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(educationalDataFaluire(response?.data))
  }
}

export default all([takeLatest(GET_EDUCATIONAL_REQUEST, getEducaionalData)])
