import {
  ALL_EXPERT_REQUEST,
  ALL_EXPERT_SUCCESS,
  ALL_EXPERT_FALUIRE,
  PATCH_EXPERT_PROFILE,
  PATCH_EXPERT_PROFILE_SUCCESS,
  PATCH_EXPERT_PROFILE_FALUIRE,
  GET_BUSINESS_NAMES,
  GET_BUSINESS_NAMES_SUCCESS,
  GET_BUSINESS_NAMES_FALUIRE,
  SHARE_EXPERT_WITH_BUSINESS,
  SHARE_EXPERT_WITH_BUSINESS_SUCCESS,
  SHARE_EXPERT_WITH_BUSINESS_FAILURE,
  SEARCH_EXPERT_REQUEST,
  SEARCH_EXPERT_SUCCESS,
  SEARCH_EXPERT_FALUIRE,
  DELETE_INDUSTRY_AND_EXPERTIES,
  DELETE_INDUSTRY_AND_EXPERTIES_SUCCESS,
  DELETE_INDUSTRY_AND_EXPERTIES_FAILURE
} from "./types"

export const expertPageRequest = data => ({
  type: ALL_EXPERT_REQUEST,
  data
})

export const expertPageSuccess = data => ({
  type: ALL_EXPERT_SUCCESS,
  data
})

export const expertPageFaluire = data => ({
  type: ALL_EXPERT_FALUIRE,
  data
})

export const patchExpertProfile = data => ({
  type: PATCH_EXPERT_PROFILE,
  data
})

export const expertProfileSuccess = data => ({
  type: PATCH_EXPERT_PROFILE_SUCCESS,
  data
})

export const expertProfileFaluire = data => ({
  type: PATCH_EXPERT_PROFILE_FALUIRE,
  data
})

export const getAllBusinessNames = data => ({
  type: GET_BUSINESS_NAMES,
  data
})

export const allBusinessNamesSuccess = data => ({
  type: GET_BUSINESS_NAMES_SUCCESS,
  data
})

export const allBusinessNamesFaluire = data => ({
  type: GET_BUSINESS_NAMES_FALUIRE,
  data
})

export const postShareExpertData = data => ({
  type: SHARE_EXPERT_WITH_BUSINESS,
  data
})

export const shareExpertDataSuccess = data => ({
  type: SHARE_EXPERT_WITH_BUSINESS_SUCCESS,
  data
})

export const shareExpertDataFaluire = data => ({
  type: SHARE_EXPERT_WITH_BUSINESS_FAILURE,
  data
})

export const searchExpertData = data => ({
  type: SEARCH_EXPERT_REQUEST,
  data
})

export const searchExpertDataSuccess = data => ({
  type: SEARCH_EXPERT_SUCCESS,
  data
})

export const searchExpertDataFaluire = data => ({
  type: SEARCH_EXPERT_FALUIRE,
  data
})

export const deleteExperienceData = data => ({
  type: DELETE_INDUSTRY_AND_EXPERTIES,
  data
})

export const deleteExperienceDataSuccess = data => ({
  type: DELETE_INDUSTRY_AND_EXPERTIES_SUCCESS,
  data
})

export const deleteExperienceDataFaluire = data => ({
  type: DELETE_INDUSTRY_AND_EXPERTIES_FAILURE,
  data
})
