import { all, call, put, takeLatest } from "redux-saga/effects"
import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  ALL_BUSINESS_REQUEST,
  PATCH_BUSINESS_PROFILE,
  SHARE_BUSINESS_WITH_EXPERT,
  GET_EXPERTS_NAMES,
  SEARCH_BUSINESS_REQUEST,
  ONBOARDING_LINK_REQUEST
} from "./types"

// actions
import {
  // pageRequest,
  // pageSuccess,
  // pageFaluire,
  // patchAccountSuccess,
  // patchAccountFailure,
  businessPageSuccess,
  businessProfileSuccess,
  businessProfileFaluire,
  businessPageRequest,
  businessPageFaluire,
  shareBusinessDataSuccess,
  shareBusinessDataFaluire,
  allExpertsNamesSuccess,
  allExpertsNamesFaluire,
  searchBusinessDataSuccess,
  searchBusinessDataFaluire,
  onBoardingLinkSuccess,
  onBoardingLinkFaluire
} from "./actions"

async function patchAproveAccountsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/business_profile/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "PATCH",
    data: data.data
  }

  return XHR(URL, options)
}

function* patchAproveAccounts({ data }) {
  try {
    const response = yield call(patchAproveAccountsAPI, data)
    yield put(businessProfileSuccess(response?.data))
    yield put(businessPageRequest(1))
  } catch (e) {
    const { response } = e
    yield put(businessProfileSuccess(response))
  }
}

async function getSearchDataAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/admin_business_pagination/?search=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getSearchData({ data }) {
  try {
    const response = yield call(getSearchDataAPI, data)
    yield put(searchBusinessDataSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(searchBusinessDataFaluire(response))
  }
}

async function getBusinessAccountsAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/admin_business_pagination/?page=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getBusinessAccounts({ data }) {
  try {
    const response = yield call(getBusinessAccountsAPI, data)
    yield put(businessPageSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(businessPageFaluire(e.response))
  }
}

async function getExpertsNamesAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/admin_expert/?search=${data}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getExpertsNames({ data }) {
  try {
    const response = yield call(getExpertsNamesAPI, data)
    yield put(allExpertsNamesSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(allExpertsNamesFaluire(response?.data))
  }
}

async function postShareBusinessAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/share_business/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postShareBusiness({ data }) {
  try {
    const response = yield call(postShareBusinessAPI, data)

    yield put(shareBusinessDataSuccess(response?.data))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(shareBusinessDataFaluire(response?.data))
  }
}

async function postonBoardingLinkAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/siteadmin/onboard/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postonBoardingLink({ data }) {
  try {
    const response = yield call(postonBoardingLinkAPI, data)
    yield put(onBoardingLinkSuccess(response?.data))
    toast.success("Success")
  } catch (e) {
    const { response } = e
    yield put(onBoardingLinkFaluire(response?.data))
  }
}

export default all([
  takeLatest(ALL_BUSINESS_REQUEST, getBusinessAccounts),
  takeLatest(PATCH_BUSINESS_PROFILE, patchAproveAccounts),
  takeLatest(SHARE_BUSINESS_WITH_EXPERT, postShareBusiness),
  takeLatest(GET_EXPERTS_NAMES, getExpertsNames),
  takeLatest(SEARCH_BUSINESS_REQUEST, getSearchData),
  takeLatest(ONBOARDING_LINK_REQUEST, postonBoardingLink)
])
