import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGING_FALUIRE,
  LOGIN_VIA_LINKEDIN_REQUEST,
  LOGIN_VIA_LINKEDIN_SUCCESS,
  LOGIN_VIA_LINKEDIN_FALUIRE,
  LOGIN_VIA_GOOGLE_REQUEST,
  LOGIN_VIA_GOOGLE_SUCCESS,
  LOGIN_VIA_GOOGLE_FALUIRE,
  LOGIN_ACCESS_TOKEN,
  RESET_STATE,
  LOGIN_PROFILE_DATA,
  LINKEDIN_CODE,
  INITIAL_PROFILE_CHECK,
  SET_DEVICE_ID,
  NOTFICATION_SETTING_TOGGLE
} from "./types"

const initialState = {
  user: false,
  requesting: false,
  error: false,
  userType: false,
  notificationData: false,
  accessToken: false,
  profileData: false,
  linkedInCode: false,
  linkedInSuccess: false,
  initialProfile: false,
  deviceID: false,
  notificationSetting:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        user: action.data
      }

    case LOGING_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case LOGIN_VIA_LINKEDIN_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case LOGIN_VIA_LINKEDIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        linkedInSuccess: action.data
      }

    case LOGIN_VIA_LINKEDIN_FALUIRE:
      return {
        ...state,
        requesting: false,
        data: action.data
      }

    case LOGIN_VIA_GOOGLE_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case LOGIN_VIA_GOOGLE_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }

    case LOGIN_VIA_GOOGLE_FALUIRE:
      return {
        ...state,
        requesting: false,
        data: action.data
      }

    case LOGIN_ACCESS_TOKEN:
      return {
        ...state,
        requesting: false,
        accessToken: action.data
      }

    case LOGIN_PROFILE_DATA:
      return {
        ...state,
        requesting: false,
        profileData: action.data
      }

    case LINKEDIN_CODE:
      return {
        ...state,
        requesting: false,
        linkedInCode: action.data
      }

    case INITIAL_PROFILE_CHECK:
      return {
        ...state,
        requesting: false,
        initialProfile: action.data
      }

    case SET_DEVICE_ID:
      return {
        ...state,
        requesting: false,
        deviceID: action.data
      }
      case NOTFICATION_SETTING_TOGGLE:
      return {
        ...state,
        requesting: false,
        notificationSetting: action.data
      }

    case RESET_STATE:
      return {
        ...state,
        user: false,
        requesting: false,
        error: false,
        userType: false,
        notificationData: false,
        accessToken: false
      }
    
    default:
      return state
  }
}
