import {
  DOWNLOAD_CONTRACT_REQUEST,
  DOWNLOAD_CONTRACT_SUCCESS,
  DOWNLOAD_CONTRACT_FALUIRE,
  GET_INDUSTRIAL_EXPERIENCE_REQUEST,
  GET_INDUSTRIAL_EXPERIENCE_SUCCESS,
  GET_INDUSTRIAL_EXPERIENCE_FALUIRE,
  ADDITIONAL_EXPERTIES_REQUEST,
  ADDITIONAL_EXPERTIES_SUCCESS,
  ADDITIONAL_EXPERTIES_FALUIRE,
  GET_EXPERT_PROFILE_REQUEST,
  GET_EXPERT_PROFILE_FALUIRE,
  GET_EXPERT_PROFILE_SUCCESS
} from "./types"

const initialState = {
  link: false,
  requesting: false,
  industries: false,
  additionalExperties: false,
  error: false,
  expertProfileData: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_CONTRACT_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case DOWNLOAD_CONTRACT_SUCCESS:
      return {
        ...state,
        requesting: false,
        link: action.data
      }

    case DOWNLOAD_CONTRACT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_INDUSTRIAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        indusrtyRequesting: true
      }

    case GET_INDUSTRIAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        indusrtyRequesting: false,
        industries: action.data
      }

    case GET_INDUSTRIAL_EXPERIENCE_FALUIRE:
      return {
        ...state,
        indusrtyRequesting: false,
        error: action.data
      }
    case ADDITIONAL_EXPERTIES_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case ADDITIONAL_EXPERTIES_SUCCESS:
      return {
        ...state,
        requesting: false,
        additionalExperties: action.data
      }
    case ADDITIONAL_EXPERTIES_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_EXPERT_PROFILE_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_EXPERT_PROFILE_FALUIRE:
      return {
        ...state,
        requesting: false,
        expertProfileData: action.data
      }
    case GET_EXPERT_PROFILE_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
