import React from "react"

import defaultImage from "assets/img/default_picture.png"

class PictureUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      imagePreviewUrl:
        this.props.avatar !== "null" ? this.props.avatar : defaultImage
    }
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleImageChange(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    this.props.setIsChange(true)
    reader.onloadend = () => {
      this.props.setselectedFile(reader.result)
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      })
    }
    reader.readAsDataURL(file)
  }
  handleSubmit(e) {
    e.preventDefault()
  }
  render() {
    return (
      <div className="picture-container" style={this.props.style}>
        <div className="picture">
          <img
            style={{ height: "-webkit-fill-available" }}
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          {this.props.disable ? null : (
            <input type="file" onChange={e => this.handleImageChange(e)} />
          )}
        </div>
      </div>
    )
  }
}

export default PictureUpload
