import { all, call, put, takeLatest } from "redux-saga/effects"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { POST_FEEDBACK_REQUEST, FEEDBACK_PAGE_REQUEST } from "./types"

// actions
import {
  pageRequest,
  toggleModal,
  pageFaluire,
  pageSuccess,
  postFeedBackSuccess,
  postFeedBackFaluire
} from "./actions"

async function postFeedBackAPI(data) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/admin_feedback_reply/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postFeedBack({ data }) {
  try {
    const response = yield call(postFeedBackAPI, data)
    yield put(postFeedBackSuccess(response?.data))
    yield put(toggleModal(false))
    // yield put(pageRequest(1))
    toast.success("Success")
  } catch (e) {
    let err = e?.response?.data?.feedback
      ? "Feedback field is required"
      : e?.response?.data?.reply
      ? "Reply field is required"
      : e?.response?.data?.detail
      ? e.response.data.detail
      : "Something went wrog."
    if (e.message == "Network Error") {
      // toast.error("Connection Error")
      yield put(postFeedBackFaluire("Connection Error"))
    } else {
      // toast.error(err)
      yield put(postFeedBackFaluire(err))
    }
  }
}

async function paginationAPI(page) {
  const accessToken = await localStorage.getItem("authToken")
  const URL = `${BASE_URL}/api/v1/admin_feedback/?page=${page}`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* pagination({ page }) {
  try {
    const response = yield call(paginationAPI, page)
    yield put(pageSuccess(response?.data))
  } catch (e) {
    yield put(pageFaluire(e.response))
  }
}

export default all([
  takeLatest(FEEDBACK_PAGE_REQUEST, pagination),
  takeLatest(POST_FEEDBACK_REQUEST, postFeedBack)
])
