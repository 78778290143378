import React, { useState, useRef, useEffect } from "react"
import PictureUpload from "components/CustomUpload/PictureUpload.js"
import Modal from "react-bootstrap/Modal"
// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap"
import Select from "react-select"
import {
  downloadContract,
  getIndustryExperience,
  postAdditionalExperties,
  postAdditionalExpertiesFailure,
  getExpertProfile
} from "./redux/actions"
import toast, { Toaster } from "react-hot-toast"
import { getProfileV2Request } from "Containers/HomeInitialProfileV2/redux/actions"
// import './style.scss';

// import ReactPaginate from 'react-paginate';

import { Link } from "react-router-dom"
import Images from "utils/Images"
import { connect } from "react-redux"

function MyProfile(props) {
  const {
    requesting,
    link,
    getExpertProfile,
    expertProfileData,
    postAdditionalExpertiesFailure
  } = props
  const fileRef = useRef()
  useEffect(() => {
    props.getIndustryExperience()
    props.getProfileV2Request()
  }, [])

  const [isFilePicked, setIsFilePicked] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [disabled, setDisabled] = useState(true)
  const [allData, setAllData] = useState(false)
  const [show, setShow] = useState(false)
  const [experty, setExperty] = useState(false)
  const [industry, setIndustry] = useState(false)

  const handleClose = () => {
    setShow(false)
    setIndustry(false)
    setExperty(false)
    postAdditionalExpertiesFailure(false)

    // ;props.user_expertise ="";props.industries =""
  }
  const handleShow = () => {
    setShow(true)

    postAdditionalExpertiesFailure(false)

    // props.industries ="";props.user_expertise =""
  }

  const handleChange = e => {
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
  }

  const download = (link, filename) => {
    // from raw data to Image!!
    var element = document.createElement("a")
    element.setAttribute("href", link)
    element.setAttribute("download", filename)
    element.style.display = "none"
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  useEffect(() => {
    // props.downloadContract()
    getExpertProfile()
    getAllData()
  }, [])

  const getAllData = async () => {
    postAdditionalExpertiesFailure(false)
    const newData = await localStorage.getItem("userAllData")
    setAllData(JSON.parse(newData))
  }

  const getExpertise = () => {
    return (
      props.data &&
      props.data.user_expertise?.map(item => {
        return {
          label: item.type,
          value: item.id
        }
      })
    )
  }

  const getIndustries = () => {
    return props.industries && props.industries?.results?.length > 0
      ? props.industries.results.map(item => {
          return {
            label: item?.name,
            value: item?.id
          }
        })
      : []
  }

  const handleEditProfile = () => {
    allData?.user?.user_type == "Expert" &&
      props.history.push("/Expert/EditProfile")
    allData?.user?.user_type == "Business" &&
      props.history.push("/business/EditProfile")
    allData?.user?.user_type == "Admin" &&
      props.history.push("/admin/EditProfile")
  }
  return (
    <>
      <Toaster position="top-center" />
      <div className="content">
        <Row>
          <Col md="12">
            <div
              style={{ display: "flex" }}
              className="justify-content-lg-between"
            >
              <CardTitle
                tag="h4"
                style={{
                  fontFamily: "Libre Caslon Text",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#CACACA"
                }}
              >
                Profile
              </CardTitle>
              {/* <div style={{ width: '200px' }}> */}

              {/* </div> */}
            </div>
            <Card
              className="card-plain"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardBody>
                <>
                  <div style={{ margin: "20px" }}>
                    {/* <Link
                      style={{ textDecoration: "none" }}
                      to="/Expert/EditProfile"
                    > */}

                    <Row
                      className="m-0    my-3  justify-content-between    "
                      style={{
                        display: "flex",

                        alignItems: "center"
                      }}
                    >
                      <div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://app.gusto.com/login"
                        >
                          <Button
                            className="py-1 px-5 my-2 btn-round"
                            style={{
                              backgroundColor: "#EB5E4A",
                              // padding: "10px 75px 10px 75px",
                              marginBottom: "0px",
                              marginTop: "0px",
                              height: "49px",
                              // borderRadius: "50px",
                              color: "white",
                              cursor: "pointer",
                              fontWeight: "700",
                              fontSize: "16px",
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                            }}
                          >
                            gusto
                          </Button>
                        </a>
                      </div>
                      <Button
                        onClick={() => handleEditProfile()}
                        className="btn-round text-capitalize"
                        style={{
                          margin: "0px",
                          backgroundColor: "white",
                          border: "2px #3A0F7D solid",

                          color: "#3A0F7D",
                          cursor: "pointer",
                          height: "42px"
                        }}
                      >
                        Edit Profile
                      </Button>
                    </Row>

                    {/* </Link> */}
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <img
                            style={{
                              width: "192px",
                              height: "192px",
                              borderRadius: "50%"
                            }}
                            src={
                              allData?.user?.profile_picture
                                ? allData?.user?.profile_picture
                                : Images.dummy_avatar
                            }
                          />
                        </div>
                        {/* <PictureUpload /> */}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "23px" }}>
                      <Col sm="12">
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Full Name
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                placeholder={`${
                                  expertProfileData
                                    ? expertProfileData?.user_detail?.name
                                    : ""
                                }`}
                                type="text"
                                disabled={disabled}
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="1"></Col>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="10"
                            >
                              LinkedIn Profile Link
                            </Label>
                            <FormGroup>
                              <Input
                                placeholder={`${
                                  expertProfileData
                                    ? expertProfileData?.user_detail
                                        ?.linkedin_profile_url
                                    : ""
                                }`}
                                type="text"
                                disabled={disabled}
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className="mt-md-4"
                      style={{ justifyContent: "center" }}
                    >
                      <Col sm="12">
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Email
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                placeholder={`${
                                  expertProfileData
                                    ? expertProfileData?.user_detail?.email
                                    : ""
                                }`}
                                type="email"
                                disabled={disabled}
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="1"></Col>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                            >
                              Hourly Rate
                            </Label>
                            <FormGroup
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <Input
                                placeholder={`${
                                  expertProfileData
                                    ? expertProfileData?.hourly_rate
                                    : ""
                                }`}
                                type="text"
                                disabled={disabled}
                                style={{
                                  width: 97,
                                  backgroundColor: "#EFEFEF"
                                }}
                              />
                              <p
                                style={{
                                  margin: 0,
                                  marginLeft: "13px",
                                  color: "#808080",
                                  fontWeight: "600"
                                }}
                              >
                                /hr
                              </p>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className="mt-md-4"
                      style={{ justifyContent: "center" }}
                    >
                      <Col sm="12">
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="5">
                            <Row
                              style={{
                                justifyContent: "space-between",
                                padding: "0px 15px 0px 15px"
                              }}
                            >
                              <div>
                                <Label
                                  style={{
                                    color: "#808080",
                                    padding: "0px",
                                    marginBottom: "7px",
                                    fontWeight: "600"
                                  }}
                                  sm="6"
                                >
                                  Resume
                                </Label>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer"
                                  }}
                                  href={
                                    expertProfileData &&
                                    expertProfileData?.resume
                                      ? expertProfileData?.resume
                                      : "#"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div
                                    style={{
                                      // backgroundColor: "red",
                                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                                      // width: "157px",

                                      // height: "51px",
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      paddingLeft: "20px",
                                      borderRadius: "4px"
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "20px",
                                        width: "16px",
                                        // marginLeft: "20px",
                                        marginRight: "2px",
                                        borderRadius: "0px"
                                      }}
                                      src={require("assets/img/uploadPdf.png")}
                                      onClick={() => fileRef.current.click()}
                                    />
                                    <span
                                      style={{
                                        marginBottom: 0,
                                        padding: 15,
                                        display: "flex",
                                        wordBreak: "break-all"
                                      }}
                                    >
                                      {"Resume.pdf"}
                                    </span>
                                  </div>
                                </a>
                              </div>
                            </Row>
                            <div style={{ marginTop: 10 }}>
                              <Label
                                style={{
                                  color: "#808080",
                                  padding: "0px",
                                  marginBottom: "7px",
                                  fontWeight: "600"
                                }}
                                sm="6"
                              >
                                Contract
                              </Label>

                              <FormGroup className={{}}>
                                <div
                                  style={{
                                    backgroundColor: "rgba(58, 15, 125, 0.3)",

                                    height: "51px",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingLeft: "20px",
                                    borderRadius: "4px",
                                    marginRight: 0,
                                    cursor: "pointer"
                                  }}
                                  onClick={() =>
                                    // download(
                                    //   link.data.file_url,
                                    //   "contract.pdf"
                                    // )
                                    props.downloadContract()
                                  }
                                >
                                  <img
                                    style={{
                                      height: "20px",
                                      width: "16px",
                                      // marginLeft: "20px",
                                      marginRight: "4px",
                                      borderRadius: "0px"
                                    }}
                                    src={require("assets/img/uploadPdf.png")}
                                    onClick={() => fileRef.current.click()}
                                  />
                                  <input
                                    ref={fileRef}
                                    onChange={handleChange}
                                    disabled={disabled}
                                    multiple={false}
                                    type="file"
                                    hidden
                                  />
                                  {isFilePicked ? (
                                    <div>
                                      <p
                                        style={{
                                          margin: "0px",
                                          marginLeft: "5px"
                                        }}
                                      >
                                        {selectedFile.name}
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          margin: "0px",
                                          marginLeft: "5px"
                                        }}
                                      >
                                        GrowTal Contractor Agreement:{" "}
                                        {expertProfileData
                                          ? expertProfileData?.user_detail?.name
                                          : "Contract"}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {/* <Button
                                    className="text-capitalize"
                                    style={{
                                      backgroundColor: "#3A0F7D",
                                      // padding: "8px 25px 8px 25px",
                                      marginBottom: "0px",
                                      borderRadius: "50px",
                                      color: "white",
                                      fontWeight: "700",
                                      fontSize: "14px",
                                      boxShadow:
                                        "0px 4px 15px rgba(0, 0, 0, 0.25)",
                                      margin: "0px"
                                    }}
                                  >
                                    Download Contract
                                  </Button> */}
                              </FormGroup>
                            </div>
                          </Col>
                          <Col md="1"></Col>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Weekly Availability
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                disabled={disabled}
                                placeholder={`${
                                  expertProfileData
                                    ? expertProfileData?.user_availability
                                    : ""
                                } hours per week`}
                                type="text"
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <div className="px-md-5 px-3  mx-lg-2 w-100">
                        <Label
                          style={{
                            color: "#808080",
                            padding: "0px",
                            marginBottom: "7px",
                            fontWeight: "600",
                            marginLeft: 10
                          }}
                          sm="6"
                        >
                          Expert Bio
                        </Label>

                        <Input
                          disabled={disabled}
                          placeholder={`${
                            expertProfileData
                              ? expertProfileData?.expert_bio
                              : ""
                          }`}
                          type="textarea"
                          style={{
                            height: "109px",
                            backgroundColor: "#EFEFEF",
                            width: "100%",
                            marginLeft: 6
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card
              className="card-plain px-4"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardHeader style={{ paddingLeft: "0px" }}>
                <Row
                  className="m-0"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Label
                    className="pl-3"
                    style={{
                      color: "#808080",
                      padding: "0px",
                      fontWeight: "600",

                      fontSize: 14,
                      fontFamily: "khula"
                    }}
                    // sm="6"
                  >
                    Areas of Expertise
                  </Label>
                </Row>
              </CardHeader>
              <CardBody style={{ alignItems: "baseline" }}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Col
                    lg="9"
                    className=" pl-4"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {expertProfileData &&
                      expertProfileData?.expertise?.length > 0 &&
                      expertProfileData?.expertise.map(item => (
                        <div>
                          <p
                            className="mb-2"
                            style={{
                              backgroundColor: "rgba(58, 15, 125, 0.3)",
                              padding: "10px 12px 10px 12px",
                              borderRadius: "8px",
                              color: "#3A0F7D",
                              fontWeight: "400",
                              fontSize: "14px",
                              marginRight: "10px",

                              marginBottom: 0
                            }}
                          >
                            {item?.detail?.name}
                          </p>
                        </div>
                      ))}
                  </Col>
                  <Col lg="3">
                    <Button
                      className="btn-round text-capitalize mr-lg-3  ml-md-n3 ml-0 "
                      style={{
                        width: "168px",
                        backgroundColor: "white",
                        border: "1px solid #3A0F7D",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                        whiteSpace: "nowrap",
                        color: "#3A0F7D"
                      }}
                      onClick={handleShow}
                    >
                      Apply For Expertise
                    </Button>
                  </Col>
                </Row>

                <div style={{ marginLeft: "20%" }}>
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body>
                      {/* <Input
                        placeholder="Areas of Experties"
                        style={{ padding: "14px" }}
                      /> */}
                      <div style={{}}>
                        {/* <label style={{ display: "flex", color: "#808080" }}>
                Client
              </label> */}
                        <Label
                          style={{
                            color: "#808080",
                            fontSize: 14,
                            fontFamily: "khula"
                          }}
                        >
                          Industry Experience
                        </Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          // value={role}
                          value={industry}
                          onChange={value => setIndustry(value)}
                          options={getIndustries()}
                          placeholder="Select Industry Experience"
                        />
                        {props?.backendError?.industry_type ? (
                          <p style={{ color: "red", marginTop: 10 }}>
                            {props?.backendError?.industry_type}
                          </p>
                        ) : null}
                      </div>

                      <div style={{}}>
                        {/* <label style={{ display: "flex", color: "#808080" }}>
                Client
              </label> */}
                        <Label
                          style={{
                            color: "#808080",
                            fontSize: 14,
                            fontFamily: "khula"
                          }}
                        >
                          Area of Expertise
                        </Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          // value={role}
                          onChange={value => setExperty(value)}
                          options={
                            props.data?.user_expertise &&
                            props.data.user_expertise
                          }
                          placeholder="Select Area of Expertise"
                        />
                        {props.backendError?.experties ? (
                          <p style={{ color: "red", marginTop: 10 }}>
                            {props?.backendError?.experties}
                          </p>
                        ) : null}
                      </div>

                      {/* <Input
                        placeholder="Industry Experience"
                        style={{ padding: "14px", marginTop: "24px" }}
                      /> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10
                        }}
                      >
                        <Button
                          className="btn-round text-capitalize"
                          style={{
                            width: "160px",
                            backgroundColor: "#3A0F7D",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                            whiteSpace: "nowrap"
                          }}
                          onClick={async () => {
                            const profile_id = await localStorage.getItem(
                              "profileid"
                            )
                            const data = {
                              industry_type: industry?.value,
                              experties: experty?.value,
                              profile: profile_id
                            }
                            props.postAdditionalExperties(data, handleClose)
                          }}
                        >
                          {requesting ? <Spinner size="sm" /> : " Apply"}
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                  {/* <Button
                          className="btn-round text-capitalize"
                          color="secondary"
                          style={{
                            width: "160px",
                            backgroundColor: "#3A0F7D",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                          }}
                        >
                          {requesting ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Complete Profile"
                          )}
                        </Button> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card
              className="card-plain pl-4"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardHeader style={{ paddingLeft: "0px" }}>
                <Label
                  className="pl-3"
                  style={{
                    color: "#808080",
                    fontWeight: "600",
                    fontSize: "14px",
                    fontFamily: "Khula"
                  }}
                >
                  Industry Experience
                </Label>
              </CardHeader>
              <CardBody
                className=" pl-3"
                style={{ flexWrap: "wrap", display: "flex" }}
              >
                {expertProfileData &&
                  expertProfileData?.industry_experience?.length > 0 &&
                  expertProfileData?.industry_experience.map(item => (
                    <div>
                      <p
                        className="mb-2"
                        style={{
                          backgroundColor: "rgba(58, 15, 125, 0.3)",
                          padding: "10px 12px 10px 12px",
                          borderRadius: "8px",
                          color: "#3A0F7D",
                          fontWeight: "400",
                          fontSize: "14px",
                          marginRight: "10px",
                          width: "fit-content",

                          marginBottom: 0
                        }}
                      >
                        {item?.industry_name}
                      </p>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  link: state.Profile.link,
  requesting: state.Profile.requesting,
  industries: state.Profile.industries,
  data: state.HomeInitialProfileV2.data,
  backendError: state.Profile.error,
  expertProfileData: state.Profile.expertProfileData
})

const mapDispatchToProps = dispatch => ({
  downloadContract: () => dispatch(downloadContract()),
  getProfileV2Request: () => dispatch(getProfileV2Request()),
  getIndustryExperience: () => dispatch(getIndustryExperience()),
  postAdditionalExperties: (data, toggle) =>
    dispatch(postAdditionalExperties(data, toggle)),
  postAdditionalExpertiesFailure: data =>
    dispatch(postAdditionalExpertiesFailure(data)),
  getExpertProfile: () => dispatch(getExpertProfile())
})
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
