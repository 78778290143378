import {
  GET_ALL_TIMETRACKING_LOGS_REQUEST,
  GET_ALL_TIMETRACKING_LOGS_SUCCESS,
  GET_ALL_TIMETRACKING_LOGS_FALUIRE,
  ADMIN_EXPORT_CSV_REQUEST,
  ADMIN_EXPORT_CSV_REQUEST_SUCCESS,
  ADMIN_EXPORT_CSV_REQUEST_FALUIRE,
  FILTER_TIMETRACKING_LOGS_REQUEST,
  FILTER_TIMETRACKING_LOGS_SUCCESS,
  FILTER_TIMETRACKING_LOGS_FALUIRE,
  PATCH_TIMETRACKING_LOGS_REQUEST,
  PATCH_TIMETRACKING_LOGS_SUCCESS,
  PATCH_TIMETRACKING_LOGS_FALUIRE,
  GET_BILLING_PERIODS_REQUEST,
  GET_BILLING_PERIODS_SUCCESS,
  GET_BILLING_PERIODS_FALUIRE
} from "./types"

export const timetrackingLogsRequest = data => ({
  type: GET_ALL_TIMETRACKING_LOGS_REQUEST,
  data
})

export const timetrackingLogsSuccess = data => ({
  type: GET_ALL_TIMETRACKING_LOGS_SUCCESS,
  data
})

export const timetrackingLogsFailure = data => ({
  type: GET_ALL_TIMETRACKING_LOGS_FALUIRE,
  data
})

export const exportCSVRequest = data => ({
  type: ADMIN_EXPORT_CSV_REQUEST,
  data
})

export const exportCSVSuccess = data => ({
  type: ADMIN_EXPORT_CSV_REQUEST_SUCCESS,
  data
})

export const exportCSVFaluire = data => ({
  type: ADMIN_EXPORT_CSV_REQUEST_FALUIRE,
  data
})

export const filterTimeTrackLogsRequest = data => ({
  type: FILTER_TIMETRACKING_LOGS_REQUEST,
  data
})

export const filterTimeTrackLogsSuccess = data => ({
  type: FILTER_TIMETRACKING_LOGS_SUCCESS,
  data
})

export const filterTimeTrackLogsFaluire = data => ({
  type: FILTER_TIMETRACKING_LOGS_FALUIRE,
  data
})

export const patchTimeTrackLogsRequest = data => ({
  type: PATCH_TIMETRACKING_LOGS_REQUEST,
  data
})

export const patchTimeTrackLogsSuccess = data => ({
  type: PATCH_TIMETRACKING_LOGS_SUCCESS,
  data
})

export const patchTimeTrackLogsFaluire = data => ({
  type: PATCH_TIMETRACKING_LOGS_FALUIRE,
  data
})

export const getBillingPeriodsRequest = data => ({
  type: GET_BILLING_PERIODS_REQUEST,
  data
})

export const getBillingPeriodsSuccess = data => ({
  type: GET_BILLING_PERIODS_SUCCESS,
  data
})

export const getBillingPeriodsFaluire = data => ({
  type: GET_BILLING_PERIODS_FALUIRE,
  data
})
