export const ALL_BUSINESS_REQUEST = "ALL_BUSINESS_REQUEST"
export const ALL_BUSINESS_SUCCESS = "ALL_BUSINESS_SUCCESS"
export const ALL_BUSINESS_FALUIRE = "ALL_BUSINESS_FALUIRE"

export const SEARCH_BUSINESS_REQUEST = "SEARCH_BUSINESS_REQUEST"
export const SEARCH_BUSINESS_SUCCESS = "SEARCH_BUSINESS_SUCCESS"
export const SEARCH_BUSINESS_FALUIRE = "SEARCH_BUSINESS_FALUIRE"

export const PATCH_BUSINESS_PROFILE = "PATCH_ACCOUNTS_REQUEST"
export const PATCH_BUSINESS_PROFILE_SUCCESS = "PATCH_BUSINESS_PROFILE_SUCCESS"
export const PATCH_BUSINESS_PROFILE_FALUIRE = "PATCH_BUSINESS_PROFILE_FALUIRE"

export const GET_EXPERTS_NAMES = "GET_EXPERTS_NAMES"
export const GET_EXPERTS_NAMES_SUCCESS = "GET_EXPERTS_NAMES_SUCCESS"
export const GET_EXPERTS_NAMES_FALUIRE = "GET_EXPERTS_NAMES_FALUIRE"

export const SHARE_BUSINESS_WITH_EXPERT = "SHARE_BUSINESS_WITH_EXPERT"
export const SHARE_BUSINESS_WITH_EXPERT_SUCCESS = "SHARE_BUSINESS_WITH_EXPERT_SUCCESS"
export const SHARE_BUSINESS_WITH_EXPERT_FAILURE = "SHARE_BUSINESS_WITH_EXPERT_FAILURE"

export const ONBOARDING_LINK_REQUEST = "ONBOARDING_LINK_REQUEST"
export const ONBOARDING_LINK_SUCCESS = "ONBOARDING_LINK_SUCCESS"
export const ONBOARDING_LINK_FAILURE = "ONBOARDING_LINK_FAILURE"