import {
  GET_NOTIFICATION_DATA_REQUEST,
  GET_NOTIFICATION_DATA_SUCCESS,
  GET_NOTIFICATION_DATA_FAILURE,
  PATCH_NOTIFICATION_REQUEST,
  PATCH_NOTIFICATION_SUCCESS,
  PATCH_NOTIFICATION_FAILURE,
  POST_DEVICE_ID_REQUEST,
  POST_DEVICE_ID_SUCCESS,
  POST_DEVICE_ID_FAILURE,
  POST_CLOSE_PERIOD_REQUEST,
  POST_CLOSE_PERIOD_SUCCESS,
  POST_HOUR_REMINDER_REQUEST,
  POST_HOUR_REMINDER_SUCCESS
} from "./types"

export const sendDeviceIdRequest = data => ({
  type: POST_DEVICE_ID_REQUEST,
  data
})

export const sendDeviceIdSuccess = data => ({
  type: POST_DEVICE_ID_SUCCESS,
  data
})
export const sendDeviceIdFaluire = data => ({
  type: POST_DEVICE_ID_FAILURE,
  data
})

export const getNotficationDataRequest = () => ({
  type: GET_NOTIFICATION_DATA_REQUEST
})

export const getNotficationDataSuccess = data => ({
  type: GET_NOTIFICATION_DATA_SUCCESS,
  data
})
export const getNotficationDataFaluire = data => ({
  type: GET_NOTIFICATION_DATA_FAILURE,
  data
})

export const patchNotficationRequest = data => ({
  type: PATCH_NOTIFICATION_REQUEST,
  data
})

export const patchNotficationSuccess = data => ({
  type: PATCH_NOTIFICATION_SUCCESS,
  data
})
export const patchNotficationFaluire = data => ({
  type: PATCH_NOTIFICATION_FAILURE,
  data
})

export const closePeriodRequest = data => ({
  type: POST_CLOSE_PERIOD_REQUEST,
  data
})
export const closePeriodSuccess = data => ({
  type: POST_CLOSE_PERIOD_SUCCESS,
  data
})

export const hourReminderRequest = data => ({
  type: POST_HOUR_REMINDER_REQUEST,
  data
})
export const hourReminderSuccess = data => ({
  type: POST_HOUR_REMINDER_SUCCESS,
  data
})
