import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  UncontrolledTooltip
} from "reactstrap"

import { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import PaginationPack from "components/PaginationPack"
import Select from "react-select"
import { Tooltip } from "@mui/material"

import useForm from "utils/useForm"
import validator from "utils/validation"

import Images from "utils/Images"
import { resourcesRequest } from "Containers/ExpertDesign/Screens/ResourceArea/redux/actions"
import {
  addContentResourse,
  addContentResourseFaluire,
  deleteContentResourse,
  editContentResourse
} from "./redux/actions"
import ReadMore from "components/ReadMore"
function AddContent(props) {
  const [userId, setUserId] = useState(false)
  const [content, setContent] = useState(false)
  const [contentdata, setContentData] = useState(false)
  const [image, setImage] = useState(false)
  const [imageName, setImageName] = useState(false)
  const [dropdownContent, setDropdownContent] = useState(false)
  const [search, setSearch] = useState("")
  const [isCheck, setIsCheck] = useState(false)

  const {
    deleteRequest,
    requesting,
    resourceData,
    resourcesRequest,
    backendError,
    successResource,
    editSuccess
  } = props

  useEffect(() => {
    editSuccess && setModal1(false)
  }, [editSuccess])

  useEffect(() => {
    successResource && setModal(false)
  }, [successResource])

  useEffect(() => {
    props.resourcesRequest({ page: 1, type: "" })
  }, [])

  useEffect(() => {
    if (contentdata) {
      handleOnChange(
        "resourcedescription",
        contentdata?.details && contentdata.details
      )
      handleOnChange("link", contentdata?.url && contentdata.url)
      setDropdownContent(contentdata?.content && contentdata.content)
      setImageName(contentdata?.image && contentdata.image)
    }
  }, [contentdata])

  const onEditClick = () => {
    const formData = new FormData()
    formData.append("details", state.resourcedescription.value)
    image && formData.append("image", image)
    formData.append("url", state.link.value)
    formData.append(
      "content",
      dropdownContent?.label ? dropdownContent?.label : contentdata?.content
    )
    const ApiData = {
      id: contentdata?.id,
      formData: formData
    }

    setState(stateSchema)
    props.editContentResourse(ApiData)
  }

  const onSaveClick = () => {
    const formData = new FormData()
    formData.append("details", state.description.value)
    image && formData.append("image", image)
    formData.append("url", state.resourceLink.value)
    content && formData.append("content", content?.label)
    formData.append("title", state.resourceTitle.value)

    props.addContentResourse(formData)
    setState(stateSchema)
  }

  const handlePageChange = page => {
    resourcesRequest({ page: page, type: "" })
  }
  const [fileName, setFileName] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [imageUrl, setImageUrl] = useState(false)

  const fileRef = useRef()
  const handleChange = async e => {
    const file = e.target.files[0]
    var reader = URL.createObjectURL(e.target.files[0])
    // var url = reader.readAsDataURL(file)

    setImage(file)
    setFileName(file?.name)
    setImageName(file?.name)
    setImageUrl(reader)

    // setImageUrl(file)
    // props.addContentResourseFaluire(false)
  }

  const toggle1 = () => {
    setModal(!modal)
    setState(stateSchema)
    setContent("")
    setFileName("")
    props.addContentResourseFaluire(false)
    setImage(false)
  }
  const toggle2 = () => {
    setState(stateSchema)
    setModal1(!modal1)
    props.addContentResourseFaluire(false)
  }

  const stateSchema = {
    description: {
      value: "",
      error: ""
    },
    resourceLink: {
      value: "",
      error: ""
    },
    resourcedescription: {
      value: "",
      error: ""
    },
    link: {
      value: "",
      error: ""
    },
    resourceTitle: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    description: {
      required: true
    },
    resourceLink: {
      required: true,
      validator: validator.url
    },
    resourcedescription: {
      required: true
    },
    link: {
      required: true,
      validator: validator.url
    },
    resourceTitle: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleOnSearch = value => {
    setSearch(value)
    // setTimeout(() => {
    resourcesRequest({
      type: value,
      page: 1
    })
    // }, 1000)
  }

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />

        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    Add Content
                  </CardTitle>
                  <Row
                    style={{
                      display: "flex",
                      paddingRight: "24px",
                      margin: "0px"
                    }}
                  >
                    <Button
                      className="btn-round btn text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        // height: "49px",
                        // marginTop: "0px",
                        borderRedius: "0px",
                        marginRight: 13
                      }}
                      onClick={toggle1}
                    >
                      + Add Resource
                    </Button>
                    {/* {check && ( */}

                    {/* )} */}
                    <Form style={{}} onSubmit={e => e.preventDefault()}>
                      <InputGroup
                        className="no-border"
                        style={{
                          borderRadius: "5px",
                          marginTop: "10px",
                          // backgroundColor: "yellow",
                          borderRadius: 30,
                          border: "1px solid #808080",
                          paddingLeft: "15px",
                          backgroundColor: "white"
                        }}
                      >
                        <Input
                          defaultValue=""
                          placeholder="Search..."
                          type="text"
                          value={search}
                          style={{
                            backgroundColor: "white",
                            borderBottomLeftRadius: "30px",
                            borderTopLeftRadius: "30px"
                          }}
                          onChange={e => {
                            handleOnSearch(e.target.value)
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              backgroundColor: "white",
                              borderBottomRightRadius: "30px",
                              borderTopRightRadius: "30px"
                            }}
                          >
                            <img
                              // onClick={() => handleSearch("Search")}
                              style={{
                                height: "17px",
                                width: "17px"
                              }}
                              src={Images.darkSearch}
                            />
                            {/* {!isSearched ? (
                              <img
                                onClick={() => handleSearch("Search")}
                                style={{
                                  height: "17px",
                                  width: "17px"
                                }}
                                src={Images.darkSearch}
                              />
                            ) : (
                              <img
                                onClick={() => handleSearch("Clear")}
                                style={{
                                  height: "17px",
                                  width: "17px"
                                }}
                                src={Images.cros}
                              />
                            )} */}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                  </Row>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  responsive="md"
                  style={{
                    border: "3px solid transparent"
                  }}
                >
                  {/* <thead> */}
                  <tr>
                    <th
                      style={{
                        color: "#808080",
                        padding: "25px"
                      }}
                    >
                      Description
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Link
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#808080"
                      }}
                    >
                      Content For
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#808080",
                        paddingLeft: "3%"
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                  {/* </thead> */}
                  <tbody>
                    {requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : resourceData && resourceData?.results?.length > 0 ? (
                      resourceData?.results.map(item => (
                        <>
                          <tr
                            style={{
                              // border: "3px solid",
                              // borderRadius: "50px !mportant",
                              backgroundColor: "white",
                              marginLeft: "25px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "25px",
                                borderBottomLeftRadius: "8px",
                                borderTopLeftRadius: "8px",
                                display: "flex",
                                alignItems: "center",
                                border: 0
                              }}
                            >
                              <div
                                style={{
                                  width: "45px",
                                  maxHeight: "45px",
                                  marginRight: 16
                                }}
                              >
                                <img
                                  style={{
                                    borderRadius: 4
                                  }}
                                  src={item.image}
                                />
                              </div>
                              <p
                                style={{
                                  margin: 0,
                                  color: "black",
                                  fontSize: 14
                                }}
                              >
                                <ReadMore
                                  length={100}
                                  item={item}
                                  text={item.details}
                                />
                              </p>

                              {/* {item.details.length > 25 ? (
                                <>
                                  <Tooltip
                                    title={
                                      
                                    }
                                    placement="top"
                                    arrow={true}
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflowX: "hidden",
                                        textOverflow: " ellipsis",
                                        maxWidth: 200,
                                        cursor: "pointer"
                                      }}
                                    >
                                      {item.details}
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                <>{item.details}</>
                              )} */}
                            </td>

                            <td
                              style={{
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: " ellipsis",
                                maxWidth: 200
                              }}
                              className="text-center"
                            >
                              {item.url}
                            </td>
                            <td className="text-center">{item.content}</td>

                            <td
                              className="text-right "
                              // className="text-left "
                              style={{
                                paddingTop: "14px",
                                paddingRight: "30px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px"
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  className="btn-round btn text-capitalize"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#3A0F7D",
                                    border: "1px solid  #3A0F7D",
                                    // display: "flex",
                                    // alignItems: "center",
                                    width: "max-content"
                                  }}
                                  onClick={() => {
                                    toggle2()
                                    setContentData(item)
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "12px",

                                      // marginLeft: "20px",
                                      marginRight: "4px",
                                      marginTop: "-3px",
                                      borderRadius: "0px"
                                    }}
                                    src={Images.edit}
                                  />
                                  Edit
                                </Button>
                                <Button
                                  className="btn-round btn text-capitalize"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#3A0F7D",
                                    border: "1px solid  #3A0F7D",
                                    // display: "flex",
                                    // alignItems: "center",
                                    width: "max-content"
                                  }}
                                  onClick={() => {
                                    props.deleteContentResourse({
                                      id: item.id
                                    })
                                    setUserId(item.id)
                                  }}
                                >
                                  {userId === item.id &&
                                  props.deleteRequest ? null : (
                                    <img
                                      style={{
                                        height: "12px",

                                        // marginLeft: "20px",
                                        marginRight: "4px",
                                        marginTop: "-3px",
                                        borderRadius: "0px"
                                      }}
                                      src={Images.del}
                                    />
                                  )}
                                  {userId === item.id && props.deleteRequest ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    "Delete"
                                  )}
                                </Button>
                              </div>
                            </td>
                          </tr>
                          <tr style={{ height: "12px" }}></tr>
                        </>
                      ))
                    ) : (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            transform: "translate(-50%, -50%)",
                            left: "50%"
                          }}
                        >
                          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                        </div>
                      </>
                    )}
                  </tbody>
                </Table>
                <div className="pt-4 d-flex justify-content-center">
                  {resourceData && resourceData?.results?.length > 0 ? (
                    requesting ? null : (
                      <PaginationPack
                        onChange={handlePageChange}
                        itemsCountPerPage={resourceData.page_size}
                        totalItemsCount={resourceData?.total_objects}
                        activePage={resourceData?.current_page_number}
                      />
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle1}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        {/* <Toaster position="top-center" /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Add Resource
          </h5>
          <img
            onClick={toggle1}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div>
            <label
              style={{
                display: "flex",
                marginBottom: "7px",
                marginTop: "17px",
                fontSize: "14px",
                fontFamily: "Khula",
                fontWeight: "600",
                color: "#808080"
              }}
            >
              Resource Image
            </label>
            <div
              style={{
                backgroundColor: "rgba(58, 15, 125, 0.3)",
                width: "100%",
                height: "55px",
                border: "1px black dotted",
                cursor: "pointer"
              }}
              onClick={() => fileRef.current.click()}
            >
              <input
                type="file"
                name="file"
                ref={fileRef}
                onChange={handleChange}
                hidden
              />
              {fileName ? (
                <div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "52px",
                      color: "#6434AD"
                    }}
                  >
                    {fileName}
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "52px",
                    color: "#6434AD"
                  }}
                >
                  <p style={{marginTop: 0, marginBottom: 0}}>+ Add Image</p>
                  <p style={{fontSize: 10, color: "#505050", display: 'block', textTransform: 'none', marginBottom: 0, marginTop: 0}}>
                  Recommended Image Resolution: 200px X 200px <br/>
                  </p>
                </div>
              )}
            </div>
            {backendError && backendError?.image && (
              <label
                style={{
                  color: "red",
                  display: "flex",
                  marginBottom: "0px"
                }}
              >
                {backendError?.image}
              </label>
            )}
          </div>
          <Row style={{}}>
            <Col>
              <Form>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "20px",
                      fontWeight: "600"
                    }}
                  >
                    Resource Title
                  </Label>
                  <Input
                    value={state.resourceTitle.value}
                    onChange={e => {
                      handleOnChange("resourceTitle", e.target.value)
                    }}
                    type="text"
                    placeholder="Enter title"
                    style={{ height: "55px" }}
                  />

                  {state.resourceTitle.error && (
                    <label style={{ color: "red", display: "flex" }}>
                      {state.resourceTitle.error}
                    </label>
                  )}
                  {/* {backendError && backendError?.url && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px"
                      }}
                    >
                      {backendError?.url}
                    </label>
                  )} */}
                </FormGroup>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "25px",
                      fontWeight: "600"
                    }}
                  >
                    Resource Description
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Enter Description."
                    name="text"
                    maxlength="170"
                    type="textarea"
                    value={state.description.value}
                    onChange={e => {
                      handleOnChange("description", e.target.value)
                    }}
                    style={{ height: "143px", backgroundColor: "" }}
                  />
                  {state.description.error && (
                    <label style={{ color: "red", display: "flex" }}>
                      {state.description.error}
                    </label>
                  )}
                  {backendError && backendError?.details && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px"
                      }}
                    >
                      {backendError?.details}
                    </label>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "20px",
                      fontWeight: "600"
                    }}
                  >
                    Resource Link
                  </Label>
                  <Input
                    value={state.resourceLink.value}
                    onChange={e => {
                      handleOnChange("resourceLink", e.target.value)
                    }}
                    type="url"
                    placeholder="https://www.link.co"
                    style={{ height: "55px" }}
                  />

                  {backendError && backendError.url && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px"
                      }}
                    >
                      {backendError?.url}
                    </label>
                  )}
                </FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    marginTop: "20px",
                    fontWeight: "600"
                  }}
                >
                  Content For
                </Label>

                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  style={{}}
                  // isSearchable={false}
                  value={content}
                  onChange={value => {
                    setContent(value)
                  }}
                  options={[
                    { value: 0, label: "EDUCATION CENTER" },
                    { value: 1, label: "BENEFITS" },
                    { value: 2, label: "RESOURCES" }
                  ]}
                />
                {backendError && backendError?.content && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {backendError?.content}
                  </label>
                )}
              </Form>
            </Col>
          </Row>
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",
                width: "132px",
                height: "49px"
              }}
              // onClick={toggle1}
              onClick={() => {
                props.addContentResourseFaluire(false)
                toggle1()
              }}
            >
              Cancel
            </Button>
            <div style={{ margin: "0px 10px 0px 10px" }}></div>
            <Button
              onClick={() => {
                onSaveClick()
              }}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px",
                width: "154px",
                whiteSpace: "nowrap"
              }}
            >
              {props.addRequest ? <Spinner size="sm" /> : " Add Resource"}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal1}
        toggle={toggle2}
        // className={className}
        // external={externalCloseBtn}
        style={{}}
      >
        {/* <Toaster position="top-center" /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Edit Resource
          </h5>
          <img
            onClick={toggle2}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div>
            <label
              style={{
                display: "flex",
                marginBottom: "7px",
                marginTop: "17px",
                fontSize: "14px",
                fontFamily: "Khula",
                fontWeight: "600",
                color: "#808080"
              }}
            >
              Resource Image
            </label>
            <div
              style={{
                backgroundColor: "rgba(58, 15, 125, 0.3)",
                borderRadius: 6,
                cursor: "pointer"
              }}
              onClick={() => fileRef.current.click()}
            >
              <input
                type="file"
                name="myImage"
                ref={fileRef}
                onChange={handleChange}
                hidden
              />
              {imageName ? (
                <div
                  style={{
                    display: "flex"
                    // justifyContent: "center"
                  }}
                >
                  <img
                    style={{
                      width: "45px",
                      height: "45px",
                      margin: "5px 15px 5px 10px",
                      borderRadius: 4,
                      alignSelf: "center"
                    }}
                    src={
                      imageUrl ? imageUrl : contentdata?.image
                      // contentdata?.image ? contentdata?.image : image
                    }
                  />
                  <div style={{}}>
                    <p
                      style={{
                        // display: "flex",
                        // justifyContent: "center",
                        alignSelf: "center",
                        // height: "52px",
                        color: "#6434AD",
                        padding: 10,
                        marginBottom: 0,
                        // maxWidth: 50,
                        wordBreak: "break-all"
                      }}
                    >
                      {imageName}
                    </p>
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "52px",
                    color: "#6434AD"
                  }}
                >
                  + Add Resume
                </p>
              )}
            </div>

            {/* <img style={{ width: '90px', height: '25px' }} src={Images.add_resume} alt="Logo" /> */}
          </div>
          <Row style={{}}>
            <Col>
              <Form>
                <FormGroup>
                  <Label
                    style={{
                      color: "#808080",
                      fontSize: 14,
                      fontFamily: "khula",
                      marginTop: "25px",
                      fontWeight: "600"
                    }}
                  >
                    Resource Description
                  </Label>
                  <Input
                    id="exampleText"
                    placeholder="Enter Description."
                    name="text"
                    maxlength="170"
                    type="textarea"
                    value={state.resourcedescription.value}
                    onChange={e =>
                      handleOnChange("resourcedescription", e.target.value)
                    }
                    style={{ height: "143px", backgroundColor: "" }}
                  />

                  {backendError && backendError?.details && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        marginBottom: "0px",
                        marginTop: 5
                      }}
                    >
                      {backendError?.details}
                    </label>
                  )}
                  {/* {props.BackendError ? (
                    <p style={{ color: "red" }}>{props.BackendError}</p>
                  ) : null}
                  {state.descriptionOfEngagement.error ? (
                    <p style={{ color: "red", padding: 5 }}>
                      {state.descriptionOfEngagement.error}
                    </p>
                  ) : null} */}
                </FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    marginTop: "20px",
                    fontWeight: "600"
                  }}
                >
                  Resource Link
                </Label>
                <Input
                  value={state.link.value}
                  onChange={e => {
                    handleOnChange("link", e.target.value)
                    setIsCheck(true)
                  }}
                  placeholder="www.link.co"
                  type="url"
                  style={{ height: "55px" }}
                />
                {state.link.error && isCheck && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.link.error}
                  </label>
                )}
                {backendError && backendError?.url && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px",
                      marginTop: 5
                    }}
                  >
                    {backendError?.url}
                  </label>
                )}
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    marginTop: "20px",
                    fontWeight: "600"
                  }}
                >
                  Content For
                </Label>

                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  style={{}}
                  placeholder={
                    contentdata?.content ? contentdata?.content : "select"
                  }
                  isSearchable={false}
                  value={dropdownContent}
                  onChange={value => {
                    setDropdownContent(value)
                    // timeTrackerRequestFaluire(false)
                    // setBtnName("Submit")
                  }}
                  options={[
                    { value: 0, label: "EDUCATION CENTER" },
                    { value: 1, label: "BENEFITS" },
                    { value: 2, label: "RESOURCES" }
                  ]}
                />
              </Form>
            </Col>
          </Row>
          <div
            style={{
              margin: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              className="btn-round btn text-capitalize"
              style={{
                backgroundColor: "#fff",
                color: "#3A0F7D",
                border: "1px solid  #3A0F7D",

                height: "49px",
                width: "132px"
              }}
              onClick={toggle2}
            >
              Cancel
            </Button>
            <div style={{ margin: "0px 10px 0px 10px" }}></div>
            <Button
              onClick={() => onEditClick()}
              className="btn-round text-capitalize"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                height: "49px",
                width: "200px",
                whiteSpace: "nowrap"
              }}
            >
              {props.editRequest ? <Spinner size="sm" /> : "Save Changes"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.Resource.requesting,
  resourceData: state.Resource.resourceData,
  deleteRequest: state.AddContent.deleteRequest,
  addRequest: state.AddContent.addRequest,
  backendError: state.AddContent.error,
  successResource: state.AddContent.successResource,
  editRequest: state.AddContent.editRequest,
  editSuccess: state.AddContent.editSuccess
})

const mapDispatchToProps = dispatch => ({
  resourcesRequest: data => dispatch(resourcesRequest(data)),
  addContentResourse: data => dispatch(addContentResourse(data)),
  addContentResourseFaluire: data => dispatch(addContentResourseFaluire(data)),
  editContentResourse: data => dispatch(editContentResourse(data)),
  deleteContentResourse: data => dispatch(deleteContentResourse(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(AddContent)
// export default AddContent
