import { all } from "redux-saga/effects"
import { push } from "connected-react-router"

import Notifications from "Containers/ExpertDesign/Screens/Notifications/redux/saga"
import LoginScreen from "Containers/LoginScreen/redux/saga"
import RegisterScreen from "Containers/RegisterScreen/redux/saga"
import ForgotScreen from "Containers/ForgotScreen/redux/saga"
import Settings from "Containers/Settings/redux/saga"
import HomeIntialProfile from "Containers/HomeIntialProfile/redux/saga"
import HomeInitialProfileV2 from "Containers/HomeInitialProfileV2/redux/saga"
import ExpertRefer from "Containers/ExpertDesign/Screens/ExpertRefer/redux/saga"
import TimeTracker from "Containers/TimeTracker/redux/saga"
import LogHour from "Containers/ExpertDesign/Screens/LogHour/redux/saga"
import ApproveAccounts from "Containers/AdminDesign/ApproveAccounts/redux/saga"
import Feedback from "Containers/AdminDesign/Feedback/redux/saga"
import EditProfile from "Containers/ExpertDesign/Screens/EditProfile/redux/saga"
import Engagement from "Containers/ExpertDesign/Screens/Engagement/redux/saga"
import Profile from "Containers/ExpertDesign/Screens/MyProfile/redux/saga"
import EducationalCenter from "Containers/ExpertDesign/Screens/EducationalCenter/redux/saga"
import Resource from "Containers/ExpertDesign/Screens/ResourceArea/redux/saga"
import BillingHistory from "Containers/BusinessDesign/NewMyTeam/BillingHistory/redux/saga"
//Business
import RequestExpert from "Containers/BusinessDesign/RequestExpert/redux/saga"
import BusinessHome from "Containers/BusinessDesign/BusinessHome/redux/saga"
import MyTeam from "Containers/BusinessDesign/MyTeam/redux/saga"
import SignedContracts from "Containers/BusinessDesign/SignedContracts/redux/saga"
import Benefits from "Containers/BusinessDesign/Benefits/redux/saga"
import reffer from "Containers/BusinessDesign/Refer/redux/saga"

//Admin
import Business from "Containers/AdminDesign/Business/redux/saga"
import ExpertsScreen from "Containers/AdminDesign/ExpertsScreen/redux/saga"
import AdminTimeTracking from "Containers/AdminDesign/AdminTimeTracking/redux/saga"
import EngagementRequest from "Containers/AdminDesign/Engagements/EngagementRequest/redux/saga"
// import AdminEngagement from "Containers/AdminDesign/Engagements/redux/saga"
import ActiveEngagement from "Containers/AdminDesign/Engagements/ActiveEngagment/redux/saga"
import Referrals from "Containers/AdminDesign/Referrals/redux/saga"
// import Engagements from "Containers/AdminDesign/Engagements/redux/saga"
import AddContent from "Containers/AdminDesign/AddContent/redux/saga"
import PushNotification from "Containers/AdminDesign/PushNotification/redux/saga"
// Business Profile
import BusinessProfile from "Containers/BusinessDesign/BusinessProfile/redux/saga"
import BusinessLogHour from "Containers/BusinessDesign/BusinessLogHistory/redux/saga"

export function* mainSaga() {
  yield all([
    LoginScreen,
    RegisterScreen,
    ForgotScreen,
    Settings,
    HomeIntialProfile,
    HomeInitialProfileV2,
    TimeTracker,
    ExpertRefer,
    LogHour,
    ApproveAccounts,
    Feedback,
    EditProfile,
    BusinessHome,
    RequestExpert,
    Business,
    Engagement,
    ExpertsScreen,
    AdminTimeTracking,
    MyTeam,
    Notifications,
    EngagementRequest,
    // AdminEngagement,
    ActiveEngagement,
    Profile,
    EducationalCenter,
    Resource,
    BusinessProfile,
    SignedContracts,
    Referrals,
    BillingHistory,
    Benefits,
    // Engagements,
    AddContent,
    PushNotification,
    reffer,
    BusinessLogHour
  ])
}
