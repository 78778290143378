import {
  GET_ALL_TIMETRACKING_LOGS_REQUEST,
  GET_ALL_TIMETRACKING_LOGS_SUCCESS,
  GET_ALL_TIMETRACKING_LOGS_FALUIRE,
  ADMIN_EXPORT_CSV_REQUEST,
  ADMIN_EXPORT_CSV_REQUEST_SUCCESS,
  ADMIN_EXPORT_CSV_REQUEST_FALUIRE,
  FILTER_TIMETRACKING_LOGS_REQUEST,
  FILTER_TIMETRACKING_LOGS_SUCCESS,
  FILTER_TIMETRACKING_LOGS_FALUIRE,
  PATCH_TIMETRACKING_LOGS_REQUEST,
  PATCH_TIMETRACKING_LOGS_SUCCESS,
  PATCH_TIMETRACKING_LOGS_FALUIRE,
  GET_BILLING_PERIODS_REQUEST,
  GET_BILLING_PERIODS_SUCCESS,
  GET_BILLING_PERIODS_FALUIRE
} from "./types"

const initialState = {
  allTimeTrackData: false,
  requesting: false,
  error: false,
  exportCSV: false,
  csvrequesting: false,
  isFiltered: false,
  patchDataSuccess: false,
  patchRequesting: false,
  billingPeriods: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TIMETRACKING_LOGS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ALL_TIMETRACKING_LOGS_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        allTimeTrackData: action.data
      }

    case GET_ALL_TIMETRACKING_LOGS_FALUIRE:
      return {
        ...state,
        error: action.data,
        requesting: false
      }

    case ADMIN_EXPORT_CSV_REQUEST:
      return {
        ...state,
        csvrequesting: true
      }

    case ADMIN_EXPORT_CSV_REQUEST_SUCCESS:
      return {
        ...state,
        csvrequesting: false,
        exportCSV: action.data
      }

    case ADMIN_EXPORT_CSV_REQUEST_FALUIRE:
      return {
        ...state,
        error: action.data,
        csvrequesting: false
      }

    case FILTER_TIMETRACKING_LOGS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case FILTER_TIMETRACKING_LOGS_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        isFiltered: true,
        allTimeTrackData: action.data
      }

    case FILTER_TIMETRACKING_LOGS_FALUIRE:
      return {
        ...state,
        error: action.data,
        requesting: false
      }

    case PATCH_TIMETRACKING_LOGS_REQUEST:
      return {
        ...state,
        patchRequesting: true
      }

    case PATCH_TIMETRACKING_LOGS_SUCCESS:
      return {
        ...state,
        error: false,
        patchRequesting: false,
        patchDataSuccess: action.data
      }

    case PATCH_TIMETRACKING_LOGS_FALUIRE:
      return {
        ...state,
        error: action.data,
        patchRequesting: false
      }

    case GET_BILLING_PERIODS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_BILLING_PERIODS_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        billingPeriods: action.data
      }

    case GET_BILLING_PERIODS_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
