import React, { useEffect, useState } from "react"

import PaginationPack from "components/PaginationPack"

// hooks
// import {useForm} from 'react-hook-form'
import Images from "utils/Images"

//Components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  UncontrolledTooltip,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Modal,
  Label,
  Form,
  FormGroup,
  Spinner
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
import ReactStars from "react-rating-stars-component"
import useForm from "utils/useForm"

import { connect } from "react-redux"

import toast, { Toaster } from "react-hot-toast"
import {
  businessRatingequest,
  getMyTeamRequest,
  businessRatingFaluire,
  addTeamRequest,
  getExpertiesRequest,
  addTeamFaluire,
  addHoursRequest,
  addHoursFaluire,
  postEndEngagementRequest,
  postEndEngagementFaluire
} from "../MyTeam/redux/actions"
import validator from "../../../utils/validation"

import { loginRequest } from "Containers/LoginScreen/redux/actions"
import { async } from "@firebase/util"
import "./style.css"
function NewMyTeam(props) {
  const {
    myTeam,
    requesting,
    rating,
    BackendError,
    businessRatingFaluire,
    experties,
    addTeamRequest,
    user,
    error
  } = props

  useEffect(() => {
    props.getMyTeamRequest()
    props.getExpertiesRequest()
  }, [])

  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [resquestHours, setResquestHours] = useState(false)
  const [endEngagement, setEndEngagement] = useState(false)

  const [open, SetOpen] = useState(false)
  const [role, setRole] = useState(false)
  const [areaOfExpertise, setAreaOfExpertise] = useState(false)
  const [save1, SetSave1] = useState("")

  const [profileData, SetProfileData] = useState(false)
  const [stars, setStars] = useState(false)
  const [allExperties, setAllExperties] = useState(false)

  useEffect(() => {
    let array = []
    if (experties) {
      experties?.user_expertise &&
        experties.user_expertise.map((item, index) =>
          array.push({ value: index.value, label: item.label })
        )
      setAllExperties(array)
    }
  }, [experties])

  const toggle = () => {
    setModal(!modal)
    setState(stateSchema)
    businessRatingFaluire(false)
  }
  const toggle1 = () => {
    SetOpen(!open)
  }
  const toggle2 = () => {
    setModal2(!modal2)
    setState(stateSchema)
    props.addTeamFaluire(false)
  }
  const toggle3 = () => {
    setModal3(!modal3)
  }

  const resquestHourModal = () => {
    setResquestHours(!resquestHours)
    setState(stateSchema)
    props.addHoursFaluire(false)
  }

  const endEngagementModal = () => {
    setEndEngagement(!endEngagement)
    setState(stateSchema)
    props.postEndEngagementFaluire(false)
  }

  const onSubmitRating = () => {
    const data = {
      description: state.description.value,
      rating: stars,
      expert: myTeam?.results[0]?.expert?.id,
      business: myTeam?.results[0]?.business
    }
    props.businessRatingequest(data, toggle)
    window.setTimeout(() => {
      props.getMyTeamRequest()
      props.getExpertiesRequest()
    }, 10000)
  }
  const onRequestHour = () => {
    const apiData = {
      hours: state.amountOfHours.value,
      description: state.requestHoursDescription.value,
      engagement: myTeam?.results[0]?.id,
      increase_hour: role?.label,
      request_to: profileData?.expert.id
    }
    setState(stateSchema)
    props.addHoursFaluire(false)
    props.addHoursRequest(apiData, resquestHourModal)
  }

  const onEndEngagement = async () => {
    const userId = await localStorage.getItem("user")

    const endEngagementData = {
      video_call: state.scheduleLink.value,
      description: state.endEngagementDescription.value,
      agreed_engagement: myTeam?.results[0]?.id,
      user: userId
    }

    props.postEndEngagementRequest(endEngagementData, endEngagementModal)
  }

  const onAddTeamMember = async () => {
    const userId = await localStorage.getItem("user")
    const data = {
      expertise_area: areaOfExpertise?.label,
      additional_info: state.additionalInformation.value,
      commitment: state.weeklyCommitment.value,
      user: userId
    }

    props.addTeamRequest(data, toggle2)
  }

  // rating
  const ratingChanged = newRating => {
    setStars(newRating)
  }
  const cardRating = cardRating => {}

  // use form
  const stateSchema = {
    expert: {
      value: "",
      error: ""
    },
    description: {
      value: "",
      error: ""
    },
    areaofExpertise: {
      value: "",
      error: ""
    },

    digitalMarketing: {
      value: "",
      error: ""
    },
    additionalInformation: {
      value: "",
      error: ""
    },
    weeklyCommitment: {
      value: "0",
      error: ""
    },
    nameOfExpert: {
      value: "",
      error: ""
    },
    Engagement: {
      value: "",
      error: ""
    },
    amountOfHours: {
      value: "0",
      error: ""
    },
    requestHoursDescription: {
      value: "",
      error: ""
    },
    scheduleLink: {
      value: "",
      error: ""
    },
    endEngagementDescription: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    description: {
      required: true
    },
    expert: {
      required: true
    },
    areaofExpertise: {
      required: true
    },
    digitalMarketing: {
      required: true
    },
    additionalInformation: {
      required: true
    },
    weeklyCommitment: {
      required: true
    },
    nameOfExpert: {
      required: true
      // validator: validator.email
    },
    Engagement: {
      required: true
      // validator: validator.email
    },
    amountOfHours: {
      required: true
      // validator: validator.email
    },
    requestHoursDescription: {
      required: true
      // validator: validator.email
    },
    searchExpert: {
      required: true
      // validator: validator.email
    },
    engagementHourDescription: {
      required: true
      // validator: validator.email
    },
    scheduleLink: {
      required: true,
      validator: validator.url
    },
    endEngagementDescription: {
      required: true
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  return (
    <>
      <div className="content">
        <Toaster position="top-center" />

        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "0px"
                  }}
                >
                  <CardTitle
                    tag="h4"
                    style={{
                      fontFamily: "Libre Caslon Text",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "27px",
                      color: "#CACACA"
                    }}
                  >
                    My Team
                  </CardTitle>

                  <Row
                    className="header"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Link to="/business/log_History">
                      <Button
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #3A0F7D",
                          borderRadius: "50px",
                          color: "#3A0F7D",
                          fontWeight: "700",
                          fontSize: "12px",
                          fontFamily: "Khula",
                          height: 42,
                          marginRight: 20,
                          whiteSpace: "nowrap"
                        }}
                      >
                        Log History
                      </Button>
                    </Link>
                    <Link to="/business/Request_Hours">
                      <Button
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #3A0F7D",
                          // padding: "15px 21px 15px 35px",
                          borderRadius: "50px",
                          color: "#3A0F7D",
                          fontWeight: "700",
                          fontSize: "12px",
                          fontFamily: "Khula",
                          height: 42,
                          marginRight: 20,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {/* {requesting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        `Continue`
                      )} */}
                        Requested hours
                      </Button>
                    </Link>
                    <Link to="/business/billing_History">
                      <Button
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #3A0F7D",
                          // padding: "15px 21px 15px 35px",
                          borderRadius: "50px",
                          color: "#3A0F7D",
                          fontWeight: "700",
                          fontSize: "12px",
                          fontFamily: "Khula",
                          height: 42,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {/* {requesting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        `Continue`
                      )} */}
                        Billing History
                      </Button>
                    </Link>
                  </Row>
                </Row>
              </CardHeader>

              <CardBody style={{ marginTop: "-60px" }}>
                {requesting ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-48px",
                      transform: "translate(-50%, -50%)",
                      left: "50%"
                    }}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      style={{}}
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <Row className="cards">
                    <Col lg="3" md="4" sm="6" style={{}}>
                      <div
                        style={{
                          backgroundColor: "white",
                          marginTop: 20,
                          borderRadius: 8,
                          border: "1px solid #E2E2E2 ",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 320,

                          padding: 20
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            flexDirection: "column"
                          }}
                        >
                          <img
                            src={require("assets/img/addCard.png")}
                            style={{
                              width: 104,
                              height: 90,
                              alignSelf: "center",
                              cursor: "pointer"
                            }}
                            onClick={toggle2}
                          />
                          <p
                            style={{
                              color: "#3A0F7D",
                              fontWeight: "bold",
                              textAlign: "center",
                              marginTop: 20
                            }}
                          >
                            + Add New Member
                          </p>
                        </div>
                        <div></div>
                      </div>
                    </Col>
                    {myTeam &&
                      myTeam?.results.length > 0 &&
                      myTeam?.results.map(item => {
                        return (
                          <>
                            <Col
                              lg="3"
                              md="4"
                              sm="6"
                              style={
                                {
                                  // width: 256
                                }
                              }
                            >
                              <div
                                style={{
                                  padding: 20,
                                  backgroundColor: "white",
                                  marginTop: 20,
                                  borderRadius: 8,
                                  border: "1px solid #E2E2E2 ",
                                  height: 320
                                }}
                              >
                                <div
                                  onClick={() => SetProfileData(item)}
                                  style={{
                                    float: "right"
                                  }}
                                >
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      aria-haspopup={true}
                                      // caret

                                      color="default"
                                      data-toggle={toggle1}
                                      id="navbarDropdownMenuLink"
                                      aria-expanded={{}}
                                      nav
                                      className="p-0 m-0"
                                    >
                                      <img
                                        // onClick={() => SetProfileData(item)}
                                        src={
                                          open == true
                                            ? Images.dotss
                                            : Images.menu_icon
                                        }
                                        style={{
                                          marginRight: "10px",

                                          height: 20
                                        }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      persist
                                      aria-labelledby="navbarDropdownMenuLink"
                                      right
                                    >
                                      <DropdownItem
                                        style={{
                                          color: "#3A0F7D",
                                          fontFamily: "Khula",
                                          fontSize: 15
                                        }}
                                        onClick={toggle}
                                        href="#"

                                        // onClick={e => e.preventDefault()}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: 150,
                                            height: 41,
                                            alignItems: "center",
                                            borderRadius: 12
                                          }}
                                        >
                                          <img
                                            src={require("assets/img/star.png")}
                                            style={{
                                              marginRight: "10px",

                                              height: 20
                                            }}
                                          />
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 15,
                                              fontWeight: "700",
                                              color: "#3A0F7D"
                                            }}
                                          >
                                            Give Rating
                                          </p>
                                        </div>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div
                                  onClick={() => {
                                    toggle3()
                                    SetProfileData(item)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: 10
                                    }}
                                  >
                                    <img
                                      // onClick={() => {
                                      //   toggle3()
                                      //   SetProfileData(item)
                                      // }}
                                      src={
                                        item.expert.profile_picture
                                          ? item.expert.profile_picture
                                          : Images.dummy_avatar
                                      }
                                      style={{
                                        width: 123,
                                        height: 123,
                                        cursor: "pointer"
                                      }}
                                    />
                                  </div>

                                  <p
                                    style={{
                                      color: "#3A0F7D",
                                      fontWeight: "bold",
                                      textAlign: "center",
                                      marginTop: 20
                                    }}
                                  >
                                    {item?.expert?.name && item.expert.name}
                                  </p>
                                  {item.expertise.length > 0 ? (
                                    <div className="example">
                                      {item.expertise.map(item => (
                                        <div>
                                          <p
                                            style={{
                                              backgroundColor:
                                                "rgba(58, 15, 125, 0.3)",
                                              padding: "10px 12px 10px 12px",
                                              borderRadius: "8px",
                                              color: "#3A0F7D",
                                              fontWeight: "400",
                                              fontSize: "14px",
                                              marginRight: "5px",
                                              whiteSpace: "nowrap"
                                            }}
                                          >
                                            {item.name}
                                          </p>
                                        </div>
                                      ))}

                                      {/* <p
                                  style={{
                                    backgroundColor: "rgba(58, 15, 125, 0.3)",
                                    padding: "10px 12px 10px 12px",
                                    borderRadius: "8px",
                                    color: "#3A0F7D",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    marginRight: "10px",
                                    width: "fit-content"
                                  }}
                                >
                                  Marketing
                                </p> */}
                                    </div>
                                  ) : (
                                    <div style={{ height: 54 }}></div>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <ReactStars
                                      count={5}
                                      value={item.rating.rating}
                                      edit={false}
                                      // onChange={item.rating && item.rating}
                                      size={24}
                                      activeColor="#ffd700"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </>
                        )
                      })}
                  </Row>
                )}
                {/* {myTeam?.results && myTeam?.results ? (
                  <div className="pt-4 d-flex justify-content-center">
                    <PaginationPack />
                  </div>
                ) : null} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <Toaster position="top-center" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Expert Rating
          </h5>
          <img
            onClick={toggle}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Label
              style={{
                color: "#808080",
                fontSize: 14,
                fontFamily: "khula",
                fontWeight: "600"
              }}
            >
              Rating
            </Label>
            <div
              style={{
                display: "flex"
              }}
            >
              {/* {[...Array(5)].map(item => {
                return (
                  <img
                    src={require("assets/img/rating.png")}
                    style={{
                      width: 18,
                      height: 18,
                      marginRight: 10
                    }}
                  />
                )
              })} */}
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
              />
            </div>
            {BackendError && BackendError?.data?.rating && (
              <label
                style={{
                  color: "red",
                  display: "flex",
                  marginBottom: "0px"
                }}
              >
                {BackendError?.data?.rating}
              </label>
            )}
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600",
                    marginTop: 24
                  }}
                >
                  Expert
                </Label>

                <Input
                  disabled
                  defultValue="Client agreement title..."
                  placeholder={
                    profileData?.expert?.name && profileData.expert.name
                  }
                  type="text"
                  style={{ backgroundColor: "#EFEFEF" }}
                />
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600",
                    marginTop: 20
                  }}
                >
                  Description
                </Label>
                <Input
                  // value={state.description.value}
                  onChange={e => {
                    handleOnChange("description", e.target.value)
                    businessRatingFaluire(false)
                  }}
                  id="exampleText"
                  placeholder="Type here"
                  name="text"
                  type="textarea"
                />
                {state.description.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.description.error}
                  </label>
                )}
                {BackendError && BackendError?.data?.description && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.data?.description}
                  </label>
                )}
              </FormGroup>
            </Form>
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                onClick={toggle}
                className="btn-round btn text-capitalize
              "
                style={{
                  backgroundColor: "#fff",
                  color: "#3A0F7D",
                  border: "1px solid  #3A0F7D",
                  marginRight: "11px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                  width: "132px",
                  height: "49px"
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-round text-capitalize
              "
                onClick={() => {
                  // RatingSaveChange()
                  onSubmitRating()
                }}
                // color="secondary"
                style={{
                  backgroundColor: "#3A0F7D",

                  width: "132px",
                  height: "49px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                }}
              >
                {props.request ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2}>
        <Toaster position="top-center" />
        <div
          className="mt-4 mx-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Add Additional Team Member
          </h5>
          <img
            onClick={toggle2}
            style={{
              height: 15,
              width: 15,
              marginTop: "-21px",
              cursor: "pointer"

              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <Form>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600",
                    marginTop: 24
                  }}
                >
                  Area of Expertise
                </Label>

                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={areaOfExpertise}
                  onChange={value => setAreaOfExpertise(value)}
                  options={allExperties && allExperties}
                  placeholder="Select Expertise"
                />
                {BackendError && BackendError?.data?.expertise_area && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.data?.expertise_area}
                  </label>
                )}
                {/* <Input
                  value={state.areaofExpertise.value}
                  onChange={e =>
                    handleOnChange("areaofExpertise", e.target.value)
                  }
                  placeholder="Type area of expertise"
                  type="text"
                  style={{ color: "#000000" }}
                />
                {state.areaofExpertise.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.areaofExpertise.error}
                  </label>
                )} */}
                {/* {BackendError && BackendError?.data.expertise_area && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.data.expertise_area}
                  </label>
                )} */}
              </FormGroup>

              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Additional Information
                </Label>

                <Input
                  value={state.additionalInformation.value}
                  onChange={e => {
                    handleOnChange("additionalInformation", e.target.value)
                    props.addTeamFaluire(false)
                  }}
                  placeholder="Type here"
                  type="textarea"
                  style={{ color: "#000000" }}
                />
                {state.additionalInformation.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.additionalInformation.error}
                  </label>
                )}
                {BackendError && BackendError?.data?.additional_info && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.data?.additional_info}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontFamily: "khula",
                    fontWeight: "600"
                  }}
                >
                  Weekly Commitment
                </Label>

                <Input
                  value={state.weeklyCommitment.value}
                  min="0"
                  onChange={e => {
                    handleOnChange("weeklyCommitment", e.target.value)
                    props.addTeamFaluire(false)
                  }}
                  placeholder="Type here"
                  type="number"
                  className="noscroll"
                  style={{ color: "#000000" }}
                />
                {state.weeklyCommitment.error && (
                  <label style={{ color: "red", display: "flex" }}>
                    {state.weeklyCommitment.error}
                  </label>
                )}
                {/* {BackendError && BackendError?.data.expertise_area && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      marginBottom: "0px"
                    }}
                  >
                    {BackendError?.data.expertise_area[0]}
                  </label>
                )} */}
              </FormGroup>
            </Form>
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                onClick={toggle2}
                className="btn-round btn text-capitalize
              "
                style={{
                  backgroundColor: "#fff",
                  color: "#3A0F7D",
                  border: "1px solid  #3A0F7D",
                  marginRight: "11px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                  width: "132px",
                  height: "49px"
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onAddTeamMember()
                }}
                className="btn-round text-capitalize
              "
                // color="secondary"
                style={{
                  backgroundColor: "#3A0F7D",
                  width: "132px",
                  height: "49px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                }}
              >
                {props.loader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
                {/* Submit */}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={modal3} toggle={toggle3}>
        <Toaster position="top-center" />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingTop: 10,
            margin: "20px"
          }}
        >
          <img
            onClick={toggle3}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div>
          <div
            style={{
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10
              }}
            >
              <img
                onClick={toggle3}
                src={
                  profileData?.expert?.profile_picture
                    ? profileData.expert.profile_picture
                    : Images.dummy_avatar
                }
                style={{ width: 123, height: 123 }}
              />
            </div>
            <p
              style={{
                color: "#3A0F7D",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 20
              }}
            >
              {profileData?.expert?.name && profileData.expert.name}
            </p>
            {profileData?.expertise && profileData?.expertise.length > 0 ? (
              <div
                className="my-3 mb-2"
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  flexWrap: "wrap",
                  // height:100,
                  overflow: "auto"
                }}
              >
                {profileData?.expertise.map(item => (
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
            ) : null}
            <p
              style={{
                color: "#808080",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: 10
              }}
            >
              Number of Hours Agreed Upon
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",

                marginTop: 10
              }}
            >
              <div
                style={{
                  width: 83,
                  height: 49,
                  backgroundColor: "#EFEFEF",
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <span style={{ color: "black", fontSize: 15 }}>
                  {profileData?.business_rate && profileData.business_rate}
                </span>
              </div>
            </div>
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                onClick={() => {
                  toggle()
                  setModal3(false)
                }}
                className="btn-round btn text-capitalize
              "
                style={{
                  backgroundColor: "#fff",
                  color: "#3A0F7D",
                  border: "1px solid  #3A0F7D",
                  marginRight: "11px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                  width: "132px",
                  height: "49px"
                }}
              >
                Rate
              </Button>
              <Button
                className="btn-round text-capitalize
              "
                // color="secondary"
                style={{
                  backgroundColor: "#3A0F7D",
                  width: "184px",
                  height: "49px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                }}
                onClick={() => {
                  resquestHourModal()
                  setModal3(false)
                }}
              >
                Request Hours
              </Button>
            </div>
            <div
              className="mb-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                endEngagementModal()
                setModal3(false)
              }}
            >
              <img
                onClick={toggle2}
                style={{
                  height: 15,
                  width: 15,
                  alignItems: "center",
                  marginRight: "7px"
                  // marginRight: "12px"
                }}
                src={require("assets/img/cross.png")}
              />
              <p
                style={{
                  color: "#3A0F7D",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 14
                }}
              >
                End Engagement
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={resquestHours}
        resquestHourModal={resquestHourModal}
        // className={className}
        // external={externalCloseBtn}
      >
        <Toaster position="top-center" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            Request Hours
          </h5>
          <img
            onClick={resquestHourModal}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Name of Expert
              </Label>
              <Input
                disabled
                defultValue="Client agreement title..."
                placeholder={
                  profileData?.expert?.name && profileData.expert.name
                }
                type="text"
                style={{ backgroundColor: "#EFEFEF" }}
              />
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Engagement
              </Label>
              <Input
                disabled
                defultValue="Client agreement title..."
                placeholder={profileData?.title && profileData.title}
                type="text"
                style={{ backgroundColor: "#EFEFEF" }}
              />
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Amount of Hours to Increase
              </Label>
              <Input
                placeholder="Enter hours"
                type="number"
                min="0"
                value={state.amountOfHours.value}
                onChange={e => {
                  handleOnChange("amountOfHours", e.target.value)
                  props.addHoursFaluire(false)
                }}
              />
              {state.amountOfHours.error && (
                <label style={{ color: "red", display: "flex" }}>
                  {state.amountOfHours.error}
                </label>
              )}
              {BackendError && BackendError?.hours && (
                <label
                  style={{
                    color: "red",
                    display: "flex",
                    marginBottom: "0px"
                  }}
                >
                  {BackendError?.hours}
                </label>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Description
              </Label>
              <Input
                id="exampleText"
                placeholder="Type here"
                name="text"
                type="textarea"
                value={state.requestHoursDescription.value}
                onChange={e => {
                  {
                    handleOnChange("requestHoursDescription", e.target.value)
                    props.addHoursFaluire(false)
                  }
                }}
              />
              {state.requestHoursDescription.error && (
                <label style={{ color: "red", display: "flex" }}>
                  {state.requestHoursDescription.error}
                </label>
              )}
              {BackendError && BackendError?.description && (
                <label
                  style={{
                    color: "red",
                    display: "flex",
                    marginBottom: "0px"
                  }}
                >
                  {BackendError?.description}
                </label>
              )}
            </FormGroup>
            <div style={{}}>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula"
                }}
              >
                Increase Hours Weekly or One-Time, Please Select Below
              </Label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="singleSelect"
                value={role}
                onChange={value => setRole(value)}
                options={[
                  {
                    value: "",
                    label: "Single Option",
                    isDisabled: true
                  },
                  // { value: "2", label: "hourly" },
                  { value: "3", label: "Weekly" },
                  { value: "4", label: "One-Time" }
                ]}
                placeholder="Select Time"
              />
              {/* <FormGroup check>
                <Label check style={{}}>
                  <Input defaultChecked type="checkbox" />
                  <span className="form-check-sign" />
                </Label>
              </FormGroup>
              <h4
                style={{
                  marginTop: "-12px",
                  marginLeft: "34px",
                  color: "#000000",
                  fontSize: "14px",
                  fountFimly: "khula",
                  fontWeight: "400"
                }}
              >
                Ongoing Engagement
              </h4> */}
            </div>
          </Form>
        </div>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={() => {
              resquestHourModal()
            }}
            className="btn-round btn text-capitalize"
            style={{
              backgroundColor: "#fff",
              color: "#3A0F7D",
              border: "1px solid  #3A0F7D",
              marginRight: "11px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              width: "132px",
              height: "49px"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onRequestHour()
              // setState(stateSchema)
              // props.addHoursFaluire(false)
            }}
            className="btn-round text-capitalize"
            // color="secondary"
            style={{
              backgroundColor: "#3A0F7D",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              width: "132px",
              height: "49px"
            }}
          >
            {props.hoursRequest ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={endEngagement}
        endEngagementModal={endEngagementModal}
        // className={className}
        // external={externalCloseBtn}
      >
        <Toaster position="top-center" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <h5
            style={{
              color: "#CACACA",
              fontSize: "22px",
              fountFimly: "Libre Caslon Text",

              fontWeight: "700",
              marginRight: "10px"
            }}
          >
            End Engagement
          </h5>
          <img
            onClick={endEngagementModal}
            style={{
              height: 15,
              width: 15,
              marginTop: "-10px",
              marginLeft: "20px",
              cursor: "pointer"
              // marginRight: "12px"
            }}
            src={require("assets/img/cros.png")}
          />
        </div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Form>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Link to Schedule a call
              </Label>

              <Input
                disabled
                style={{ cursor: "pointer" }}
                placeholder="https://meetings.hubspot.com/chris1979"
                type="url"
                value={state.scheduleLink.value}
                // onChange={e => {
                //   handleOnChange("scheduleLink", e.target.value)
                // }}
              />

              {/* {state.scheduleLink.error && (
                <label style={{ color: "red", display: "flex" }}>
                  {state.scheduleLink.error}
                </label>
              )} */}
              {/* {BackendError && BackendError?.video_call && (
                <label
                  style={{
                    color: "red",
                    display: "flex",
                    marginBottom: "0px"
                  }}
                >
                  {BackendError?.video_call}
                </label>
              )} */}
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontFamily: "khula",
                  fontWeight: "600"
                }}
              >
                Description
              </Label>
              <Input
                id="exampleText"
                placeholder="Type here"
                name="text"
                type="textarea"
                value={state.endEngagementDescription.value}
                onChange={e => {
                  handleOnChange("endEngagementDescription", e.target.value)
                }}
              />
              {/* {state.endEngagementDescription.error && (
                <label style={{ color: "red", display: "flex" }}>
                  {state.endEngagementDescription.error}
                </label>
              )} */}
              {BackendError && BackendError?.description && (
                <label
                  style={{
                    color: "red",
                    display: "flex",
                    marginBottom: "0px"
                  }}
                >
                  {BackendError?.description}
                </label>
              )}
            </FormGroup>
          </Form>
        </div>

        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={() => {
              endEngagementModal()
              props.postEndEngagementFaluire(false)
            }}
            className="btn-round btn text-capitalize"
            style={{
              backgroundColor: "#fff",
              color: "#3A0F7D",
              border: "1px solid  #3A0F7D",
              marginRight: "11px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              width: "132px",
              height: "49px"
            }}
          >
            Cancel
          </Button>
          {state.endEngagementDescription.value ? (
            <a
              href={"https://meetings.hubspot.com/chris1979"}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button
                onClick={onEndEngagement}
                className="btn-round text-capitalize"
                // color="secondary"
                style={{
                  backgroundColor: "#3A0F7D",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                  width: "132px",
                  height: "49px"
                }}
              >
                {props.hoursRequest ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
                {/* Submit */}
              </Button>
            </a>
          ) : (
            <Button
              onClick={onEndEngagement}
              className="btn-round text-capitalize"
              // color="secondary"
              style={{
                backgroundColor: "#3A0F7D",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                width: "132px",
                height: "49px"
              }}
            >
              {props.hoursRequest ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
              {/* Submit */}
            </Button>
          )}
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.MyTeam.requesting,
  BackendError: state.MyTeam.error,
  myTeam: state.MyTeam.myTeam,
  rating: state.MyTeam.rating,
  experties: state.MyTeam.experties,
  user: state.LoginScreen.user,
  request: state.MyTeam.request,
  loader: state.MyTeam.loader,
  hoursRequest: state.MyTeam.hoursRequest
})
const mapDispatchToProps = dispatch => ({
  getMyTeamRequest: data => dispatch(getMyTeamRequest(data)),
  businessRatingequest: (data, toggle) =>
    dispatch(businessRatingequest(data, toggle)),
  businessRatingFaluire: data => dispatch(businessRatingFaluire(data)),
  addTeamRequest: (data, toggle2) => dispatch(addTeamRequest(data, toggle2)),
  getExpertiesRequest: data => dispatch(getExpertiesRequest(data)),
  loginRequest: data => dispatch(loginRequest(data)),
  addTeamFaluire: data => dispatch(addTeamFaluire(data)),
  addHoursRequest: (data, resquestHourModal) =>
    dispatch(addHoursRequest(data, resquestHourModal)),
  addHoursFaluire: data => dispatch(addHoursFaluire(data)),
  postEndEngagementRequest: (data, endEngagementModal) =>
    dispatch(postEndEngagementRequest(data, endEngagementModal)),
  postEndEngagementFaluire: data => dispatch(postEndEngagementFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(NewMyTeam)
// export default NewMyTeam
