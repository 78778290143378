import { BENEFITS_REQUEST, BENEFITS_SUCCESS, BENEFITS_FALUIRE } from "./types"

const initialState = {
  requesting: false,
  error: false,
  benefits: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BENEFITS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case BENEFITS_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        benefits: action.data
      }

    case BENEFITS_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
