import {
  BUSINESS_LOG_HOUR_REQUEST,
  BUSINESS_LOG_HOUR_SUCCESS,
  BUSINESS_LOG_HOUR_FALUIRE,
  BUSINESS_LOG_HOUR_PAGE_REQUEST,
  BUSINESS_LOG_HOUR_PAGE_SUCCESS,
  BUSINESS_LOG_HOUR_PAGE_FALUIRE,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_SUCCESS,
  BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_FALUIRE
} from "./types"

export const logOurRequest = data => ({
  type: BUSINESS_LOG_HOUR_REQUEST,
  data
})

export const logOurSuccess = data => ({
  type: BUSINESS_LOG_HOUR_SUCCESS,
  data
})

export const logOurFaluire = data => ({
  type: BUSINESS_LOG_HOUR_FALUIRE,
  data
})

export const pageRequest = data => ({
  type: BUSINESS_LOG_HOUR_PAGE_REQUEST,
  data
})

export const pageSuccess = data => ({
  type: BUSINESS_LOG_HOUR_PAGE_SUCCESS,
  data
})

export const pageFaluire = data => ({
  type: BUSINESS_LOG_HOUR_PAGE_FALUIRE,
  data
})

export const exportCSVRequest = () => ({
  type: BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST
})

export const exportCSVSuccess = data => ({
  type: BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_SUCCESS,
  data
})

export const exportCSVFaluire = data => ({
  type: BUSINESS_LOG_HOUR_EXPORT_CSV_REQUEST_FALUIRE,
  data
})
