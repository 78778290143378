import TimeTracker from "./Containers/TimeTracker"
import WelcomeScreen from "Containers/WelcomeScreen"
import LoginScreen from "Containers/LoginScreen"
import RegisterScreen from "Containers/RegisterScreen"
import ForgotScreen from "Containers/ForgotScreen"
import ExpertBio from "Containers/BusinessDesign/ExpertBio"
import ExpertRating from "Containers/BusinessDesign/ExpertRating"
import ExpertDesign from "Containers/ExpertDesign"
import ConfirmPassword from "Containers/ForgotScreen/ConfirmPassword"
import LogHour from "Containers/ExpertDesign/Screens/LogHour"
import Notification from "Containers/AdminDesign/Notification"
import Engagement from "Containers/ExpertDesign/Screens/Engagement"
import MyTeam from "Containers/BusinessDesign/MyTeam"
import ManageHistory from "Containers/BusinessDesign/ManageHistory"
import Benefits from "Containers/BusinessDesign/Benefits"
import Refer from "Containers/BusinessDesign/Refer"
import ExpertHire from "Containers/BusinessDesign/ExpertHired"
import HistoricalView from "Containers/BusinessDesign/HistoricalView"
import SignedContracts from "Containers/BusinessDesign/SignedContracts"
import RequestExpert from "Containers/BusinessDesign/RequestExpert"
import LearningResources from "Containers/ExpertDesign/Screens/ResourceArea/LearningResources"
import EducationResources from "Containers/ExpertDesign/Screens/EducationalCenter/EducationResources"
// import ConfirmPassword from "Containers/ForgotScreen/ConfirmPassword"
// import BusinessDesign from "Containers/BusinessDesign"
import Settings from "Containers/Settings"
import Notifications from "Containers/ExpertDesign/Screens/Notifications"
import BusinessHome from "Containers/BusinessDesign/BusinessHome"
import MyProfile from "Containers/ExpertDesign/Screens/MyProfile"
import EditProfile from "Containers/ExpertDesign/Screens/EditProfile"
import AdminTimeTracking from "Containers/AdminDesign/AdminTimeTracking"
import TimeTrackingLogs from "Containers/AdminDesign/AdminTimeTracking/Screens/TimeTrackingLogs"
import Business from "Containers/AdminDesign/Business"
import BusinessProfile from "Containers/BusinessDesign/BusinessProfile"
import BusinessEditProfile from "Containers/BusinessDesign/BusinessEditProfile"
import HomeIntialProfile from "Containers/HomeIntialProfile"
import HomeInitialProfileV2 from "Containers/HomeInitialProfileV2"
import BillingInformation from "Containers/ExpertDesign/Screens/BillingInformation"
import EducationalCenter from "Containers/ExpertDesign/Screens/EducationalCenter"
import ExpertRefer from "Containers/ExpertDesign/Screens/ExpertRefer"
import ResourceArea from "Containers/ExpertDesign/Screens/ResourceArea"
import ManageContracts from "Containers/ExpertDesign/Screens/ResourceArea/Screens/ManageContracts"
import ReferralHistory from "Containers/ExpertDesign/Screens/ExpertRefer/ReferralHistory"
import BusinessReferralHistory from "Containers/BusinessDesign/Refer/Screens/BusinessReferralHistory"
// admimScreeen
import Listofallusers from "Containers/AdminDesign/Listofallusers"
import ApproveAccounts from "Containers/AdminDesign/ApproveAccounts"
import Feedback from "Containers/AdminDesign/Feedback"
import Engagements from "Containers/AdminDesign/Engagements"
import ActiveEngagement from "Containers/AdminDesign/Engagements/ActiveEngagment"
import EngagementRequest from "Containers/AdminDesign/Engagements/EngagementRequest"
import EngagementOpportunity from "Containers/AdminDesign/Engagements/EngagementOpportunity"
import EngagementsHistory from "Containers/AdminDesign/Engagements/EngagementsHistory"
import ActiveEngagements from "Containers/ExpertDesign/Screens/Engagement/ActiveEngagements"
import ExpertEngagementHistory from "Containers/ExpertDesign/Screens/Engagement/ExpertEngagementHistory"
import RequestedHours from "Containers/ExpertDesign/Screens/Engagement/RequestedHours"
import Referrals from "Containers/AdminDesign/Referrals"
import BusinessReferral from "Containers/AdminDesign/Referrals/BusinessReferral"
import ExpertReferralHistory from "Containers/AdminDesign/Referrals/ExpertReferralHistory"
import ExpertsScreen from "Containers/AdminDesign/ExpertsScreen"
import Images from "utils/Images"
import NewMyTeam from "Containers/BusinessDesign/NewMyTeam"
import BillingHistory from "Containers/BusinessDesign/NewMyTeam/BillingHistory"
import AddContent from "Containers/AdminDesign/AddContent"
import PushNotification from "Containers/AdminDesign/PushNotification"
import RequestHours from "Containers/BusinessDesign/RequestExpert/RequestHours"
import BusinessLogHour from "Containers/BusinessDesign/BusinessLogHistory"

const routes = [
  // Auth routes ...............

  {
    path: "/welcome",
    name: "Welcome",
    // mini: 'L',
    icon: "nc-icon nc-bank",
    component: WelcomeScreen,
    layout: "/auth"
  },
  {
    path: "/login",
    name: "Login",
    // mini: 'L',
    icon: "nc-icon nc-bank",
    component: LoginScreen,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    // mini: 'R',
    icon: "nc-icon nc-bank",
    component: RegisterScreen,
    layout: "/auth"
  },
  {
    path: "/forgot",
    name: "ForgotScreen",
    // mini: 'LS',
    icon: "nc-icon nc-bank",
    component: ForgotScreen,
    layout: "/auth"
  },
  {
    path: "/change-password/",
    name: "ChangePassword",
    // mini: 'LS',
    icon: "nc-icon nc-bank",
    component: ConfirmPassword,
    layout: "/auth"
  },

  // {
  //   path: "/engagement_history",
  //   name: "HomeIntialProfile",
  //   // mini: 'LS',
  //   icon: "nc-icon nc-bank",
  //   component: HomeIntialProfile,
  //   layout: "/auth"
  // },

  {
    path: "/HomeIntialProfile",
    name: "HomeIntialProfile",
    // mini: 'LS',
    icon: "nc-icon nc-bank",
    component: HomeIntialProfile,
    layout: "/profile",
    isProfile: true
  },
  {
    path: "/BusinessHome",
    name: "BusinessHome",
    // mini: 'LS',
    icon: "nc-icon nc-bank",
    component: BusinessHome,
    layout: "/profile",
    isProfile: true
  },

  {
    path: "/HomeInitialProfileV2",
    name: "HomeInitialProfileV2",
    // mini: 'LS',
    icon: "nc-icon nc-bank",
    component: HomeInitialProfileV2,
    layout: "/profile",
    isProfile: true
  },

  // Busuness routes ...............

  // {
  //   path: "/my_team",
  //   name: "My Team",
  //   icon: "nc-icon nc-bank",
  //   image: Images.Vector_3,
  //   component: MyTeam,
  //   layout: "/business",
  //   isShow: "business"
  // },
  {
    path: "/my_team",
    name: "My Team",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: NewMyTeam,
    layout: "/business",
    isShow: "business"
  },
  {
    path: "/billing_History",
    name: "BillingHistory",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: BillingHistory,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/log_History",
    name: "LogHistort",
    icon: "nc-icon nc-bank",
    image: Images.LogHistory,
    component: BusinessLogHour,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/expert_bio",
    name: "Expert Bio",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: ExpertBio,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/expert_rating",
    name: "Expert Rating",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: ExpertRating,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/benefits",
    name: "Benefits",
    icon: "nc-icon nc-bank",
    image: Images.Benefits,
    component: Benefits,
    layout: "/business",
    isShow: "business"
  },
  {
    path: "/business_refer",
    name: "Refer",
    icon: "nc-icon nc-bank",
    image: Images.Referrals,
    component: Refer,
    layout: "/business",
    isShow: "business"
  },
  {
    path: "/signed_contracts",
    name: "Signed Contracts",
    icon: "nc-icon nc-bank",
    image: Images.SignedContracts,
    component: SignedContracts,
    layout: "/business",
    isShow: "business"
  },
  // {
  //   path: "/manage_history",
  //   name: "Manage History",
  //   icon: "nc-icon nc-bank",
  //   image: Images.ManagementHistory,
  //   component: ManageHistory,
  //   layout: "/business",
  //   isShow: "business"
  // },

  {
    path: "/expert_hire",
    name: "Expert Hire",
    icon: "nc-icon nc-bank",
    image: Images.Business,
    component: ExpertHire,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/historical_view",
    name: "Historical View",
    icon: "nc-icon nc-bank",
    image: Images.HistoricalView,
    component: HistoricalView,
    layout: "/business"
    // isShow: "business"
  },

  {
    path: "/business_profile",
    name: "BusinessProfile",
    icon: "nc-icon nc-bank",
    image: Images.SignedContracts,
    component: BusinessProfile,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/business_Edit_profile",
    name: "BusinessEditProfile",
    icon: "nc-icon nc-bank",
    image: Images.SignedContracts,
    component: BusinessEditProfile,
    layout: "/business"
    // isShow: "business"
  },

  {
    path: "/request_expert",
    name: "Request Expert",
    icon: "nc-icon nc-bank",
    image: Images.RequestAdditionalExpert,
    component: RequestExpert,
    layout: "/business"
    // isShow: "business"
  },
  {
    path: "/business_referral_history",
    name: "BusinessReferralHistory",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: BusinessReferralHistory,
    layout: "/business"
    // isShow: "business"
  },

  {
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-bank",
    image: Images.Vector_2,
    component: Settings,
    layout: "/business"
    // isShow: "expert"
  },

  {
    path: "/Notifications",
    name: "Notifications",
    icon: "nc-icon nc-bank",
    image: Images.Vector_2,
    component: Notifications,
    layout: "/business"
    // isShow: "expert"
  },

  {
    path: "/MyProfile",
    name: "MyProfile",
    image: Images.Vector_2,
    component: MyProfile,
    layout: "/business"
    // isShow: "expert"
  },

  {
    path: "/EditProfile",
    name: "EditProfile",
    image: Images.Vector_2,
    component: EditProfile,
    layout: "/business"
    // isShow: "expert"
  },
  {
    path: "/Request_Hours",
    name: "RequestHours",
    image: Images.Vector_2,
    component: RequestHours,
    layout: "/business"
    // isShow: "business"
  },

  // Expets routes..............

  {
    path: "/TimeTracker",
    name: "Time Tracker",
    icon: "nc-icon nc-bank",
    image: Images.Vector_1,
    component: TimeTracker,
    layout: "/Expert",
    isShow: "expert"
  },
  {
    path: "/LogHistory",
    name: "Log History",
    icon: "nc-icon nc-bank",
    image: Images.LogHistory,
    component: LogHour,
    layout: "/Expert",
    isShow: "expert"
  },
  {
    path: "/Engagements",
    name: "Engagements",
    icon: "nc-icon nc-bank",
    image: Images.Engagements,
    component: Engagement,
    layout: "/Expert",
    isShow: "expert"
  },

  {
    path: "/Engagements_active",
    name: "Active Engagements",
    icon: "nc-icon nc-bank",
    image: Images.Vector_7,
    component: ActiveEngagements,
    layout: "/Expert"
    // isShow: "expert"
  },

  {
    path: "/Engagements_history",
    name: "Expert Engagement History",
    icon: "nc-icon nc-bank",
    image: Images.Vector_7,
    component: ExpertEngagementHistory,
    layout: "/Expert"
    // isShow: "expert"
  },

  {
    path: "/Engagements_requested_hours",
    name: "Requested Hours",
    icon: "nc-icon nc-bank",
    image: Images.Vector_7,
    component: RequestedHours,
    layout: "/Expert"
    // isShow: "expert"
  },

  {
    path: "/Billing Informations",
    name: "Billing Information",
    icon: "nc-icon nc-bank",
    image: Images.BillingInformations,
    component: BillingInformation,
    layout: "/Expert",
    isShow: "expert"
  },
  {
    path: "/Educational Center",
    name: "Education Center",
    icon: "nc-icon nc-bank",
    image: Images.EducationalCenter,
    component: EducationResources,
    layout: "/Expert",
    isShow: "expert"
  },
  // {
  //   path: "/EducationResources",
  //   name: "Apply For Expertise",
  //   icon: "nc-icon nc-bank",
  //   // image: Images.EducationalCenter,
  //   component: EducationResources,
  //   layout: "/Expert"
  //   // isShow: "expert"
  // },
  // {
  //   path: "/LearningResources",
  //   name: "Learning Resources",
  //   icon: "nc-icon nc-bank",
  //   image: Images.EducationalCenter,
  //   component: LearningResources,
  //   layout: "/Expert"
  //   // isShow: "expert"
  // },

  {
    path: "/Refer",
    name: "Refer",
    icon: "nc-icon nc-bank",
    image: Images.Referrals,
    component: ExpertRefer,
    layout: "/Expert",
    isShow: "expert"
  },
  {
    path: "/Resource Area",
    name: "Resources",
    icon: "nc-icon nc-bank",
    image: Images.ResourceArea,
    component: LearningResources,
    layout: "/Expert",
    isShow: "expert"
  },

  {
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-bank",
    image: Images.Vector_2,
    component: Settings,
    layout: "/Expert"
    // isShow: "expert"
  },

  {
    path: "/Notifications",
    name: "Notifications",
    icon: "nc-icon nc-bank",
    image: Images.Vector_2,
    component: Notifications,
    layout: "/Expert"
    // isShow: "expert"
  },

  {
    path: "/MyProfile",
    name: "MyProfile",
    image: Images.Vector_2,
    component: MyProfile,
    layout: "/Expert"
    // isShow: "expert"
  },

  {
    path: "/EditProfile",
    name: "EditProfile",
    image: Images.Vector_2,
    component: EditProfile,
    layout: "/Expert"
    // isShow: "expert"
  },
  {
    path: "/Refer_history_Expert",
    name: "ReferralHistory",
    icon: "nc-icon nc-bank",
    image: Images.Vector_7,
    component: ReferralHistory,
    layout: "/Expert"
    // isShow: "expert"
  },
  {
    path: "/manage_contracts",
    name: "ManageContracts",
    icon: "nc-icon nc-bank",
    image: Images.Vector_7,
    component: ManageContracts,
    layout: "/Expert"
    // isShow: "admin"
  },

  // Admin routes ...............

  {
    path: "/List_of_all_users",
    name: "List of all users",
    icon: "nc-icon nc-bank",
    image: Images.Listofallusers,
    component: Listofallusers,
    layout: "/admin"
    // isShow: "admin"
  },

  // {
  //   path: "/Approve_Accounts",
  //   name: "Approve Accounts",
  //   icon: "nc-icon nc-bank",
  //   image: Images.ApproveAccounts,
  //   component: ApproveAccounts,
  //   layout: "/admin",
  //   isShow: "admin"
  // },
  {
    path: "/time_tracker",
    name: "Time Tracker",
    icon: "bi bi-hourglass-top",
    image: Images.Vector_1,
    component: AdminTimeTracking,
    layout: "/admin",
    isShow: "admin"
  },
  {
    path: "/Feedback",
    name: "Feedback",
    icon: "nc-icon nc-bank",
    image: Images.feedBack,
    component: Feedback,
    layout: "/admin",
    isShow: "admin"
  },

  {
    path: "/time_tracking_logs",
    name: "Time Tracking Logs",
    icon: "bi bi-hourglass-top",
    image: Images.Vector_1,
    component: TimeTrackingLogs,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/business",
    name: "Business",
    icon: "bi bi-hourglass-top",
    image: Images.Business,
    component: Business,
    layout: "/admin",
    isShow: "admin"
  },
  {
    path: "/experts-screen",
    name: "Experts",
    icon: "bi bi-hourglass-top",
    image: Images.Experts,
    component: ExpertsScreen,
    layout: "/admin",
    isShow: "admin"
  },

  {
    path: "/engagements",
    name: "Engagements",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: Engagements,
    layout: "/admin",
    isShow: "admin"
  },
  {
    path: "/engagements_active",
    name: "EngagmentsActive",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: ActiveEngagement,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/engagements_Request",
    name: "EngagementRequest",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: EngagementRequest,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/engagements_opportunity",
    name: "EngagementOpportunity",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: EngagementOpportunity,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/engagements_history",
    name: "EngagementsHistory",
    icon: "nc-icon nc-bank",
    image: Images.Vector_3,
    component: EngagementsHistory,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/referrals",
    name: "Referrals",
    icon: "bi bi-hourglass-top",
    image: Images.Referrals,
    component: Referrals,
    layout: "/admin",
    isShow: "admin"
  },
  {
    path: "/referrals_business",
    name: "Business Referral",
    icon: "bi bi-hourglass-top",
    image: Images.Referrals,
    component: BusinessReferral,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/referrals_expert_history",
    name: "ExpertReferralHistory",
    icon: "bi bi-hourglass-top",
    image: Images.Referrals,
    component: ExpertReferralHistory,
    layout: "/admin"
    // isShow: "admin"
  },

  {
    path: "/LogHour",
    name: "Log History",
    icon: "nc-icon nc-bank",
    image: Images.Vector_2,
    component: LogHour,
    layout: "/admin"
    // isShow: "admin"
  },

  {
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-bank",
    image: Images.Vector_2,
    component: Settings,
    layout: "/admin"
    // isShow: "admin"
  },

  {
    path: "/Notifications",
    name: "Notifications",
    icon: "bi bi-hourglass-top",
    image: Images.Vector_1,
    component: Notification,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/MyProfile",
    name: "MyProfile",
    image: Images.Vector_2,
    component: MyProfile,
    layout: "/admin"
    // isShow: "admin"
  },

  {
    path: "/EditProfile",
    name: "EditProfile",
    image: Images.Vector_2,
    component: EditProfile,
    layout: "/admin"
    // isShow: "admin"
  },

  {
    path: "/billing_informations",
    name: "Billing Informations",
    icon: "nc-icon nc-bank",
    image: Images.Vector_4,
    component: BillingInformation,
    layout: "/admin"
    // isShow: "admin"
  },

  // {
  //   path: "/educational_center",
  //   name: "Educational Center",
  //   icon: "nc-icon nc-bank",
  //   image: Images.Vector_5,
  //   component: EducationalCenter,
  //   layout: "/admin",
  //   // isShow: "admin"
  // },

  // {
  //   path: "/Refer",
  //   name: "Refer",
  //   icon: "nc-icon nc-bank",
  //   image: Images.Vector_6,
  //   component: Refer,
  //   layout: "/admin",
  //   // isShow: "admin"
  // },

  {
    path: "/resource_area",
    name: "Resources",
    icon: "nc-icon nc-bank",
    image: Images.Vector_7,
    component: ResourceArea,
    layout: "/admin"
    // isShow: "admin"
  },
  {
    path: "/add_content",
    name: "Add Content",
    icon: "nc-icon nc-bank",
    image: Images.addC,
    component: AddContent,
    layout: "/admin",
    isShow: "admin"
  },
  {
    path: "/PushNotification",
    name: "Push Notification",
    icon: "nc-icon nc-bank",
    image: Images.not,
    component: PushNotification,
    layout: "/admin",
    isShow: "admin"
  }

  // admin screen
]

export default routes
