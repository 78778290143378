import {
    HOME_PROFILE_REQUEST,
    HOME_PROFILE_SUCCESS,
    HOME_PROFILE_FALUIRE,
} from './types';
export const homeProfileRequest = (data) => ({
    type:  HOME_PROFILE_REQUEST,
    data
});

export const homeProfileRequestSuccess = (data) => ({
    type: HOME_PROFILE_SUCCESS,
    data,
});
export const homeProfileRequestFaluire = (data) => ({
    type: HOME_PROFILE_FALUIRE,
    data,
});