import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"
// import AsyncStorage from '@react-native-community/async-storage';

// import { showMessage } from 'react-native-flash-message';
// import toast from "react-hot-toast"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  ADDITIONAL_EXPERTIES_REQUEST,
  DOWNLOAD_CONTRACT_REQUEST,
  GET_INDUSTRIAL_EXPERIENCE_REQUEST,
  GET_EXPERT_PROFILE_REQUEST
} from "./types"

import { loginSuccess } from "Containers/LoginScreen/redux/actions"

// actions
import {
  downloadContractSuccess,
  downloadContractFailure,
  getIndustryExperienceSuccess,
  getIndustryExperienceFailure,
  postAdditionalExpertiesSuccess,
  postAdditionalExpertiesFailure,
  getExpertProfileSuccess,
  getExpertProfileFailure,
  getExpertProfile
} from "./actions"

async function downloadContractAPI() {
  const URL = `${BASE_URL}/api/v1/contract/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* downloadContractAPIData() {
  try {
    const response = yield call(downloadContractAPI)
    if (response?.data?.data?.file_url) {
      var element = document.createElement("a")
      element.setAttribute("href", response?.data?.data?.file_url)
      element.setAttribute("download", "contract.pdf")
      element.style.display = "none"
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      toast.success("Success")
    } else {
    }
    yield put(downloadContractSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(downloadContractFailure())
  }
}

async function getIndustryAPI() {
  const URL = `${BASE_URL}/api/v1/industry_experiences/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getIndustryData() {
  try {
    const response = yield call(getIndustryAPI)
    yield put(getIndustryExperienceSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getIndustryExperienceFailure(response?.data))
  }
}

async function gadditionalExpertiesAPI(data) {
  const URL = `${BASE_URL}/api/v1/apply_experiences/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* postadditionalExpertiesData({ data, setModal }) {
  try {
    const response = yield call(gadditionalExpertiesAPI, data)
    yield put(postAdditionalExpertiesSuccess(response.data))
    yield put(getExpertProfile())
    toast.success("Success")

    setModal()
  } catch (e) {
    const { response } = e
    yield put(postAdditionalExpertiesFailure(response?.data))
  }
}

async function getProfileReqAPI(data) {
  const URL = `${BASE_URL}/api/v1/user_profile/`
  const accessToken = await localStorage.getItem("authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getProfileReq({ data }) {
  try {
    const response = yield call(getProfileReqAPI, data)
    yield put(getExpertProfileSuccess(response.data))
    yield put(loginSuccess(response.data?.user_detail))
  } catch (e) {
    const { response } = e
    yield put(getExpertProfileFailure(response?.data))
  }
}

export default all([
  takeLatest(DOWNLOAD_CONTRACT_REQUEST, downloadContractAPIData),
  takeLatest(GET_INDUSTRIAL_EXPERIENCE_REQUEST, getIndustryData),
  takeLatest(ADDITIONAL_EXPERTIES_REQUEST, postadditionalExpertiesData),
  takeLatest(GET_EXPERT_PROFILE_REQUEST, getProfileReq)
])
