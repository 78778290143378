import {
  PATCH_MYTEAM_REQUESTENGAGEMENT,
  PATCH_MYTEAM_REQUESTENGAGEMENT_SUCCESS,
  PATCH_MYTEAM_REQUESTENGAGEMENT_FALUIRE,
  GET_MYTEAM_REQUEST,
  GET_MYTEAM_SUCCESS,
  GET_MYTEAM_FALUIRE,
  BUSINESS_RATING_REQUEST,
  BUSINESS_RATING_SUCCESS,
  BUSINESS_RATING_FALUIRE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FALUIRE,
  GET_EXPERTIES_REQUEST,
  GET_EXPERTIES_SUCCESS,
  GET_EXPERTIES_FALUIRE,
  ADD_HOURS_REQUEST,
  ADD_HOURS_SUCCESS,
  ADD_HOURS_FALUIRE,
  POST_END_ENGAGEMENT_REQUEST,
  POST_END_ENGAGEMENT_SUCCESS,
  POST_END_ENGAGEMENT_FALUIRE
} from "./types"

const initialState = {
  requesting: false,
  error: false,
  RequestEngagement: false,
  myTeam: false,
  experties: false,
  request: false,
  loader: false,
  hoursRequest: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PATCH_MYTEAM_REQUESTENGAGEMENT:
      return {
        ...state,
        requesting: true
      }

    case PATCH_MYTEAM_REQUESTENGAGEMENT_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        RequestEngagement: action.data
      }

    case PATCH_MYTEAM_REQUESTENGAGEMENT_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_MYTEAM_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_MYTEAM_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        myTeam: action.data
      }

    case GET_MYTEAM_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }
    case BUSINESS_RATING_REQUEST:
      return {
        ...state,
        request: true
      }

    case BUSINESS_RATING_SUCCESS:
      return {
        ...state,
        error: false,
        request: false
      }

    case BUSINESS_RATING_FALUIRE:
      return {
        ...state,
        request: false,
        error: action.data
      }

    case ADD_TEAM_REQUEST:
      return {
        ...state,
        loader: true
      }

    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        error: false,
        loader: false
      }

    case ADD_TEAM_FALUIRE:
      return {
        ...state,
        loader: false,
        error: action.data
      }
    case GET_EXPERTIES_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_EXPERTIES_SUCCESS:
      return {
        ...state,
        error: false,
        requesting: false,
        experties: action.data
      }

    case GET_EXPERTIES_FALUIRE:
      return {
        ...state,
        error: false,
        requesting: false,
        experties: action.data
      }

    case ADD_HOURS_REQUEST:
      return {
        ...state,
        hoursRequest: true
      }

    case ADD_HOURS_SUCCESS:
      return {
        ...state,
        error: false,
        hoursRequest: false
      }

    case ADD_HOURS_FALUIRE:
      return {
        ...state,
        error: action.data,
        hoursRequest: false
      }

    case POST_END_ENGAGEMENT_REQUEST:
      return {
        ...state,
        hoursRequest: true
      }

    case POST_END_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        error: false,
        hoursRequest: false
      }

    case POST_END_ENGAGEMENT_FALUIRE:
      return {
        ...state,
        error: action.data,
        hoursRequest: false
      }

    default:
      return state
  }
}
