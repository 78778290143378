import {
  REQUEST_ADDITIONAL_EXPERT,
  REQUEST_ADDITIONAL_EXPERT_SUCCESS,
  REQUEST_ADDITIONAL_EXPERT_FALUIRE
  // TOGGLE_MODAL,
  // FEEDBACK_PAGE_REQUEST,
  // FEEDBACK_PAGE_SUCCESS,
  // FEEDBACK_PAGE_FALUIRE
} from "./types"

export const RequestAddtionalExpert = (data, toggle) => ({
  type: REQUEST_ADDITIONAL_EXPERT,
  data,
  toggle
})

export const AddtionalExpertSuccess = data => ({
  type: REQUEST_ADDITIONAL_EXPERT_SUCCESS,
  data
})

export const AddtionalExpertFaluire = data => ({
  type: REQUEST_ADDITIONAL_EXPERT_FALUIRE,
  data
})

// export const toggleModal = data => ({
//   type: TOGGLE_MODAL,
//   data
// })

// export const pageRequest = page => ({
//   type: FEEDBACK_PAGE_REQUEST,
//   page
// })

// export const pageSuccess = page => ({
//   type: FEEDBACK_PAGE_SUCCESS,
//   page
// })

// export const pageFaluire = error => ({
//   type: FEEDBACK_PAGE_FALUIRE,
//   error
// })
