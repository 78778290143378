import React, { useState, useRef } from "react"
import PictureUpload from "components/CustomUpload/PictureUpload.js"
// hooks
// import {useForm} from 'react-hook-form'

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap"
import Select from "react-select"
// import './style.scss';

// import ReactPaginate from 'react-paginate';
import Images from "utils/Images"

import { Link } from "react-router-dom"

function ExpertBio(props) {
  const fileRef = useRef()

  const [isFilePicked, setIsFilePicked] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [disabled, setDisabled] = useState(true)

  const handleChange = e => {
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <CardTitle
                tag="h4"
                style={{
                  fontFamily: "Libre Caslon Text",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#CACACA"
                }}
              >
                <img
                  onClick={() => props.history.goBack()}
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "20px"
                  }}
                  src={Images.back_button}
                />
                Expert Bio
              </CardTitle>
              {/* <div style={{ width: '200px' }}> */}

              {/* </div> */}
            </div>
            <Card
              className="card-plain"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardHeader></CardHeader>
              <CardBody>
                <>
                  <div style={{ margin: "20px" }}>
                    <Link to="/Expert/EditProfile">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "-26px"
                        }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px",
                            border: "2px #3A0F7D solid",
                            borderRadius: "30px",
                            padding: "8px 26px 6px 26px"
                          }}
                        >
                          <p
                            style={{
                              // border: '2px #3A0F7D solid',
                              // ,

                              color: "#3A0F7D",
                              fontWeight: "700",
                              fontSize: "16px",
                              margin: "0px",

                              alignContent: "center"
                            }}
                          >
                            Edit Profile
                          </p>
                        </div> */}
                      </div>
                    </Link>
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <img
                            style={{
                              width: "192px",
                              height: "192px",
                              borderRadius: "50%"
                            }}
                            src={require("assets/img/Profile.png")}
                          />
                        </div>
                        {/* <PictureUpload /> */}
                      </Col>
                    </Row>
                    <Row
                      style={{ justifyContent: "center", marginTop: "23px" }}
                    >
                      <Col sm="12">
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              full Name
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                placeholder="Ron Sharon"
                                type="text"
                                disabled={disabled}
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="1"></Col>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="10"
                            >
                              LinkedIn Profile Link
                            </Label>
                            <FormGroup>
                              <Input
                                placeholder="www.linkedIn.com/ron"
                                type="text"
                                disabled={disabled}
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className="mt-md-4"
                      style={{ justifyContent: "center" }}
                    >
                      <Col sm="12">
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Email
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                placeholder="ron.sharon@example.com"
                                type="email"
                                disabled={disabled}
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="1"></Col>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="10"
                            >
                              Hourly Rate
                            </Label>
                            <FormGroup
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <Input
                                placeholder="40"
                                type="text"
                                disabled={disabled}
                                style={{
                                  width: 97,
                                  backgroundColor: "#EFEFEF"
                                }}
                              />
                              <p
                                style={{
                                  margin: 0,
                                  marginLeft: "13px",
                                  color: "#808080",
                                  fontWeight: "600"
                                }}
                              >
                                /hr
                              </p>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className="mt-md-4"
                      style={{ justifyContent: "center" }}
                    >
                      <Col sm="12">
                        <Row style={{ justifyContent: "center" }}>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Resume
                            </Label>
                            <FormGroup className={{}}>
                              <div
                                style={{
                                  backgroundColor: "rgba(58, 15, 125, 0.3)",
                                  width: "157px",
                                  height: "51px",
                                  alignItems: "center",
                                  display: "flex",
                                  paddingLeft: "20px",
                                  borderRadius: "4px"
                                }}
                              >
                                <img
                                  style={{
                                    height: "20px",
                                    width: "16px",
                                    // marginLeft: "20px",
                                    marginRight: "4px",
                                    borderRadius: "0px"
                                  }}
                                  src={require("assets/img/uploadPdf.png")}
                                  onClick={() => fileRef.current.click()}
                                />
                                <input
                                  ref={fileRef}
                                  onChange={handleChange}
                                  disabled={disabled}
                                  multiple={false}
                                  type="file"
                                  hidden
                                />
                                {isFilePicked ? (
                                  <div>
                                    <p
                                      style={{
                                        margin: "0px",
                                        marginLeft: "5px"
                                      }}
                                    >
                                      {selectedFile.name}
                                    </p>
                                  </div>
                                ) : (
                                  "fileNmae.pdf"
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1"></Col>
                          <Col md="5">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="10"
                            >
                              Vertical Section
                            </Label>
                            <FormGroup
                              className="d-sm-flex"
                              style={{
                                width: "fit-content"
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    backgroundColor: "rgba(58, 15, 125, 0.3)",
                                    padding: "10px 12px 10px 12px",
                                    borderRadius: "8px",
                                    color: "#3A0F7D",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    marginRight: "10px"
                                  }}
                                >
                                  Marketing
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    backgroundColor: "rgba(58, 15, 125, 0.3)",
                                    padding: "10px 12px 10px 12px",
                                    borderRadius: "8px",
                                    color: "#3A0F7D",
                                    fontWeight: "400",
                                    fontSize: "14px"
                                  }}
                                >
                                  Digital Marketing
                                </p>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className={"pl-md-5"}
                      style={{ justifyContent: "center", marginTop: "23px" }}
                    >
                      <Col sm="12">
                        <Row style={{}}>
                          <Col md="5" className={"pr-md-0"}>
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Weekly Availability
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                disabled={disabled}
                                placeholder="10 - 20 hours per week"
                                type="text"
                                style={{ backgroundColor: "#EFEFEF" }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className={"pl-lg-0"} md="1"></Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className={"pl-md-5 mr-md-4"}
                      style={{ justifyContent: "center", marginTop: "23px" }}
                    >
                      <Col sm="12">
                        <Row style={{}}>
                          <Col md="12">
                            <Label
                              style={{
                                color: "#808080",
                                padding: "0px",
                                marginBottom: "7px",
                                fontWeight: "600"
                              }}
                              sm="6"
                            >
                              Expert Bio
                            </Label>
                            <FormGroup className={{}}>
                              <Input
                                disabled={disabled}
                                placeholder="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                                type="textarea"
                                style={{
                                  maxWidth: "990px",
                                  height: "109px",
                                  backgroundColor: "#EFEFEF"
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card
              className="card-plain pl-lg-5"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardHeader style={{ paddingLeft: "0px" }}>
                <Label
                  className="pl-3"
                  style={{
                    color: "#808080",
                    padding: "0px",
                    fontWeight: "600"
                  }}
                  sm="6"
                >
                  Primary Expertise
                </Label>
              </CardHeader>
              <CardBody className="d-sm-flex pl-3">
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    Marketing
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    Digital Marketing
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    SEO
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    Email Strategy
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card
              className="card-plain pl-lg-5"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <CardHeader style={{ paddingLeft: "0px" }}>
                <Label
                  className="pl-3"
                  style={{
                    color: "#808080",
                    fontWeight: "600",
                    fontSize: "14px"
                  }}
                >
                  Secondary Expertise
                </Label>
              </CardHeader>
              <CardBody className="d-sm-flex pl-3">
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    Marketing
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    Digital Marketing
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    SEO
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "rgba(58, 15, 125, 0.3)",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      color: "#3A0F7D",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginRight: "10px",
                      width: "fit-content"
                    }}
                  >
                    Email Strategy
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card
              className="card-plain pl-lg-5"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                display: "flex"
              }}
            >
              <CardHeader className="pl-3">
                <Row className="justify-content-center justify-content-sm-between m-0">
                  <Label
                    style={{
                      color: "#808080",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginTop: "17px"
                    }}
                  >
                    Check payment history of this expert
                  </Label>
                  {/* <div style={{ backgroundColor: "red" }}> */}
                  <Button
                    className="btn-round text-capitalize"
                    color="secondary"
                    style={{
                      backgroundColor: "#3A0F7D",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                    }}
                    // onClick={toggle}
                  >
                    Refer Expert
                  </Button>
                  {/* </div> */}
                </Row>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ExpertBio
