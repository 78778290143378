export const GET_ACTIVE_ENGAGEMENT_REQUEST = "GET_ACTIVE_ENGAGEMENT_REQUEST"
export const GET_ACTIVE_ENGAGEMENT_SUCCESS = "GET_ACTIVE_ENGAGEMENT_SUCCESS"
export const GET_ACTIVE_ENGAGEMENT_FALUIRE = "GET_ACTIVE_ENGAGEMENT_FALUIRE"

export const PATCH_ACTIVE_ENGAGEMENT_REQUEST = "PATCH_ACTIVE_ENGAGEMENT_REQUEST"
export const PATCH_ACTIVE_ENGAGEMENT_SUCCESS = "PATCH_ACTIVE_ENGAGEMENT_SUCCESS"
export const PATCH_ACTIVE_ENGAGEMENT_FALUIRE = "PATCH_ACTIVE_ENGAGEMENT_FALUIRE"

export const PATCH_END_ENGAGEMENT_REQUEST = "PATCH_END_ENGAGEMENT_REQUEST"
export const PATCH_END_ENGAGEMENT_SUCCESS = "PATCH_END_ENGAGEMENT_SUCCESS"
export const PATCH_END_ENGAGEMENT_FALUIRE = "PATCH_END_ENGAGEMENT_FALUIRE"

export const GET_ENGAGEMENT_HISTORY_REQUEST = "GET_ENGAGEMENT_HISTORY_REQUEST"
export const GET_ENGAGEMENT_HISTORY_SUCCESS = "GET_ENGAGEMENT_HISTORY_SUCCESS"
export const GET_ENGAGEMENT_HISTORY_FALUIRE = "GET_ENGAGEMENT_HISTORY_FALUIRE"

export const CREATE_ADMIN_ENGAGEMENT_REQUEST = "CREATE_ADMIN_ENGAGEMENT_REQUEST"
export const CREATE_ADMIN_ENGAGEMENT_SUCCESS = "CREATE_ADMIN_ENGAGEMENT_SUCCESS"
export const CREATE_ADMIN_ENGAGEMENT_FALUIRE = "CREATE_ADMIN_ENGAGEMENT_FALUIRE"

export const GET_ADMIN_EXPERT_SEARCH_REQUEST = "GET_ADMIN_EXPERT_SEARCH_REQUEST"
export const GET_ADMIN_EXPERT_SEARCH_SUCCESS = "GET_ADMIN_EXPERT_SEARCH_SUCCESS"
export const GET_ADMIN_EXPERT_SEARCH_FALUIRE = "GET_ADMIN_EXPERT_SEARCH_FALUIRE"

export const GET_ADMIN_BUSINESS_SEARCH_REQUEST =
  "GET_ADMIN_BUSINESS_SEARCH_REQUEST"
export const GET_ADMIN_BUSINESS_SEARCH_SUCCESS =
  "GET_ADMIN_BUSINESS_SEARCH_SUCCESS"
export const GET_ADMIN_BUSINESS_SEARCH_FALUIRE =
  "GET_ADMIN_BUSINESS_SEARCH_FALUIRE"

export const GET_ENGAGEMENT_OPPORTUNITY = "GET_ENGAGEMENT_OPPORTUNITY"
export const GET_ENGAGEMENT_OPPORTUNITY_SUCCESS =
  "GET_ENGAGEMENT_OPPORTUNITY_SUCCESS"
export const GET_ENGAGEMENT_OPPORTUNITY_FALUIRE =
  "GET_ENGAGEMENT_OPPORTUNITY_FALUIRE"
