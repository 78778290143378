import React, { useEffect, useState } from "react"
import moment from "moment"

import { connect } from "react-redux"
// import { compareAsc, format } from "date-fns"
import PaginationPack from "components/PaginationPack"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  Col,
  Table,
  Label,
  Form,
  FormGroup,
  Spinner
} from "reactstrap"
import Select from "react-select"
import { Drawer } from "@mui/material"

import toast, { Toaster } from "react-hot-toast"
import Images from "utils/Images"
import "./style.css"
import {
  getBillingRequest,
  filterSuccess,
  getBillingCSV,
  getExpertUser
} from "./redux/actions"
function BillingHistory(props) {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [multiData, setMultiData] = useState(false)
  const [selectLabels, setSelectLabels] = useState("")
  const [formatedDate, setFormatedDate] = useState(false)

  const handlePageChange = page => {
    // resourcesRequest(page)
    props.getBillingRequest({
      start_date: "",
      end_date: "",
      name: "",
      page: page
    })
  }

  const {
    billingHistory,
    requesting,
    billingPeriod,
    getBillingRequest,
    filterSuccess,
    filteredEngagements,
    getBillingCSV,
    csvRequesting,
    expertUsers
  } = props

  const onFilter = () => {
    const apiData = {
      start_date: startDate ? `${moment(startDate).format("yyyy-MM-DD")}` : "",
      end_date: endDate ? `${moment(endDate).format("yyyy-MM-DD")}` : "",
      name: selectLabels?.label ? selectLabels?.label : "",
      page: "1"
    }

    getBillingRequest(apiData)
  }
  const onClearFilter = () => {
    const apiData = {
      start_date: "",
      end_date: "",
      name: "",
      page: "1"
    }
    getBillingRequest(apiData)
    // toggle()
    setStartDate("")
    setEndDate("")
    setSelectLabels("")
    setShow(false)
    setShow5(false)
  }
  useEffect(() => {
    filterSuccess && setOpen(false)
  }, [filterSuccess])

  useEffect(() => {
    getBillingRequest({ start_date: "", end_date: "", name: "", page: "1" })
    props.getExpertUser()
  }, [])

  useEffect(() => {
    let tempArr = []
    expertUsers &&
      expertUsers.map(item => {
        tempArr.push({ value: item.expert_name, label: item?.expert_name })
      })
    setMultiData(tempArr)
  }, [expertUsers])

  useEffect(() => {
    expertUsers && setOpen(false)
  }, [expertUsers])

  const [open, setOpen] = useState(false)

  const [modal, setModal] = useState(false)
  const [show, setShow] = useState()
  const toggle1 = () => {
    setShow(!show)
  }
  const toggle = () => {
    setOpen(!open)
    setStartDate("")
    setEndDate("")
    setSelectLabels("")
  }
  const [isAnyFilter, setAnyFilter] = useState(false)
  const [engagement, setEngagement] = useState("")
  const [show5, setShow5] = useState()
  const toggle5 = () => setShow5(!show5)

  // import "./style.scss"

  // import ReactPaginate from 'react-paginate';
  const Applybtn = () => {
    if (
      {
        start_date: startDate,
        end_date: (endDate = `${moment(endDate).format("yyyy-DD-MM")}`),
        name: selectLabels?.label,
        page: "1"
      }
    ) {
      setShow(show)
      setShow5(show5)
    } else {
      setShow(!show)
      setShow5(!show5)
    }
  }
  const handleExportCSV = () => {
    const apiData = {
      start_date: startDate ? `${moment(startDate).format("yyyy-MM-DD")}` : "",
      end_date: endDate ? `${moment(endDate).format("yyyy-MM-DD")}` : "",
      name: selectLabels?.label ? selectLabels?.label : ""
    }
    getBillingCSV(apiData)
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      onClick={() => props.history.goBack()}
                      style={{
                        height: "40px",
                        width: "40px",
                        marginRight: "19px",
                        cursor: "pointer"
                      }}
                      src={require("assets/img/backbtn.png")}
                    />
                    <CardTitle
                      tag="h4"
                      style={{
                        fontFamily: "Libre Caslon Text",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#CACACA"
                      }}
                    >
                      Billing History
                    </CardTitle>
                  </div>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px"
                    }}
                  >
                    <Button
                      onClick={() => handleExportCSV()}
                      className="btn-round btn px-2 py-3 text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        width: "220px",
                        height: "42px",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px"
                      }}
                    >
                      {csvRequesting ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          <img
                            style={{
                              height: 13,
                              marginRight: "13px",
                              borderRadius: "0px"
                            }}
                            src={require("assets/img/LogLight.png")}
                          />
                          Export Excel, CSV
                        </>
                      )}
                    </Button>
                    <Button
                      onClick={() => setOpen(true)}
                      className="btn-round btn   text-capitalize"
                      style={{
                        backgroundColor: "#fff",
                        color: "#3A0F7D",
                        border: "1px solid  #3A0F7D",
                        // width: "220px",
                        height: "42px",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px"
                      }}
                    >
                      <img
                        style={{
                          height: 13,
                          // width: "12px",
                          // marginLeft: "20px",
                          marginRight: "6px"
                        }}
                        src={require("assets/img/filtericon.png")}
                      />
                      Filter
                    </Button>
                  </Row>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Table
                    responsive="md"
                    style={{
                      border: "3px solid transparent",
                      borderRadius: "8px"
                    }}
                  >
                    <thead>
                      <tr className="w-100">
                        <th
                          style={{
                            color: "#808080",
                            padding: "25px",
                            backgroundColor: "#f4f3ef"
                          }}
                          // className="w-25"
                        >
                          Experts
                        </th>
                        <th
                          className="text-left"
                          style={{
                            color: "#808080",
                            width: "20%",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Engagement Name
                        </th>
                        <th
                          className="text-left"
                          style={{
                            color: "#808080",
                            width: "10%",
                            backgroundColor: "#f4f3ef"
                          }}
                        >
                          Hours
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            width: "10%",
                            backgroundColor: "#f4f3ef",
                            textAlign: "left"
                          }}
                        >
                          Rate
                        </th>
                        <th
                          style={{
                            color: "#808080",
                            width: "10%",
                            backgroundColor: "#f4f3ef",
                            textAlign: "left"
                          }}
                        >
                          Total
                        </th>
                        <th
                          style={{
                            color: "#808080",

                            backgroundColor: "#f4f3ef"
                          }}
                          className="text-left w-25 pr-lg-5"
                        >
                          Time Frame
                        </th>
                      </tr>
                    </thead>
                    {props.requesting ? (
                      <div
                        style={{
                          position: "absolute",
                          top: 150,
                          transform: "translate(-50%, -50%)",
                          left: "50%"
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          style={{}}
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <tbody>
                        {billingHistory &&
                        billingHistory?.results.length > 0 ? (
                          billingHistory?.results.map(item => (
                            <>
                              <tr
                                style={{
                                  backgroundColor: "white",
                                  marginLeft: "25px",
                                  border: "3px solid transparent"
                                }}
                              >
                                <td
                                  style={{
                                    padding: "25px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.expert_name}
                                </td>
                                <td
                                  style={{
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.description}
                                </td>
                                <td className="text-left pr-lg-3">
                                  {item.total_hours}
                                </td>
                                <td className="text-left">
                                  ${item.business_rate}/hr
                                </td>
                                <td className="text-left">
                                  {item?.total && `$${item?.total}`}
                                </td>
                                <td
                                  className="text-left pr-lg-5"
                                  style={{
                                    // paddingRight: "52px",
                                    paddingLeft: "10px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                  }}
                                >
                                  {moment(item.billing_start_date).format(
                                    "DD MMM -"
                                  )}{" "}
                                  {moment(item.billing_end_date).format(
                                    "DD MMM "
                                  )}
                                </td>
                              </tr>
                              <tr style={{ height: "12px" }}></tr>
                            </>
                          ))
                        ) : (
                          <>
                            <div
                              style={{
                                position: "absolute",
                                top: "100%",
                                transform: "translate(-50%, -50%)",
                                left: "50%"
                              }}
                            >
                              <h6 style={{ textAlign: "center" }}>
                                No Data Found
                              </h6>
                            </div>
                          </>
                        )}
                      </tbody>
                    )}
                  </Table>
                </div>
                <div className="pt-4 d-flex justify-content-center">
                  {billingHistory && billingHistory?.results.length > 0 ? (
                    props.requesting ? null : (
                      <div className="pt-4 d-flex justify-content-center">
                        <PaginationPack
                          onChange={handlePageChange}
                          itemsCountPerPage={billingHistory?.page_size}
                          totalItemsCount={billingHistory?.total_objects}
                          activePage={billingHistory?.current_page_number}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div class="container demo"></div>
      </div>
      <Drawer
        classes="mt-3"
        variant="temporary"
        // anchor="right"

        // variant="right"
        open={open}
        anchor={"right"}
        onClose={() => toggle()}
        style={{ marginTop: 20 }}
      >
        <div className=" " style={{ borderRadius: "0px", width: 275 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "20px"
            }}
          >
            <h5
              style={{
                color: "#CACACA",
                fontSize: "22px",
                fountFimly: "Libre Caslon Text",
                fontWeight: "700",
                marginRight: "10px"
              }}
            >
              Filter
            </h5>
            <img
              onClick={() => toggle()}
              style={{
                height: 15,
                width: 15,
                marginTop: "-10px",
                marginLeft: "20px",
                cursor: "pointer"
              }}
              src={require("assets/img/cros.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "80vh"
            }}
          >
            <div style={{}}>
              <div
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                  paddingBottom: 10
                }}
                className="my-2"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Label
                    style={{
                      fontSize: 15,
                      fontFamily: "khula"
                    }}
                  >
                    Date
                  </Label>

                  <img
                    style={{
                      height: 15,
                      width: 15,
                      cursor: "pointer",

                      marginLeft: "20px"
                    }}
                    onClick={toggle1}
                    src={show == true ? Images.mines : Images.plus}
                  />
                </div>

                {show && (
                  <>
                    <Form>
                      <FormGroup>
                        <Label
                          style={{
                            color: "#808080",
                            fontSize: 14,
                            fontFamily: "khula",
                            fontWeight: "600",
                            marginTop: 14
                          }}
                        >
                          From
                        </Label>

                        <Input
                          placeholder="Expert Name goes here.."
                          type="date"
                          style={{ color: "#000000" }}
                          value={startDate}
                          onChange={e => {
                            setStartDate(e.target.value)
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          style={{
                            color: "#808080",
                            fontSize: 14,
                            fontFamily: "khula",
                            fontWeight: "600",
                            marginTop: 10
                          }}
                        >
                          To
                        </Label>
                        <Input
                          id="exampleText"
                          name="text"
                          type="date"
                          min={startDate}
                          value={endDate}
                          onChange={e => {
                            setEndDate(e.target.value)
                            // setAnyFilter(true)
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </>
                )}
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                  paddingBottom: 10
                }}
                className="my-2"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Label
                    style={{
                      fontSize: 15,
                      fontFamily: "khula",
                      margin: 0
                    }}
                  >
                    Expert
                  </Label>

                  <img
                    // onClick={toggle}
                    style={{
                      height: 15,
                      width: 15,

                      marginLeft: "20px",
                      cursor: "pointer"
                      // marginRight: "12px"
                    }}
                    onClick={toggle5}
                    src={show5 == true ? Images.mines : Images.plus}
                  />
                </div>

                {show5 && (
                  <>
                    <Form>
                      <FormGroup>
                        <Label
                          style={{
                            color: "#808080",
                            fontSize: 14,
                            fontFamily: "khula",
                            fontWeight: "600",
                            marginTop: 14
                          }}
                        >
                          Expert Name
                        </Label>

                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          isSearchable={false}
                          value={selectLabels}
                          onChange={value => {
                            setSelectLabels(value)
                            // setAnyFilter(true)
                          }}
                          // onChange={e => {
                          //   setSelectLabels(`${e.map(a => a.value)}`)
                          // }}
                          options={multiData}
                          placeholder="Select from below"
                        />
                      </FormGroup>
                    </Form>
                  </>
                )}
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#fff"
              }}
            >
              <div
                className="mx-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //  position:"absolute",

                  paddingBottom: 20
                }}
              >
                {}
                <Button
                  onClick={() => {
                    // handleDateClear()
                    onClearFilter()
                  }}
                  className="btn-round btn text-capitalize
              "
                  style={{
                    backgroundColor: "#fff",
                    color: "#3A0F7D",
                    marginTop: 0,
                    //   border: "1px solid  #3A0F7D",
                    marginRight: "11px",
                    //   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    display: "flex",

                    alignItems: "center",
                    marginLeft: "0px",
                    marginRight: "0px"
                  }}
                >
                  <img
                    // onClick={toggle}
                    style={{
                      height: 10,
                      width: 10,

                      marginRight: "5px"
                    }}
                    src={require("assets/img/cross.png")}
                  />
                  Clear{" "}
                </Button>

                <Button
                  onClick={() => {
                    // handleFilterConfirm()
                    // setOpen(false)
                    onFilter()
                    // Applybtn
                  }}
                  className="btn-round text-capitalize
              "
                  // color="secondary"
                  style={{
                    marginTop: 0,

                    backgroundColor: "#ffff",
                    border: "1px solid #3A0F7D",
                    color: "#3A0F7D",
                    //   width: "154px",
                    //   height: "49px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                  }}
                >
                  {props.filterRequesting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Apply"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.BillingHistory.requesting,
  billingHistory: state.BillingHistory.billingHistory,
  billingPeriod: state.BillingHistory.billingPeriod,
  filterRequesting: state.BillingHistory.filterRequesting,
  filterSuccess: state.BillingHistory.filterSuccess,
  filteredEngagements: state.LogHour.filteredEngagements,
  csvRequesting: state.BillingHistory.csvRequesting,
  expertUsers: state.BillingHistory.expertUsers
})
const mapDispatchToProps = dispatch => ({
  getBillingRequest: (data, toggle) =>
    dispatch(getBillingRequest(data, toggle)),
  getBillingCSV: data => dispatch(getBillingCSV(data)),
  getExpertUser: data => dispatch(getExpertUser(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory)
